import { Component, ElementRef, OnInit, ViewChild,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-ecommerce-detallearticulo',
  templateUrl: './ecommerce-detallearticulo.component.html',
  styleUrls: ['./ecommerce-detallearticulo.component.css']
})
export class EcommerceDetallearticuloComponent implements OnInit {
  @ViewChild('mdlCambPrecio') btnCambPrecio!: ElementRef;
  public strRuta_Origen: string = "";
  private cartSubscription: Subscription;
  private envioSubscription: Subscription;
  public strNombre_ruta : string = ""; 
  public bloques: any[] = [];
  public arrayLineas: any = [];
  public elementosPorPagina: number = 5;
  public strConexion: string = 'COMERCIAL';
  public items: any = [];
  public objArticulo:any={};
  public strNumCte:string;
  public objData_Art:any={};
  public strAlmacen:string='1';
  public strEstatus_Almacen:string='';
  public arrayPrecio:any=[];
  public arrayOpciones:any=[];
  public strOpcion_Selected:string='';
  public dblPrecio_Selected:number=0;
  public dblDisponible_nacional:number=0;
  public  dblReservado_nacional:number=0; 
  public  objDisponible_almacen:any={};
  public arraySaldou:any=[];
  public arrayRelacionados: any=[];
  public arraySustitutos:any=[];
  public arraySusPrincipal:any=[];
  public arrayRelacionadosPrincipal:any=[];
  public arrayCompararArts:any=[];
  public tagName:any;
  public arrayAccesos:any=[];
  public boolBtn_Comparar:boolean=false;
  public boolBtn_CPrecion:boolean=false
  public boolBtn_Precio4:boolean=false;
  public arraySaldoUFilter:any =[];
  public boolBtn_Costo:any =false;
  public cartItems:any =[];
  public strLinea:string="";
  public dblEnvio:number=0;
  private secretKey: string = 'ec0d3li2o2e';
  public boolMostrar_agotado:boolean =false;
  activeSlide = 0;
  public objCliente: any = {
    strCliente: "",
    strNombre: "",
    strDireccion: "",
    ClienteSeleccionado: false,
    strSucursal: "",
    NombreSucursal: "",
    tipoSucursal: ""
  };
 
  public objOpcion:any={};
  value: number = 0;
  itemsPerSlide = 5;
  visibleItems: any[] = [];

  constructor( private route: ActivatedRoute,elem:ElementRef, private router: Router, public _alertify: AlertifyService,private ecommerService: EcommerceCarrService,private _httpService: PeticionesService,private localSt: LocalStorageService) { 
    this.route.queryParams.subscribe(params => {
      const decryptedData = this.decryptData(params['data']);
      if (params['data']) {
        this.objArticulo = JSON.parse(decryptedData);
       
      } 
    });
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.strAlmacen = '1'; 
    this.strLinea =this.objArticulo.strGrupo
  }

  ngOnInit(): void {
    this.fnGetDetalle();
    this.fnGetSustitutos();
    this.fnGetRelacionados();
    this.objArticulo.strUrlImg1 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' ;
    this.objArticulo.strUrlImg_principal = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' ;
    this.objArticulo.strUrlImg2 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-2.JPG' ;
    this.objArticulo.strUrlImg3 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-3.JPG' ;
    this.objArticulo.strUrlImg4 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-4.JPG' ;
    this.objArticulo.strUrlImg5 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-5.JPG' ;
  this.items = [
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' }
  ];



    this.cartSubscription = this.ecommerService.getCartItems().subscribe((items) => {
      this.cartItems = items;
        this.cartItems.forEach(prod => {
          let opcion:any;
          if(prod.strTipoOpcion=='Si'){ 
          opcion = prod.arrayOpciones.find(o => o.strOpcion == prod.strOpcion)
          prod.strNombreOpcion = opcion.strNombreOpcion
          }
        });
    });



  }

  fnAdd_Rest_Amount(objArt: any, tipo: string) {
     

    if (tipo == 'add') {
      objArt.dblCantidad = objArt.dblCantidad + 1;
    } else {
      objArt.dblCantidad = objArt.dblCantidad - 1;
    }

    if(objArt.strEstatus == "DESCONTINUADO"){
      let maxCantidad = this.dblDisponible_nacional - this.dblReservado_nacional;
      let FindArt = this.cartItems.find(o => o.strArticulo == objArt.strArticulo && objArt.strOpcion == o.strOpcion)
  
    if(FindArt){
      let disponible = maxCantidad - FindArt.dblCantidad
      if(objArt.dblCantidad  > disponible){
          objArt.dblCantidad = disponible;  
      }
    }else{
        if (objArt.dblCantidad  > maxCantidad) {
          objArt.dblCantidad = maxCantidad;  
        }
      }
    }

  }
fnGetRelacionados(){
  let articulo = {
    strAccion: "getRelacionados",  
    strConexion: this.strConexion,
    strArticulo:this.objArticulo.strArticulo 
  };
  this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
    result => {
     /*  arrayRelacionadosPrincipal */ 
      this.arrayRelacionados=result; 

      let CantArts = 0,cantPags = 0, art=0;
      this.arrayRelacionadosPrincipal =[];
      this.arrayRelacionados = result; 
      CantArts = this.arrayRelacionados.length 
      cantPags =  Math.ceil(CantArts / 4)
      for(let i = 0; i< cantPags ;i++){
        this.arrayRelacionadosPrincipal[i] = { arts: [] };
        this.arrayRelacionadosPrincipal[i].arts.push({
          strGrupo: this.arrayRelacionados[art]?.strGrupo,
          strCategoria: this.arrayRelacionados[art]?.strCategoria,
          strLinea: this.arrayRelacionados[art]?.strLinea,
          strArticulo: this.arrayRelacionados[art]?.strArticulo,
          url: this.arrayRelacionados[art] && this.arrayRelacionados[art].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arrayRelacionados[art]?.strDescripcion1
        });
        
        this.arrayRelacionadosPrincipal[i].arts.push({
          strGrupo: this.arrayRelacionados[art + 1]?.strGrupo,
          strCategoria: this.arrayRelacionados[art + 1]?.strCategoria,
          strLinea: this.arrayRelacionados[art + 1]?.strLinea,
          strArticulo: this.arrayRelacionados[art + 1]?.strArticulo,
          url: this.arrayRelacionados[art + 1] && this.arrayRelacionados[art + 1].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art + 1].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arrayRelacionados[art+1]?.strDescripcion1
        });
      
        this.arrayRelacionadosPrincipal[i].arts.push({
          strGrupo: this.arrayRelacionados[art + 2]?.strGrupo,
          strCategoria: this.arrayRelacionados[art + 2]?.strCategoria,
          strLinea: this.arrayRelacionados[art + 2]?.strLinea,
          strArticulo: this.arrayRelacionados[art + 2]?.strArticulo,
          url: this.arrayRelacionados[art + 2] && this.arrayRelacionados[art + 2].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art + 2].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arrayRelacionados[art+2]?.strDescripcion1
        });
      
        this.arrayRelacionadosPrincipal[i].arts.push({
          strGrupo: this.arrayRelacionados[art + 3]?.strGrupo,
          strCategoria: this.arrayRelacionados[art + 3]?.strCategoria,
          strLinea: this.arrayRelacionados[art + 3]?.strLinea,
          strArticulo: this.arrayRelacionados[art + 3]?.strArticulo, 
          url: this.arrayRelacionados[art + 3] && this.arrayRelacionados[art + 3].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art + 3].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
             strDescripcion:this.arrayRelacionados[art+3]?.strDescripcion1
        });
        art += 4;
      }
    });
}
fnGetSustitutos(){
  let articulo = {
    strAccion: "getSustitutos",  
    strConexion: this.strConexion,
    strArticulo:this.objArticulo.strArticulo 
  };
  console
  this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
    result => { 
      let CantArts = 0,cantPags = 0, art=0;
      this.arraySusPrincipal =[];
      this.arraySustitutos = result; 
      CantArts = this.arraySustitutos.length 
      cantPags =  Math.ceil(CantArts / 4)
      for(let i = 0; i< cantPags ;i++){
        this.arraySusPrincipal[i] = { arts: [] };
        this.arraySusPrincipal[i].arts.push({
          strGrupo: this.arraySustitutos[art]?.strGrupo,
          strCategoria: this.arraySustitutos[art]?.strCategoria,
          strLinea: this.arraySustitutos[art]?.strLinea,
          strArticulo: this.arraySustitutos[art]?.strArticulo,
          dblPrecio: this.arraySustitutos[art]?.dblPrecioLista,
          url: this.arraySustitutos[art] && this.arraySustitutos[art].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art]?.strDescripcion1
        });
        
        this.arraySusPrincipal[i].arts.push({
          strGrupo: this.arraySustitutos[art+ 1]?.strGrupo,
          strCategoria: this.arraySustitutos[art+ 1]?.strCategoria,
          strLinea: this.arraySustitutos[art+ 1]?.strLinea,
          strArticulo: this.arraySustitutos[art + 1]?.strArticulo,
          url: this.arraySustitutos[art + 1] && this.arraySustitutos[art + 1].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art + 1].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art+1]?.strDescripcion1
        });
      
        this.arraySusPrincipal[i].arts.push({
          strGrupo: this.arraySustitutos[art+ 2]?.strGrupo,
          strCategoria: this.arraySustitutos[art+ 2]?.strCategoria,
          strLinea: this.arraySustitutos[art+ 2]?.strLinea,
          strArticulo: this.arraySustitutos[art + 2]?.strArticulo,
          url: this.arraySustitutos[art + 2] && this.arraySustitutos[art + 2].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art + 2].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art+2]?.strDescripcion1
        });
      
        this.arraySusPrincipal[i].arts.push({
          strGrupo: this.arraySustitutos[art+ 3]?.strGrupo,
          strCategoria: this.arraySustitutos[art+ 3]?.strCategoria,
          strLinea: this.arraySustitutos[art+ 3]?.strLinea,
          strArticulo: this.arraySustitutos[art + 3]?.strArticulo, 
          url: this.arraySustitutos[art + 3] && this.arraySustitutos[art + 3].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art + 3].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art+3]?.strDescripcion1
        });
        art += 4;
      }
    });
}
  fnAddToCarr(objArticulo: any,boolAhora:boolean=false) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
    if(this.boolMostrar_agotado){
      Swal.fire("Error","El producto se encuentra agotado!","error")
    }else{
   //agregamos la imagen seleccionada
   objArticulo.strUrlImg = this.objArticulo.strUrlImg1;

   console.log(objArticulo.strUrlImg)
   objArticulo.strOpcion=this.strOpcion_Selected;
   objArticulo.arrayOpciones=this.arrayOpciones; 
   objArticulo.intPrecioLista=objArticulo.dblPrecioLista;
   objArticulo.intPrecio =objArticulo.dblPrecioLista;
//si no tiene permisos para ver el precio 4 no lo podemos agregar para que no se muestre en carrito resumen
    

   objArticulo.strComentario = "";
  // this.spinerCarrito = true;
   const deepCopy = JSON.parse(JSON.stringify(objArticulo));

   this.ecommerService.addToCart(deepCopy);
   setTimeout(() => {
    // this.spinerCarrito = false;
    if(!boolAhora){
     this._alertify.success("Articulo agregado correctamente");
    }else{
     this.router.navigate(['/tienda-carrito']);
    }
     
   }, 400);
    }
 
  }
  fnCambio_opcion(){ //'https://www.cleanclean.mx/imagenes/'+product.strArticulo +'.JPG'
    //inicializar como principal la imagen de la opcion seleccionada
    this.objArticulo.strUrlImg1 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-'+this.strOpcion_Selected+'.JPG' ;
    console.log(this.objArticulo.strUrlImg1)

    this.objData_Art.strOpcion= this.strOpcion_Selected;
    this.fnGetEstatus_Almacen();
    this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
    //cambia lo disponible nacional de acuerdo a la opcion
    let nacional_opcion =[]
    nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
    this.dblDisponible_nacional=0;
    this.dblReservado_nacional=0;
    nacional_opcion.forEach(element => {
      let disponible=element.dblCantidad ?element.dblCantidad :0;
      let reservado =element.dblReservado ?element.dblReservado :0;
      this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
      this.dblReservado_nacional=this.dblReservado_nacional +reservado;
     });
     this.objOpcion = this.arrayOpciones.find(x => x.strOpcion == this.objData_Art.strOpcion);
     this.fnFiltrarSaldou(); 
  }
  fnGetOpciones(){
    let articulo = {
      strAccion: "getOpcion",  
      strConexion: this.strConexion,
      strBusqueda:this.objArticulo.strArticulo 
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => { 
        this.arrayOpciones=result;
        this.strOpcion_Selected = (this.arrayOpciones && this.arrayOpciones.length > 0 && this.arrayOpciones[0].strOpcion) ? this.arrayOpciones[0].strOpcion : "";
        this.objOpcion = this.arrayOpciones.find(x => x.strOpcion == this.objData_Art.strOpcion);
      });
  }

  fnGetDetalle(){
    let articulo = {
      strAccion: "getDataArt", 
      strCliente:  (this.objCliente.strCliente) ? this.objCliente.strCliente : '',
      strCteFam:   (this.objCliente.strCteFam) ? this.objCliente.strCteFam : '',
      strConexion: this.strConexion,
      strBusqueda:this.objArticulo.strArticulo
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => { 
        console.log(result[0]);
        this.objData_Art= result[0];
        this.objData_Art.dblCantidad=1;
        let partes = this.objData_Art.strDescripcion.split('•').map(item => item.trim()).filter(item => item !== '');
        this.objData_Art.strDescripcionArray = partes;
        this.strOpcion_Selected = this.objData_Art.strOpcion &&  this.objData_Art.strOpcion !='' ?this.objData_Art.strOpcion:'';
        this.dblPrecio_Selected = this.objData_Art.dblPrecioLista
        this.fnGetEstatus_Almacen(); //se ponen aqui porque dependen del parametro stropcion que devuelve esta funcion
        this.fnGetDisponible(); //se ponen aqui porque dependen del parametro stropcion que devuelve esta funcion
      if(this.objData_Art.strTipoOpcion == "Si"){
        this.fnGetOpciones();
      }
    });
  }
  fnGetEstatus_Almacen(){
    let articulo = {
      strAccion: "getEstatusAlm",  
      strConexion: this.strConexion,
      strArticulo:this.objArticulo.strArticulo ,
      strOpcion:this.objData_Art.strOpcion ? this.objData_Art.strOpcion :null,
      strAlmacen:this.strAlmacen
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => {
        this.strEstatus_Almacen=result[0]?result[0].strEstatusAlmacen:'';
        
      });
  }
  fnFiltrarSaldou(){ 
    this.arraySaldoUFilter = this.strOpcion_Selected !=''?this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) : this.arraySaldou;
    this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
          
    this.boolMostrar_agotado = this.objDisponible_almacen.dblCantidad &&  this.objDisponible_almacen.dblCantidad >0 ? false:true;
  }
  fnGetDisponible(){
    this.boolMostrar_agotado = false;
    this.arraySaldou =[] 
    let articulo = {
      strAccion: "getDisponible",
      strConexion: this.strConexion,
      strArticulo:this.objArticulo.strArticulo
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => {  
          this.arraySaldou = result; 
          this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
          
          this.boolMostrar_agotado = this.objDisponible_almacen.dblCantidad &&  this.objDisponible_almacen.dblCantidad >0 ? false:true;
          
          console.log(this.objDisponible_almacen );
         
          this.dblDisponible_nacional=0
          this.dblReservado_nacional=0
          let nacional_opcion =[] 
          nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
          nacional_opcion.forEach(element => {
            
            let disponible=element && element.dblCantidad ?element.dblCantidad :0;
            let reservado =element && element.dblReservado ?element.dblReservado :0;
            this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
            this.dblReservado_nacional=this.dblReservado_nacional +reservado;
            this.fnFiltrarSaldou()
          }); 
      });
  }

   chunkArray(array: any[], size: number): any[] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  increment() {
    this.value++;
  }

  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }

  onImageError(event: any) {
 
    event.target.src = 'assets/detoportunidad/noDisponible.jpg';
      /*  
  /*  event.target.src = (this.objArticulo.strUrlImg_principal != '' && this.strOpcion_Selected !='' ) ?this.objArticulo.strUrlImg_principal : 'assets/detoportunidad/noDisponible.jpg'; // Ruta de tu imagen local en los assets
    this.objArticulo.strUrlImg1 =this.strOpcion_Selected !=''?this.objArticulo.strUrlImg_principal: 'assets/detoportunidad/noDisponible.jpg';
  */
  }

  addToCart(obj:any){ 
    const objString = JSON.stringify(obj); 
    const encryptedData = this.encryptData(objString);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/tienda-art'],{ 
        queryParams: { data: encryptedData } 
      });})
  }

  oncategoria(){
    this.router.navigate(['/tienda-categoria'], { 
      queryParams: { strCategoria: this.objArticulo.strCategoria }    });
  }
  onCardClick(){
    this.router.navigate(['/tienda-sub-categoria'], {
      queryParams: {strLinea:this.objArticulo.strCategoria,subLinea:this.objArticulo.strGrupo,tipoBusqueda:"",strArticulo:""}
    });
  }

  validarCantidad(art: any, input: boolean = false, event: Event) {
    let inputElement = event.target as HTMLInputElement;
      let valorIngresado = parseInt(inputElement.value);
      if (isNaN(valorIngresado) || inputElement.value.trim() === '') {
        inputElement.value = '1'; // Cambia el valor visualmente en el input
        art.dblCantidad = 1; // Ajusta el valor en el modelo de datos
        return;
      }
    if (art.strEstatus === 'DESCONTINUADO' && input) {
      
      let maxCantidad = this.dblDisponible_nacional - this.dblReservado_nacional;
  
  
      // Manejar el caso cuando el valor es vacío o NaN (después de presionar Delete)
     
  
      let FindArt = this.cartItems.find(o => o.strArticulo === art.strArticulo && art.strOpcion === o.strOpcion);
      if (FindArt) {
        let restante = maxCantidad - FindArt.dblCantidad;
        if (valorIngresado > restante) {
          inputElement.value = restante.toString(); // Ajusta visualmente el valor del input
          art.dblCantidad = restante; // Ajusta el valor en el modelo
        } else {
          art.dblCantidad = valorIngresado; // Actualiza el modelo con el valor actual
        }
      } else {
        if (valorIngresado > maxCantidad) {
          inputElement.value = maxCantidad.toString(); // Ajusta visualmente el valor del input
          art.dblCantidad = maxCantidad; // Ajusta el valor en el modelo
        } else {
          art.dblCantidad = valorIngresado; // Actualiza el modelo con el valor actual
        }
      }
    }
  }
  setActiveSlide(index: number) {
    this.activeSlide = index;
  }

  prevSlide() {
    this.activeSlide = (this.activeSlide - 1 + 5) % 5;
  }

  nextSlide() {
    this.activeSlide = (this.activeSlide + 1) % 5;
  }
  
 
/*   validarCantidad(art:any, input:boolean=false,event: Event){
    

 
 


    if(art.strEstatus == 'DESCONTINUADO' && input){
      let inputElement = event.target as HTMLInputElement;
    let maxCantidad = this.dblDisponible_nacional - this.dblReservado_nacional;
    let valorIngresado = parseInt(inputElement.value);
    let FindArt = this.cartItems.find(o => o.strArticulo == art.strArticulo && art.strOpcion == o.strOpcion)
 
    if(FindArt){
      let restante = maxCantidad - FindArt.dblCantidad
      if (valorIngresado > restante) {
        inputElement.value = restante.toString(); // Ajusta visualmente el valor del input
        art.dblCantidad = restante;  // Ajusta el valor en el modelo
      } else {
        art.dblCantidad = restante; // Actualiza el modelo con el valor actual
      }
    }else{
      if (valorIngresado > maxCantidad) {
        inputElement.value = maxCantidad.toString(); // Ajusta visualmente el valor del input
        art.dblCantidad = maxCantidad;  // Ajusta el valor en el modelo
      } else {
        art.dblCantidad = valorIngresado; // Actualiza el modelo con el valor actual
      }
    }
  } 
  }
 */
  fnDisponible():boolean{
    let maxCantidad = this.dblDisponible_nacional - this.dblReservado_nacional;
    let rest:boolean=false
    if(this.objData_Art.strEstatus=='DESCONTINUADO'){
      let FindArt = this.cartItems.find(o => o.strArticulo == this.objData_Art.strArticulo && this.objData_Art.strOpcion == o.strOpcion)
      if(FindArt){
        let disponible = maxCantidad - FindArt.dblCantidad
        if(this.objData_Art.dblCantidad > disponible){
          this.objData_Art.dblCantidad = 0
          rest = true;
        }else{
          rest = false;
        }
      }else{
        if(this.objData_Art.dblCantidad > maxCantidad){
          rest = true;
        }else{
          rest = false;
        }
      }
      
    }else{
      rest = false;
    }
    
    return rest;
  }
  preventPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
   preventDecimal(event: KeyboardEvent) {
    if (event.key === '.') {
      event.preventDefault(); // Previene la entrada del punto o "e" (si el input es numérico)
    }
  }

  encryptData(data: any): string {
    try {
      const stringifiedData = JSON.stringify(data); // Convertir objeto a string
      const encrypted = CryptoJS.AES.encrypt(stringifiedData, this.secretKey).toString();
      return encodeURIComponent(encrypted); // Codificar para URL
    } catch (error) {
      console.error('Error al encriptar datos:', error);
      return '';
    }
  }

  decryptData(encryptedData: string): any {
    try {
      const decodedData = decodeURIComponent(encryptedData); // Decodificar desde URL
      const bytes = CryptoJS.AES.decrypt(decodedData, this.secretKey);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted); // Convertir string de vuelta a objeto
    } catch (error) {
      console.error('Error al desencriptar datos:', error);
      return null;
    }
  }
}
 