import { Component, OnInit,ElementRef, ViewChild} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import * as bootstrap from 'bootstrap';
import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';
import { DataDBService } from '../servicios/data-db.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.css']
})
export class PlantillasComponent implements OnInit {
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  public boolShowExportar :boolean=false;
  public tituloart:string=""
  public arrayTipo:any=[{nombre :'--TODOS--' ,strTipo:''},{nombre :'CLIENTE',strTipo:'CLIENTE'},{nombre :'LEADS',strTipo:'LEADS'}];
  public strTipo:string='';
  public arrayCotizaciones:any=[];
  public strConexion:string="";
  public strArticulo:string = ""; 
  public arrayDetalleArts:any=[];
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[]; 
  public strGerente: string="";
  public arrayAccesos:any=[];
  public arrayDetPlantillas:any=[];
  public boolAddArt:boolean = false;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public tagName:any;
  public objCte:any={};
  public spiner:boolean=false;
  public spinerMdl:boolean=false; 
  public arrayOriginal:any=[];
  public strEstatus:string="POR AUTORIZAR"
  public arrayHistPrecios:any=[];
  public strTipo_plantilla:string='';
  public spinerHistPrecios:boolean=false;
  public boolBtnAutorizar: boolean=false;
  public boolBtnRechazar: boolean=false;
  public boolMostrarMsj_Aut:boolean=false;
  public arraySubcategorias:any =[];
  public arrayArticulos:any =[];
  public  strSubLinea:string ="";
  public comentarioRechazo:string="";
  public strAgente_crea :string =""
  currentPage: number = 1;
  itemsPerPage: number = 10;
  arrayItems =[10,25,50,100]
  @ViewChild('btncerrar') btnCerrarMdl!: ElementRef;
  @ViewChild('btnCerrarRechazo') btnCerrarRechazo!: ElementRef;

  public arrayEstatus:any =[{strEstatus:'AUTORIZADO'},{strEstatus:'POR AUTORIZAR'},{strEstatus:'RECHAZADO'}];
  constructor(private indexedDBService: DataDBService,public _alertify: AlertifyService,private carroservice:CarroService,elem:ElementRef,private localSt:LocalStorageService, private editPl:PlantillafinService,   private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) { 
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
    //array de accesos
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    }

  ngOnInit(): void {
    //obtener niveles de acceso 
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos=value?value:[];

          //this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
            if(this.arrayAccesos.length>0){
              var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
              
              if(objAcceso==undefined||objAcceso.length==0){
                this.router.navigate(['/login']);
              }
              else{
                this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
                this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
                this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
                this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
                this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
                this.strGerente = (!this.boolGerente && this.boolSucursal) ?objAcceso[0].strUsuario : ''; //this.localSt.retrieve('strUsuario') 
                this.boolBtnAutorizar= objAcceso.find((o: any) => o.strAccion == 'btnAutorizar') ? true : false;
                this.boolBtnRechazar = objAcceso.find((o: any) => o.strAccion == 'btnRechazar') ? true : false;
                this.strAgente_crea = this.localSt.retrieve('strAgente');
                this.fnGetFiltros();
                this.fnGetPlantillas();
              }
            }
            else {
              this.router.navigate(['/login']);
            }

          });
   
  }

 fnGetFiltros(){
  this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
    this.arrayPeriodo = nuevoValor;
  });

  this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
    this.arrayEjercicio = nuevoValor;
  });

  //agentes
  this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
//    console.log(nuevoValor);
    this.arrayAgentes = nuevoValor;
  });

  //sucursales
  this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
  //  console.log(nuevoValor);
    this.arraySucursales = nuevoValor;
  });

  //gerentes
  this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
  //  console.log(nuevoValor);
    this.arrayGerentes = nuevoValor;
  });
}

 
  fnGetPlantillas(){
    this.spiner = true;
    let obj = {
       strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
       strGerente:this.strGerente,
       strSucursal:this.strSucursal,
       intEjercicio: this.intEjercicio,
       intPeriodo: this.intPeriodo,
       strConexion:this.strConexion,
       strAccion:'getPlantillas',
       strEstatus: this.strEstatus
    };
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
      result => {  
        console.log(result)
        this.arrayCotizaciones = result
     
        this.spiner = false; 
    

      },
      error => {
        this.spiner = false;
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });

 
  } 
 
  fnCalculaPorcentaje(x:any){ 
   // x.dblPrecioSugerido  = (x.respaldo_sugerido * x.dblPorcentaje/100) + x.respaldo_sugerido ;
   x.dblPrecioSugerido = Number(((x.respaldo_sugerido * x.dblPorcentaje / 100) + x.respaldo_sugerido).toFixed(2));

  }
  fnGetDetallePlantilla (objVenta:any, opcion:number) {
    this.objCte = []
    this.spinerMdl = true;
    this.boolMostrarMsj_Aut=false; //ocultamos el mensaje de espera
    this.arrayDetPlantillas =[];
    this.objCte = objVenta;
    this.strTipo_plantilla = objVenta.strTipo
 
    let obj={ intIDMovimientoVenta: objVenta.intIDMovimientoVenta,
      strConexion:this.strConexion,
      strAccion:'getDetallePlantilla'
   }

   this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
     result => { 
       this.arrayDetPlantillas = result
       this.objCte.strFamilia = result[0].strFamilia
       this.objCte.strGiro = result[0].strGiro
       
       this.arrayDetPlantillas.forEach(element => {
        element.respaldo_sugerido = element.dblPrecioSugerido;
       });
       this.fnValidar_todo();
       if(opcion==2){
        this.fnSetCarrito(result, objVenta.intIDMovimientoVenta,objVenta,opcion);
        let objPlantilla = {boolActive:true, intIDMovimientoVenta:objVenta.intIDMovimientoVenta}
        this.editPl.setPlantilla(objPlantilla);
       }
       else if (opcion==3){
        this.fnSetCarrito(result, objVenta.intIDMovimientoVenta,objVenta,opcion);
        let objPlantilla = {boolActive:false, intIDMovimientoVenta:objVenta.intIDMovimientoVenta}
        this.editPl.setPlantilla(objPlantilla);
       }
       this.spinerMdl = false; 
     }, error => {
       console.error('Error al consumir la API:', error);
       this.spinerMdl = false;
       // Puedes manejar el error de alguna manera específica aquí
     });
  }
  fnEliminarlst(objVenta:any){
    this.arrayDetPlantillas =[];
    this.objCte = objVenta;
    let obj={ 
      intIDMovimientoVenta: objVenta.intIDMovimientoVenta,
      strConexion:this.strConexion,
      strAccion:'deletePlantilla'
   }
   this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
     result => { 
       console.log(result)
       if(result[0].code == "200"){
        Swal.fire({
          icon: "success",
          title: "Eliminado Correctamente",
          text:result[0].strMensaje
        });
        this.fnGetPlantillas();
       }else{
        Swal.fire({
          icon: "error",
          title: "Error al Eliminar",
          text:result[0].strMensaje
        });
       }
     }, error => {
       console.error('Error al consumir la API:', error);
       this.spinerMdl = false;
       // Puedes manejar el error de alguna manera específica aquí
     });
  }

  fechasComoDate(cotizacion): Date {
    const fecha = moment(cotizacion.strFecha, 'MM/DD/YYYY hh:mm:ss');
    // Verificar si la fecha es válida antes de convertirla a Date
    if (fecha.isValid()) {
      return fecha.toDate();
    } else {
      console.warn('Fecha inválida:', cotizacion.strFecha);
      // Puedes devolver null o una fecha por defecto, según tus necesidades
      return cotizacion.strFecha;
    }
  }

  fnSetEstatusPlantilla(estatus:string, intIDMovimientoVenta :number, strObservaciones:string, strCliente:string)
  {
    this.boolMostrarMsj_Aut=true;
    this.spinerMdl=true
    //actualizamos los precios 
    this.fnUpatePreciosPlantilla(intIDMovimientoVenta)

   let xmlArticulos =[]/* [{strArticulo:'BN',dblPrecio:20}] */
   this.arrayDetPlantillas.forEach(x => {
    xmlArticulos.push({strArticulo:x.strArticulo,dblPrecio:x.dblPrecioSugerido})
   });
    let obj={ 
      strVigencia: '',
      strCliente: strCliente,
      strEstatus:estatus,
      strAutorizo:this.localSt.retrieve('strUsuario'),
      strObservaciones:estatus == "RECHAZADO"?this.comentarioRechazo:strObservaciones,
      intIDMovimientoVenta: intIDMovimientoVenta,
      strConexion:this.strConexion,
      xmlArticulos:xmlArticulos,
      strAccion:'setEstatusPlantilla'
   }
 

   this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
     result => {
      if(result[0].code == "200"){
        this._alertify.success(result[0].strMensaje)
        this.fnGetPlantillas();
        if(estatus == "RECHAZADO"){
          this.comentarioRechazo = ""
          this.btnCerrarRechazo.nativeElement.click()
        }else{
          this.btnCerrarMdl.nativeElement.click();
        }
        
       }else{
        this._alertify.error("Error en:"+ result[0].strMensaje)
       }
       console.log(result); 
       this.spinerMdl=false;
     },
     error => {
      this.spinerMdl=false;
        Swal.fire("Error al consumir",error.message,"error")
       console.error('Error al consumir la API:', error);
       // Puedes manejar el error de alguna manera específica aquí
     });
  }
  
  fnSetEstatusCotizacion(estatus:string, intIDMovimientoVenta :number, strObservaciones:string, strCliente:string)
  {
    
    //actualizamos los precios 
    this.fnUpatePreciosPlantilla(intIDMovimientoVenta)

   let xmlArticulos =[]/* [{strArticulo:'BN',dblPrecio:20}] */
   this.arrayDetPlantillas.forEach(x => {
    xmlArticulos.push({strArticulo:x.strArticulo,dblPrecio:x.dblPrecioSugerido})
   });
    let obj={ 
      strVigencia: '',
      strCliente: strCliente,
      strEstatus:estatus,
      strAutorizo:this.localSt.retrieve('strUsuario'),
      strObservaciones:strObservaciones,
      intIDMovimientoVenta: intIDMovimientoVenta,
      strConexion:this.strConexion,
      xmlArticulos:xmlArticulos,
      strAccion:'setEstatusPlantilla_Cotizacion_leads'
   }


   this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
     result => {
      if(result[0].code == "200"){
        this._alertify.success(result[0].strMensaje)
        this.fnGetPlantillas();
       this.btnCerrarMdl.nativeElement.click();
       }else{
        this._alertify.error("Error en:"+ result[0].strMensaje)
       }
       console.log(result); 
     },
     error => {
       console.error('Error al consumir la API:', error);
       // Puedes manejar el error de alguna manera específica aquí
     });
  }
 fnUpatePreciosPlantilla(intIDMovimientoVenta:number){
  let xmlArticulos =[]
  this.arrayDetPlantillas.forEach(x => {
    xmlArticulos.push({strArticulo:x.strArticulo,dblPrecio:x.dblPrecioSugerido})
   });
  let obj={ 
    intIDMovimientoVenta: intIDMovimientoVenta,
    strConexion:this.strConexion, 
    strAccion:'updatePreciosPlantilla',
    xmlArticulos:xmlArticulos
 }


 this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
   result => {
    if(result[0].code == "200"){
      Swal.fire({
        icon: "success",
        title: "Actualizado Correctamente",
        text:result[0].strMensaje
      });
     }else{
      Swal.fire({
        icon: "error",
        title: "Error al Actualizar",
        text:result[0].strMensaje
      });
     }
     console.log(result); 
   },
   error => {
     console.error('Error al consumir la API:', error);
     // Puedes manejar el error de alguna manera específica aquí
   });
 }
 fnSetCarrito(arrayArtis:any, intIDMovimientoVenta :number, objVenta:any,opcion:number){
  //borramos carrito
  this.carroservice.deleteCarrito();
  console.log(arrayArtis)

  //preparamos los articulos
  arrayArtis.forEach(element => {
    let precio =opcion ==2?element.dblPrecioLista:element.dblPrecioSugerido;
    let preciop =opcion ==2?element.dblPrecioPlantilla:element.dblPrecioSugerido;
              let objArt = {  boolEditaPlantilla: opcion ==2?true:false,   checkbx: opcion ==2?true:false,
              dblCalificacion: 3,
              dblCantidad: element.dblCantidad,
              dblCostoPromedio: 0,  dblCostoTeorico: 0,
              dblPrecio: 0, dblPrecio2: 0,  dblPrecio3: 0,  dblPrecio4: 0,   dblPrecio10: 0,
              dblPrecioLista: precio,  
              dblPrecioMinimo:element.dblPrecioMinimo,  
              dblPrecioPlantilla: preciop,  dblPrecioPolitica: element.dblPrecioPolitica,  dblPrecioSugerido: element.dblPrecioSugerido,
              dblTipoCambio: 0,  dblUltimoPrecio: 0,  intEjercicio: 0,
              intIDMovimientoVenta: intIDMovimientoVenta,  intIDSucursal: 0,
              intPeriodo: 0,  intPrecio: 0,  intPrecio2: element.dblPrecio2,
              intPrecio3: element.dblPrecio3, intPrecio4: element.dblPrecio4,  intPrecioLista: element.dblPrecioLista,
              intPrecioPlantilla: preciop, intPrecioPolitica: 0, intTotalEstrellas: 2,
              pagina: 1,  strArticulo: element.strArticulo, strCategoria: element.strCategoria,
              strComentario: "", strDescripcion1: element.strDescripcion1, strLinea: element.strLinea,
              strNombreOpcion: "",  strOpcion:element.strOpcion,  strTipo:element.strTipo,
              strTipoOpcion: element.strTipoOpcion, arrayOpciones:[]


                  }
    let mdP =opcion ==2?true:false;
    this.carroservice.setModoPlantilla(mdP);
    this.carroservice.setArticulo( objArt);
  });
  this._alertify.success("Articulo agregado correctamente");
  this.router.navigate(['/carritoresumen']);
    console.log(objVenta)
    //agregar cliente
    let alm =objVenta.strSucursal=='0'?'1':objVenta.strSucursal;
  let objCte = {
      ClienteSeleccionado:true,
      strAgente: objVenta.strAgente,
strNombre :  objVenta.strNombreCliente,
strCliente:  objVenta.strCliente,
intIDSucursal:objVenta.intIDSucursal , 
strAlmacen:objVenta.strAlmacen,
strClaveUsoCFDI :  objVenta.strClaveUsoCFDI,
strCondicion :  objVenta.strCondicion,
strDescripcionUsoCFDI :  objVenta.strDescripcionUsoCFDI,
strDireccion :   objVenta.strDireccion,
strInfopago :   objVenta.strInfopago,
strInfopagoDescripcion:  objVenta.strInfopagoDescripcion,
strNombreSucursal:"",
strSucursal:"0",
strTipo:"CLIENTE"
    } 
console.log(objCte)
this.carroservice.setCte(objCte);
 }

 fnEliminarPartida(intIDMovimientoVenta:number,art:any){

  let obj={ 
    intIDMovimientoVenta: intIDMovimientoVenta,
    strArticuloBusqueda:art.strArticulo,
    strObservaciones:art.strOpcion,
    strConexion:this.strConexion, 
    strAccion:'deletePlantilla_Art'
 }

 this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
   result => {
    if(result[0].code == "200"){
      this._alertify.success(result[0].strMensaje)
      this.fnGetDetallePlantilla(this.objCte,1);
     }else{
      this._alertify.error("Error en:"+ result[0].strMensaje)
     }
 
   },
   error => {
     console.error('Error al consumir la API:', error);
     // Puedes manejar el error de alguna manera específica aquí
   });
 }
 fnReenviarSolicitud(intIDMovimientoVenta:number){
  this.spinerMdl =true;
  let arrayEnviar =[];
  this.arrayDetPlantillas.forEach(element =>{
      let objEnv = {strArticulo:element.strArticulo,strOpcion: element.strOpcion, dblPrecio:element.dblPrecioSugerido, dblCantidad: element.dblCantidad}
      arrayEnviar.push(objEnv);
  });
  console.log(arrayEnviar);
  let obj={ 
    intIDMovimientoVenta: intIDMovimientoVenta,
    xmlArticulos:arrayEnviar, 
    strConexion:this.strConexion, 
    strAccion:'setTerminarEdicion_Plantilla'
 }

 this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
   result => {
    if(result[0].code == "200"){
      this._alertify.success(result[0].strMensaje)
      this.fnGetPlantillas();
      this.btnCerrarMdl.nativeElement.click();
     }else{
      this._alertify.error("Error en:"+ result[0].strMensaje)
     }
     this.spinerMdl =false;
   },
   error => {
    this.spinerMdl =false;
     console.error('Error al consumir la API:', error);
     // Puedes manejar el error de alguna manera específica aquí
   });
 }

 openFirsModal(tipoMdl:string="") {
  console.log("ingresa")
  let firstModalElement = document.getElementById('mdlAutorizar');
  let firstModal = new bootstrap.Modal(firstModalElement);
    setTimeout(() => {
      firstModal.show();
    }, 100); 
}

onSecondModalClose(obj:any) {
  this.fnMostrarPedidos(obj)
  let firstModalElement = document.getElementById('staticBackdrop');
  let firstModal = new bootstrap.Modal(firstModalElement);

  // Asegúrate de que el primer modal esté completamente cerrado antes de abrirlo de nuevo
  setTimeout(() => {
    firstModal.show();

  }, 100); // Un pequeño retraso (300ms) para permitir que el segundo modal se cierre completamente
}

onModal3Close() {

  let firstModalElement = document.getElementById('staticBackdrop2');
  let firstModal = new bootstrap.Modal(firstModalElement);

  // Asegúrate de que el primer modal esté completamente cerrado antes de abrirlo de nuevo
  setTimeout(() => {
    firstModal.show();

  }, 100); // Un pequeño retraso (300ms) para permitir que el segundo modal se cierre completamente
}

fnMostrarPedidos(obj:any) {
  this.spinerHistPrecios =true;
  this.tituloart =""
console.log(obj)
console.log(this.objCte)
this.tituloart = obj.strArticulo
  let objEnviar = {
     'strCliente': this.objCte.strCliente.replace(/^\s+|\s+$/g, ""),
       'strArticulo': obj.strArticulo }
  this._httpService.getpagViejitaPlantillas(objEnviar,"getRevisionPreciosArt").subscribe(
    result => {
      this.arrayHistPrecios= result;
      console.log(result)
      this.spinerHistPrecios =false;
    },error=>{
      this.spinerHistPrecios =false;
      console.log(error.message)
    }); 
   
}

fnDetalleCte(cliente:string){
  //guardamos en el servicio de indexed db la ruta
  let objRuta_Anterior={
    ruta_origen:'/directorio',
    ruta_destino:'/detalleLeadCliente',
    nombre:'Directorio'
  }
  this.indexedDBService.removeItem('objRuta_Anterior');
  this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
  //agregamos a carrito el cliente
  this.btnCerrarMdl.nativeElement.click();
this.router.navigate(['/detalleLeadCliente'],{queryParams:{strCliente: cliente}});
}

  fnRechazarPlantilla(){
    this.fnSetEstatusPlantilla('RECHAZADO',this.objCte.intIDMovimientoVenta,this.objCte.strObservaciones,this.objCte.strCliente)

  }
  dividirTextoEnDosFilas(texto: string,strOpcion:string,strArticulo:string): string {
    // Divide el texto en dos partes
    const mitad = Math.floor(texto.length / 2);
    const primeraParte = texto.slice(0, mitad);
    const segundaParte = texto.slice(mitad);

    // Unimos las dos partes con un salto de línea (br)
    return `<span class="fs12 fw-bold">${strArticulo}</span> -${primeraParte}<br>${segundaParte}- <span class="fw-bold">${strOpcion}</span>`;
  }

  fnGetArticulos_2(){
    this.spiner = true;
    this.arraySubcategorias=[];
    this.arrayArticulos = [];
    this.strSubLinea='';
    let articulo = {
      strAccion: "getArticulo_Busqueda",
      strArticuloBusqueda: this.strArticulo,
 
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
       
        let algo=result;
        algo.forEach(element => {
          element.imageUrl='https://www.cleanclean.mx/imagenes/'+element.strArticulo+'.JPG'
          let valida = this.arraySubcategorias.find(o=>o.strGrupo ==element.strGrupo );
          if(!valida && valida!='' && valida!=' '){
            this.arraySubcategorias.push({strGrupo:element.strGrupo  })
          }
        });
        this.arraySubcategorias.sort(function (a,b){
          if (a.strGrupo > b.strGrupo) {
            return 1;
          }
          if (a.strGrupo < b.strGrupo) {
            return -1;
          }
          return 0;
        })
        
        this.arrayArticulos = algo;
        this.spiner = false;
        console.log(this.arrayArticulos);
      },error=>{
        console.log(error.message)
        this.spiner = false;
      });
  }
  fnAddArt(){
    if(this.boolAddArt){
      this.boolAddArt = false
    }else{
      this.boolAddArt = true
    }
    this.strArticulo = ""
    this.arraySubcategorias=[];
    this.arrayArticulos = [];
  }
  fnagregarArticulo(){

  }
  fnValida_preciominimo(objPrecio:any){ 
    if(objPrecio.dblPrecioSugerido <objPrecio.dblPrecioMinimo  && this.strAgente_crea !='003' && this.strAgente_crea !='912'){ //solo el perfil del señor agustin puede autorizar o rechazaer
      this.boolShowExportar=true ;
    }
    else {
      this.boolShowExportar=false;
    }
  }
  fnValidar_todo(){
    let cont = 0;
    this.arrayDetPlantillas .forEach(element => {
      if(element.dblPrecioSugerido <=element.dblPrecioMinimo ){
        cont = cont +1;
      }
      
    });

    if(cont> 0 && this.strAgente_crea !='003' && this.strAgente_crea !='912'){ //solo el perfil del señor agustin puede autorizar o rechazaer
      this.boolShowExportar=true ;
    }
    else {
      this.boolShowExportar=false ;
    }
  }
}
