import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FiltrosService } from '../servicios/filtros.service';
import { Modal } from 'bootstrap';

import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { DataDBService } from '../servicios/data-db.service';
import { takeUntil, timeout } from 'rxjs/operators';
import * as bootstrap from 'bootstrap';
import { Subject } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-dashboard-vendedor',
  templateUrl: './dashboard-vendedor.component.html',
  styleUrls: ['./dashboard-vendedor.component.css']
})
export class DashboardVendedorComponent implements OnInit, OnDestroy {
  @ViewChild('mdlBody') mdlBody!: ElementRef;
  @ViewChild('mdlBody2') mdlBody2!: ElementRef;
  @ViewChild('mdlBody3') mdlBody3!: ElementRef;
  modal1!: Modal;
  modal2!: Modal;
  modal3!: Modal;
  @ViewChild('btnCerrarAct') btnCerrarAct!: ElementRef;
  public intPedidos_P: number;
  public arrayMinutas: any = []
  public intConsignas_P: number;
  public boolModal : boolean=false;
  public intComodatos_P: number;
  public intRemisiones_P: number;
  public strConexion: string = "";
  public arrayDetalleArts: any = [];
  public arrayAgentes: any = [];
  public arrayPeriodo: any = [];
  public strAgente: string = "";
  public strAgenteLS: any = ''
  public arraySucursales: any = [];
  public strSucursal: string = "";
  public arrayGerentes: any = [];
  public strGerente: string = "";
  public arrayAccesos: any = [];
  public boolGerente: boolean = true;
  public boolSucursal: boolean = true;
  public boolAgente: boolean = true;
  public strSSN: string;
  arrayEjercicio: any = [];
  intEjercicio: number = 0;
  intPeriodo: number;
  inputSearch: string = "";
  isHoveredButton: boolean = false;
  public objetivo: number = 20000;
  public avance: number = 1500;
  public porcentaje: number = 0;
  public strAcPrincipal :any;
  public arrayActAtrasadas: any = [];
  public arrayActProximas: any = [];
  public arrayActHoy: any = [];
  public arrayActOport: any = [];
  public arrayActividades: any = [];
  public arrayTotalAtrasdas: any = [{ strTipo: "Llamada", intContador: 0 }, { strTipo: "Visita", intContador: 0 }, { strTipo: "Correo", intContador: 0 }, { strTipo: "Tarea", intContador: 0 }];
  public arrayTotalHoy: any = [{ strTipo: "Llamada", intContador: 0 }, { strTipo: "Visita", intContador: 0 }, { strTipo: "Correo", intContador: 0 }, { strTipo: "Tarea", intContador: 0 }];
  public arrayTotalProximas: any = [{ strTipo: "Llamada", intContador: 0 }, { strTipo: "Visita", intContador: 0 }, { strTipo: "Correo", intContador: 0 }, { strTipo: "Tarea", intContador: 0 }];
  public strComentario: string = "";
  public strResultado: string = "";
  public contenido: string = '';
  public tagName: string = '';
  public strAgente_Crea: string;
  public loader: boolean = true
  public intIDActividad: number = 0;
  public ObjModal: any = {};
  public spinerNavTabs: boolean = false;
  public strComentarioD: string = ''
  public strNombre: string = '';
  public arrayAgente_Filter: any = [];
  public filtroCliente: string = '';
  public currentYear: number;
  public arrayClientesPorLinea = [];
  public intID_Leads: number;
  public arraycte4500: any = [];
  @ViewChild('MConcluirActividadesInicio', { static: false }) modal1Ref!: ElementRef;
  @ViewChild('MConcluirActividades', { static: false }) modal3Ref!: ElementRef;
  @ViewChild('MConcluirSubTareas', { static: false }) modal2Ref!: ElementRef;
  public objAct: any = {
    arrayAct: [] // Inicialización para evitar undefined
  };
  public objDetActividad: any;
  public strTituloModal: string = "";
  public spinerTipoClientes: boolean = false;
  public altoModal: number = 0;
  public altoModal2: number = 0;
  public altoModal3: number = 0;
  public spiner: boolean = false;
  public arrayLineas: any = [];
  public arrayDetTipCliente: any = [];
  public leadsCategoria: any = []
  public arrayVentas: any = []
  public arrayProspecto: any = []
  public arrayLeadsCategoria: any = []
  public arrayAgentesTodos: any = [];
  public arrayLeadsEstrellas: any = []
  public dblTotalPorcentajeAvance: number = 0;
  public conicGradientStyle: any = ''
  public conicGradientStyleProspectos: any = '';
  public intComprasIndirectas: number = 0;
  public intPresupuestosPendientes: number = 0;
  public arraycte10000: any = [];
  public arraycte50000: any = [];
  public arraycte100000: any = [];
  public arraycte150000: any = [];
  public arraycte250000: any = [];
  public objModal: any = { titulo: "" };
  public strEstatus:string=""
  public arraycte250000mayor: any = [];
  public intAnticipos: number = 0;
  public arrayComentarios: any = [];
  public arrayDetClientesXlinea: any = [];
  public intGastos: number = 0;
  public objTotal: {
    totalObjetivo: number;
    totalventa: number;
    totalMargen: number;
    totalAvance: number;
  } = {
      totalObjetivo: 0,
      totalventa: 0,
      totalMargen: 0,
      totalAvance: 0
    };
  public spinerModal: boolean = false;
  public spinnerTab: boolean = false;
  public cuentasCorporativas: any = []
  public leadsProspectos: any = [];
  public arrayCteNuevos: any = [];
  public mdlNombre: string = "";
  public oportunidadesClientesActuales: any = []
  public objReporte: any = {};
  public objCtaCorporativa: any;
  @ViewChild('infoGralDiv') infoGralDiv!: ElementRef;
  @ViewChild('editableDiv2') editableDiv2!: ElementRef;

  @ViewChild('btnCanvas') btnCanvas!:ElementRef;
  @ViewChild('btnCanvasInicio') btnCanvasInicio!:ElementRef;
  @ViewChild('btnCanvasSubAct') btnCanvasSubAct!:ElementRef;
  public mdlComentarios: string = "";
  public altInfo: any;
  public strUsuario: string;
  public arrayTipoCliente: any = [];
  public arrayAgentesActividades: any = [];
  public strfecha: Date;
  public strTitle_SV: string;
  public arrayDetalleSuenio: any = [];
  private unsubscribe$ = new Subject<void>();
  public objSubTarea: any = []
  public arraySubTareas: any = []
  public time1: string = "am";
  public boolAddSubTarea:boolean=false;
  public time2: string = "am";
  public mdlHoraInicio: string = "07:00";
  public mdlHoraFin: string = "07:00";
  public strBuscarAgente: string;
  public arrayAgentes_tareas: any = [];
  public arrayEnviarAnexosActividades: any = []
  @ViewChild('fileInputActividades') fileInputActividades!: ElementRef;
  public arrayAgregarAnexosActividades: any = []
  public arrayAm: any = []
  public arraypm: any = []
  public arrayHora: any = [];
  constructor(private indexedDBService: DataDBService, elem: ElementRef, public router: Router, private _filtroServ: FiltrosService, private _httpService: PeticionesService, public localSt: LocalStorageService,) {
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strNombre = localSt.retrieve('strNombre')
    //array de accesos
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.currentYear = new Date().getFullYear();
    let hora = 7, minutos = 0, strH = "", strM = "";
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayHora.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }
    hora = 7
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayAm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }
    hora = 0
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      //obtener niveles de acceso 
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);

        if (objAcceso == undefined || objAcceso.length == 0) {
          this.router.navigate(['/login']);
        }
        else {

          let objData = this.arrayAccesos.length > 0 ? this.arrayAccesos[0] : {}

          this._httpService.setMostrarMenu(true);
          this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
          this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
          this.strSucursal = !this.boolSucursal ? objData.sucursal : ''; //this.localSt.retrieve('strSucursal') 
          this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
          this.strAgente = objData.strAgente;//this.boolAgente ? '' :objData.strAgente;// this.localSt.retrieve('strAgente'); //this.boolAgente ? '' : 
          
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgenteLS = objData.strAgente; //this.localSt.retrieve('strAgente');
          this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');

       
          this.strGerente = (!this.boolGerente && this.boolSucursal) ? objData.strUsuario : ''; //this.localSt.retrieve('strUsuario') 
          this.strUsuario = objData.strUsuario;
          this.strSSN = objData.strSSN;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
            this.fnGetAgentes_tareas();
            this.fnGetFiltros();
            this.fnGetActividades();
            this.fnGetOport();
            this.fnGetMinutas();
            this.fnGetTablaLineas();
            this.fnGetFunnel3();
            this.fnGetFunnel2();
            this.fnGetFunnel1();
            this.fngetIndicadorVentaOportunidad();
            this.fngetIndicadorVentaLeads();
            this.fngetIndicadorClasEstrellas();
            this.fngetIndicadorClasAAA();
            this.fnGetMovimientosPendientes();
            this.fnGetIndicadores_Intranet();
            this.fncteXlinea();
            this.fngetCteXtipo();
            this.fnget4500();
            this.fnget10000();
            this.fnget50000();
            this.fnget100000();
            this.fnget150000();
            this.fnget250000();
            this.fnget250000mayor()
            this.fngetCtenuevos();
          });
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });



    document.addEventListener('click', (event: any) => {
      const offcanvasElement = document.querySelector('.offcanvas.show');
      if (offcanvasElement && !offcanvasElement.contains(event.target)) { 
        this.btnCanvas.nativeElement.click()
        this.btnCanvasInicio.nativeElement.click()
        this.btnCanvasSubAct.nativeElement.click()
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.altInfo = this.infoGralDiv.nativeElement.offsetHeight;

    }, 2500);
  
      this.modal1 = new Modal(this.modal1Ref.nativeElement);
      this.modal2 = new Modal(this.modal2Ref.nativeElement);
      this.modal3 = new Modal(this.modal3Ref.nativeElement);
    

    this.fncargarsummer();

  }


  ngOnDestroy(): void {
    // Emitir un valor para completar las suscripciones
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  irSuenio(id_suenio:number, estatus:string){
 
    this.router.navigate(['/detoport'], { queryParams: { idLead: id_suenio, sts: estatus} });
  }
  fnOpenModal_Suenios_ventas(estatus: string) {
    this.strTitle_SV = estatus;
    this.arrayDetalleSuenio = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getDetalleVentaSuenio',
      strEstatus: estatus
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayDetalleSuenio = result;
        });
  }
  fnOpenModal_Suenios_prospectos(estatus: string) {
    this.strTitle_SV = estatus;
    this.arrayDetalleSuenio = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getDetalleVentaProspecto',
      strEstatus: estatus
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayDetalleSuenio = result;
        });
  }
  fnGet_todo() {
    this.fnGetMovimientosPendientes();
    this.fnGetActividades("filtrar");
    this.fnGetOport();
    this.fnGetMinutas();
    this.fnGetTablaLineas();
    this.fnGetFunnel3();
    this.fnGetFunnel2();
    this.fnGetFunnel1();
    this.fngetIndicadorVentaOportunidad();
    this.fngetIndicadorVentaLeads();
    this.fngetIndicadorClasEstrellas();
    this.fngetIndicadorClasAAA();
    this.fnGetIndicadores_Intranet();
    this.fncteXlinea();
    this.fngetCteXtipo();
    this.fnget4500();
    this.fnget10000();
    this.fnget50000();
    this.fnget100000();
    this.fnget150000();
    this.fnget250000();
    this.fnget250000mayor()
    this.fngetCtenuevos();
  }
  fncargarsummer() {
    const summernoteElement = $('#summernote');
    // Destruir la instancia existente de Summernote
    if (summernoteElement.data('summernote')) {
      summernoteElement.summernote('destroy');
    }

    // Reinicializar Summernote
    summernoteElement.summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
      },
      height: 150,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']]
      ]
    });

    // Actualizar el contenido del editor
    summernoteElement.summernote('code', this.contenido);
  }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
      this.arrayAgentesTodos = JSON.parse(JSON.stringify(nuevoValor));

    });
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnGetOport() {
    this.arrayActOport = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getOportunidades'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayActOport = result;
        });
  }

  fnGetMinutas() {
    this.arrayMinutas = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getMinuta'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayMinutas = result;
        });
  }

  fnGetActividades(tipo: string = "") {
    this.loader = true
    this.arrayActAtrasadas = [];
    this.arrayActHoy = [];
    this.arrayActProximas = [];
   // let agente = this.strAgente !='' ?this.strAgente:this.strAgenteLS;
    let obj = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getActividades'
    };

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_DashBoard')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
      
          this.arrayActividades = result;
          this.arrayActividades.forEach(item => {
            
            item.select = false;
            let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
          
            item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
            if (item.strClasificacion_Actividad == "HOY") {
              this.arrayActHoy.push(item)
            } else if (item.strClasificacion_Actividad == "ATRASADO") {
              this.arrayActAtrasadas.push(item)
            } else if (item.strClasificacion_Actividad == "PROXIMO") {
              this.arrayActProximas.push(item)
            }

          });
          this.fncargarsummer();
          if (this.arrayActAtrasadas.length > 0 && tipo == "") {
            this.fncargarsummerInicio()
          }
          this.loader = false
          this.fnSumarActividades();
        }
      );
  }

  fnSumarActividades() {
    this.arrayTotalAtrasdas[0].intContador = 0;
    this.arrayTotalAtrasdas[1].intContador = 0;
    this.arrayTotalAtrasdas[2].intContador = 0;
    this.arrayTotalAtrasdas[3].intContador = 0;

    this.arrayTotalHoy[0].intContador = 0;
    this.arrayTotalHoy[1].intContador = 0;
    this.arrayTotalHoy[2].intContador = 0;
    this.arrayTotalHoy[3].intContador = 0;

    this.arrayTotalProximas[0].intContador = 0;
    this.arrayTotalProximas[1].intContador = 0;
    this.arrayTotalProximas[2].intContador = 0;
    this.arrayTotalProximas[3].intContador = 0;

    for (let i = 0; i < this.arrayActAtrasadas.length; i++) {
      if (this.arrayActAtrasadas[i].strTipo == "Llamada") this.arrayTotalAtrasdas[0].intContador++;
      if (this.arrayActAtrasadas[i].strTipo == "Correo") this.arrayTotalAtrasdas[2].intContador++;
      if (this.arrayActAtrasadas[i].strTipo == "Visita") this.arrayTotalAtrasdas[1].intContador++;
      if (this.arrayActAtrasadas[i].strTipo == "Tarea") this.arrayTotalAtrasdas[3].intContador++;
    }

    for (let i = 0; i < this.arrayActHoy.length; i++) {
      if (this.arrayActHoy[i].strTipo == "Llamada") this.arrayTotalHoy[0].intContador++;
      if (this.arrayActHoy[i].strTipo == "Correo") this.arrayTotalHoy[2].intContador++;
      if (this.arrayActHoy[i].strTipo == "Visita") this.arrayTotalHoy[1].intContador++;
      if (this.arrayActHoy[i].strTipo == "Tarea") this.arrayTotalHoy[3].intContador++;
    }

    for (let i = 0; i < this.arrayActProximas.length; i++) {
      if (this.arrayActProximas[i].strTipo == "Llamada") this.arrayTotalProximas[0].intContador++;
      if (this.arrayActProximas[i].strTipo == "Correo") this.arrayTotalProximas[2].intContador++;
      if (this.arrayActProximas[i].strTipo == "Visita") this.arrayTotalProximas[1].intContador++;
      if (this.arrayActProximas[i].strTipo == "Tarea") this.arrayTotalProximas[3].intContador++;
    }

    this.fnmDLConAct(this.objAct, this.strTituloModal)
  }
  fnEnProceso(objAct: any) {
    //let sp=  'sp_modVentas_Leads';
    let objectEnviar = {
      strAccion: objAct.intID_Leads == 0 ? 'update_Actividad_Oportunidad_Proceso' : "update_Actividad_Proceso",
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: objAct.intID_Leads == 0 ? objAct.intID_Leads_Oportunidad : objAct.intID_Leads,
      strComentario: this.strComentario,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(objectEnviar, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetActividades();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          
          (error);
        }
      );
  }
  fnAutorizar(objAct: any) {
    let objectEnviar = {
      strAccion: objAct.intID_Leads == 0 ? 'update_Actividad_Oportunidad_Autorizar' : "update_Actividad_Autorizar",
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: objAct.intID_Leads == 0 ? objAct.intID_Leads_Oportunidad : objAct.intID_Leads,
      strComentario: this.strComentario,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(objectEnviar, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetActividades();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          
          (error);
        }
      );
  }
  fnRechazar(objAct: any) {
    let objectEnviar = {
      strAccion: objAct.intID_Leads == 0 ? 'update_Actividad_Oportunidad_Rechazar' : "update_Actividad_Rechazar",
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: objAct.intID_Leads == 0 ? objAct.intID_Leads_Oportunidad : objAct.intID_Leads,
      strComentario: this.strComentario,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(objectEnviar, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetActividades();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          
          (error);
        }
      );
  }
  fnConcluirActividad(objAct) {

    this.strComentario = this.contenido
    let objectEnviar = {
      strAccion: objAct.intID_Leads == 0 ? 'update_Actividad_Oportunidad' : "update_Actividad",
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: objAct.intID_Leads == 0 ? objAct.intID_Leads_Oportunidad : objAct.intID_Leads,
      strComentario: this.strComentario,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }

    this._httpService.mostrarCotizaciones(objectEnviar, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetActividades();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          
          (error);
        }
      );
  }
  fnmDLConAct(obj: any, titulo: string) {
    this.strAcPrincipal = {objeto:obj,titulo:titulo}
  
    this.contenido = "";
    this.strResultado = "";
    this.objAct = {};
    this.objAct.arrayAct = [];
    this.strComentario = "";
    // Llamar a fncargarsummer para reinicializar Summernote
    this.fncargarsummer();
    // Actualizar los datos después de reinicializar Summernote
    this.objAct = obj;

    this.objAct.objAct = {};
    this.strTituloModal = titulo;
    this.spiner = true;
    this.altoModal = 0;
    setTimeout(() => {
      this.spiner = false;
      this.obtenerAltoDivINfoGRAL();
      this.objAct.arrayAct = this.strTituloModal === 'Vencidas' ? this.arrayActAtrasadas : this.strTituloModal === 'Proximas' ? this.arrayActProximas : this.arrayActHoy;
      this.objAct.arrayAct.forEach(element => {

        element.select = false;
      });


    }, 2000);

  }

  obtenerAltoDivINfoGRAL() {
    this.altoModal = this.mdlBody.nativeElement.offsetHeight;
    /*   this.divTimeLine = divTimeLine
      this.divtimeLineBody = divtimeLineBody 
    
      ('Alto del div:', this.altoModal);*/
  }
  obtenerAltoDivINfoGRAL2() {
    this.altoModal2 = this.mdlBody2.nativeElement.offsetHeight;

    /*   this.divTimeLine = divTimeLine
      this.divtimeLineBody = divtimeLineBody */

  }
  obtenerAltoDivINfoGRAL3() {
    this.altoModal3 = this.mdlBody3.nativeElement.offsetHeight;
 
    /*   this.divTimeLine = divTimeLine
      this.divtimeLineBody = divtimeLineBody */

  }
  fnSeleccionarAct(x: any, num: number,subTarea:boolean=false) {
    this.boolAddSubTarea= false;
    if (this.objAct.arrayAct && this.objAct.arrayAct.length > 0) {
      this.objAct.arrayAct.forEach(ite => {
        ite.select = false;
      })
    }

    if(subTarea){
      this.arraySubTareas.forEach(item => {
        item.select = false;
      });
    }

    this.objAct.arrayAct = [];
    if (x.strClasificacion_Actividad == "HOY") {/*  */
      this.objAct.arrayAct = this.arrayActHoy
    } else if (x.strClasificacion_Actividad == "ATRASADO") {
      this.objAct.arrayAct = this.arrayActAtrasadas
    } else if (x.strClasificacion_Actividad == "PROXIMO") {
      this.objAct.arrayAct = this.arrayActProximas
    }
    this.intIDActividad = x.intID_Actividad
    this.objAct.objAct = x
    this.objAct.arrayAct.forEach(ite => {
      ite.select = false;
    })


    this.fngetComentarios()
    if (this.objAct.arrayAct || this.objAct.arrayAct.length > 0) {

      this.objAct.arrayAct.forEach(ite => {
        if (ite.intID_Actividad == this.intIDActividad) {
          ite.select = true;
        } else {
          ite.select = false;
        }

      });
      if(subTarea){
        this.arraySubTareas.forEach(ite => {
          if (ite.intID_Actividad == this.intIDActividad) {
            ite.select = true;
          } else {
            ite.select = false;
          }
        });
      }

    }
 
    this.fncargarsummerSubActividad()
    document.getElementById('botonCerrarOffcanva')!.click()
    document.getElementById('botonCerrarOffcanva2')!.click()
    /*   this.objAct.arrayAct[num].select = true; */
  }



fncerraract(){
 /*  this.fnGetActividades() */

 this.fnmDLConAct(this.strAcPrincipal.objeto,this.strAcPrincipal.titulo) 
   

  if (this.modal2) {
    this.modal2.hide();
    this.modal2Ref.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.modal3.show(); // Solo abrir modal3 cuando boolModal es true
    }, { once: true });
  } else {
    this.modal3.show(); // Solo abrir modal3 cuando boolModal es true
  }

  if(this.arrayActAtrasadas.length > 0){
    this.arrayActAtrasadas.forEach(item => {
      item.select = false
    });
  }

  if(this.arrayActHoy.length > 0){
    this.arrayActHoy.forEach(item => {
      item.select = false
    });
  }

  if(this.arrayActProximas.length > 0){
    this.arrayActProximas.forEach(item => {
      item.select = false
    });
  }
  
}

  fnCerrar(){
    if (this.boolModal) {
      return;
  }
    this.fnGetActividades()

  
      if (this.modal2) {
        this.modal2.hide();
        this.modal2Ref.nativeElement.addEventListener('hidden.bs.modal', () => {
          this.modal1.show(); // Solo abrir modal1 cuando boolModal es false
        }, { once: true });
      } else {
        this.modal1.show(); // Solo abrir modal1 cuando boolModal es false
      }
      
  
 

 
    
    if(this.arrayActAtrasadas.length > 0){
      this.arrayActAtrasadas.forEach(item => {
        item.select = false
      });
    }

    if(this.arrayActHoy.length > 0){
      this.arrayActHoy.forEach(item => {
        item.select = false
      });
    }

    if(this.arrayActProximas.length > 0){
      this.arrayActProximas.forEach(item => {
        item.select = false
      });
    }
    
    
  }

  fngetSubtareas() {
    this.arraySubTareas = [];
    let obj = {
      strAccion: 'getSubActividades',
      strAgente: this.strAgente,
     /*  strSucursal: this.strSucursal,
      strGerente: this.strGerente, */ 
      intID: this.objSubTarea.intID_Actividad,
      strConexion: this.strConexion
    };
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_DashBoard')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

   


          this.arraySubTareas =  result
          this.arraySubTareas.forEach(item => {
            let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
            item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
            item.select = false;
          });



            
         



        }, error => {
          this.spinnerTab = false
          console.error('Error al consumir la API:', error);

        });
  }



  fnGetTablaLineas() {
    this.spinnerTab = true
    this.arrayLineas = [];
    let obj = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo,
      strConexion: this.strConexion,
      strAccion: 'getAvancexLinea'
    };

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_DashBoard')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayLineas = result
          this.objTotal.totalObjetivo = this.arrayLineas.reduce((sum, item) => sum + item.dblObjetivo, 0);
          this.objTotal.totalventa = this.arrayLineas.reduce((sum, item) => sum + item.dblVenta, 0);
          this.objTotal.totalMargen = this.arrayLineas.reduce((sum, item) => sum + item.dblMargen, 0);
          this.objTotal.totalAvance = this.arrayLineas.reduce((sum, item) => sum + item.dblAvance, 0);
          this.spinnerTab = false

          let habil = result[0].intDiaHabil;
          let recorrido = result[0].intDiaRecorrido;
          let avanceTotal = (this.objTotal.totalObjetivo / habil) * recorrido;

          this.dblTotalPorcentajeAvance = (this.objTotal.totalventa / avanceTotal) * 100;

        }, error => {
          this.spinnerTab = false
          console.error('Error al consumir la API:', error);

        });
  }

  fnIrCanvas() {
    this.router.navigate(['/tableroLeads']);

  }


  onGerenteChange(srtCam: string) {
    if (srtCam == "Ger") {
      this.strSucursal = ""
      this.strAgente = ""
    } else if (srtCam == "Suc") {
      this.strAgente = ""
    }
  }

  fnEnviarNuevoPedido() {

    this.router.navigate(['/cotizador_Linea']);
  }

  enviarOportunidad(obj: any) {
    this.router.navigate(['/detoport'], { queryParams: { idLead: obj.intID_Leads, sts: obj.strEstatus } });
  }

  enviarMinuta(obj: any) {

    this.router.navigate(['/minuta'], { queryParams: { intID_Minuta: obj.intID_Leads } });
  }

  fnGetFunnel3() {
    this.oportunidadesClientesActuales = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getFunnelOportunidades'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.oportunidadesClientesActuales = result
        });
  }

  fnGetFunnel2() {
    this.leadsProspectos = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getFunnelLeads'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.leadsProspectos = result
        });
  }

  fnGetFunnel1() {
    this.cuentasCorporativas = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getFunnelCuentaCoorpo'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result.length > 1) {
            this.cuentasCorporativas = result
          } else {
            this.cuentasCorporativas = []
          }
        });
  }

  fngetIndicadorVentaOportunidad() {
    this.arrayVentas = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getIndicadorVentaOportunidad'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayVentas = result

          // Define los colores asociados con cada estatus
          const coloresPorEstatus: { [key: string]: string } = {
            ABIERTO: '#faeebe',
            GANADOS: '#a9dfbf',
            PERDIDOS: '#f1948a',
            // Puedes agregar más estatus y colores si es necesario
          };
          const totalAvance = this.arrayVentas.reduce((sum, item) => sum + item.dblAvance, 0);

          // Calcular los porcentajes y ordenarlos
          const porcentajesOrdenados = this.arrayVentas
            .map(item => ({
              dblAvance: item.dblAvance,
              porcentaje: ((item.dblAvance / totalAvance) * 100).toFixed(2) + '%',
              color: coloresPorEstatus[item.strEstatus] || '#f4f6f6' // Asigna el color basado en el estatus
            }))
            .sort((a, b) => b.dblAvance - a.dblAvance);

          // Convertir los porcentajes a números y calcular puntos finales acumulativos
          const puntosFinales = porcentajesOrdenados.reduce((acc, item, index) => {
            const porcentajeNumerico = parseFloat(item.porcentaje);
            const acumuladoAnterior = index === 0 ? 0 : acc[index - 1].fin;
            const fin = acumuladoAnterior + porcentajeNumerico;
            acc.push({ ...item, inicio: acumuladoAnterior, fin });
            return acc;
          }, []);

          // Validar si todos los dblAvance son cero
          const todosCeros = porcentajesOrdenados.every(item => item.dblAvance === 0);

          // Construir el estilo del gradiente
          let conicGradientStyle: string;

          if (todosCeros) {
            // Si todos los valores son cero, usar un color gris claro por defecto
            conicGradientStyle = 'conic-gradient(#d3d3d3 0% 100%)';
          } else {
            // Construir el gradiente basado en los colores asociados y puntos finales
            conicGradientStyle = puntosFinales
              .map(item => `${item.color} ${item.inicio}% ${item.fin}%`)
              .join(', ');
            conicGradientStyle = `conic-gradient(${conicGradientStyle})`;
          }

          // Asignar el gradiente al estilo en Angular
          this.conicGradientStyle = conicGradientStyle;

        });
  }

  fngetIndicadorVentaLeads() {
    this.arrayProspecto = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getIndicadorVentaLeads'
    };
    if (this.strAgente != '' && this.strAgente != null) {
      objEnvi.strAgente = this.strAgente
      objEnvi.strSucursal = ""
      objEnvi.strGerente = ""
    } else if (this.strSucursal != "" && this.strSucursal != null) {
      objEnvi.strAgente = ""
      objEnvi.strSucursal = this.strSucursal
      objEnvi.strGerente = ""
    } else if (this.strGerente != "" && this.strGerente != null) {
      objEnvi.strAgente = ""
      objEnvi.strSucursal = ""
      objEnvi.strGerente = this.strGerente
    }
    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayProspecto = result

          const totalAvance = this.arrayProspecto.reduce((sum, item) => sum + item.dblAvance, 0);
          const coloresPorEstatus: { [key: string]: string } = {
            ABIERTO: '#faeebe',
            GANADOS: '#a9dfbf',
            PERDIDOS: '#f1948a',
            // Puedes agregar más estatus y colores si es necesario
          };

          // Calcular el porcentaje de cada elemento
          const porcentajes = this.arrayProspecto.map(item => ({
            dblAvance: item.dblAvance,
            porcentaje: ((item.dblAvance / totalAvance) * 100).toFixed(2) + '%' // Formatear a dos decimales
          }));

          // Calcular los porcentajes y ordenarlos
          const porcentajesOrdenados = this.arrayProspecto
            .map(item => ({
              dblAvance: item.dblAvance,
              porcentaje: ((item.dblAvance / totalAvance) * 100).toFixed(2) + '%',
              color: coloresPorEstatus[item.strEstatus] || '#f4f6f6' // Asigna el color basado en el estatus
            }))
            .sort((a, b) => b.dblAvance - a.dblAvance);

          // Convertir los porcentajes a números y calcular puntos finales acumulativos
          const puntosFinales = porcentajesOrdenados.reduce((acc, item, index) => {
            const porcentajeNumerico = parseFloat(item.porcentaje);
            const acumuladoAnterior = index === 0 ? 0 : acc[index - 1].fin;
            const fin = acumuladoAnterior + porcentajeNumerico;
            acc.push({ ...item, inicio: acumuladoAnterior, fin });
            return acc;
          }, []);
          // Validar si todos los dblAvance son cero
          const todosCeros = porcentajesOrdenados.every(item => item.dblAvance === 0);

          if (todosCeros) {
            // Si todos los valores son cero, usar un color gris claro por defecto
            this.conicGradientStyleProspectos = 'conic-gradient(#d3d3d3 0% 100%)';
          } else {
            // Construir el gradiente basado en los colores asociados y puntos finales
            this.conicGradientStyleProspectos = puntosFinales
              .map(item => `${item.color} ${item.inicio}% ${item.fin}%`)
              .join(', ');
            this.conicGradientStyleProspectos = `conic-gradient(${this.conicGradientStyleProspectos})`;
          }

          // Asignar el gradiente al estilo en Angular
          this.conicGradientStyleProspectos = this.conicGradientStyleProspectos;
        });
  }

  fngetIndicadorClasAAA() {
    this.cuentasCorporativas = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getIndicadorClasAAA'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayLeadsCategoria = result
        });
  }

  fngetIndicadorClasEstrellas() {
    this.cuentasCorporativas = [];
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getIndicadorClasEstrellas'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayLeadsEstrellas = result
        });
  }
  fnGetMovimientosPendientes() { //getMovPendientesVendedor
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getMovimientos_Pendientes'
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let objR = result[0];
          if (objR) {
            this.intPedidos_P = objR.strPedidos;
            this.intConsignas_P = objR.strConsignas;
            this.intComodatos_P = objR.strComodatos;
            this.intRemisiones_P = objR.strRemisiones;
          }
          else {
            this.intPedidos_P = 0;
            this.intConsignas_P = 0;
            this.intComodatos_P = 0;
            this.intRemisiones_P = 0;
          }
        });
  }
  fnGetMovimientosPendientes_anterior() { //getMovPendientesVendedor
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strDivision: this.strGerente
    };

    this._httpService.getVentasMovimientos_p(objEnvi)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let objR = result[0];
          this.intPedidos_P = objR.strPedidos;
          this.intConsignas_P = objR.strConsignas;
          this.intComodatos_P = objR.strComodatos;
          this.intRemisiones_P = objR.strRemisiones;

        });
  }

  fnModalCtaCorporativa(tipo: number, card: string) {
    /* 0=por contactar, 1=presentacion, 2=recorrido/Cotizacion, 
    3=propuestaEconomica, 4=negociacion, 5=Ganados */
    this.objCtaCorporativa = {}
    this.objCtaCorporativa.tipo = tipo == 0 ? 'Por Contactar' : tipo == 1 ? 'Presentación' : tipo == 2 ? 'Recorrido/Cotización' :
      tipo == 3 ? 'Propuesta Economica' : tipo == 4 ? 'Negociación' : "Ganados"
    this.objCtaCorporativa.titulo = card
  }
  fnGetIndicadores_Intranet() {
    let objEnvi = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion,
      strAccion: 'getIndicadores_Intranet',
      strSSN: this.strSSN
    };

    this._httpService.mostrarCotizaciones(objEnvi, "sp_ModVentas_DashBoard")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let objR = result[0];
          this.intAnticipos = objR ? objR.intAnticipos : 0;
          this.intGastos = objR ? objR.intGastos : 0;
          this.intPresupuestosPendientes = objR ? objR.intPresupuestosPendientes : 0;
          this.intComprasIndirectas = objR ? objR.intComprasIndirectas : 0;

        });
  }

  fncargarsummerInicio() {
    const summernoteElement = $('#summernoteInicio');
    // Destruir la instancia existente de Summernote
    if (summernoteElement.data('summernote')) {
      summernoteElement.summernote('destroy');
    }

    // Reinicializar Summernote
    summernoteElement.summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
      },
      height: 150,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']]
      ]
    });

    // Actualizar el contenido del editor
    summernoteElement.summernote('code', this.contenido);
    setTimeout(() => {
      this.obtenerAltoDivINfoGRAL2()
    }, 2000);

    document.getElementById('btnModalInicio')!.click()
  }


  fncargarsummerSubActividad() {
    const summernoteElement = $('#summernoteInicio2');
    // Destruir la instancia existente de Summernote
    if (summernoteElement.data('summernote')) {
      summernoteElement.summernote('destroy');
    }

    // Reinicializar Summernote
    summernoteElement.summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
      },
      height: 150,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']]
      ]
    });

    // Actualizar el contenido del editor
    summernoteElement.summernote('code', this.contenido);
    setTimeout(() => {
      this.obtenerAltoDivINfoGRAL2()
    }, 2000);

  
  }


  fnInsertComentario() {

    let obj = {
      strAccion: 'setComentario_Leads_Actividad',
      intID_Actividad: this.intIDActividad,
      strAgente: this.strAgente_Crea,
      strComentario: this.strComentarioD,
      strConexion: this.strConexion
    }
    
   
    this._httpService.mostrarCotizaciones(obj, "sp_ModVentas_Leads_Familia")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strComentarioD = ''
            this.fngetComentarios();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        });
  }

  fngetComentarios() {

    let obj = {
      strAccion: 'getListComentario_Actividad',
      intID_Actividad: this.intIDActividad,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayComentarios = result
        }, error => {
          var error = <any>error; 
          (error);
          //this.spinerBool = false
        });
  }
  fngetctesXlinea(strLinea: string, intMes: number) {
    this.spinerModal = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientesXLineaDetalle",
      strLinea: strLinea,
      intPeriodo: intMes,
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }

    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayDetClientesXlinea = result;
        this.ObjModal = {
          Titulo: "CLIENTES POR LINEA",
          Subtitulo: "LISTADO DE CLIENTES POR LINEA",
          datos: this.arrayDetClientesXlinea,
          totalmov: this.arrayDetClientesXlinea.length,
          importetotal: 0,
          strMdal: 'ctePorLinea',
          strTipoCte: strLinea,
          intPeriodo: intMes
        }
        this.spinerModal = false;
      }, error => {
        
        ("error en: " + error)
        this.spinerModal = false;
      });

  }

  fngetfiltrar4500(det: string, intmes: number, strModal: string) {

    this.spinerModal = false;
    this.objReporte.strAccion = "getClientesXLineaDetalle";
    this.objReporte.intPeriodo = intmes
    this.objReporte.strSucursal = det
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayDetClientesXlinea = result;
        this.ObjModal.datos = this.arrayDetClientesXlinea
        this.ObjModal.totalmov = this.arrayDetClientesXlinea.length
        this.spinerModal = false;
        this.ObjModal.intPeriodo = intmes
      }, error => {
        
        ("error en: " + error)
        this.spinerModal = false;
      });

  }
  fnImporteTotalListCte(): number {
    let respTotal: number = 0;
    this.ObjModal.datos.forEach(element => {
      respTotal += element.dblImporteConFactor;
    });
    return respTotal;
  }
  fncteXlinea() {
    this.spinerNavTabs = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientesXLinea",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }

    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayClientesPorLinea = result;

        this.spinerNavTabs = false;
      }, error => {
        
        ("Error en: " + error);
        this.spinerNavTabs = false;
      });
  }
  fnget4500() {
    this.spinerTipoClientes = true;

    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientes4500",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte4500 = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }
  fngetCteXtipo() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientesXTipo",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }

    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayTipoCliente = result;

        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }

  fngetCtenuevos() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientesNuevosTotal",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }

    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayCteNuevos = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      });
  }


  fnabrirModal(x: string, objTipoCte: any = {}, intMes: number = 0, tipocte: string = "") {
    this.ObjModal = {}
    if (x == "tipoCte") {
      let accion = "";
      /* this.ObjModal.Tile */
      accion = tipocte == "7500" ? "getClientes4500Detalle" : tipocte == "Nuevos" ?
        "getClientesNuevosDetalle" : tipocte == "Total" ? "getClientesTotalDetalle" :
          tipocte == "10" ? "getClientes10000Detalle" :
            tipocte == "50" ? "getClientes50000Detalle" :
              tipocte == "100" ? "getClientes100000Detalle" :
                tipocte == "150" ? "getClientes150000Detalle" :
                  tipocte == "250" ? "getClientes250000Detalle" :
                    tipocte == "250+" ? "getClientesmas250000Detalle" :
                      "getClientesXTipoDetalle";
      this.fngetdet4500(objTipoCte, accion, intMes, tipocte);
    }


  }
  fngetdet4500(objC: any, det: string, intmes: number, strTipoCliente: string = "") {
    this.spinerModal = true
    this.objReporte = {
      strAccion: det,
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: intmes,
      strTipo: strTipoCliente
    }

    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayDetTipCliente = result;
        this.ObjModal = {
          Titulo: "Tipo clientes",
          Subtitulo: det == "getClientes4500Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 4500" : det == "getClientesNuevosDetalle" ?
            "Listado de Clientes Nuevos" : det == "getClientesTotalDetalle" ? "Listado de Clientes Total" :
              det == "getClientes10000Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MENOR A 10,000" :
                det == "getClientes50000Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 10,000 Y MENOR A 50,000" :
                  det == "getClientes100000Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 50,000 Y MENOR A 100,000" :
                    det == "getClientes150000Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 100,000 Y MENOR A 150,000" :
                      det == "getClientes250000Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 100,000 Y MENOR A 150,000 " :
                        det == "getClientesmas250000Detalle" ? "LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 250,000" :
                          "LISTADO DE TIPO DE CLIENTES",
          datos: this.arrayDetTipCliente,
          totalmov: this.arrayDetTipCliente.length,
          importetotal: 0,
          strMdal: 'tipoCte',
          strTipoCte: strTipoCliente,
          intPeriodo: intmes

        }
        this.objReporte.intPeriodo = this.intPeriodo;
        this.spinerModal = false;
      }, error => {
        
        ("Error en: " + error);
        this.spinerModal = false;
      }
    );
  }

  fnget10000() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientes10000",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte10000 = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }

  fnget50000() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientes50000",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte50000 = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }
  fnget100000() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientes100000",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte100000 = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }
  fnget150000() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientes150000",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte150000 = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }
  fnget250000() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientes250000",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte250000 = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }

  fnget250000mayor() {
    this.spinerTipoClientes = true;
    this.objReporte = {};
    this.objReporte = {
      strAccion: "getClientesMas250000",
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo
    }
    this._httpService.getLineasComodatos(this.objReporte, 'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arraycte250000mayor = result;
        this.spinerTipoClientes = false;
      }, error => {
        
        ("Error en: " + error.message);
        this.spinerTipoClientes = false;
      }
    );
  }

  fnModalRegistrar(tipo: string) {
    this.editableDiv2.nativeElement.innerHTML ='';
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo;
    this.arrayAgentesActividades = [];
    this.strBuscarAgente = "";
    this.arrayAgregarAnexosActividades = [];
    this.fileInputActividades.nativeElement.value = '';
  }

  fnAgregarSubTarea(){
    if(!this.boolAddSubTarea){
      this.strBuscarAgente = ""

    }
    this.boolAddSubTarea = this.boolAddSubTarea == true? false:true;
    
    (this.objSubTarea)
    this.arraySubTareas.forEach(item => {
      item.select = false;
    });
    

    this.fnSubTareasModal()
  }

  fnBusquedaCte(strBuscarAgente: string) {
    let texto = strBuscarAgente.toUpperCase();
    this.arrayAgente_Filter = [];
    if (!texto || texto == "") {
      this.arrayAgente_Filter = [];
      return;
    }
    for (const post of this.arrayAgentes_tareas) {
      let texto2 = post.strNombre.toUpperCase();
      if (texto2.indexOf(texto) > -1) {
        this.arrayAgente_Filter.push(post);
      }
    };
  }
   fnSeleccionaAgente(agt: any) {
      //this.strAgente = agt.strAgente;
      this.strBuscarAgente = "";/* agt.strAgente+'-'+agt.strNombre  */
      this.arrayAgente_Filter = [];
      let findAgente = this.arrayAgentesActividades.find(agtf => agtf.strAgente == agt.strAgente);
      if (!findAgente) {
        this.arrayAgentesActividades.push(agt)
      } else {
        Swal.fire("Error", "Agente existente, seleccione otro agente.", "error")
      }
    }
    fnEliminarAgenteAct(agt: any) {
      this.arrayAgentesActividades = this.arrayAgentesActividades.filter(item => item !== agt);
    }
    onInput(event: Event) {
      const target = event.target as HTMLElement;
      this.mdlComentarios = target.innerHTML; // Guarda el contenido HTML
      
      (this.mdlComentarios)
    }

    onFileSelectedActividad(event: any): void {
        let files: FileList = event.target.files;
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let newTotalSize = file.size;
            if (newTotalSize > 10 * 1024 * 1024) {
              Swal.fire({
                icon: 'warning',
                title: 'Límite excedido',
                text: 'El tamaño total de los archivos no puede superar los 10MB.',
              });
              return;
            }
    
            let nombreOriginal = file.name.split('.').slice(0, -1).join('.');
            let extension = file.name.split('.').pop();
    
            this.arrayAgregarAnexosActividades.push({
              nombreArchivo: nombreOriginal,
              extencion: extension,
              archivo: file
            });
          }
        }
      }
      fnAdjuntarArchivosActividad(): void {

        if (this.fileInputActividades) {
          this.arrayAgregarAnexosActividades = [];
          this.fileInputActividades.nativeElement.value = '';
          this.fileInputActividades.nativeElement.click();
        }
      }
      fnEliminarAnexosAct(): void {
        this.fileInputActividades.nativeElement.value = '';
        this.arrayAgregarAnexosActividades = [];
      }
      fnGetAgentes_tareas() {

        let obj = {
          strAccion: 'getAgentes_Leads',
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.arrayAgentes_tareas = result
            }, error => {
    
              console.error('Error al consumir la API:', error);
            });
    
      }



      fnAnexosActividad() {

        if (this.arrayAgregarAnexosActividades.length > 0) {
          let formData = new FormData();
          this.arrayAgregarAnexosActividades.forEach((o, index) => {
            if (o.archivo instanceof File) {
              formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
            } else {
              console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
            }
          });
          this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
            
            (result)
            this.arrayEnviarAnexosActividades = []
            let arrayRest = result;
            arrayRest.forEach(obj => {
              this.arrayEnviarAnexosActividades.push(
                {
                  strTipo: obj.strTipo,
                  strRespuesta: obj.file,
                  strNombre: obj.strNombre,
                  strAgente: this.strAgente,
                  strEstatus: ""
                });
            });
            
            (this.arrayEnviarAnexosActividades[0])
            this.fnEnviarActividad(this.arrayEnviarAnexosActividades[0])
          }, (error) => {
            
            (error);
          });
        } else {
          this.fnEnviarActividad()
        }
      }

       fnEnviarActividad(anexos: any = {}) {
          let xml: any = []
          if (this.arrayAgentesActividades.length > 0) {
            this.arrayAgentesActividades.forEach(item => {
              xml.push({ strAgente: item.strAgente, strNombreAgente: item.strNombre })
            });
          }
          if (this.strfecha == null || this.strfecha == undefined) {
            Swal.fire("Error", "seleccione una fecha correcta", "error");
          } else {
            let objEnv: { [key: string]: any } = {
              strAccion: "setActividad_Leads_Familia_Multiple", /* 'setActividad_Leads_Familia', */
              intID_TIPO:  6,
              strTitulo: this.mdlNombre,
              strAgente: "",/* this.strAgente */
              strFechaInicioServicio: this.convertDateToString(this.strfecha),
              
              strConexion: this.strConexion,
              strComentario: this.mdlComentarios,
              intID_Leads: this.objSubTarea.intID_Leads,
              intID_Actividad:this.objSubTarea.intID_Actividad,
              strAgente_Crea: this.strAgente_Crea,
              xmlArticulos: xml,
            }
            if (this.objModal.titulo != "Tarea") {
              objEnv.strHoraInicio = this.mdlHoraInicio;
              objEnv.strHoraFin = this.mdlHoraFin;
            }
            if (anexos && Object.keys(anexos).length > 0) {
              objEnv.strTipo_CIERRE = anexos.strTipo;
              objEnv.strUrl_Logo = anexos.strRespuesta;
              objEnv.strResultado = anexos.strNombre;
              objEnv.strMotivo = ""
            }
            
            (objEnv)
            this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                result => {
                  Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
                  if (result[0].code == "200") {
                    this.arrayAgentesActividades = [];
                    this.fnLimpiarSubTareas(this.objSubTarea)
                    this.fnSubTareasModal()
               
                    /* this.fnGetTimeLine();
                    this.btnCerrar.nativeElement.click(); */
                  }
                }, error => {
                  var error = <any>error; 
                  (error);
                }
              );
          }
        }
      
  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  fnAbrirSubtarea(obj:any){
    
    (obj)
  }

  fnSelectTarea(info: any,modal:boolean){
    this.boolModal = modal
    if(this.boolModal){
      if (this.modal3) {
        this.modal3.hide();
        this.modal3Ref.nativeElement.addEventListener('hidden.bs.modal', () => {
          this.modal2.show();
        }, { once: true });
      } else {
        this.modal2.show();
      }
    }else{
      if (this.modal1) {
        this.modal1.hide();
        this.modal1Ref.nativeElement.addEventListener('hidden.bs.modal', () => {
          this.modal2.show();
        }, { once: true });
      } else {
        this.modal2.show();
      }
    }
    


    this.obtenerAltoDivINfoGRAL3()
    this.fncargarsummerSubActividad()
    this.boolAddSubTarea = false;
    this.fnLimpiarSubTareas(info);
    this.fnSubTareasModal();
  }

  fnLimpiarSubTareas(info){
    
    (info)
    this.objSubTarea = info
    this.fngetSubtareas();
    this.contenido = "";
    this.strResultado = "";
    this.objAct = {};
    this.objAct.arrayAct = [];
    this.strComentario = "";
    // Llamar a fncargarsummer para reinicializar Summernote
    this.fncargarsummer();
    // Actualizar los datos después de reinicializar Summernote
    /*this.objAct = obj; */
    this.objAct.objAct = {};
    /*  this.strTituloModal = titulo; */
    this.altoModal = 0;
    setTimeout(() => {

      this.obtenerAltoDivINfoGRAL();
     /*  this.objAct.arrayAct = this.strTituloModal === 'Vencidas' ? this.arrayActAtrasadas : this.strTituloModal === 'Proximas' ? this.arrayActProximas : this.arrayActHoy;
      this.objAct.arrayAct.forEach(element => {

        element.select = false;
      }); */


    }, 2000);
  }
  fnSubTareasModal(){
    this.editableDiv2.nativeElement.innerHTML ='';
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = "";
    this.arrayAgentesActividades = [];
    this.strBuscarAgente = "";
    this.arrayAgregarAnexosActividades = [];
    this.fileInputActividades.nativeElement.value = '';
    
    ("Entra a limpiar modal")
  }
}
