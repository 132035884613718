<div class="container-fluid py-3 px-5 py-lg-4 bgPage">
    <!-- Informacion del cliente --> 
    <div class="row">
        <div class="col-12">
            <div class=" shadow cardrounded bg-white">
                <div class="bgcards ">
                    <div class="row ">
                        <div class="col-12 ">
                            <h1 class="mb-0 py-2 ps-4">
                                Información del cliente
                            </h1>
                        </div>
                        <div class="col-12">
                            <hr class="m-0">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row py-3">
                        <div class="col-12 col-md-2 d-flex justify-content-center align-items-center">
                            <mat-form-field class="col-10 " appearance="outline">
                                <mat-label>Cliente</mat-label>
                                <input [(ngModel)]="strNumCte" matInput placeholder="446010" (keyup)="fnBuscarCte(strNumCte);" matInput [matAutocomplete]="auto">
                                <span matSuffix class="material-symbols-outlined">
                                    account_circle
                                </span>
                            </mat-form-field>
                            <mat-autocomplete #auto="matAutocomplete" class="w-100">
                                <mat-option class="fs12 hoption" *ngFor="let option of arrayClientes; let i = index;"
                                  [value]="option.strCliente" (click)="fnllenarInputs(option,null,1);">
                                  {{option.strNombreMostrar}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div class="col-12 col-md-3 d-flex justify-content-center align-items-center">
                            <mat-form-field class="col-10" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input [(ngModel)]="objCliente.strNombre" matInput placeholder="{{strUsuario}}">
                                <span matSuffix class="material-symbols-outlined">
                                    badge
                                </span>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3 d-flex justify-content-center align-items-center">
                            <mat-form-field  class="col-10" appearance="outline" [disabled]="true">
                                <mat-label>Sucursal  </mat-label>
                                <mat-select [(ngModel)]="objCliente.strNombreSucursal" name="sucursal"  [disabled]="boolSelectSucursal">

                                    <mat-option class="py-3" [value]="''"[disabled]="true"  > 
                                        <input type="search" [(ngModel)]="strBuscar" class="form-control" placeholder="Buscar Sucursal" (click)="$event.stopPropagation()">
                                    </mat-option>
                                    <mat-option selected [value]="''" (click)="fnllenarInputs(objCliente,{},2);" >
                                   
                                        --Seleccione Sucursal--
                                    </mat-option>
                                
                                  <mat-option [ngClass]="{'d-none':food.strNombreSucursal == '' }"   *ngFor="let food of arraySucursalesCte | filtersearch: strBuscar: ['strNombreSucursal']" [value]="food.strNombreSucursal" (click)="fnllenarInputs(objCliente,food,2);"> <!-- .strNombreSucursal | slice:0:20-->
                                  <b>({{food.intIDSucursal}} ) </b>  {{food.strNombreSucursal}}
                                  </mat-option>
                                </mat-select>
                                <span matSuffix class="material-symbols-outlined">
                                    location_city
                                </span>
                            </mat-form-field>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Termina informacion del cliente -->
    <div class="row pt-4 d-flex justify-content-between align-items-start">
        <!--  card de productos -->
        <div class="col-12 mb-4 mb-lg-0  col-lg-7">
            <div class="shadow cardrounded bg-white ">
                <div class="row bgcards mx-0">
                    <div class="col-12 text-center">
                        <h1 class="mb-0 pt-2 ps-4">
                            Productos
                        </h1>
 
                    </div>
                    <div class="col-12">
                        <div class="form-check form-switch" *ngIf="intMuestraActivar_Plantilla==1">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="intModoPlantilla" [(ngModel)]="boolModoPlantilla" (change)="activaModoPlantilla()">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Modo Plantilla</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr class="mb-0">
                    </div>
                </div>
                <div class="py-3 cuerpProductos row ">
                    <div *ngFor="let obj of arrayCarritoArts; index as ind;" class="row mt-2 ">
                        <div class="col-12">
 
                            <div class="row d-flex justify-content-center align-items-center position-relative ">
                                <div class="alert alert-danger top-0 start-0 ms-4 " role="alert" 
                                   *ngIf="boolModoPlantilla && (  obj.precio_plantilla_valido ==1 && obj.precio_politica_valido ==0 &&  obj.precio_menor_plantilla ==0 )"> 
                                     El precio sugerido  debe ser mayor al precio mínimo  <b> Revisar con tu gerente</b>  
                                </div>
                                <div class="alert alert-danger top-0 start-0 ms-4 " role="alert" 
                                   *ngIf="boolModoPlantilla && (  obj.precio_politica_valido ==1 )"> 
                                     No puedes generar   plantilla para un artículo con precio politica  <b> Revisar con tu gerente</b>  
                                </div>

                                <div class="alert alert-danger top-0 start-0 ms-4 " role="alert" 
                                   *ngIf="boolModoPlantilla && (  obj.precio_menor_plantilla ==1  && obj.precio_politica_valido ==0)"> 
                                     No puedes generar   plantilla para un artículo con precio menor de la plantilla ya existente  <b> Revisar con tu gerente</b>  
                                </div>


                             
                                <div class="col-2 d-flex justify-content-center ">
                                    <img [attr.src]="'https://www.cleanclean.mx/imagenes/' + obj.strArticulo + '.JPG'"
                                        class="w-75 mx-auto" alt="">
                                </div>
                                <div class="col-9">
                                    <div class="row">
                                        <div class="col">
                                            <span class="fs-7 fw-bold">
                                                {{obj.strArticulo}}  
                                            </span>
                                            <span class="fs-7"> {{obj.strDescripcion1}}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-2 mb-3">
                                  
                                        <div class="col-3">
                                        

                                            <div class="dropdown dropend">
                                                <a class="btn btn-secondary dropdown-toggle" href="#" role="button"   data-bs-toggle="dropdown" aria-expanded="false">
                                                    <label *ngIf="!boolModoPlantilla">{{obj.dblPrecioLista |currency}}
                                                    </label>
                                                    <label *ngIf="boolModoPlantilla  "> {{obj.dblPrecioSugerido |  currency}}</label>
                                                </a>
                                                <ul class="dropdown-menu">
                                                    <li *ngIf="obj.intPrecioPolitica>0"
                                                        (click)="fnEligePrecio(obj.intPrecioPolitica, obj,2)">Politica
                                                        {{obj.intPrecioPolitica|currency}} </li>
                                                    <li *ngIf="obj.intPrecioPlantilla>0"
                                                        (click)="fnEligePrecio(obj.intPrecioPlantilla, obj,2)">
                                                        Plantilla {{obj.intPrecioPlantilla|currency}} </li>
                                                    <li *ngIf="obj.intPrecioLista>0"
                                                        (click)="fnEligePrecio(obj.intPrecioLista, obj,2)"> Prec. Lista:
                                                        {{obj.intPrecioLista|currency}} </li>
                                                    <li *ngIf="obj.intPrecio>0"
                                                        (click)="fnEligePrecio(obj.intPrecio, obj,2)"> <b>Precio 1</b>
                                                        {{obj.intPrecio|currency}} </li>
                                                    <li *ngIf="obj.intPrecio2>0"
                                                        (click)="fnEligePrecio(obj.intPrecio2, obj,2)"> Precio 2
                                                        {{obj.intPrecio2|currency}} </li>
                                                    <li *ngIf="obj.intPrecio3>0"
                                                        (click)="fnEligePrecio(obj.intPrecio3, obj,2)"> Precio 3
                                                        {{obj.intPrecio3|currency}} </li>
                                                    <li *ngIf="obj.intPrecio4>0"
                                                        (click)="fnEligePrecio(obj.intPrecio4, obj,2)"> Precio 4
                                                        {{obj.intPrecio4|currency}} </li>
                                                    <li *ngIf="boolBtn_preciominimo"
                                                        (click)="fnEligePrecio(obj.dblPrecioMinimo, obj,2)"> Precio Minimo
                                                        {{obj.dblPrecioMinimo|currency}} </li>
                                                    <li *ngIf="obj.strLinea!='TAPETES' && obj.strLinea!='SERVICIOS'">
                                                        <a class="dropdown-item"> *Precio Sugerido
                                                            <input type="number" class="form-control"
                                                                placeholder="Precio Sugerido"
                                                                aria-label="Precio Sugerido"
                                                                aria-describedby="basic-addon1"
                                                                [(ngModel)]="obj.dblPrecioSugerido"
                                                                [disabled]="!boolModoPlantilla"
                                                                (input)="fnEligePrecio(obj.dblPrecioSugerido,obj,2)"></a>
                                                    </li>
                                                    <li *ngIf="obj.strLinea=='TAPETES' || obj.strLinea=='SERVICIOS'"><a class="dropdown-item"> Precio Sugerido T
                                                        <input type="number" class="form-control"
                                                            placeholder="Precio Sugerido"
                                                            aria-label="Precio Sugerido"
                                                            aria-describedby="basic-addon1"
                                                            [(ngModel)]="obj.dblPrecioSugerido"
                                                            
                                                            (ngModelChange)="fnEligePrecio(obj.dblPrecioSugerido,obj,2)"></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="btn-group  mx-3  " role="group" aria-label="Basic example">
                                                <button type="button"
                                                    [ngClass]="{'text-primary':obj.dblCantidad>1,'text-black-50':obj.dblCantidad<=1}"
                                                    (click)="fnbuttomGroup(obj,'-')"
                                                    class="btn border border-end-0 fw-bolder  py-0 px-2 btn1"><span
                                                        class="fs-3">-</span></button>
                                                <input type="number" value="0" (keydown)="restric($event)" (input)="restrictInput($event, obj)"   type="text" [value]="obj.dblCantidad"
                                                    class="form-control text-center rounded-0 border-start-0 border-end-0 p-0 fs-7">
                                                <button type="button" (click)="fnbuttomGroup(obj,'+')"
                                                    class="btn border  border-start-0 fw-bolder text-primary py-0 px-2 btn1">+</button>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="px-3 d-flex flex-column rounded bg-light shadow-sm ">
                                                <span class="mx-auto fs-7">Precio total</span>
                                                <span class="mx-auto fw-bold fs-5">{{(obj.dblPrecioLista *
                                                    obj.dblCantidad)|currency}}</span>
                                            </div>
                                        </div>
                                        <!--</div>-->
                                    </div>
                                    <div class="row mb-3 mt-2 d-flex justify-content-between align-items-center">
                                        <div class="col">
                                            <div class="col-12  d-flex align-items-center px-0">
                                                <span class="fw-bolder mb-0 fs-7 me-2"
                                                    [hidden]="obj.strTipoOpcion == 'No'">
                                                    Opcion:
                                                </span>
                                                <div class="col-3 me-2" [hidden]="obj.strTipoOpcion == 'No'">
                                                    <select class="form-select shadow-sm" [(ngModel)]="obj.strOpcion"
                                                        aria-label=".form-select-lg example"
                                                        (change)="onOpcionSeleccionada(obj.strOpcion)">
                                                        <option *ngFor="let item of obj.arrayOpciones; let i = index;"
                                                            [ngValue]="item.strOpcion">
                                                            <span class="me-2"> <b>
                                                                    {{item.strOpcion}}</b></span>&nbsp;&nbsp;
                                                            <span>
                                                                {{item.strNombreOpcion}}
                                                            </span>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class=" px-3 border-start border-end">
                                                    <button matTooltip="Eliminar Articulo"
                                                        (click)="fnDeleteCar(ind,obj)"
                                                        class="btn btn-danger fs-7 p-2 shadow-sm d-flex align-items-center">
                                                        <i class="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                                <div class="px-3 me-auto border-end">
                                                    <button matTooltip="Agregar observación" data-bs-toggle="modal"
                                                        data-bs-target="#modalArticulo" (click)="fnmodal(obj,ind)"
                                                        class="btn btn-primary  fs-7 shadow  p-2 d-flex align-items-center">
                                                        <i class="bi bi-pin"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" [ngClass]="{'d-none':obj.strComentario == ''}">
                                        <div class="col-12">
                                            <span class="fw-bold mt-2">Comentario: </span> {{obj.strComentario}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center" *ngIf="ind<(arrayCarritoArts.length-1)">
                            <div class="col-10">
                                <hr class="my-2">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="mt-0">
                    </div>
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="redirectToCarrito();"
                            class="btn btn-primary mb-3 mx-3 d-flex align-items-center">
                            <span class="material-symbols-outlined">
                                arrow_back_ios_new
                            </span>
                            Continuar comprando
                        </button>
                        <button (click)="fnborrarCarrito();" class="btn btn-danger mb-3 mx-3 d-flex align-items-center">
                            <span class="material-symbols-outlined">
                                delete
                            </span>Vaciar carrito
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- fin card de productos -->

        <!--  card de Resumen -->
        <div class="col-12 col-lg-4">
            <div class="cardrounded bg-white shadow">
                <div class="bgcards ">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="mb-0 pt-2 ps-4">
                                Sub total
                            </h1>
                        </div>
                        <div class="col-12">
                            <hr class="mb-0">
                        </div>
                    </div>
                </div>
                <div class=" mt-4 p-0">
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <div class="row rounded shadow-sm mb-3 py-2 bg-light d-flex align-items-center">
                                <div class="col-5">
                                    <h3 class="mb-0"> Sub total</h3>
                                </div>
                                <div class="col-7 text-end">
                                    <span class="fw-bold fs-6">
                                        {{fnSubTotal()|currency}}
                                    </span>
                                </div>
                            </div>
                            <div class="row rounded shadow-sm my-2 py-2 bg-light d-flex align-items-center">
                                <div class="col-5">
                                    <h3 *ngIf="strConexion=='COMERCIAL'" class="mb-0">
                                        Iva(16%)
                                    </h3>
                                    <h3 *ngIf="strConexion=='DOMINICANA'" class="mb-0">
                                        ITBIS(18%)
                                    </h3>
                                </div>
                                <div class="col-7 text-end">
                                    <span class="fw-bold fs-6">
                                        {{fnSubiva()|currency}}
                                    </span>
                                </div>
                            </div>
                            <div class="row rounded my-3 py-2 shadow-sm bg-light d-flex align-items-center">
                                <div class="col-4">
                                    <h2 class="mb-0 fw-bolder">
                                        Total:
                                    </h2>
                                </div>
                                <div class="col-8 text-end">
                                    <span class="fw-bold fs-5">
                                        {{fnTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                            *ngIf="strConexion=='DOMINICANA'">DOP</span>
                                    </span>
                                </div>
                            </div>
                            <!--Comentarios-->
                            <div class="row rounded my-3 py-2 shadow-sm bg-light d-flex align-items-center"
                                *ngIf="boolModoPlantilla">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Leave a comment here"
                                        id="floatingTextarea" [(ngModel)]="strComentarios"></textarea>
                                    <label for="floatingTextarea">Comentarios</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="">
                    </div>
                    <div class="col-12 d-flex  flex-column ">
                        
                            <div class="col  mx-3 d-flex align-items-center justify-content-center ">
                                <div class="input-group mb-3">
                                    <input #file type="file" required  accept=".xlsx, .pdf, .png, .jpg, .jpeg" 
                                    (change)="agregarOrden($event)" class="form-control"
                                     placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2">
                                    <button class="btn btn-primary" type="button" id="button-addon2"  (click)="subirOrdenCompra()" ><i class="fa-solid fa-cloud-arrow-up" ></i> Subir</button>
                                </div>
                            </div> 
                         
                       <!--<div class="mb-3 m-auto col-8">
                            <input class="form-control mb-3 col-4" #file type="file" required  accept=".xlsx, .pdf, .png, .jpg, .jpeg" (change)="agregarOrden($event)"> 
                            <button (click)="subirOrdenCompra()" class="btn btn-primary col-4"><i class="fa-solid fa-cloud-arrow-up"></i> Subir Archivo</button>
                        </div>-->
                         
                        <button [disabled]="arrayCarritoArts.length<1"
                            *ngIf="(strTipo_Accion_Cotizacion =='VER' || strTipo_Accion_Cotizacion =='') &&flagProspecto && !boolModoPlantilla"
                            (click)="fnimprimirprospecto();" data-bs-toggle="modal" data-bs-target="#prospecto"
                            matTooltip="Generar pdf"
                            class="mx-auto  col-8 btn btn-primary mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-file-earmark-pdf me-3"></i>  Pdf
                        </button>
                        
 <!-- && !boolModoPlantilla  -->
                        <button [disabled]="arrayCarritoArts.length<1"  
                        *ngIf="!flagProspecto  
                        && ( strTipo_Accion_Cotizacion =='VER' ||  strTipo_Accion_Cotizacion =='')    " 
                            matTooltip="Generar pdf" (click)="fncreatepdf();"
                            class="mx-auto  col-8 btn btn-secondary mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-file-earmark-pdf me-3"></i> Pdf
                        </button>
 

                        <button [disabled]="arrayCarritoArts.length<1|| fnactivarboton()" 
                        (click)="fnGuardarPlantilla('CLIENTE')"   *ngIf="(boolModoPlantilla  && tipoCliente!='PROSPECTO') ||  
                       ( editPlanActive && tipoCliente!='PROSPECTO' )"                          
                            class="mx-auto col-8 btn  btn-primary mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-file-earmark-bar-graph me-2"></i> Plantilla  
                        </button> 

                       <button [disabled]="arrayCarritoArts.length<1|| fnactivarboton()"      *ngIf="(boolModoPlantilla  && tipoCliente!='PROSPECTO' && btnPolitica) ||  
                       ( editPlanActive && tipoCliente!='PROSPECTO'  && btnPolitica) "                          
                            class="mx-auto col-8 btn  btn-info mb-3 mx-3 d-flex align-items-center justify-content-center shadow" data-bs-toggle="modal" data-bs-target="#modalPolitica"
                            >
                            <i class="fa-regular fa-file-powerpoint me-2"></i> Política  
                        </button> 
                        
                        
                        <!--  *ngIf="boolModoPlantilla && !editPlanActive  && tipoCliente!='PROSPECTO'"
                        matTooltip="Generar Plantilla" "-->
                        <button [disabled]="arrayCarritoArts.length<1 " *ngIf="editPlanActive"
                            matTooltip="Generar Plantilla" (click)="fnsetEditPlantilla()"
                            class="mx-auto col-8 btn  btnEditPlantilla mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-pencil-square me-2"></i> Terminar Edición
                        </button>
               
 
                        <button [disabled]="arrayCarritoArts.length<1 " data-bs-toggle="modal"
                            *ngIf="tipoCliente!='' && tipoCliente!='PROSPECTO' 
                            && !boolModoPlantilla && boolPrecio_dif_Plantilla  "
                            (click)="fnOpenModalPedido();" data-bs-target="#exampleModal" matTooltip="Enviar Pedido"
                            class="mx-auto col-8 btn  btnEnviar mb-3 mx-3 d-flex align-items-center justify-content-center shadow"> <!--&& !editPlanActive-->
                            <i class="bi bi-send me-3"></i> Pedido
                        </button>
                        <!--&& !boolModoPlantilla-->
                        <button [disabled]="arrayCarritoArts.length<1 "
                            *ngIf=" btnGuardar_Cotizacion && strTipo_Accion_Cotizacion !='VER'   && !editPlanActive"
                            matTooltip="Generar cotización" (click)="fnGuardarCotizacion()"
                            class="mx-auto col-8 btn text-white btn-success mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-save me-2"></i>    Guardar Cotización  
                        </button>

                        <button [disabled]="arrayCarritoArts.length<1 || fnactivarboton()"
                            *ngIf="strTipo_Accion_Cotizacion !='VER' && !editPlanActive 
                            && boolModoPlantilla && (objCliente.strTipo && objCliente.strTipo=='PROSPECTO')
                            &&(objCliente.strTipoMovimiento &&
                            (objCliente.strTipoMovimiento=='COTIZACION_LEADS'||objCliente.strTipoMovimiento=='COTIZACION_OPORTUNIDAD'))" 
                            matTooltip="Generar cotización"
                            (click)="fnGuardarPlantilla('PROSPECTO')"
                            class="mx-auto col-8 btn text-white btn-warning mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-save me-2"></i> Guardar Cotización Prospecto
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <!-- fin card de Resumen -->
    </div>



    <!-- Inicia modal -->
    <div class="modal fade" id="modalArticulo" tabindex="-1" aria-labelledby="modalArticuloLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header py-2 bg-whitees">
                    <h1 class="modal-title mb-0 fw-bold" id="modalArticuloLabel">Informacion del producto</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-0">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 text-secondary">
                                    Articulo
                                </div>
                                <h2 class="fw-bold">
                                    {{objArtModal.strArticulo}}
                                </h2>
                            </div>
                            <div class="row">
                                <div class="col-12 text-secondary">
                                    Descripción
                                </div>
                                <p class="fs-7 fw-bold">
                                    {{objArtModal.strDescripcion}}
                                </p>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12 text-secondary">
                                    Comentario
                                </div>
                                <textarea class="form-control px-2" [(ngModel)]="objArtModal.strComentario"
                                    rows="3"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center ">
                            <img [src]="'https://www.cleanclean.mx/imagenes/' + objArtModal.strArticulo+ '.JPG'"
                                class="w-75 mx-auto" alt="">
                        </div>
                    </div>
                </div>
                <div class="modal-footer py-2 bg-whitees">

                    <button type="button" class="btn btn-primary me-auto" (click)="fnguardarComentario()">Guardar
                        cambios</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        #btnCerrarModal>Cerrar</button>

                </div>
            </div>
        </div>
    </div>
    <!-- Fin modal   style="visibility: hidden;"  [hidden]="!vartri"-->

    <!--modal  politica-->
    <!-- Modal -->
    <div class="modal fade" id="modalPolitica" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bgModalPedido">
                    <h2 class="mx-auto modal-title mb-0 text-white fw-bold" id="exampleModalLabel">Política
                    </h2>
                    <button type="button" #btnCerrarModalPedido class="btn-close ms-0" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3">
                        <div class="col-12  align-items-center">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Nombre Convenio</label>
                                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="ingresa el nombre y  número del convenio" [(ngModel)]="strNombreConvenio" maxlength="50" (input)="fnValidarPolitica()">
                              </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6 d-flex align-items-center">
                            <div class="mb-3">
                              <label for="fechaInicio" class="form-label">Fecha Inicio</label>
                              <input type="date" class="form-control" id="fechaInicio" [(ngModel)]="fechaInicio" (input)="fnValidarPolitica()">
                            </div>
                          </div>
                          
                          <div class="col-6 d-flex align-items-center">
                            <div class="mb-3">
                              <label for="fechaFin" class="form-label">Fecha Fin</label>
                              <input type="date" class="form-control" id="fechaFin" [(ngModel)]="fechaFin" (input)="fnValidarPolitica()">
                            </div>
                          </div>
                          
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" [(ngModel)]="boolNivelCliente" (change)="fnPolitica_niveles('cliente')"  >
                                <label class="form-check-label" for="flexCheckIndeterminate">
                                  Nivel Cliente
                                </label>
                              </div>
                        </div>
                        <div class="col-8  d-flex   align-items-center">
                          {{objCliente.strCliente}} {{objCliente.strNombre}}
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="objCliente.strFamilia !=''">
                        <div class="col-4 d-flex align-items-center">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" [(ngModel)]="boolNivelFamilia" (change)="fnPolitica_niveles('familia')"  >
                                <label class="form-check-label" for="flexCheckIndeterminate">
                                  Nivel Familia
                                </label>
                              </div>
                        </div>
                        <div class="col-8  d-flex   align-items-center">
                          {{objCliente.strFamilia}}  
                        </div>
                    </div>
                    <div class="row mt-3"> <!--adjuntar documentos-->
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Anexar Convenio</label>
                            <input class="form-control" type="file" id="formFile"  (change)="seleccionarArchivo($event)" >
                          </div>
                    </div>
                    <div class="row mt-3"> <!--observaciones-->
                        <div class="form-floating">
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" [(ngModel)]="strObservacionesConvenio" ></textarea>
                            <label for="floatingTextarea">Comentarios</label>
                          </div>

                    </div>
                   
                
                   
                </div>
                <div class="modal-footer">
                  
                    <button type="button" class="btn btn-primary"  (click)="fnEnviarPolitica_docto()" [disabled]="btnEnviar_politica" >Enviar</button>
                     
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarModalPolitica>Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin modal politica -->
    <!--modal  Pedido-->
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bgModalPedido">
                    <h2 class="mx-auto modal-title mb-0 text-white fw-bold" id="exampleModalLabel">Encabezado del pedido
                    </h2>
                    <button type="button" #btnCerrarModalPedido class="btn-close ms-0" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row bg-light py-3">
                        <div class="col-4 d-flex align-items-center">
                            <span>Fecha Requerida:</span>
                        </div>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="strfechaRequerida" [min]="fechaMinima"  type="date" (keydown)="false">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span><span class="text-danger">*</span>Sucursal:</span>
                        </div>
                        <div class="col-8  d-flex   align-items-center">
                            <mat-form-field  class="col-10" appearance="outline">
                                <mat-label>Sucursal  </mat-label>
                                <mat-select [(ngModel)]="objCliente.strNombreSucursal" name="sucursal" >
                                    <mat-option class="py-3" [value]="''"[disabled]="true"  > 
                                        <input type="search" [(ngModel)]="strBuscar" class="form-control" placeholder="Buscar Sucursal" (click)="$event.stopPropagation()">
                                    </mat-option>
                                    <mat-option selected [value]="''" (click)="fnllenarInputs(objCliente,{},2);" > <!--(selectionChange)="onSelectionChange($event,objCliente)""-->
                                        --Seleccione Sucursal--
                                    </mat-option>
                                
                                  <mat-option [ngClass]="{'d-none':food.strNombreSucursal == '' }"   *ngFor="let food of arraySucursalesCte | filtersearch: strBuscar: ['strNombreSucursal']" [value]="food.strNombreSucursal" (click)="fnllenarInputs(objCliente,food,2);"> <!-- .strNombreSucursal | slice:0:20-->
                                  <b>({{food.intIDSucursal}} ) </b>  {{food.strNombreSucursal}}
                                  </mat-option>
                                </mat-select>
                                <span matSuffix class="material-symbols-outlined">
                                    location_city
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span><span class="text-danger">*</span>Condiciones de pago:</span>
                        </div>
                        <div class="col-8">
                            <input placeholder="Pago CE" class="form-control" type="text"
                                [(ngModel)]="objCliente.strCondicion" disabled>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span><span class="text-danger">*</span>Uso de CFDI:</span>
                        </div>
                        <div class="col-8">
                            <input class="form-control" placeholder="G03 Gastos en general" type="text" [(ngModel)]="objCliente.strClaveUsoCFDI+' '+objCliente.strDescripcionUsoCFDI" disabled>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span><span class="text-danger">*</span>Almacén:</span>
                        </div>
                        <div class="col-8">
                            <input class="form-control" placeholder="ACTIFIJO" type="text" [(ngModel)]="objCliente.strAlmacen" disabled>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span><span class="text-danger">*</span>Forma de pago:</span>
                        </div>
                        <div class="col-8">
                            <input class="form-control" placeholder="03 Transferencia electronica de fondos" type="text"
                                [(ngModel)]="objCliente.strInfopago +' '+objCliente.strInfopagoDescripcion" disabled>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span>Referencia:</span>
                        </div>
                        <div class="col-8">
                            <input class="form-control" placeholder="Referencia"
                                (input)="checkInputLength($event,'referencia')" [(ngModel)]="strReferencia" type="text"
                                maxlength="50">
                            <div *ngIf="execede50" class="text-danger">Solo se aceptan 50 caracteres</div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span>Orden de compra:</span>
                        </div>
                        <div class="col-8">
                            <input class="form-control" placeholder="Orden"
                                (input)="checkInputLength($event,'ordenCompra')" [(ngModel)]="strOrdenCompra"
                                type="text" maxlength="50">
                            <div *ngIf="execede51" class="text-danger">Solo se aceptan 50 caracteres</div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span>Comentarios:</span>
                        </div>
                        <div class="col-8">
                            <textarea class="form-control" (input)="checkInputLength($event,'comentario')"
                                placeholder="Comentarios" [(ngModel)]="strComentarios" name="" id="" cols="30" rows="2"
                                maxlength="100"></textarea>
                            <div *ngIf="execede100" class="text-danger">Solo se aceptan 100 caracteres</div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 d-flex align-items-center">
                            <span>Observaciones:</span>
                        </div>
                        <div class="col-8">
                            <textarea class="form-control" (input)="checkInputLength($event,'observacion')"
                                placeholder="Observaciones" [(ngModel)]="strObservaciones" name="" id="" cols="30"
                                rows="2" maxlength="100"></textarea>
                            <div *ngIf="execede101" class="text-danger">Solo se aceptan 100 caracteres</div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 text-center">
                             
                            <span *ngIf="!boolMostrarBotonExportar"> Por Favor Espera mientras se procesa el pedido</span>
                            <span *ngIf="!strfechaRequerida || strfechaRequerida ==''" class="text-danger mb-1"> Selecciona la fecha requerida</span>  <br>
                            <button type="button" class="btn btn-primary" (click)="fnInsertarPedido()" *ngIf="boolMostrarBotonExportar" [disabled]="!strfechaRequerida || strfechaRequerida ==''">Exportar</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarModalPedido>Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin modal Pedido -->
</div>

<div class="modal fade" id="prospecto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mb-0" id="exampleModalLabel">Agregar Prospecto</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Prospecto</label>
                    <input type="email" class="form-control" [(ngModel)]="strProstectro" id="exampleFormControlInput1"
                        placeholder="JUAN FRANCISCO">
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Empresa</label>
                    <input type="email" class="form-control" [(ngModel)]="strEmpresaProspecto"
                        id="exampleFormControlInput1" placeholder="HOTEL WROM PLAZA">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btncerrar>Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="fncreatepdf();">Guardar PDF</button>
            </div>
        </div>
    </div>
</div>

<div  [hidden]="!vartri"> <!--[hidden]="!vartri"-->
    <div class="container " #pdfTableFiniquito>
        <div class="row exPdfBordePrincipal p-1 mb-4 d-flex justify-content-center">
            <div class="row exPdfBordePrincipal p-1 ">
                <div class="col-4 d-flex align-items-center">
                    <img src="assets/img/logo/ecodeli.png"   alt="" style="width: 60% !important;">
                </div>
                <div class="col-5 d-flex align-items-center justify-content-center">
                    <h3 class="fs-1 mb-0">Cotización</h3> 
                </div>
                <div class="col-3 d-flex align-items-center justify-content-end">
                    <h3 class="fs-5 mb-0">Fecha: {{currentDate}}</h3>
                </div>
            </div>
        </div><!-- *ngIf="objCliente.ClienteSeleccionado"  *ngIf="flagProspecto"-->

        <div class="row  cardrounded bg-whitees shadow" *ngIf="flagProspecto && conp">
            <div class="mx-auto mt-2 text-center col-12 fs-5 fw-bold py-1  ">
                Cliente
                <div class="row d-flex justify-content-center">
                    <div class="col-10">
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row  mb-3  d-flex justify-content-between ">
                <div class="col-6 d-flex">
                    <div style="width: 25%;" class="fw-bold fs-7">Con atención a:</div>
                    <div style="width: 75%;" class="fs-8">{{strProstectro}}</div>
                </div>
                <div class="col-6 d-flex">
                    <div style="width: 25%;" class="fw-bold fs-7">Empresa:</div>
                    <div style="width: 75%;" class="fs-8"> {{strEmpresaProspecto}}</div>
                </div>
            </div>
        </div>

        <div class="row  cardrounded bg-whitees shadow" *ngIf="objCliente.ClienteSeleccionado">
            <div class="mx-auto mt-2 text-center col-12 fs-7 fw-bold py-1  ">
               Información del Cliente
               <hr class="mt-1">
               
            </div>
            <div class="row  mb-3  d-flex justify-content-between ">
                <div class="col-6 d-flex">
                    <div style="width: 30%;" class="fw-bold fs-7">Con atención a:</div>
                    <div style="width: 75%;" class="fs-8"> <b>{{objCliente.strCliente}}</b> {{objCliente.strNombre}}</div>
                </div>
                <div class="col-6 d-flex">
                    <div style="width: 25%;" class="fw-bold fs-7">Dirección:</div>
                    <div style="width: 75%;" class="fs-8"> {{objCliente.strDireccion}}</div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <table class="table table-bordered  ">
                    <thead class="bgTablehead text-white">
                        <tr>
                            <th class="text-center fs-7 border-top bgtableHead">Cantidad</th>
                            <th class="text-center fs-7 border-top bgtableHead">Producto</th>
                            <th class="text-center fs-7 border-top bgtableHead">Descripcion</th>
                            <th class="text-center fs-7 text-nowrap border-top bgtableHead">Precio Unit.</th>
                            <th class="text-center fs-7 border-top bgtableHead">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let x of arrayCarritoArts;let ind = index">
                            <td class="text-center align-middle fs-7" [ngClass]="{'bgtablepdf':ind % 2==0}"> {{x.dblCantidad}}</td>
                            <td class="text-center" style="width: 150px;" [ngClass]="{'bgtablepdf':ind % 2==0}">
                                <div>
                                    <!-- <img [src]="x.base64Image"  class="w-75 mx-auto" alt=""> -->
                                    <img [src]="'https://www.ecodeli.com/img/PaginaWeb/' + x.strArticulo + '.JPG'"
                                        class="w-50 mx-auto" alt="">
                                </div>
                            </td>
                            <td class="text-start  fs-8 " [ngClass]="{'bgtablepdf':ind % 2==0}">
                                {{x.strDescripcion1}}. {{x.strDescripcion}}
                                <div class="fw-bold mt-1" *ngIf="x.strTipoOpcion=='Si'">
                                    Opcion:  {{ getNombreOpcion(x) }} <!-- {{x.strNombreOpcion}} -->
                                </div>
                                <div class="fw-bold mt-1 ">
                                    Clave: {{x.strArticulo}} /Marca : {{x.strFamilia}}
                                     <div class="fw-normal" *ngIf="x.strComentario!=''">
                                        Comentario: {{x.strComentario}}</div>
                                </div>
                            </td>
                            <td class="text-end align-middle fs-7 " [ngClass]="{'bgtablepdf':ind % 2==0}">{{x.dblPrecioLista|currency}}</td>
                            <td class="text-end align-middle fs-7 fw-bold" [ngClass]="{'bgtablepdf':ind % 2==0}">{{x.dblPrecioLista * x.dblCantidad|currency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-4 avoid-break">
            <div class="col-12 d-flex justify-content-between">
                <div class="ms-auto col-4 texto_p">
                    PRECIOS SUJETOS A PREVIA AUTORIZACIÓN.
                    ESTE ES UN DOCUMENTO NO OFICIAL.
                    VALIDO POR 1 DÍA A PARTIR DE LA EMISIÓN DE DOCUMENTO.
                </div>
                <div class="col-4 ms-auto">
                    <div class="row">
                        <div class="col-6 text-end text-nowrap fs-7 fw-bold">
                            SUB TOTAL:
                        </div>
                        <div class="col-5 text-nowrap text-end fs-7 fw-bold me-auto">
                            {{ fnSubTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                *ngIf="strConexion=='DOMINICANA'">DOP</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-end text-nowrap fs-7 fw-bold">
                            <span *ngIf="strConexion=='COMERCIAL'">IVA 16%:</span><span
                                *ngIf="strConexion=='DOMINICANA'">ITBIS 18%:</span>
                        </div>
                        <div class="col-5 text-nowrap text-end fs-7 fw-bold me-auto">
                            {{ fnSubiva()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                *ngIf="strConexion=='DOMINICANA'">DOP</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-end text-nowrap fs-7 fw-bold">
                            TOTAL:
                        </div>
                        <div class="col-5 text-nowrap text-end fs-7 fw-bold me-auto">
                            {{fnTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                *ngIf="strConexion=='DOMINICANA'">DOP</span>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="row mt-3">
            <div class="mx-auto col-6 ">
                <div class="col-12 text-center">
                    ATENTAMENTE
                </div>
                <div class="pt-3 mx-auto col-9">
                    <hr>
                </div>
                <div class="pt-2 mx-auto text-center col-9">
                    {{strUsuario}}
                </div>
                <div class="pt-1 mx-auto text-center col-9">
                    {{strCorreoUser}}
                </div>
            </div>
        </div>
    </div>
</div>