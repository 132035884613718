import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import * as html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import { PeriodicElement } from '../reportecomodatos/reportecomodatos.component';
import { FiltrosService } from '../servicios/filtros.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AltaClienteService } from '../servicios/alta-cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { historicovtaxlinea } from '../directorio/directorio.component';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataDBService } from '../servicios/data-db.service';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { hasBgRendering } from '@fullcalendar/core/internal';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var $: any;

interface Food {
  value: string;
  viewValue: string;
}
export interface tareasCte {
  strEstatus: string;
  strTitulo: string;
  strOrigen: string;
  strAgente: string;
  strFechaRequerida: string;
  fechaVencimiento: string;
  progreso: string;

}
@Component({
  selector: 'app-detalle-leads',
  templateUrl: './detalle-leads.component.html',
  styleUrls: ['./detalle-leads.component.css']
})

export class DetalleLeadsComponent implements OnInit {
  displayedColumns: string[] = ['#', 'Imagen', 'strArticulo', 'strDescripcion', 'intCotizaciones', 'dblCantidad'];
  displayedColumnsCotizacion: string[] = ['#', 'Imagen', 'strArticulo', 'strDescripcion', 'strOpcion', 'strUnidad', 'dblCantidad', 'dblPrecioSugerido', 'dblPrecio', 'dblImporte'];
  columnsTareasCte: string[] = ['strEstatus', 'strTitulo', 'strAgente', 'strOrigen', 'strFechaRequerida'];/* , 'fechaVencimiento' ,'progreso'*/
  dataSource: MatTableDataSource<PeriodicElement>;
  public objEditarActividad: any = {};
  dataSourceCotizaciones: MatTableDataSource<PeriodicElement>
  public booltitl: boolean = false;
  public strNombre_ruta: string = "";
  public objRuta_Anterior: any = {};
  public banderaPreguntaCambioFase: boolean = false
  public arraySecciones: any = []
  public strTipoLead: any = ""
  public strArticulo: string = ''
  public tabSeleccionada: any = 0
  public objInfo: any = {};
  public intEditInfoGeneral: number = 0;  // 0 no editable y 1 editable
  public strMensaje_cierre_1: string = '';
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  public strMensaje_cierre_2: string = '';
  @ViewChild('paginVtasXlin') paginador: MatPaginator;
  @ViewChild('swiperContainer') swiperContainer!: ElementRef;
  @ViewChild('btnCerrarAct') btnCerrarAct!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('inputComentarios') inputComentarios!: ElementRef;
  @ViewChild('fileInput2') fileInput2!: ElementRef;
  dataHisXvta = new MatTableDataSource<historicovtaxlinea>()
  @ViewChild('pdfTableInfoAdd', { static: true }) el!: ElementRef
  @ViewChild('pdftableFlujoCte', { static: true }) tableFlujoCte!: ElementRef
  @ViewChild('fileLogo') fileLogo!: ElementRef;
  @ViewChild('mdlSuc') btnCerrarMdlSuc!: ElementRef;
  datosTareasCte: MatTableDataSource<tareasCte>;
  public inputFlujoCte: string = "";
  public spinerHisVta: boolean = false;
  public intPeriodo: number;
  public arrayLineas2: any = []
  //public formData: FormData = new FormData();
  public formData: FormData;
  public arrayBuscarClientes: any = []
  public arrayHistoricoVta: any = [];
  public intEjercicio: number;
  public cotizacionRec: any = [];
  public formMinuta: FormGroup;
  public strBuscarAgente: string;
  public objModalHistorico: any = {};
  public strAgenteLD: string = '';
  public int15dias: number;
  public strNombreCorto: any = ''
  public int30dias: number;
  public int60dias: number;
  public int90dias: number;
  public int90mas: number;
  public arraySelect_suc_tareas: any = []
  public intIDActividad: any;
  public arrayNombresSecciones: any = []
  public strBuscar: string = "";
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  @ViewChild('sortArtFav') sortArtFav: MatSort;
  @ViewChild('ingoGralDiv') ingoGralDiv!: ElementRef;
  @ViewChild('fileModal') fileModal!: ElementRef;
  @ViewChild('fileInputActividades') fileInputActividades!: ElementRef;
  @ViewChild('btnCerrarMinuta') btnCerrarMinuta!: ElementRef;
  public altInfo: any;
  public objHisxLin: any = {
    strEjercicio: "", strPeriodo: "", dblAccesorios: 0, dblAccesoriosMargen: 0,
    dblKimberly: 0, dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
    , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0, dblQuimicosMargen: 0,
    dblDespachadores: 0, dblDespachadoresMargen: 0, dblTork: 0, dblTorkMargen: 0,
    dblXibu: 0, dblXibuMargen: 0, dblFoaom: 0, dblFoaomMargen: 0, total: 0
  }
  public arraySectores: any = []
  public arrayAgente_Filter: any = [];
  public arraySubGiro: any = []
  public arraySubGiroTotal: any = []
  public arraySegundaLetra: any = []
  public arrayFitroSegunda: any = []
  public arrayTercerLetra: any = []
  public arrayPotenciador: any = []
  public arrayFiltroTercera: any = []
  @ViewChild('btnCerrarEdit') btnCerrarEdit!: ElementRef;
  public arrayEnviarAnexosActividades: any = []
  public arrayAnexMdlAdd: any = []
  public strPotencializador: any = ''
  public intId_segunda: any
  public intId_tercera: any = 0
  public panelOpenState = false;
  public arrayAjusteLineas: any = [];
  public strDescripcionArticulo: any = ''
  public arrayVtaXart: any = [];
  public strTipo: string = "lead";
  public arrayOportunidades: any = []
  public inputSearchInfoAdd: string = "";
  public strConexion: string = "";
  public strSucursal: string = "";
  public strNombreMostarCliente: any = ''
  public strSucursal_modal: string = "";
  public strGerente: string = "";
  public strGerente_modal: string = "";
  public strAgente: string = "";
  public excelData: any = []
  public excelDataAux: any = []
  public activeButton: string = "cards";
  public strClasificacion: string = ""
  public intTotalImportFCte: number;
  public intFlagActividad: any
  public arrayTipoCotizacion: any = []
  public arrayCarpetas: any = []
  public strNombreCarpeta: any = ''
  public arrayAnexosModal: any = []
  public boolGerente: boolean = false;
  public boolSucursal: boolean = false;
  public boolAgente: boolean = false;
  public isHoveredButton: boolean = false;
  public maxLengthComentario: number = 100;
  public arraySucursales: any = [];
  public arrayGerentes: any = [];
  public arrayAccesos: any = [];
  public arrayEjercicio: any = [];
  public arrayPeriodo: any = [];
  public arrayGiros: any = [];
  public arrayTamanoEmpresa: any = [];
  public arrayPresencia: any = [];
  public arrayUbicaciones: any = [];
  public array_por_contactar: any = [];
  public strEstatusCotizacion: any = ''
  public intIDMovimientoVenta: number = 0
  public myForm: FormGroup;
  public formLead: FormGroup;
  public formOportunidades: FormGroup;
  public intFlagAvanza4: any
  public arrayHistoricoXlinea: any = [];
  public intFlagAvanza5: any
  public tagName: any;
  public filterPost: any;
  public objAlta: any = []
  public arrayLisFamilia: any = [];
  public strTipoCotizacion: string = "";
  public intTotalSucursales: any = '';
  public strRazonSocial: any = '';
  public strSitioWeb: any = '';
  public arrayBusquedaArticulo: any = []
  public strRFC: any = '';
  public strFechaEstimadaCierreInput: any = '';
  public dblImporte: string = "";
  public strPrioridad: string = "";
  public strNombreEmpresa: string = "";
  public strEtapa: string = "Por Contactar";
  public strSector: string = "";
  public spComentarioGeneral: any = ''
  public emailEsObligatorio: any;
  public emailEsValido: any;
  public calendarioLeads: any = []
  public ListadoHistorial: any = [];
  public inthov: boolean = false;
  public arrayPresentacion: any = [];
  public arrayRecorridoLead: any = [];
  public arrayRecorridoProspecto: any = [];
  public arrayPropuesta: any = [];
  public arrayNegociacion: any = [];
  public arrayCierre: any = [];
  public arrayPropuestaCliente: any = [];
  public arrayNegociacionCliente: any = [];
  public arrayContacto: any = [];
  public arrayCalendario: any = []
  public arrayCierreCliente: any = []; public strZona: any;
  public arrayEstadoCuenta: any = [];
  public spinnerPC: any
  public spinnerP: any
  public spinnerR: any
  public spinnerPER: any
  public arrayInfoAdicional: any = [];
  public arrayAgentes: any = [];
  public arrayAgentes_tareas: any = [];
  public boolConcluir:boolean = false;
  public arrayAsigAgentes: any = [];
  public intTotal_por_contactar: any = 0;
  public intTotal_presentacion: any = 0;
  public intTotal_recorrido_lead: any = 0;
  public intTotal_recorrido_prospecto: any = 0;
  public intTotal_propuesta: any = 0;
  public intTotal_negociacion: any = 0;
  public intTotal_cierre: any = 0;
  public intTotal_Perdido: any = 0;
  public intTotal_propuesta_cliente: any = 0;
  public intTotal_negociacion_cliente: any = 0;
  public intTotal_cierre_cliente: any = 0;
  public intFlagAvanza1: any
  public strAgenteSP: any;
  public strAgenteAsignado: any;
  public strfecha_editar: string;
  public strInicialesAgente: any = '';
  public strFechaActividadUltima: any;
  public strFechaPerdido: any;
  public dblMontoPorContactar: any = 0;
  public dblMontoPresentacion: any = 0;
  public dblMontoRecorridoLead: any = 0;
  public dblMontoRecorridoProspecto: any;
  public dblMontoPropuesta: any;
  public dblMontoNegociacion: any;
  public strComentarioD: string = ''
  public arrayComentarios: any = []
  public mdlCerrarFecha: Date;
  public mdlCerrarImporte: number;
  public mdlCerrarMotivo: string = "";
  public dblMontoCierre: any;
  private unsubscribe$ = new Subject<void>();
  public dblMontoPropuestaCliente: any;
  public dblMontoNegociacionCliente: any;
  public dblMontoCierreCliente: any;
  public diferenciaDias: any;
  public diferenciaHoras: any;
  public diferenciaMinutos: any;
  public strActividadSimple: any = '';
  public intValidacionFamilia: number = 0
  public arrayAnexosUpdate: any = [];
  public strMotivoCancelado: any;
  public strMotivoPerdido: any;
  public strFechaActividad: any;
  public intDuracionActividad: any = 0;
  public strMensaje: any = ''
  public strComentario: any;
  public strResultado: any = '';
  public strTipoActividad: any = '';
  public strEstatus: any;
  public arrayArticuloAgregados: any = []
  public spinerVtaXart: boolean = false;
  public strDescripcionActividad: any;
  public intTiempoDias: any = 0;
  public strNumeroCliente: any = '';
  public intTiempoHoras: any = 0;
  public banderaCierre: any = true;
  public banderaFuturo: any = false;
  public cotizacionAcep: any = []
  public guardarHabilitado: any = true;
  public strCoordinadorOperativo: any;
  public objCliente: any = []
  public arrayLineasSelect: any = [];
  public intGiro: any = '';
  public strLinea: string = "";
  public strFamilia: any = '';
  public arrayArts: any = [];
  public strMedioConocio: any = 'VENDEDOR';
  public strProbabilidad: any = '';
  public strFechaEstimadaCierre: any = '';
  public spinerInfAdd: boolean = false;
  public strFechaCancelado: any;
  public strNumeroEmpleados: any = '';
  displayhisvtas: string[] = ['ejercicio', 'periodo', 'acces', 'margenA', 'kimberly', 'margenk', 'rubermaid', 'margenru', 'bluetisue', 'margenblu', 'quimicos', 'margenqu', 'despachadores', 'margendes', 'tork', 'margentork', 'xibu', 'margenx', 'foaom', 'margenf', 'total'];
  public strNombreContacto: any = '';
  public strPuestoContacto: any = '';
  public strTelefonoContacto: string = '';
  public strEmailContacto: any = '';
  public selectedRenovFile: File;
  public strUrl: any = '';
  public strCodigoPostal: any;
  public strCalle: any = '';
  public strEstado: any = '';
  public strNumeroExterior: any = '';
  public strDelegacion: any = '';
  public strBuscarAct: any = ''
  public strEntreCalles: any = '';
  public strColonia: any = '';
  public strLadaEmpresa: any = '';
  public strTelefonoEmpresa: any = '';
  public strOtraColonia: any = '';
  public strTipoAnexo: any = 'Contrato';
  public intid_Cliente: any = 0
  public arrayAnexos: any = [];
  public intID_Familia: any = 0
  public banderaColonia: any = false;
  public banderaTipo: any = true;
  public strTieneServicio: any
  public intCantidadArticulos: number = 0
  public arrayColonias: any = [];
  public ListaAnexos: any = [];
  public intIDCotizacion: any
  public strTipoAgente: any;
  public nameArchivo: any
  public arrayTotalLeads: any;
  public intIDLeads: any = 0; //'3350';
  public strFase: any = '';
  public strTituloActividad: string = ''
  public objDetActividad: any;
  public arrayAsigContacto1: any = [];
  public arrayAsigContacto2: any = [];
  public arrayAsigContacto3: any = [];
  public banderaFase: any;
  public arrayZonas: any;
  public opcionSeleccionada: string = 'espera';
  public fechaActual: any;
  public arrayActividades: any = [];
  public strNombreAgente: any = ''
  public strNombreOportunidad: string = ''
  public dblMonto: any = ''
  public arrayListadoSucursales: any = []
  public subcadena = ",";
  public strFechaCierre: any = ''
  public intID_linea: any = ''
  public fechaInicioServicio: any = null;
  public strComentarioCancelado: any;
  public objSubtarea: any = []
  public strComentarioPerdido: any;
  public strFechaInicioServicio: any;
  public intCantidadElementos: any = 0;
  public arrayFamilias: any = [];
  public arrayRespuestas: any = []
  public datosFiltradosContactar: any = [];
  public spinerVtaXlin: boolean = false;
  public datosFiltradosPresentacion: any = [];
  public datosFiltradosRecorrido: any = [];
  public filtroFecha: string = '';
  public datosFiltradosPerdidos: any;
  public arrayPerdidos: any = [];
  public dblMontoPerdidos: any = 0;
  public intIDAnexo: any;
  public arrayContactos: any;
  public arrayContactosDetalle: any = [];
  public banderaEspera: any = true;
  public banderaGanado: any = false;
  public banderaCancelado: any = false;
  public strEstatusCierre: any = ''
  public intTotalPreguntas: number = 0
  public strFamiliaNueva: any = ''
  public banderaPerdido: any = false;
  public strRecomendacion: any;
  public intSaldoCorriente: number;
  public selectedItem: any;
  public banderaContacto: any;
  public banderaCotizaciones: any = false;
  public elementosPresentacion: any;
  public elementosRecorrido: any;
  public elementosPerdido: any;
  public elementosPEProspecto: any;
  public elementosNProspecto: any;
  public elementosCProspecto: any;
  public elementosPECliente: any;
  public elementosNCliente: any;
  public intCobradoFCte: number;
  public IntSaldoFCte: number;
  public elementosCCliente: any;
  public esDispositivoPequeno: any;
  public intIDPeritaje: any;
  public intFlagAvanza3: any = 0;
  public intIDUbicacion: any;
  public intIDCliente: any
  public intID_Leads_Sucursal: number = 0
  public arraySelectSucursales: any = []
  public arrayFlujocte: any = [];
  public montoPrueba: any
  public strRutaLogo: any = ''
  public strDBRutaLogo: any = ''
  public intFlagAvanza2: any
  public intTotalServiciosPC: any = 0;
  public intTotalMaterialesPC: any = 0;
  public intTotalServiciosP: any = 0;
  public intTotalMaterialesP: any = 0;
  public intTotalServiciosR: any = 0;
  public intTotalMaterialesR: any = 0;
  public intTotalServiciosPER: any = 0;
  public intTotalMaterialesPER: any = 0;
  public intTotalServiciosPRO: any = 0;
  public intTotalMaterialesPRO: any = 0;
  public intTotalServiciosNEG: any = 0;
  public intTotalMaterialesNEG: any = 0;
  public intTotalServiciosCIERRE: any = 0;
  public intTotalMaterialesCIERRE: any = 0;
  public intTotalServiciosPROPUESTACLIENTE: any = 0;
  public intTotalMaterialesPROPUESTACLIENTE: any = 0;
  public intTotalServiciosNECLIENTE: any = 0;
  public intTotalMaterialesNECLIENTE: any = 0;
  public intTotalServiciosCICLIENTE: any = 0;
  public intTotalMaterialesCICLIENTE: any = 0;
  public intId_Contacto: any = 0
  public spinerFlujoCte: boolean = false;
  public arraySubTareas: any = []
  public intIDPresencia: any = ''
  @ViewChild('editableDiv2') editableDiv2!: ElementRef;
  public strComentarios: any = ''
  public intId_Leads_Relacion: any = 0
  public intId_Direccion: any = 0
  public contenido: string = "";
  public strTextButton: string = 'seleccione Archivo'
  public strAsunto: string = '';
  public file: File;
  public strfecha: Date;
  public strDestinatario: string;
  public strRemitente: string;
  public cotizacionP: any = []
  public arrayCotizaciones: any = []
  public arrayActividadesTab: any = []
  public itemD: any = 0;
  public objAct: any = [];
  public arrayAgregarAnexosActividades: any = []
  @ViewChild('btnCerrarOportunidades') btnCerrarOportunidades!: ElementRef;
  public arrayAgentesActividades: any = [];
  public strBuscarArticulo: any = ''
  rowspans: { [key: number]: number } = {};
  arrayLineas: Food[] = [
    { value: '', viewValue: 'TODAS' },
    { value: 'ACCESORIOS', viewValue: 'ACCESORIOS' },
    { value: 'KIMBERLY', viewValue: 'KIMBERLY' },
    { value: 'QUIMICOS', viewValue: 'QUIMICOS' },
    { value: 'RUBBERMAID', viewValue: 'RUBBERMAID' },
    { value: 'BLUE TISSUE', viewValue: 'BLUE TISSUE' },
    { value: 'DESPACHADORES ECODELI', viewValue: 'DESPACHADORES ECODELI' },
    { value: 'TORK', viewValue: 'TORK' },
    { value: 'FOAOM', viewValue: 'FOAOM' },
    { value: 'XIBU', viewValue: 'XIBU' }
  ];
  public data = [
    {
      strSeccion: "Sección 1",
      dblValor: 10.0,
      intID: 1,
      intOrden: 1,
      intTipoRespuesta: 1,
      strPregunta: "Pregunta 1 de Sección 1",
    },
    {
      strSeccion: "Sección 1",
      dblValor: 8.0,
      intID: 2,
      intOrden: 2,
      intTipoRespuesta: 2,
      strPregunta: "Pregunta 2 de Sección 1",
    },
    {
      strSeccion: "Sección 2",
      dblValor: 9.5,
      intID: 3,
      intOrden: 1,
      intTipoRespuesta: 1,
      strPregunta: "Pregunta 1 de Sección 2",
    },
    {
      strSeccion: "Sección 2",
      dblValor: 7.0,
      intID: 4,
      intOrden: 2,
      intTipoRespuesta: 2,
      strPregunta: "Pregunta 2 de Sección 2",
    },
    {
      strSeccion: "Sección 3",
      dblValor: 6.5,
      intID: 5,
      intOrden: 1,
      intTipoRespuesta: 1,
      strPregunta: "Pregunta 1 de Sección 3",
    }
  ];

  public arrayOport: any = [
    { tipo: 'oportunidad', fecha: '10 jun 2024', estatus: 'Oportunidad Ganada', descripcion: 'ofrecer servicio de limpieza', importe: 3600, cierre: 'junio 10, 2024', agente: 'Agustin Bujaidar', hora: '14:43' },
    { tipo: 'tarea', fecha: '10 jun 2024', estatus: 'Tarea Completada', descripcion: 'Seguimiento de oportunidad Ofrecer servicios de limpieza', importe: 3600, cierre: 'junio 10, 2024', agente: 'Agustin Bujaidar', hora: '14:42' },
    { tipo: 'tarea', fecha: '10 jun 2024', estatus: 'Nueva Tarea', descripcion: 'Seguimiento de oportunidad Ofrecer servicios de limpieza', importe: 3600, cierre: 'junio 10, 2024', agente: 'Agustin Bujaidar', hora: '14:40' },
    { tipo: 'oportunidad', fecha: '10 jun 2024', estatus: 'Nueva Oportunidad', descripcion: 'ofrecer servicio de limpieza', importe: 3600, cierre: 'junio 10, 2024', agente: 'Agustin Bujaidar', hora: '14:39' },
    /*  {tipo:'tarea',fecha:'10 jun 2024',estatus:'Nueva Tarea',descripcion:'Seguimiento de oportunidad Ofrecer servicios de limpieza',importe:3600,cierre:'junio 10, 2024',agente:'Agustin Bujaidar',hora:'14:40'},
     {tipo:'oportunidad',fecha:'10 jun 2024',estatus:'Nueva Oportunidad',descripcion:'ofrecer servicio de limpieza',importe:3600,cierre:'junio 10, 2024',agente:'Agustin Bujaidar',hora:'14:39'} */
  ]
  public objModal: any = {};
  public spinerCte: boolean = false;
  public objvtaXarticulo: any = {};
  public objVtasXlinea: any = {};
  public arrayDetCliente: any = [];
  public arrayCabeceraXart: any = [];
  public spinerEdoCta: boolean = false;
  public strResponsable: string = ''
  public objResponsable: any = {}
  public arrayColSuc: any = []
  /* Variables martin */
  public objModalAct: any = {
    titulo: ""
  };
  public intTotalContestadas: any = 0
  public time1: string = "am";
  public time2: string = "am";
  public mdlHoraInicio: string = "00:00";
  public mdlHoraFin: string = "00:00";
  public mdlNombre: string = "";
  public mdlComentarios: string = "";
  public strfechaAct: Date;
  public arrayTime: any = [{ time: "am" }, { time: "pm" }]
  public arrayOportTimeLine: any = [];
  public strRuta_Origen: string = "";
  public objRuta_Parametros: any = {};
  @ViewChild('btnCerrar') btnCerrar!: ElementRef;

  public arrayHora: any = [];

  public objSucCliente: any = {
    NOMBRE_SUCURSAL: "",
    DIRECCION: "",
    DIRECCION_NUMERO: "",
    CODIGO_POSTAL: "",
    COLONIA: "",
    CONTACTO_NOMBRE: "",
    CONTACTO_PUESTO: "",
    CONTACTO_EMAIL: "",
    CONTACTO_TELEFONO: "",
  }

  public objSuFamilia: any = {
    RAZON_SOCIAL: "",
    RFC: "",
    NOMBRE_SUCURSAL: "",
    DIRECCION: "",
    DIRECCION_NUMERO: "",
    CODIGO_POSTAL: "",
    COLONIA: "",
    CONTACTO_NOMBRE: "",
    CONTACTO_PUESTO: "",
    CONTACTO_EMAIL: "",
    CONTACTO_TELEFONO: "",
  }
  public strBuscarContacto: any = '';
    public myFormConctacto: FormGroup;
  public intPotenciador: number = 0
  public formSucFamilia: FormGroup;
  public formSucCliente: FormGroup;
  public banderaFormulario: boolean;
  public strAgente_Crea: string; 
  public arrayMinuta: any = [];
  public spineract: boolean = false;
  constructor(private paginatorIntl: MatPaginatorIntl, private editPl: PlantillafinService, private indexedDBService: DataDBService, private rutaActiva: ActivatedRoute, private sanitizer: DomSanitizer, private _httpService: PeticionesService, private carroservice: CarroService, public _alertify: AlertifyService, private formbuilder: FormBuilder, private _filtroServ: FiltrosService, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService, private alta_cliente: AltaClienteService, private elementRef: ElementRef, private datePipe: DatePipe) {/*, public socket: SocketServiceService */
    //inicializamos la ruta de retorno

    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];


    });


    this.indexedDBService.getItem('objRuta_Anterior', (value) => {

      this.objRuta_Anterior = value;
      this.strNombre_ruta = value && value.ruta_destino == '/detalleLeads' ? value.nombre : 'Tablero Leads';
      this.strRuta_Origen = value && value.ruta_destino == '/detalleLeads' ? value.ruta_origen : '/tableroLeads';
      this.objRuta_Parametros = value && value.parametros ? value.parametros : {};
    });
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos');
    this.arrayTipoCotizacion = [
      { strTipoCotizacion: 'Productos en General', Seleccionado: false },
      { strTipoCotizacion: 'Licitaciones', Seleccionado: false },
      { strTipoCotizacion: 'Cuenta Nacional', Seleccionado: false },
      { strTipoCotizacion: 'Público en General', Seleccionado: false }
    ]

    this.arrayContactosDetalle = [{
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    }]
    this.myFormConctacto = this.formbuilder.group({
      strAccion: new FormControl('setContacto', []),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      strConexion: new FormControl(this.strConexion, []),
    });

    this.myForm = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strSector: new FormControl('', [Validators.maxLength(20), Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      strCodigoPostal: new FormControl('', [Validators.maxLength(5), Validators.required]),
      strDelegacion: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strEstado: new FormControl('', [Validators.maxLength(100), Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intIDNo_Empleados: new FormControl('', [Validators.required]),
      intid_Ubicacion: new FormControl('', [Validators.required]),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      strEstatus: new FormControl('POR CONTACTAR', []),
      strAccion: new FormControl('setLeads', []),
      strConexion: new FormControl(this.strConexion, []),
      strAgente: new FormControl(this.strAgenteSP, []),
      strClasificacion: new FormControl('', [])
    });

    this.formLead = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.required]),
      strSector: new FormControl('', [Validators.required]),
      intGiro: new FormControl('', [Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intID_Familia: new FormControl(0, []),
      strNumeroEmpleados: new FormControl('', [Validators.required]),
      strRazonSocial: new FormControl('', []),
      strRFC: new FormControl('', []),
      /*  strTipoCotizacion: new FormControl(this.strTipoCotizacion, [Validators.required]), */
      strMedioConocio: new FormControl('VENDEDOR', [Validators.required]),
      strTieneServicio: new FormControl('', []),
      intIDUbicacion: new FormControl('', [Validators.required]),
    })
    this.formLead = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.required]),
      strNombreCorto: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      strSector: new FormControl('', [Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      intId_subGiro: new FormControl(0, []),
      intIDPresencia: new FormControl(0, [Validators.required]),
      intID_Familia: new FormControl(this.intID_Familia, []),
      strRazonSocial: new FormControl('', []),
      strRFC: new FormControl('', []),
      intId_segunda: new FormControl(0, []),
      intId_tercera: new FormControl(0, []),
      intID_Contacto: new FormControl(0, []),
      strPotencializador: new FormControl('', []),
      strMedioConocio: new FormControl('VENDEDOR', []),

    });

    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.strSucursal_modal = this.strSucursal;
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.strGerente_modal = this.strGerente;
      }
    }

    this.fngetGiros();
    this.fnGetFamilias();
    this.fnformSucursal();
    this.fnFormOportunidades()
    let hora = 7, minutos = 0, strH = "", strM = "";
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayHora.push({ hora: strH + hora + ":" + strM + minutos });

      if (minutos === 30) {
        minutos = 0;
        hora += 1;
      } else {
        minutos += 30;
      }
    }
    this.fnAddMinuta();
    this.customizeItemsPerPageLabel();
    
  }

  ngAfterViewInit() {
    this.altInfo = this.ingoGralDiv.nativeElement.offsetHeight;
    this.fnInitSummer()/* 
    this.dataSource.sort = this.sortArtFav;
 */
  }
    fnBuscarContacto3(event: KeyboardEvent): void {
      var inputValue = (event.target as HTMLInputElement).value;
      if (inputValue.length > 2) {
        this._serviciosService.mostrarCotizaciones({
          strAccion: 'getContacto_Busqueda',
          strResultado: inputValue,
          strConexion: this.strConexion
        }, 'sp_ModVentas_Leads_Familia')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.arrayAsigContacto3 = result
            },
            error => {
              var error = <any>error;
              console.log(error);
            }
          );
      } else {
        this.arrayAsigContacto3 = [];
      }
    }
  

  ngOnInit(): void {
    // Recuperar el parámetro idLead de la URL
    this.route.queryParamMap.subscribe(params => {
      if (params.get('idLead')) {
        this.intIDLeads = params.get('idLead');
        localStorage.setItem('idLead', this.intIDLeads)
      } else {
        this.intIDLeads = localStorage.getItem('idLead')
      }
      this.fnGetAgentes_tareas();
      this.fnGetFormulario();
      this.fngetSectores();
      this.fngetSubGiros();
      this.fngetSegundaLetra();
      this.fngetTerceraLetra();
      this.fnGetFiltros();
      this.fngetPresencia();
      this.getSelectSucursales();
      this.fnGetClientesDetalle();
      this.fngetArticulosFavoritos();
      this.fngetLineas();
      this.fnListadoOoprtunidad();
      this.fnGetAnexos();
      this.fnGetMinutas();
      this.fngetActividades(this.intIDLeads);
    });
    this.fnGetActLeads();
  }
  fnGetAgentes_tareas() {

    let obj = {
      strAccion: 'getAgentes_Leads',
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        this.arrayAgentes_tareas = result
      }, error => {

        console.error('Error al consumir la API:', error);
      });

  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }
 saveContacto() {
    this.intEditInfoGeneral = 0
    let rest: any = [];
    let objForm: any;
    this.myFormConctacto.value.strContacto_Nombre = this.myFormConctacto.get('strContacto_Nombre')?.value;
    this.myFormConctacto.value.strContacto_Telefono = this.myFormConctacto.get('strContacto_Telefono')?.value;
    this.myFormConctacto.value.strContacto_Email = this.myFormConctacto.get('strContacto_Email')?.value;
    this.myFormConctacto.value.strContacto_Puesto = this.myFormConctacto.get('strContacto_Puesto')?.value;
    this.myFormConctacto.value.intID_Contacto = this.objInfo.intID_Contacto
    this.myFormConctacto.value.strConexion = this.strConexion;
    objForm = this.myFormConctacto.value;
    console.log(this.objInfo)
    this._serviciosService.mostrarCotizaciones(objForm, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          rest = result
          Swal.fire({
            icon: rest[0].code == '200' ? 'success' : 'error',
            title: rest[0].code == '200' ? 'Enviado' : 'Error',
            text: rest[0].strMensaje,
          });
          if (rest[0].code == '200') {
            this.fnDetalleLead();
          }
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }
  
    fnBuscarContacto2(event: KeyboardEvent): void {
      var inputValue = (event.target as HTMLInputElement).value;
      if (inputValue.length > 2) {
        this._serviciosService.mostrarCotizaciones({
          strAccion: 'getContacto_Busqueda',
          strResultado: inputValue,
          strConexion: this.strConexion
        }, 'sp_ModVentas_Leads_Familia')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.arrayAsigContacto2 = result
            },
            error => {
              var error = <any>error;
              console.log(error);
            }
          );
      } else {
        this.arrayAsigContacto2 = [];
      }
    }
    fnselecContacto(objCont: any) {
      this.strBuscarContacto = '';
      this.myForm.controls['strContacto_Nombre'].setValue(objCont.strContacto_Nombre);
      this.myForm.controls['strContacto_Telefono'].setValue(objCont.strContacto_Telefono);
      this.myForm.controls['strContacto_Email'].setValue(objCont.strContacto_Email);
      this.myForm.controls['strContacto_Puesto'].setValue(objCont.strContacto_Puesto);
  
      /*this.myForm.get('strContacto_Nombre').disable();
      this.myForm.get('strContacto_Puesto').disable();
      this.myForm.get('strContacto_Telefono').disable();
      this.myForm.get('strContacto_Email').disable();*/
  
      this.arrayAsigContacto1 = [];
      this.arrayAsigContacto2 = [];
      this.arrayAsigContacto3 = [];
    }
  
    fnBuscarContacto1(event: KeyboardEvent): void {
      var inputValue = (event.target as HTMLInputElement).value;
      if (inputValue.length > 2) {
        this._serviciosService.mostrarCotizaciones({
          strAccion: 'getContacto_Busqueda',
          strResultado: inputValue,
          strConexion: this.strConexion
        }, 'sp_ModVentas_Leads_Familia')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.arrayAsigContacto1 = result
            },
            error => {
              var error = <any>error;
              console.log(error);
            }
          );
      } else {
        this.arrayAsigContacto1 = [];
      }
    }
  editarInformacionGeneral() {
    console.log(this.arrayContactosDetalle)
    this.intEditInfoGeneral = 1

    this.myFormConctacto.controls['strContacto_Nombre'].setValue(this.arrayContactosDetalle[0].strNombreContacto);
    this.myFormConctacto.controls['strContacto_Telefono'].setValue(this.arrayContactosDetalle[0].strContactoTelefono);
    this.myFormConctacto.controls['strContacto_Email'].setValue(this.arrayContactosDetalle[0].strContactoEmail);
    this.myFormConctacto.controls['strContacto_Puesto'].setValue(this.arrayContactosDetalle[0].strContactoPuesto);
  }
  fnGetFiltros() {
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnDetalleLead() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLead_Unique',
      strConexion: this.strConexion,
      intID_Leads: this.intIDLeads
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.itemD = result[0];

        if (this.itemD) {

          this.strTipoLead = (this.itemD.strTipo_Leads) ? this.itemD.strTipo_Leads : ''
          if (this.strTipoLead == 'FAMILIA') { this.fnGetListadoSucursal() } else { this.fnGetListadoSucursal_Sucursal() }
          this.intIDActividad = this.itemD.intID_Actividad
          this.strTituloActividad = this.itemD.strTitulo

          // this.boolAgente = this.itemD.boolAgente;
          this.strClasificacion = (this.itemD.strClasificacion) ? this.itemD.strClasificacion : 'CCC';

          this.strNombreEmpresa = this.itemD.strNombreEmpresa;

          this.arrayContactosDetalle[0].strNombreContacto = this.itemD.strContacto_Nombre;
          this.arrayContactosDetalle[0].strContactoPuesto = this.itemD.strContacto_Puesto;
          this.arrayContactosDetalle[0].strContactoTelefono = this.itemD.strContacto_Telefono;
          this.arrayContactosDetalle[0].strContactoEmail = this.itemD.strContacto_Email;
          this.arrayContactosDetalle[0].intID_Contacto = this.itemD.intId_Contacto;
          this.objInfo = this.arrayContactosDetalle[0]
          console.log(this.itemD)
          this.intID_Familia = (this.itemD.intID_Familia == 0) ? 0 : this.itemD.intID_Familia;
          this.strFechaInicioServicio = this.itemD.fechaInicioServicio;
          if (this.itemD.strtipoCotizacion) {
            this.strTipoCotizacion = this.itemD.strtipoCotizacion
            let tipoCotizacion = (this.itemD.strtipoCotizacion).split(',')
            if (tipoCotizacion.length > 0) {
              tipoCotizacion.forEach(element => {
                this.arrayTipoCotizacion.map((dato: any) => {
                  if (dato.strTipoCotizacion === element) {
                    dato.Seleccionado = true;
                    this.banderaCotizaciones = true
                  }
                  return dato;
                });
              });
            }
          }
          this.strPotencializador = this.itemD.strPotencializador
          if (this.itemD.strPotencializador) {
            let poten = (this.itemD.strPotencializador).split(',')
            if (poten.length > 0) {
              poten.forEach(element => {
                this.arrayPotenciador.map((dato: any) => {
                  if (dato.strLinea === element) {
                    dato.Seleccionado = true;

                  }
                  return dato;
                });
              });
            }
          }
          this.strEtapa = this.itemD.strEstatus
          this.strNumeroExterior = this.itemD.strNo_exterior
          let fecha = new Date(this.itemD.strFechaCierre_Estimada)
          this.strFechaEstimadaCierreInput = this.formatoFecha(fecha)
          this.strFechaEstimadaCierre = this.formatoFecha2(fecha)
          this.strTieneServicio = (this.itemD.strServicio_Limpieza) ? this.itemD.strServicio_Limpieza : ''
          this.intValidacionFamilia = this.itemD.intValidacionFamilia
          let rangoEmpleados = this.arrayTamanoEmpresa.filter((b: any) => b.intIDNo_Empleados == this.itemD.intIDNo_Empleados)
          this.strNumeroEmpleados = this.itemD.intIDNo_Empleados + ',' + (rangoEmpleados && rangoEmpleados[0] && rangoEmpleados[0].strClasificacion ? rangoEmpleados[0].strClasificacion : "")
          this.strDBRutaLogo = (this.itemD.strUrl_Logo) ? this.itemD.strUrl_Logo : ''
          this.intFlagActividad = this.itemD.intFlagActividad

          this.intFlagAvanza1 = this.itemD.intFlagAvanza1
          this.intFlagAvanza2 = this.itemD.intFlagAvanza2
          this.intFlagAvanza3 = this.itemD.intFlagAvanza3
          this.intFlagAvanza4 = this.itemD.intFlagAvanza4
          this.intFlagAvanza5 = this.itemD.intFlagAvanza5

          this.strAgenteLD = this.itemD.strAgente
          this.strCodigoPostal = this.itemD.strCodigoPostal
          this.strDelegacion = this.itemD.strDelegacion
          this.strEstado = this.itemD.strEstado
          this.strCalle = this.itemD.strCalle
          this.strColonia = this.itemD.strColonia
          this.strEntreCalles = this.itemD.strEntrecalles
          this.strFase = this.itemD.strFase
          this.intId_Leads_Relacion = this.itemD.intId_Leads_Relacion
          this.intId_Direccion = this.itemD.intId_Direccion
          this.intid_Cliente = this.itemD.intid_Cliente
          this.strNombreEmpresa = this.itemD.strNombreEmpresa
          this.strSector = this.itemD.strSector
          this.dblImporte = this.itemD.dblMonto
          this.strSitioWeb = this.itemD.strSitio_Web
          this.strMedioConocio = 'VENDEDOR'
          this.strPrioridad = this.itemD.strPrioridad
          this.strRFC = this.itemD.strRFC;
          this.strNombreAgente = this.itemD.strNombreAgente
          this.intGiro = this.itemD.intId_Giro

          this.intIDPresencia = this.itemD.intIDPresencia
          this.strRecomendacion = this.itemD.strRecomendacion
          this.intCantidadElementos = this.itemD.intCantidadEmpleados
          this.intTotalSucursales = this.itemD.intNo_Sucursales
          this.strRazonSocial = this.itemD.strRazon_Social
          this.strNombreCorto = this.itemD.strNombreCorto
          this.intId_segunda = this.itemD.intId_segunda,
            this.intId_tercera = this.itemD.intId_tercera,
            this.intIDUbicacion = this.itemD.intid_Ubicacion
          if (this.strCodigoPostal) { this.fnGetDatosFiscalesDetalle(this.strCodigoPostal) }
          this.fnGetCotizaciones();
          this.fnGetAnexos();
          this.fnGetClientesDetalle();
          this.fnFiltrarSegundaLetra();
        }
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }
  formatoFecha(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  formatoFecha2(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }

  fnformSucursal() {
    if (this.strTipoLead == "FAMILIA") {
      this.formSucFamilia = this.formbuilder.group({
        strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
        strRFC: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(13)]),
        strRazonSocial: new FormControl('', [Validators.maxLength(100), Validators.required]),
        strCodigoPostal: new FormControl('', [Validators.minLength(5), Validators.maxLength(5), Validators.required]),
        strColonia: new FormControl('', [Validators.maxLength(100), Validators.required]),
        direccion: new FormControl('', [Validators.required]),
        numeroDireccion: new FormControl('', [Validators.required]),
        strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
        strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      });
    } else if (this.strTipoLead == "CLIENTE") {
      this.formSucCliente = this.formbuilder.group({
        strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
        strCodigoPostal: new FormControl('', [Validators.minLength(5), Validators.maxLength(5), Validators.required]),
        strColonia: new FormControl('', [Validators.maxLength(100), Validators.required]),
        direccion: new FormControl('', [Validators.required]),
        numeroDireccion: new FormControl('', [Validators.required]),
        strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
        strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      });
    } else {
      this.formSucCliente = this.formbuilder.group({
        strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
        strCodigoPostal: new FormControl('', [Validators.minLength(5), Validators.maxLength(5), Validators.required]),
        strColonia: new FormControl('', [Validators.maxLength(100), Validators.required]),
        direccion: new FormControl('', [Validators.required]),
        numeroDireccion: new FormControl('', [Validators.required]),
        strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
        strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      });
      this.formSucFamilia = this.formbuilder.group({
        strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
        strRFC: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(13)]),
        strRazonSocial: new FormControl('', [Validators.maxLength(100), Validators.required]),
        strCodigoPostal: new FormControl('', [Validators.minLength(5), Validators.maxLength(5), Validators.required]),
        strColonia: new FormControl('', [Validators.maxLength(100), Validators.required]),
        direccion: new FormControl('', [Validators.required]),
        numeroDireccion: new FormControl('', [Validators.required]),
        strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
        strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
        strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      });
    }
  }

  fngetGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayGiros = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetAnexos() {
    try {

      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAnexos',
        intIdLeads: this.intIDLeads,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            console.log(result)
            if (result.length > 0) {
              let nombresCarpetasSet = new Set<string>();
              let carpetasMap = new Map<string, any>();

              result.forEach((b: any) => {
                let nombreCarpeta = b.strUrl.split('/')[1];

                // Obtener la extensión del archivo
                let extension = nombreCarpeta.split('.').pop()?.toLowerCase();

                // Si la extensión es una de las especificadas, asignar a "Sin Carpeta"
                let extensionesSinCarpeta = ["pdf", "png", "xlsx", "jpg", "jpeg"];
                if (extensionesSinCarpeta.includes(extension || "")) {
                  nombreCarpeta = "Sin Carpeta";
                }

                if (!carpetasMap.has(nombreCarpeta)) {
                  carpetasMap.set(nombreCarpeta, {
                    nombreCarpeta,
                    xmlDocumentos: [],
                    xmlDocumentosBD: [],
                  });
                  nombresCarpetasSet.add(nombreCarpeta);
                }

                // Agregar el documento a la carpeta correspondiente
                carpetasMap.get(nombreCarpeta).xmlDocumentosBD.push(b);
              });


              // Convertir el Map en un array
              this.arrayCarpetas = Array.from(carpetasMap.values());
              console.log(this.arrayCarpetas)
              //this.arrayAnexos = result
            } else {
              this.arrayCarpetas = [
                {
                  nombreCarpeta: 'Sin Carpeta',
                  xmlDocumentos: [],
                  xmlDocumentosBD: []
                }
              ]
            }
          },
          error => {
            var error = <any>error;
            console.log(error);

          }
        );
    } catch (error) {
      console.log(error.message)

    }
  }


  fnListadoOoprtunidad() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getListado_oportunidad_lead',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        /* for (let index = 0; index < 10; index++) {
         this.arrayOportunidades.push(result)
       }  */
        this.arrayOportunidades = result

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetFamilias() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFamilias',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayFamilias = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  onGiroChange(event: any, tipo: string) {
    let clasificacion = event.target.value.split(',')[1];

    if (tipo === 'presencia') {
      this.strClasificacion = clasificacion + this.strClasificacion.substring(1);
    } else if (tipo === 'sucursales') {
      this.strClasificacion = this.strClasificacion.charAt(0) + clasificacion + this.strClasificacion.substring(2);
    } else {
      this.strClasificacion = this.strClasificacion.substring(0, 2) + clasificacion;
    }

  }

  stopPropagation2(event: any) {
    event.stopPropagation();
  }

  stopPropagation(event: any, estado: any) {
    event.stopPropagation();
    if (estado == 'Ganado') {
      this.banderaGanado = true
      this.banderaEspera = false
      this.banderaCancelado = false
      this.banderaPerdido = false
    } else if (estado == 'Espera') {
      this.banderaEspera = true
      this.banderaGanado = false
      this.banderaCancelado = false
      this.banderaPerdido = false
    } else if (estado == 'perdido') {
      this.banderaEspera = false
      this.banderaGanado = false
      this.banderaCancelado = false
      this.banderaPerdido = true
    } else {
      this.banderaEspera = false
      this.banderaGanado = false
      this.banderaCancelado = true
      this.banderaPerdido = false
    }
  }

  fnupdate_Actividad() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad',
      intID_Actividad: this.intIDActividad,
      strResultado: this.strResultado,
      strComentario: this.contenido,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == '200') {
          this._alertify.success('Guardado Correctamente');
          this.fngetActividades(this.intIDLeads)
          this.strResultado = ''
          this.strComentario = ''
        } else { }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetActividades(id: any) {
    this.arrayActividades = []

    let objenV = {
      strAccion: 'getActividades',
      intID_Leads: id,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objenV, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result.length > 0) {
          /* this.intFlagAvanza1 = result[0].intFlagAvanza1 */
          /* this.intFlagActividad = result[0].intFlagActividad */
          this.arrayActividades = result
          if (this.arrayActividades.length > 0) {
            this.arrayActividades.forEach(obj => {
              // Paso 1: Convertir strFechaRequerida a un objeto Date
              let fechaRequerida = new Date(obj.strFechaRequerida);
              // Paso 2: Obtener la fecha actual
              let fechaActual = new Date();
              // Paso 3: Calcular la diferencia en milisegundos
              let diferenciaMilisegundos = fechaRequerida.getTime() - fechaActual.getTime();
              // Paso 4: Convertir la diferencia de milisegundos a días
              let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
              obj.diasDiferencia = diferenciaDias
            });
          }
          this.datosTareasCte = new MatTableDataSource(this.arrayActividades);

          this.arrayActividades.forEach(obj => {
            obj.seeSubAct = false;
          });

          this.fnTabletareasCte();
        } else {
          this.intFlagAvanza1 = 0
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  /*   fnllenarArrayTareas() {
 
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades',
      intID_Leads:this.objInfo.intID_Leads,
      strConexion: this.strConexion,
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayActividades = result
        console.log(this.arrayActividades)
        this.datosTareasCte = new MatTableDataSource(this.arrayActividades);
        this.fnTabletareasCte();
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    ); */


  handleContentChange(contents: string = "") {
    this.contenido = contents;
  }
  fnInitSummer() {

    $('.summernote').summernote('destroy');
    $('.summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      height: 250,
      callbacks: {
        onInit: () => {
          $('.summernote').summernote('code', '');
          this.contenido = ""
          // Ajustar tamaños de las imágenes al inicializar
        },
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
          this.adjustImageSizes();
          this.compressImage(files[0]);
        },
      },
      toolbar: [
        ['style', ['style']], // estilos generales
        ['font', ['bold', 'underline', 'clear']], // texto
        ['fontname', ['fontname']], // tipo de fuente (opcional)
        ['color', ['color']], // color de texto
        ['para', ['ul', 'ol', 'paragraph']], // listas y alineación
        ['table', ['table']] 
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('.summernote').on('summernote.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }

  // Método para agregar la imagen al editor
  compressImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, img.width, img.height);

        // Reducción de la calidad al 90%
        const dataUrl: any = canvas.toDataURL('image/jpeg', 0.95);
        this.addImageToEditor(dataUrl);
      };
      const imgNode = $('<img>').attr('src', e.target.result as string).css({
        width: '300px !important',  // Ancho fijo con !important
        height: 'auto !important'   // Mantiene la proporción con !important
      });

    };
    reader.readAsDataURL(file);
  }

  addImageToEditor(dataUrl: string) {
    $('.summernote').summernote('insertImage', dataUrl);
  }

  adjustImageSizes() {
    $('.summernote').summernote('code', function (contents) {
      const $contents = $('<div>').html(contents);
      $contents.find('img').each(function () {
        $(this).css({
          width: '100px !important',  // Ancho deseado
          height: '100px !important'   // Mantiene la proporción
        }).addClass('custom-img-size'); // Asegurar que tenga la clase personalizada
      });
      return $contents.html();
    });
  }

  fnCancelado_Perdido() { }

  cambiarFechaCancelado(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaCancelado = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  fnCambiarFuturo(event: any) {
    if (event.target.value == 'SI') {
      this.banderaFuturo = true
    } else {
      this.banderaFuturo = false
    }
  }

  cambiarFechaPerdido(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaPerdido = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  fnInsertFechaInicioServicio() { }

  cambiarFechaInicioServicio(event: any) {
    let fecha = (event.target.value).split('-')
    this.fechaInicioServicio = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  fnInsertActividades() {
    let fechaAux = (this.strFechaActividad) ? this.strFechaActividad : this.fechaInicioServicio

    let fechaF: any
    if (this.fechaInicioServicio) {
      fechaF = this.fechaInicioServicio
    } else {
      let fechaFormato = new Date(fechaAux)
      let dia = (fechaFormato.getDate() < 10) ? ('0' + fechaFormato.getDate()) : fechaFormato.getDate()
      let mes = (fechaFormato.getMonth() < 10) ? ('0' + (fechaFormato.getMonth() + 1)) : (fechaFormato.getMonth() + 1)
      let anio = fechaFormato.getFullYear()
      let hora = (fechaFormato.getHours() < 10) ? ('0' + fechaFormato.getHours()) : fechaFormato.getHours()
      let minutos = (fechaFormato.getMinutes() < 10) ? ('0' + fechaFormato.getMinutes()) : fechaFormato.getMinutes()
      let segundos = (fechaFormato.getSeconds() < 10) ? ('0' + fechaFormato.getSeconds()) : fechaFormato.getSeconds()
      fechaF = dia + '-' + (mes) + '-' + anio + ' ' + hora + ':' + minutos + ':' + segundos
    }

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'Insert_Actividades',
      intIdLeads: this.intIDLeads,
      strAgente: this.strAgenteLD,
      strFechaCierre_Estimada: fechaF,
      strTipo: this.strTipoActividad,
      strResultado: '',
      strComentario: this.strComentario,
      strAsunto: this.strResultado,
      strContenido: '',
      strEstatus: (this.fechaInicioServicio) ? 'CONCLUIDO' : 'PENDIENTE',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == '200') {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fngetActividades(this.intIDLeads)
          this.fnDetalleLead()
          //this.fnGetPorContactar()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnCotizador() {
    let objNombre_familia = this.arrayFamilias.find(o => o.intID_Familia == this.intID_Familia);
    let strNombre_familia = objNombre_familia ? objNombre_familia.strFamilia : '';
    this.carroservice.deleteCarrito();
    this.objCliente = {
      strCliente: this.intIDLeads + '',
      strNombre: this.strNombreEmpresa,
      strDireccion: 'Morelos ', //this.intIDLeads,
      ClienteSeleccionado: true,
      strNombreSucursal: this.strSucursal,
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.strAgenteSP,
      strAlmacen: "",
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA",
      strTipoMovimiento: 'COTIZACION_LEADS',
      strOrigen: '/detalleLead/', //Servirá para redireccionar al componente origen
      strCteFam: strNombre_familia,//agregamos familia 
      strOrigen2: '/detalleLead?idLead=' + this.intIDLeads,  //Servirá para redireccionar al componente origen
      strEstatus_Cotizacion_Leads: this.strEtapa == 'NEGOCIACION' ? 'POR AUTORIZAR' : 'AUTORIZADO',
      strEtapa_Leads: this.strEtapa
    };

    let activarPlantilla = this.strEtapa == 'NEGOCIACION' ? true : false; //validacion para que no puedan modificar precios desde la primer cotización
    this.carroservice.setModoPlantilla(activarPlantilla);

    this.carroservice.setCte(this.objCliente);
    this.router.navigate(['/cotizador_Linea/']);
  }
  fnAddCar_Cotizacion(item: any, TIPO: string) {
    this.intIDMovimientoVenta = item.intIDMovimientoVenta
    let obj = {
      strAccion: 'getCotizaciones_Cte_Det',
      intIDMovimientoVenta: this.intIDMovimientoVenta,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {

        this.dataSourceCotizaciones = result
        let opcion = TIPO == 'COPIA' ? 2 : 1;
        let opcion2 = TIPO == 'EDITAR' ? true : false;

        let estatus_cotizacion = item.strEstatus ? item.strEstatus : '';
        let Activar_Plantilla = 1;
        this.editPl.setPlantilla({ boolActive: opcion2, intIDMovimientoVenta: this.intIDMovimientoVenta, Activar_Plantilla: Activar_Plantilla, strTipo_Accion_Cotizacion: TIPO });

        // let activarPlantilla = this.strEtapa == 'NEGOCIACION' && TIPO != 'VER' ? true : false; //validacion para que no puedan modificar precios desde la primer cotización
        let activarPlantilla = false;

        this.carroservice.setModoPlantilla(activarPlantilla);

        this.fnSetCarrito(result, this.intIDMovimientoVenta, item, opcion, TIPO)
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }
  fnSetCarrito(arrayArtis: any, intIDMovimientoVenta: number, objVenta: any, opcion: number, TIPO: string) {
    let estatus_cotizacion = objVenta.strEstatus ? objVenta.strEstatus : '';
    let estatus_Cliente = objVenta.strEstatusCliente ? objVenta.strEstatusCliente : '';

    //borramos carrito
    this.carroservice.deleteCarrito();

    //preparamos los articulos
    arrayArtis.forEach(element => {
      let precioSugerido = element.dblPrecioSugerido && element.dblPrecioSugerido > 0 ? element.dblPrecioSugerido : element.dblPrecio;
      let precio = TIPO == 'VER' || TIPO == 'EDITAR' || (TIPO == 'COPIA' && estatus_cotizacion != 'RECHAZADO') ? precioSugerido : element.dblPrecio;
      let preciop = estatus_cotizacion != 'RECHAZADO' ? precioSugerido : 0;
      let objArt = {
        boolEditaPlantilla: opcion == 2 ? true : false,

        checkbx: opcion == 2 ? true : false,
        dblCalificacion: 3,
        dblCantidad: element.dblCantidad,
        dblCostoPromedio: 0, dblCostoTeorico: 0,
        dblPrecio: 0,
        dblPrecio2: element.dblPrecio2,
        dblPrecio3: 0, dblPrecio4: 0, dblPrecio10: 0,
        dblPrecioLista: precio,
        dblPrecioMinimo: 0,

        dblPrecioPlantilla: preciop,
        dblPrecioPolitica: element.dblPrecioPolitica,
        dblPrecioSugerido: preciop,//element.dblPrecioSugerido,
        dblTipoCambio: 0,
        dblUltimoPrecio: 0,
        intEjercicio: 0,
        intIDMovimientoVenta: intIDMovimientoVenta,
        intIDSucursal: 0,
        intPeriodo: 0, intPrecio: 0, intPrecio2: element.dblPrecio2,
        intPrecio3: element.dblPrecio3, intPrecio4: element.dblPrecio4,
        intPrecioLista: element.dblPrecioLista,
        intPrecioPlantilla: preciop,
        intPrecioPolitica: 0, intTotalEstrellas: 2,
        pagina: 1, strArticulo: element.strArticulo, strCategoria: element.strCategoria,
        strComentario: "", strDescripcion1: element.strDescripcion1, strLinea: element.strLinea,
        strNombreOpcion: "",
        strOpcion: element.strOpcion,
        strTipo: 'PROSPECTO',
        strTipoOpcion: element.strTipoOpcion, arrayOpciones: []


      }

      this.carroservice.setArticulo(objArt);
    });


    //agregar cliente
    let alm = objVenta.strSucursal == '0' ? '1' : objVenta.strSucursal;
    let objNombre_familia = this.arrayFamilias.find(o => o.intID_Familia == this.intID_Familia);
    let strNombre_familia = objNombre_familia ? objNombre_familia.strFamilia : '';

    this.objCliente = {
      strCliente: this.intIDLeads + '',
      strNombre: this.strNombreEmpresa,
      strDireccion: '', //this.intIDLeads,
      ClienteSeleccionado: true,
      strNombreSucursal: objVenta.strSucursal,
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.strAgenteSP,
      strAlmacen: alm,
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA",
      strTipoMovimiento: 'COTIZACION_LEADS',
      strOrigen: '/detalleLead/', //Servirá para redireccionar al componente origen
      strCteFam: strNombre_familia,//agregamos familia 
      strOrigen2: '/detalleLead?idLead=' + this.intIDLeads,  //Servirá para redireccionar al componente origen
      strEstatus_Cotizacion_Leads: this.strEtapa == 'NEGOCIACION' ? 'POR AUTORIZAR' : 'AUTORIZADO'

    };

    this.carroservice.setCte(this.objCliente);
    //this._alertify.success("Articulo agregado correctamente");
    this.router.navigate(['/carritoresumen']);
  }

  fnGetCotizaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getCotizaciones_Cte',
      strCliente: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        this.arrayCotizaciones = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  toggleCotizacion(cotizacion: any) {
    this.arrayTipoCotizacion.forEach(e => {
      if (cotizacion == e.strTipoCotizacion) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });
  }

  cambiarFecha(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaEstimadaCierre = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    this.strFechaEstimadaCierreInput = fecha[0] + '-' + fecha[1] + '-' + fecha[2]
  }

  fngetPresencia() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getPresencia',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPresencia = result
        this.fngetUbicaciones()

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetUbicaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUbicaciones',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayUbicaciones = result
        this.fngetRangoEmpleados()
        // this.fnDetalleLead();
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetRangoEmpleados() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getRangoEmpleados',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayTamanoEmpresa = result
        this.fnDetalleLead()
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  SubirLogo() {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/AnexoLogo_Lead.php', [], this.ListaAnexos).then((result) => {

        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });

        this.fnUpdateLead_Nuevo()
      }, (error) => {
        console.log(error);
      });
    } else {
      //this.fnInsertLead()
      this.fnUpdateLead_Nuevo()
    }

  }

  SubirAnexos() {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/EvidenciaAnexo_Leads.php', [], this.ListaAnexos).then((result) => {

        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });
        //this.fnInsertLead()
        this.fnUpdateLead_Nuevo()
      }, (error) => {
        console.log(error);
      });
    } else {
      //this.fnInsertLead()
      this.fnUpdateLead_Nuevo()
    }

  }

  fnUpdateLead_Nuevo() {
    this.strTipoCotizacion = ''
    let tipoCotizacion = this.arrayTipoCotizacion.filter((b: any) => b.Seleccionado == true)
    if (tipoCotizacion.length > 0) {
      tipoCotizacion.forEach(element => {
        this.strTipoCotizacion += (element.strTipoCotizacion) + ','
      });
    }
console.log(this.strFechaEstimadaCierre)
    this.strPotencializador = ''
    let poten = this.arrayPotenciador.filter((b: any) => b.Seleccionado == true)
    if (poten.length > 0) {
      poten.forEach((element: any) => {
        this.strPotencializador += (element.strLinea) + ','
      });
    }

    this.arrayCarpetas.forEach((b: any) => {
      if (b.xmlDocumentos.length > 0) {
        b.xmlDocumentos.forEach((c: any) => {
          this.arrayAnexosUpdate.push(c)
        })

      }
    })
    let objEnviar ={
      strAccion: 'updateLeads',
      intid_Ubicacion: (this.intIDUbicacion),
      intId_segunda: this.formLead.value.intId_segunda,
      intId_tercera: this.formLead.value.intId_tercera,
      strPotencializador: this.strPotencializador,
      intID_Familia: this.intID_Familia,
      strCodigoPostal: this.strCodigoPostal,
      strEstado: this.strEstado,
      strDelegacion: this.strDelegacion,
      strColonia: this.strColonia,
      strCalle: this.strCalle,
      strNo_exterior: this.strNumeroExterior,
      strEntrecalles: this.strEntreCalles,
      intLada: this.strLadaEmpresa,
      intTelefono: this.strTelefonoEmpresa,
      strZona: this.strZona,
      intId_Contacto: this.intId_Contacto,
      strContacto_Nombre: this.arrayContactosDetalle[0].strNombreContacto,
      strContacto_Telefono: this.arrayContactosDetalle[0].strContactoTelefono,
      strContacto_Puesto: this.arrayContactosDetalle[0].strContactoPuesto,
      strContacto_Email: this.arrayContactosDetalle[0].strContactoEmail,
      intIdLeads: this.intIDLeads,
      strNombreEmpresa: this.strNombreEmpresa,
      intId_Giro: this.intGiro,
      strSector: this.strSector,
      strRazon_Social: this.strRazonSocial,
      strRFC: this.strRFC,
      intIDNo_Empleados: (this.strNumeroEmpleados),
      strMedio_NosConocio: this.strMedioConocio,
      strEstatus: this.strTieneServicio,
      strAgente: this.strAgenteLD,
      dblMonto: this.dblImporte,
      strFechaCierre_Estimada: this.isFechaValida(this.strFechaEstimadaCierre) ? this.strFechaEstimadaCierre : null ,/* this.strFechaEstimadaCierre */
      strSitio_Web: this.strSitioWeb,
      strPrioridad: this.strPrioridad,
      intId_Direccion: this.intId_Direccion,
      intNo_Sucursales: this.intTotalSucursales,
      strFase: this.strFase,
      strtipoCotizacion: this.strTipoCotizacion,
      strFechaInicioServicio: this.fechaInicioServicio,
      intCantidadEmpleados: this.intCantidadElementos,
      strRecomendacion: this.strRecomendacion,
      intid_Cliente: this.intid_Cliente,
      intIDPresencia: this.formLead.value.intIDPresencia,
      strNombreCorto: this.formLead.value.strNombreCorto,
      strClasificacion: this.strClasificacion,
      xmlAnexos: this.arrayAnexosUpdate,
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    console.log(objEnviar)
    this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result);
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Actualizado",
            text:result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.arrayAnexosUpdate = []
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Actualizado",
            showConfirmButton: false,
            timer: 1500
          });
        }
        if (this.banderaPreguntaCambioFase && this.banderaFormulario) {
          Swal.fire({
            title: "¿Aplicar Cambio de fase?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, cambiar de fase!"
          }).then((result) => {
            if (result.isConfirmed) {
              this.fnCambioRecorrido()
              Swal.fire({
                title: "Cambio de fase exitoso!",
                text: "",
                icon: "success"
              });
              this.banderaPreguntaCambioFase = false
            }
          });
        }

        this.fnDetalleLead()
        this.strTipoCotizacion = ''
        this.ListaAnexos = []
        this.arrayAnexosUpdate = []

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  agregarCarpeta() {
    this.arrayCarpetas.push({
      nombreCarpeta: this.strNombreCarpeta,
      xmlDocumentos: []
    })

    this.strNombreCarpeta = ''
  }
  resetForm() {
    this.myForm.controls['strContacto_Nombre'].setValue('');
    this.myForm.controls['strContacto_Telefono'].setValue('');
    this.myForm.controls['strContacto_Email'].setValue('');
    this.myForm.controls['strContacto_Puesto'].setValue('');
    this.arrayAsigContacto1 = [];
    this.arrayAsigContacto2 = [];
    this.arrayAsigContacto3 = [];

    this.myForm.get('strContacto_Nombre').enable();
    this.myForm.get('strContacto_Puesto').enable();
    this.myForm.get('strContacto_Telefono').enable();
    this.myForm.get('strContacto_Email').enable();

    this.intEditInfoGeneral = 0;
  }


  fnGetClientesDetalle() {
    this.spinerCte = true;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getInfoCte',
      strCliente: this.intid_Cliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        this.arrayDetCliente = result;
        if (this.arrayDetCliente.length > 0) {
          this.objModal = this.arrayDetCliente[0]
        }

        /*this.objModal.strCliente = x.strCliente
        this.objModal.strNombre = x.strNombre
        this.objModal.strEstatus = x.strEstatus*/
        this.spinerCte = false;
        this.fnGetEstadoCuenta();
      }, error => {
        this.spinerCte = false;
        console.error('Error al consumir la API:', error);
      });
  }

  fnGetEstadoCuenta() {
    this.spinerEdoCta = true;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getEstadoCuenta',
      strCliente: this.intid_Cliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        this.arrayEstadoCuenta = result;

        this.spinerEdoCta = false;
        this.fnGetHistoricoVta()
      }, error => {
        this.spinerEdoCta = false;
        console.error('Error al consumir la API:', error);
      });
  }

  fnAvanzarPresentacion() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_1',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });

          this.fnDetalleLead()
          /* this.fnGetPorContactar()
          this.fnGetPresentacion() */
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarRecorrido() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_2',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnDetalleLead()
          /* this.fnGetPresentacion()
          this.fnGetRecorrido() */
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarProspecto() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_3',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnDetalleLead()
          /* this.fnGetPresentacion()
          this.fnGetRecorrido() */
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnsetEstatus_Cotizacion(idCotizacion: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setEstatus_Cotizacion',
      intIDMovimientoVenta: idCotizacion,
      strEstatus: this.strEstatusCotizacion,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          if (this.banderaPreguntaCambioFase) {
            if (this.strEstatusCotizacion == 'RECHAZADO') {
              this.fnAvanzarNegociacion()
            } else {

            }
            /* Swal.fire({
              title: "Desea proceder con el cambio de fase?",
              text: "se podra enviar en otra ocacion!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, cambiar de fase!"
            }).then((result) => {
              if (result.isConfirmed) {
               
                Swal.fire({
                  title: "Cambio de fase exitoso!",
                  text: "",
                  icon: "success"
                });
                this.banderaPreguntaCambioFase = false
              }
            }); */
          }

          this.intIDCotizacion = ''
          this.strEstatusCotizacion = ''
          this.fnGetCotizaciones()
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarNegociacion() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_Negociacion',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetActLeads()
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_Negociacion',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnDetalleLead()
          this.fnGetActLeads()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarCierre() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_Cierre',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnDetalleLead()
          /* this.fnGetPropuesta()
          this.fnGetCierre() */
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  Readexcel(file: File) {
    //this.spinner = true;
    this.excelData = []
    this.excelDataAux = []
    let filereader = new FileReader();
    filereader.readAsBinaryString(file);
    filereader.onload = (e) => {
      try {
        let arrayErrores: any = []
        var workBook = XLSX.read(filereader.result, { type: 'binary' });
        var sheetNames = workBook.SheetNames;
        this.excelDataAux = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);
        let todosTienenCampos: any
        if (this.strTipoLead == 'FAMILIA') {
          todosTienenCampos = this.excelDataAux.every(item =>
            item.hasOwnProperty('CODIGO_POSTAL') &&
            item.hasOwnProperty('RAZON_SOCIAL') &&
            item.hasOwnProperty('RFC') &&
            item.hasOwnProperty('CONTACTO_NOMBRE')
          );

        } else {
          todosTienenCampos = this.excelDataAux.every(item =>
            item.hasOwnProperty('CODIGO_POSTAL') &&
            item.hasOwnProperty('CONTACTO_TELEFONO') &&
            item.hasOwnProperty('CONTACTO_NOMBRE')
          );
        }

        if (todosTienenCampos) {
          this.excelDataAux.forEach((e: any) => {

            if (e.CODIGO_POSTAL.toString().length != 5) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de agregarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.CODIGO_POSTAL)
              this.excelDataAux = []
            } else if (!this.contieneSoloNumeros(e.CODIGO_POSTAL)) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de revisarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.CODIGO_POSTAL)
              this.excelDataAux = []
            }
            else { }
          });

          if (arrayErrores.length == 0) {
            const xmlCP = this.excelDataAux.map((cp: any) => {
              return { strCodigoPostal: cp.CODIGO_POSTAL };
            });

            this.fnValidarCP(xmlCP)

          } else {
            this.excelDataAux = []
            console.log('no se puede hacer esta transaccion')
          }
        } else {
          try {
            this.excelDataAux = []
            this.excelData = []

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Campos Incompletos!, Revisa que todos los campos esten con información",
            });
            const fileInput = this.fileInput2.nativeElement as HTMLInputElement;
            fileInput.value = ''; // Esto debería resetear el input file

            // Forzar el cambio de valor para disparar el evento 'change'
            fileInput.dispatchEvent(new Event('input', { bubbles: true }));
            fileInput.dispatchEvent(new Event('change', { bubbles: true }));
          } catch (error) {
            console.log('error')
          }


        }

      } catch (error) {

        this.excelDataAux = [];
        //this.spinner = false;
        Swal.fire({ icon: 'error', title: 'Error', text: 'El archivo seleccionado no es un archivo valido, descarga el layout y vuelve a intentarlo.', });
      }
    }
  }

  contieneSoloNumeros(cadena: string): boolean {
    // Iterar sobre cada carácter de la cadena
    for (let i = 0; i < cadena.length; i++) {
      // Verificar si el carácter actual no es un dígito numérico
      if (!this.esDigitoNumerico(cadena[i])) {
        return false; // Se encontró un carácter que no es un número
      }
    }
    return true; // Todos los caracteres son numéricos
  }

  fnValidarCP(xmlCP: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setValidaCP',
      xmlAnexos: xmlCP,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.excelData = this.excelDataAux
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          /*Swal.fire({
            icon: "error",
            title: "Oops...",
            text: 'Error al validar documento',
          });*/
          this.excelData = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  // Función auxiliar para verificar si un carácter es un dígito numérico
  private esDigitoNumerico(caracter: string): boolean {
    return /^[0-9]$/.test(caracter); // Utiliza una expresión regular para verificar si el carácter es un número del 0 al 9
  }

  fnDescargarFormatoFamilia() {
    const rutaArchivo = 'assets/FormatoSucursalesFamilia.xlsx'
    const link = document.createElement('a');
    link.href = rutaArchivo;
    link.download = 'FormatoSucursalesFamilias.xlsx';
    link.click();
  }

  fnDescargarFormatoCliente() {
    const rutaArchivo = 'assets/FormatoSucursalesCliente.xlsx'
    const link = document.createElement('a');
    link.href = rutaArchivo;
    link.download = 'FormatoSucursalesClientes.xlsx';
    link.click();
  }

  handleFileInput(event: any) {
    const fileInput = event.target as HTMLInputElement;
    const fileList = fileInput.files;

    //this.nameArchivo = fileList[0].name;

    this.Readexcel(fileList[0]);
  }

  handleDrop(event: DragEvent) {

    event.preventDefault();
    const fileList = event.dataTransfer.files;

    if (fileList.length > 0) {
      //this.capturador = false;

      // this.nameArchivo= fileList[0].name;
      this.Readexcel(fileList[0]);
    } else {
      // this.spinner = false;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    // Agregar estilos para resaltar el área de soltar
  }
  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    // Eliminar estilos de resaltado cuando se sale del área de soltar
  }

  fnEnviarAltaCliente() {

    this.objAlta = {
      strRfc: (this.strRFC) ? this.strRFC : '',
      strRazonSocial: (this.strRazonSocial) ? this.strRazonSocial : '',
      strNombreAgente: this.strNombreAgente,
      strCfdi: '',
      strRegimenFiscal: '',
      strCp: (this.strCodigoPostal) ? this.strCodigoPostal : '',
      strEstado: (this.strEstado) ? this.strEstado : '',
      strCiudad: (this.strDelegacion) ? this.strDelegacion : '',
      strColonia: (this.strColonia) ? this.strColonia : '',
      strCalle: (this.strCalle) ? this.strCalle : '',
      strNoExterior: (this.strNumeroExterior) ? this.strNumeroExterior : '',
      strNombreComercial: this.strNombreEmpresa,
      strTipoCliente: '',
      strAgente: this.strAgenteLD,
      strContacto: (this.arrayContactosDetalle[0].strNombreContacto) ? this.arrayContactosDetalle[0].strNombreContacto : '',
      strCorreo: (this.arrayContactosDetalle[0].strContactoEmail) ? this.arrayContactosDetalle[0].strContactoEmail : '',
      strTelefono: (this.arrayContactosDetalle[0].strContactoTelefono) ? this.arrayContactosDetalle[0].strContactoTelefono : '',
      strOrigen: '/detalleLead?idLead=' + this.intIDLeads
    }
    this.alta_cliente.setCte_alta(this.objAlta);
    this.router.navigate(['/altaCliente/']);
  }

  fngetArticulosFavoritos() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFavoritos_leads',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result.length > 0) {
          this.dataSource = result
          /*  this.dataSource.sort = this.sortArtFav; */
        } else {

        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  agregarAnexo(event: any, nombreCarpeta: any) {
    const fecha = new Date();
    const archivo: File = event.target.files[0];

    let totalSize = this.ListaAnexos.reduce((sum, file) => sum + file.size, 0);

    // Definir el límite máximo en bytes (10MB)
    const maxSize = 10 * 1024 * 1024;

    // Verificar si el nuevo archivo supera el límite
    if (totalSize + archivo.size > maxSize) {
      Swal.fire("Error", "El tamaño total de los archivos no puede superar los 10MB.", "error");
      return; // Se detiene la ejecución y no se agregan los archivos
    }

    const tipo = archivo.type.split('/');
    if (tipo[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      tipo[1] = "xlsx";
    }
    const nombreArchivo = this.intIDLeads + '-' + this.strTipoAnexo + ' ' + fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDay() + ' ' + fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds() + '.' + tipo[1];
    const rutaArchivo = 'EvidenciaLeads/' + nombreCarpeta + '/' + nombreArchivo;

    const nuevoArchivo = new File([archivo], nombreArchivo, { type: archivo.type });
    this.ListaAnexos.push(nuevoArchivo);

    this.arrayCarpetas.map((b: any) => {
      if (b.nombreCarpeta == nombreCarpeta) {
        let obj = {
          strTipo: tipo[1],
          strUrl: rutaArchivo,
          strTipoDocumento: this.strTipoAnexo,
          strNombre: nombreArchivo
        }
        b.xmlDocumentos.push(obj)
      }
    })
    console.log(this.arrayCarpetas)

    /*  this.arrayAnexosUpdate.push({
       strTipo: tipo[1],
       strUrl: rutaArchivo,
       strTipoDocumento: this.strTipoAnexo,
       strNombre: nombreArchivo
     }); */

  }
  seleccionarTab(index: number) {
    this.tabSeleccionada = index;
  }
  fnGetHistoricoVta() {
    this.spinerHisVta = true
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getHistorico',
      strCliente: this.intid_Cliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        this.arrayHistoricoVta = result;
        this.objModalHistorico = this.arrayHistoricoVta[0]
        this.spinerHisVta = false
        this.fnGetInfoAdicional();

      }, error => {
        this.spinerHisVta = false
        console.error('Error al consumir la API:', error);
      });
  }

  // Función para cambiar la imagen del logo
  cambiarImgLogo(event: Event) {
    const fileInput = event.target as HTMLInputElement;

    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedRenovFile = fileInput.files[0];
      this.strDBRutaLogo = 'Adjuntos/Leads/Logo/' + this.selectedRenovFile.name
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedRenovFile);
      reader.onload = (event: any) => {
        this.strRutaLogo = event.target.result;
      };
    }
  }


  // Función para enviar el archivo al servidor
  fnAnexoMov() {

    const formData = new FormData();
    formData.append('archivo', this.selectedRenovFile);

    this._serviciosService.setAnexMov1(formData).subscribe(
      (resultados: any) => {

        if (resultados.mensaje == '200') {

          Swal.fire({
            icon: 'success',
            title: 'Oops...',
            text: 'Actualizado!',
          });
          this.fnUpdateLogo()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al subir imagen!',
          });
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al subir imagen!',
        });
      }
    );

  }

  fnUpdateLogo() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Logo_Leads',
      intID_Leads: this.intIDLeads,
      strUrl_Logo: this.strDBRutaLogo,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnDetalleLead()
          this.strRutaLogo = ''
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetInfoAdicional() {
    this.spinerInfAdd = true
    this.intSaldoCorriente = 0;
    this.int15dias = 0;
    this.int30dias = 0;
    this.int60dias = 0;
    this.int90dias = 0;
    this.int90mas = 0;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getInfoAdicional',
      strCliente: this.intid_Cliente
    };
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        this.arrayInfoAdicional = result;
        this.arrayInfoAdicional.forEach(x => {
          this.intSaldoCorriente += x.dblSaldoCorriente
          this.int15dias += x.dblSaldo15
          this.int30dias += x.dblSaldo30
          this.int60dias += x.dblSaldo60
          this.int90dias += x.dblSaldo90
          this.int90mas += x.dblSaldoA
        });
        this.spinerInfAdd = false
        this.fnGetflujocte()

      }, error => {
        this.spinerInfAdd = false
        console.error('Error al consumir la API:', error);
      });
  }
  fnGetflujocte() {
    this.spinerFlujoCte = true
    this.intTotalImportFCte = 0;
    this.intCobradoFCte = 0;
    this.IntSaldoFCte = 0;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getFlujoCte',
      strCliente: this.intid_Cliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        this.arrayFlujocte = result;
        this.arrayFlujocte.forEach(e => {
          this.intTotalImportFCte = this.intTotalImportFCte + e.dblImporte;
          this.intCobradoFCte = this.intCobradoFCte + e.dblCobrado;
          this.IntSaldoFCte = this.IntSaldoFCte + e.dblSaldo;
        });
        this.spinerFlujoCte = false
        this.fnGetHistoricoxLinea();
      }, error => {
        this.spinerFlujoCte = false
        console.error('Error al consumir la API:', error);
      });
  }
  fnNamePeriodo(intPer: number): string {
    let strPeriodo = ""
    strPeriodo = intPer == 1 ? "Enero" : intPer == 2 ? "Febrero" : intPer == 3 ? "Marzo" : intPer == 4 ? "Abril" :
      intPer == 5 ? "Mayo" : intPer == 6 ? "Junio" : intPer == 7 ? "Julio" : intPer == 8 ? "Agosto" : intPer == 9 ? "Septiembre" :
        intPer == 10 ? "Octubre" : intPer == 11 ? "Noviembre" : intPer == 12 ? "Diciembre" : "";
    return strPeriodo;
  }

  fncreatepdf(strC: string) {
    const options = {
      margin: 8,
      filename: 'Cotizacion.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    const content = strC == "cuentacte" ? this.el.nativeElement : this.tableFlujoCte.nativeElement;

    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        pdf.save("Estado de Cuenta.pdf");
      });
  }
  applyFilterhisxvta(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataHisXvta.filter = filterValue.trim().toLowerCase();
  }
  fnGetHistoricoxLinea() {
    this.spinerVtaXlin = false
    this.objHisxLin = {
      dblAccesorios: 0, dblAccesoriosMargen: 0,
      dblKimberly: 0, dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
      , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0, dblQuimicosMargen: 0,
      dblDespachadores: 0, dblDespachadoresMargen: 0, dblTork: 0, dblTorkMargen: 0,
      dblXibu: 0, dblXibuMargen: 0, dblFoaom: 0, dblFoaomMargen: 0, total: 0
    }
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getHistLinea_Cte',
      strCliente: this.intid_Cliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {

        this.arrayHistoricoXlinea = result;

        this.arrayHistoricoXlinea.forEach(x => {
          const intEle = this.arrayAjusteLineas.filter(j => j.strEjercicio === x.strEjercicio && j.strPeriodo === x.strPeriodo);
          if (intEle.length === 0) {
            this.arrayAjusteLineas.push({
              strEjercicio: x.strEjercicio, strPeriodo: x.strPeriodo,
              dblAccesorios: 0, dblAccesoriosMargen: 0, dblKimberly: 0
              , dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
              , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0
              , dblQuimicosMargen: 0, dblDespachadores: 0, dblDespachadoresMargen: 0
              , dblTork: 0, dblTorkMargen: 0, dblXibu: 0, dblXibuMargen: 0
              , dblFoaom: 0, dblFoaomMargen: 0, total: 0
            });
          }
        });

        this.arrayAjusteLineas.forEach(o => {
          this.arrayHistoricoXlinea.forEach(x => {
            if (o.strEjercicio === x.strEjercicio && o.strPeriodo === x.strPeriodo) {
              if (x.strLinea == 'ACCESORIOS') {
                o.dblAccesorios = x.dblImporte;
                o.dblAccesoriosMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'KIMBERLY') {
                o.dblKimberly = x.dblImporte;
                o.dblKimberlyMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'QUIMICOS') {
                o.dblQuimicos = x.dblImporte;
                o.dblQuimicosMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'RUBBERMAID') {
                o.dblRubbermaid = x.dblImporte;
                o.dblRubbermaidMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'BLUE TISSUE') {
                o.dblBlueTissue = x.dblImporte;
                o.dblBlueTissueMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'DESPACHADORES ECODELI') {
                o.dblDespachadores = x.dblImporte;
                o.dblDespachadoresMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'TORK') {
                o.dblTork = x.dblImporte;
                o.dblTorkMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'FOAOM') {
                o.dblFoaom = x.dblImporte;
                o.dblFoaomMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'XIBU') {
                o.dblXibu = x.dblImporte;
                o.dblXibuMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              }
            }
          });
          o.total = (o.dblAccesorios ? o.dblAccesorios : 0) + (o.dblKimberly ? o.dblKimberly : 0) + (o.dblQuimicos ? o.dblQuimicos : 0) +
            (o.dblRubbermaid ? o.dblRubbermaid : 0) + (o.dblBlueTissue ? o.dblBlueTissue : 0) + (o.dblDespachadores ? o.dblDespachadores : 0) +
            (o.dblTork ? o.dblTork : 0) + (o.dblFoaom ? o.dblFoaom : 0) + (o.dblXibu ? o.dblXibu : 0);

          this.objHisxLin.dblAccesorios = this.objHisxLin.dblAccesorios + o.dblAccesorios;
          this.objHisxLin.dblAccesoriosMargen = this.objHisxLin.dblAccesoriosMargen + o.dblAccesoriosMargen;
          this.objHisxLin.dblKimberly = this.objHisxLin.dblKimberly + o.dblKimberly;
          this.objHisxLin.dblKimberlyMargen = this.objHisxLin.dblKimberlyMargen + o.dblKimberlyMargen;
          this.objHisxLin.dblRubbermaid = this.objHisxLin.dblRubbermaid + o.dblRubbermaid;
          this.objHisxLin.dblRubbermaidMargen = this.objHisxLin.dblRubbermaidMargen + o.dblRubbermaidMargen;
          this.objHisxLin.dblBlueTissue = this.objHisxLin.dblBlueTissue + o.dblBlueTissue;
          this.objHisxLin.dblBlueTissueMargen = this.objHisxLin.dblBlueTissueMargen + o.dblBlueTissueMargen;
          this.objHisxLin.dblQuimicos = this.objHisxLin.dblQuimicos + o.dblQuimicos;
          this.objHisxLin.dblQuimicosMargen = this.objHisxLin.dblQuimicosMargen + o.dblQuimicosMargen;
          this.objHisxLin.dblDespachadores = this.objHisxLin.dblDespachadores + o.dblDespachadores;
          this.objHisxLin.dblDespachadoresMargen = this.objHisxLin.dblDespachadoresMargen + o.dblDespachadoresMargen;
          this.objHisxLin.dblTork = this.objHisxLin.dblTork + o.dblTork;
          this.objHisxLin.dblTorkMargen = this.objHisxLin.dblTorkMargen + o.dblTorkMargen;
          this.objHisxLin.dblXibu = this.objHisxLin.dblXibu + o.dblXibu;
          this.objHisxLin.dblXibuMargen = this.objHisxLin.dblXibuMargen + o.dblXibuMargen;
          this.objHisxLin.dblFoaom = this.objHisxLin.dblFoaom + o.dblFoaom;
          this.objHisxLin.dblFoaomMargen = this.objHisxLin.dblFoaomMargen + o.dblFoaomMargen;
          this.objHisxLin.total = this.objHisxLin.total + o.total;
        });

        this.objHisxLin.dblAccesoriosMargen = ((this.objHisxLin.dblAccesoriosMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblKimberlyMargen = ((this.objHisxLin.dblKimberlyMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblRubbermaidMargen = ((this.objHisxLin.dblRubbermaidMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblBlueTissueMargen = ((this.objHisxLin.dblBlueTissueMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblQuimicosMargen = ((this.objHisxLin.dblQuimicosMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblDespachadoresMargen = ((this.objHisxLin.dblDespachadoresMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblTorkMargen = ((this.objHisxLin.dblTorkMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblXibuMargen = ((this.objHisxLin.dblXibuMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblFoaomMargen = ((this.objHisxLin.dblFoaomMargen / this.arrayAjusteLineas.length));

        this.dataHisXvta.data = this.arrayAjusteLineas
        this.dataHisXvta.paginator = this.paginador;
        this.spinerVtaXlin = false
        this.fnVtaXarticulo(this.intid_Cliente, this.intEjercicio);

      }, error => {
        this.spinerVtaXlin = false
        console.error('Error al consumir la API:', error);
      });
  }

  fnVtaXarticulo(x: any, ejer: number) {
    this.spinerVtaXart = true;

    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getVtaXArt',
      strCliente: x,
      intEjercicio: ejer,
      strLinea: ''
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        this.arrayVtaXart = result

        const uniqueYearsSet = new Set(this.arrayVtaXart.map(item => item.strEjercicio));
        this.arrayCabeceraXart = Array.from(uniqueYearsSet).map(year => ({ strEjercicio: year }));
        const arts = new Set(this.arrayVtaXart.map(item => item.strArticulo));
        this.arrayArts = Array.from(arts).map(year => ({ strArticulo: year }));
        this.arrayArts.forEach(elem => {
          const matchingObj = this.arrayVtaXart.find(item => item.strArticulo === elem.strArticulo);
          if (matchingObj) {
            Object.assign(elem, matchingObj);
          }
        });
        this.arrayVtaXart.forEach(art => {
          this.arrayArts.forEach(item => {
            if (art.strArticulo == item.strArticulo) {
              if (art.strPeriodo == 1) {
                item['ene' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 2) {
                item['feb' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 3) {
                item['mar' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 4) {
                item['abr' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 5) {
                item['may' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 6) {
                item['jun' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 7) {
                item['jul' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 8) {
                item['ago' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 9) {
                item['sep' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 10) {
                item['oct' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 11) {
                item['nov' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 12) {
                item['dic' + art.strEjercicio] = art.intCantidad;
              }
            }
          });
        });

        this.arrayArts.forEach(item => {
          item.intTotal = 0
          this.arrayCabeceraXart.forEach(art => {
            let suma =
              (item['ene' + art.strEjercicio] ? item['ene' + art.strEjercicio] : 0) +
              (item['feb' + art.strEjercicio] ? item['feb' + art.strEjercicio] : 0) +
              (item['mar' + art.strEjercicio] ? item['mar' + art.strEjercicio] : 0) +
              (item['abr' + art.strEjercicio] ? item['abr' + art.strEjercicio] : 0) +
              (item['may' + art.strEjercicio] ? item['may' + art.strEjercicio] : 0) +
              (item['jun' + art.strEjercicio] ? item['jun' + art.strEjercicio] : 0) +
              (item['jul' + art.strEjercicio] ? item['jul' + art.strEjercicio] : 0) +
              (item['ago' + art.strEjercicio] ? item['ago' + art.strEjercicio] : 0) +
              (item['sep' + art.strEjercicio] ? item['sep' + art.strEjercicio] : 0) +
              (item['oct' + art.strEjercicio] ? item['oct' + art.strEjercicio] : 0) +
              (item['nov' + art.strEjercicio] ? item['nov' + art.strEjercicio] : 0) +
              (item['dic' + art.strEjercicio] ? item['dic' + art.strEjercicio] : 0)
            item.intTotal = item.intTotal + suma
          })
        });
        this.spinerVtaXart = false;

      }, error => {
        this.spinerVtaXart = false;
        console.error('Error al consumir la API:', error);
      });
  }

  fngetLineas() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLineas',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayLineas2 = result

        result.forEach((element: any) => {
          if(element.strLinea != "ECODELI"){
          let data = {
            Seleccionado: false,
            strLinea: element.strLinea,
            intID_linea: element.intID_linea
          }
          this.arrayPotenciador.push(data)
        }
        });
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  funConsultarArt(art: any) {
    if (art.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getArt_Linea',
        strLinea: this.formOportunidades.get('strLinea')?.value, //this.strLinea,
        strBusqueda: art,
        strCliente: '',
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {
          this.arrayArts = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayArts = []
    }

  }

  funArticulos(art: any) {

  }

  fnAgregarArticulo() {
    let data = {
      strArticulo: this.strBuscarArticulo/* ,
      strDescripcion: this.strDescripcionArticulo */
    }
    this.arrayArticuloAgregados.push(data)
    this.strBuscarArticulo = ''
    this.intCantidadArticulos = 0
  }
  fnBuscarCte(cte: string) {
    this.formOportunidades.patchValue({
      strResponsable2: ""
    });
    if (cte.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAgentes_OP',
        strBusqueda: cte,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {

          this.arrayAsigAgentes = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigAgentes = [];
    }
  }
  fnselecCliente(objClient: any) {
    this.objResponsable = objClient;
    this.strResponsable = objClient.strNombreAgente
    this.formOportunidades.patchValue({
      strResponsable: objClient.strNombreAgente
    });
    this.formOportunidades.patchValue({
      strResponsable2: objClient.strNombreAgente
    });
    this.arrayAsigAgentes = [];
  }

  fnselecCTE(objClient: any) {
    this.strNumeroCliente = objClient.strNombreMostrar
    this.strNombreMostarCliente = objClient.strCliente
    this.arrayBuscarClientes = [];
  }


  fnselecArticulo(obj: any) {
    this.strBuscarArticulo = obj.strArticulo
    this.strDescripcionArticulo = obj.strDescripcion
    this.arrayArts = [];
    this.fnAgregarArticulo();
  }

  fnInsertOportunidad(object: any) {

    let idLinea = this.arrayLineas2.filter((b: any) => b.strLinea == this.strLinea)

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setOportunidad_Leads',
      strTitulo: object.strTitulo,//this.strNombreOportunidad,
      dblImporte: object.intMonto, //this.dblMonto,
      strFecha_Cierre: object.strFecha,//this.strFechaCierre,
      strAgente: this.objResponsable.strAgente,//this.strResponsable,
      intID_Leads: this.intIDLeads,
      intID_Linea: (idLinea[0]) ? idLinea[0].intID_linea : '',
      intID_Leads_Sucursal: this.intID_Leads_Sucursal,
      xmlArticulos: this.arrayLineasSelect, //this.arrayArticuloAgregados,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Oportunidad Registrada con exito",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnListadoOoprtunidad()
          this.fnGetActLeads()
          this.btnCerrarOportunidades.nativeElement.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al Agregar!"
          });
        }

        this.strNombreOportunidad = ''
        this.dblMonto = ''
        this.strFechaCierre = ''
        this.intID_linea = ''
        this.strResponsable = ''
        this.arrayArts = []
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnLineaSeleccionada(linea: any) {

    this.strLinea = linea
  }

  fnCheckValidaciones(b: any) {

    if (b == 'presentacion') {
      if (this.strEtapa !== 'PRESENTACION' && this.strEtapa !== 'RECORRIDO' && this.strEtapa !== 'PROPUESTA ECONOMICA' && this.strEtapa !== 'NEGOCIACION' && this.strEtapa !== 'GANADO' && this.strEtapa !== 'PERDIDO') {
        this.objAct.strEstatus = 'PENDIENTE'

        if (this.intFlagAvanza1 === 0) {

          if (this.intIDActividad == 0) {
            this.fnModalRegistrarAct('Correo')
            document.getElementById('btnModal')!.click()
          } else {
            this.fnInitSummer()
            this.banderaPreguntaCambioFase = true
            document.getElementById('botonConcluir')!.click()
          }
        } else {
          this.fnEnviarPresentacion()
        }
      }

    } else if (b == 'recorrido') {

      if (this.strEtapa != 'RECORRIDO' && this.strEtapa != 'POR CONTACTAR' && this.strEtapa !== 'PROPUESTA ECONOMICA' && this.strEtapa !== 'NEGOCIACION' && this.strEtapa !== 'GANADO' && this.strEtapa !== 'PERDIDO') {
        this.banderaPreguntaCambioFase = true
    /*     if (!this.formLead.valid || !this.banderaCotizaciones) {
          document.getElementById('botonLead')?.click()
        } else if (!this.banderaFormulario && this.formLead.valid) {
          document.getElementById('botonFormF')?.click()
        } else { */
          this.fnCambioRecorrido()
       /*  } */
      }

    } else if (b == 'propuesta') {
      if (this.strEtapa !== 'PROPUESTA ECONOMICA' && this.strEtapa !== 'NEGOCIACION' && this.strEtapa !== 'GANADO' && this.strEtapa !== 'PERDIDO') {
      /*   if (this.arrayCotizaciones.length == 0) {
          document.getElementById('botonCotizaciones')?.click()
        } else { */
          this.fnCambiarPropuestaEcomica()
       /*  } */
      }

    } else if (b == 'negociacion') {
      if (this.strEtapa !== 'NEGOCIACION' && this.strEtapa !== 'GANADO' && this.strEtapa !== 'PERDIDO') {
        this.banderaPreguntaCambioFase = true
        this.cotizacionP = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'POR AUTORIZAR')
        this.cotizacionAcep = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'ACEPTADO')
        this.cotizacionRec = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'RECHAZADO')

        if (this.cotizacionP.length > 0) {
          this.strMensaje = 'Tiene una cotización con estatus PENDIENTE, lo cual impide avanzar a la siguiente fase'
        } else if (this.cotizacionAcep.length > 0 && this.cotizacionP.length == 0) {
          this.strMensaje = 'Ya no es necesario pasar a la fase de negociación. Usted tiene una cotización aceptada, por favor proceda a cerrar el negocio'
        } /* else if (this.cotizacionRec.length > 0 && this.cotizacionAcep.length == 0) {
          this.strMensaje = 'Tiene una cotización con estatus PENDIENTE, lo cual impide avanzar al cierre. Por favor, genere una nueva cotización.'
        } */

        if (this.intFlagAvanza5 == 0) {
          document.getElementById('btnConcluirCotizaciones')?.click()
        } else {
          this.fnAvanzarNegociacion()
        }
      }

    } else { }
  }

  fnEnviarActividad(anexos: any = {}) {
    let xml: any = []
    if (this.arrayAgentesActividades.length > 0) {
      this.arrayAgentesActividades.forEach(item => {
        xml.push({ strAgente: item.strAgente, strNombreAgente: item.strNombre })
      });
    }
    if (this.strfechaAct == null || this.strfechaAct == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnv: { [key: string]: any } = {
        strAccion: 'setActividad_Leads_multiple',/* setActividad_Leads */
        intID_TIPO: this.objModal.titulo == "Correo" ? 1 : this.objModal.titulo == "Llamada" ? 3 : this.objModal.titulo == "Visita" ? 2 : 6,
        strTitulo: this.mdlNombre,
        strAgente: "",/* this.strAgenteLD */
        strFechaInicioServicio: this.convertDateToString(this.strfechaAct),
        strConexion: this.strConexion,
        strComentario: this.mdlComentarios,
        intID_Leads: this.intIDLeads,
        strAgente_Crea: this.strAgente_Crea,
        xmlArticulos: xml,
      }
      if (this.objModal.titulo != "Tarea") {
        objEnv.strHoraInicio = this.mdlHoraInicio;
        objEnv.strHoraFin = this.mdlHoraFin;
      }
      if (anexos && Object.keys(anexos).length > 0) {
        objEnv.strTipo_CIERRE = anexos.strTipo;
        objEnv.strUrl_Logo = anexos.strRespuesta;
        objEnv.strNombreCorto = anexos.strNombre;
        objEnv.strEstatus = "";
      }
      if (this.objModal.titulo == "Subtarea") {
        objEnv.intID_Actividad = this.objSubtarea.intID_Actividad;
      }
      this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
        result => {
          Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.arrayAgentesActividades = [];
            this.fnGetActLeads()
            this.fnDetalleLead()
            this.fngetActividades(this.intIDLeads)
            this.btnCerrar.nativeElement.click();
          }

        }, error => {
          var error = <any>error; console.log(error);
        }
      );
    }


  }

  fnCambiarPropuestaEcomica() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_3',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetActLeads()
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnCambioRecorrido() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_2',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetActLeads()
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  fnModalRegistrarAct(tipo: string) {
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfechaAct = null;
    this.objModal.titulo = tipo;

    this.strBuscarAgente = ""
    console.log("Esto se ejecuta después de 3 segundos");
    this.arrayAnexMdlAdd = [];
    this.fileInputActividades.nativeElement.value = "";
    this.arrayAgregarAnexosActividades = [];
    this.inputComentarios.nativeElement.innerHTML = ""



  }

  fnGetActLeads() {
    let objEnv = {
      strAccion: 'get_timeline_leads',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayOportTimeLine = result
        this.arrayActividadesTab = this.arrayOportTimeLine.filter(o => o.strTipo == "Visita" || o.strTipo == "Llamada" || o.strTipo == "Correo")
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnEstatusCierre(estatus: any) {
    if(estatus == 'PERDIDO'  ||(estatus == 'GANADO'  && this.strRFC != null && this.strRFC != "")){
      this.strEstatusCierre = estatus;
      this.strMensaje_cierre_1 = estatus == 'PERDIDO' ? '¡Lamentamos oir eso! Sigamos adelante con determinación y optimismo!' : '¡Bien hecho! Ahora a cerrar el negocio';
      this.strMensaje_cierre_2 = estatus == 'PERDIDO' ? 'Explica los motivos por los que has perdido  esta oportunidad' : 'Explica los motivos por los que has ganado esta oportunidad';
      document.getElementById('btnCierreGanado')?.click()
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Falta el RFC del prospecto',
        html: `
           <p>Para poder cambiar el estatus del prospecto a <strong>Ganado</strong>, es necesario registrar su <strong>RFC</strong>. Por favor, realice los siguientes pasos:</p>
    <ol style="text-align: left;">
      <li>Diríjase a la sección inferior y seleccione la pestaña <strong>Dirección</strong>.</li>
      <li>Ubique el campo de texto correspondiente al <strong>RFC</strong> e ingrese el valor requerido.</li>
      <li>Haga clic en el botón <strong>Guardar</strong> para confirmar la información.</li>
    </ol>
        `
      });
    }

  }

  fnEnviarPresentacion() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_1',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetActLeads()
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnCerraLead() {
    let objEnv = {
      strAccion: 'setAvanzaPaso_Cierre',
      intIdLeads: this.intIDLeads,
      strEstatus: this.strEstatusCierre,
      strMotivo: this.strComentarios,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnDetalleLead()
          this.fnGetActLeads()
        } else {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }

  fnEnProceso(objAct: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Proceso',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = "";
          this.contenido = "";
          this.fngetActividades(this.intIDLeads);
          this.btnCerrarAct.nativeElement.click();

        } else {
          this.contenido = ''
          this.strMensaje = ''
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnRechazar(objAct: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Rechazar',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = ""
          this.contenido = "";
          this.fngetActividades(this.intIDLeads);
          this.btnCerrarAct.nativeElement.click();


        } else {
          this.contenido = ''
          this.strMensaje = ''
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnConcluirActividad() {
    let processedContent = this.contenido || '';
    processedContent = processedContent.replace(/<img(?![^>]*\bclass=["'][^"']*\bimg-fluid\b)/g, '<img class="img-fluid"');


    // Ahora se puede asignar el contenido procesado a [innerHTML]
    /* this.contenido = processedContent; */
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad',
      intID_Actividad: this.intIDActividad,
      intID_Leads: this.intIDLeads,
      strComentario: processedContent,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = ''
          this.contenido = ''
          this.fnInitSummer()
          if (this.banderaPreguntaCambioFase) {
            Swal.fire({
              title: "¿Aplicar cambio de fase?",
              text: "se podra enviar en otra ocacion!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, cambiar de fase!"
            }).then((result) => {
              if (result.isConfirmed) {
                this.fnEnviarPresentacion()

                Swal.fire({
                  title: "Cambio de fase exitoso!",
                  text: "",
                  icon: "success"
                });

                this.banderaPreguntaCambioFase = false
              }
              this.fnDetalleLead()
              this.fnGetActLeads()
              this.fngetActividades(this.intIDLeads);
            });
          } else {
            this.fnDetalleLead()
            this.fnGetActLeads()
            this.fngetActividades(this.intIDLeads);
          }
        } else {
          this.contenido = ''
          this.strMensaje = ''
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  enviarDetalleOporturnidad(estatus: any, obj: any) {
    //guardamos en el servicio de indexed db la ruta
    let objRuta_Anterior = {
      ruta_origen: '/detalleLead',
      ruta_destino: '/detoport',
      nombre: 'Detalle Leads',
      parametros: { idLead: this.intIDLeads },
      ruta_anterior_1: {
        nombre: this.strNombre_ruta,
        ruta_origen: this.strRuta_Origen,
        parametros: {}
      }
    }


    this.indexedDBService.removeItem('objRuta_Anterior');
    this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);
    this.router.navigate(['/detoport'], { queryParams: { idLead: obj.intID_Leads_Oportunidad, sts: estatus } });
  }
  fnModalCerrar() {
    this.mdlCerrarFecha = null;
    this.mdlCerrarImporte = 0;
    this.mdlCerrarMotivo = "";

  }

  onMouseEnter() {
    this.inthov = true;
  }

  onMouseLeave() {
    this.inthov = false;
  }

  fuActualizarTipoLead() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Tipo_Leads',
      intID_Leads: this.intIDLeads,
      strTipo_Leads: this.strTipoLead,
      intID_Familia: this.intID_Familia,
      strAgente: this.strAgenteLD,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {

          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            text: result[0].strMensaje,
          });
          //this.fnActualizarActivo();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  transform(value: string): string {
    // Asegúrate de que el valor es una cadena y tiene una fecha válida
    if (!value) return value;

    // Extrae solo la fecha
    const datePart = value.split(' ')[0];
    return datePart;
  }
  fnmDLConAct(Act: any) {
    this.boolConcluir = false;
    this.intIDActividad = Act.intID_Actividad
    let idAct = Act.intID_Actividad, obj: any, Act2: any;

    Act2 = this.arrayActividades.find(x => x.intID_Actividad == idAct);
    if(Act2){
      this.objAct = Act2;
    }else{
      Act2 = this.arraySubTareas.find(x => x.intID_Actividad == idAct);
      this.objAct = Act2;
    }
    obj = Act2
    this.objDetActividad = {};
    this.spineract = true;
    this.contenido = "";
    this.strResultado = ""
    this.fngetComentarios()
    this.strTituloActividad = obj.strTitulo
    this.intIDActividad = obj.intID_Actividad



    this.objAct = obj

    let agente = this.arrayAgentes.find(x => x.strAgente == obj.strAgente_Crea)
    this.objAct.nombreCrea = agente.strNombre
    if (obj.strEstatus == "CONCLUIDO" || obj.strEstatus == "POR AUTORIZAR") {
      this.fnGetDetActi(obj);
    }

    this.fnInitSummer();
    this.spineract = false;
    this.fnGetAnexosActividades()
  }

  fnGetDetActi(objAct: any) {
    this.spineract = true;
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result.length > 0) {
          this.objDetActividad = result[0];
          this.spineract = false;
        }
        this.spineract = false;
        this.objAct.strObservaciones = this.objDetActividad.strObservaciones == "" ? "--campo vacio--" : this.objDetActividad.strObservaciones
        this.objAct.strResultado = this.objDetActividad.strResultado == "" ? "0" : this.objDetActividad.strResultado
      }, error => {
        this.spineract = false;
        var error = <any>error; console.log(error);
      }
    );
  }
  fnAutorizar(objAct: any) {
    let objEnv = {
      strAccion: 'update_Actividad_Autorizar',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Autorizado',
            text: result[0].strMensaje,
          });
          this.fngetActividades(objAct.intID_Leads)
          this.btnCerrarAct.nativeElement.click()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }


  fnInsertSucursales() {
    let objEnv = {
      strAccion: 'setLeads_Cte_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.strAgente,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }


      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnInsertSucursalesSucursales() {
    let objEnv = {
      strAccion: 'Set_Lead_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.strAgente,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnInsertSucursalesCliente() {
    let objEnv = {
      strAccion: 'setLeads_Cte_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.strAgente,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnGetListadoSucursal() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'GetListado_Suc_Familia',
      intID_leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayListadoSucursales = result
        let razonesSociales: any = []
        this.arrayListadoSucursales.forEach((element: any) => {

          let nombre = element.strNombreEmpresa_Cliente
          if (razonesSociales.includes(nombre)) {
          } else {
            razonesSociales.push(nombre)
          }
        });

        let arrayRazon: any = []
        razonesSociales.forEach(element => {
          let filtro = this.arrayListadoSucursales.filter((b: any) => b.strNombreEmpresa_Cliente == element)
          arrayRazon.push(filtro)
        });

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetListadoSucursal_Sucursal() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'GetListado_Suc_Cliente',
      intID_leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayListadoSucursales = result

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetFormulario() {
    this.arraySecciones = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFormulario',
      intID_Giro: this.intGiro,
      intID_leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        let seccionesMap = new Map<string, any[]>();
        result.forEach((element: any) => {
          let seccion = element.strSeccion;
          if (!seccionesMap.has(seccion)) {
            seccionesMap.set(seccion, []);
            this.arrayNombresSecciones.push(seccion);
          }
          seccionesMap.get(seccion).push(element);
        });

        this.intTotalPreguntas = 0;
        let resultado: any = ''
        seccionesMap.forEach((arregloAux: any[], seccion: any, index: any) => {
          resultado = arregloAux[0].strResultado
          const arrayPreguntas = arregloAux.map((b: any) => ({
            strRespuesta: (!b.strResultado) ? '' : b.strResultado,
            intID_Formulario: b.intID_Formulario,
            strTipoRespuesta: b.strTipoRespuesta,
            strPregunta: b.strPregunta,
            arrayRespuestas: b.strTipoRespuesta === 'LISTA' ? b.strRespuesta.split(',') : [],
            depende_pregunta_id: b.intDependeId,
            depende_pregunta_text: b.strDependeRespuesta,
            disabled: false,
            disabledRespuesta: (b.strResultado) ? true : false
          }));

          this.intTotalPreguntas += arregloAux.length;

          this.arraySecciones.push({
            index,
            strSeccion: seccion,
            intTotalPreguntas: arregloAux.length,
            intTotalContestadas: (resultado) ? arregloAux.length : 0,
            preguntas: arrayPreguntas,
          });
        });

        this.banderaFormulario = (resultado) ? true : false


      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  fnGetDetalleCotizacion(item: any) {
    let estatus_cotizacion = item.strEstatus;

    this.intIDMovimientoVenta = item.intIDMovimientoVenta
    let obj = {
      strAccion: 'getCotizaciones_Cte_Det',
      intIDMovimientoVenta: this.intIDMovimientoVenta,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        result.forEach(arts => {
          if (arts.dblPrecioSugerido > 0) {
            arts.dblImporte = arts.dblPrecioSugerido * arts.dblCantidad
          } else {
            arts.dblImporte = arts.dblCantidad * arts.dblPrecio
          }
        });
        this.dataSourceCotizaciones = result
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }
  fnNumberPregunta(event: any, respuestas: any) {
    let filtro = Array.from(new Set(respuestas.preguntas
      .filter((b: any) => b.depende_pregunta_id > 0)
      .map((b: any) => b.depende_pregunta_id)));

    if (filtro.length > 0) {
      filtro.forEach((element: any) => {
        let depende = respuestas.preguntas.find((b: any) => b.intID_Formulario == element);
        let resp = respuestas.preguntas.filter((b: any) => b.depende_pregunta_id == element);

        if (depende.strRespuesta != resp[0].depende_pregunta_text) {
          resp.forEach((b: any) => {
            b.strRespuesta = 'N/A';
            b.disabled = true;
          });
        } else {
          resp.forEach((b: any) => {
            if (b.strRespuesta == 'N/A') {
              b.strRespuesta = '';
            }
            b.disabled = false;
          });
        }
      });
    }

    respuestas.intTotalContestadas = respuestas.preguntas.reduce((total: number, element: any) => {
      return total + (element.strRespuesta !== '' ? 1 : 0);
    }, 0);

    if ((respuestas.intTotalContestadas == respuestas.intTotalPreguntas) && this.intTotalPreguntas > this.intTotalContestadas) {
      this.intTotalContestadas += respuestas.intTotalContestadas;
    }

  }


  mostrarComentarios(index: any, seccion: any) {
    let nombre: any = seccion + ' ' + index

    let ocultar = document.getElementById(nombre)!

    if (ocultar.style.display == 'none') {
      ocultar.style.display = 'block'
    } else {
      ocultar.style.display = 'none'
    }
  }
  click(pregunta: any, i: any, m: any) {

    //this.index = ''

    //this.index = pregunta.intID
    document.getElementById("file-input" + i + m)?.click();
  }
  calculateRowspan(index: number, field1: string, field2: string): number {
    let rowspan = 1;
    const current = this.arrayListadoSucursales[index];
    if (index > 0) {
      const previous = this.arrayListadoSucursales[index - 1];
      if (current[field1] === previous[field1] && current[field2] === previous[field2]) {
        rowspan = 0;
      }
    }
    // Calcular la cantidad de filas que deben combinarse
    for (let i = index + 1; i < this.arrayListadoSucursales.length; i++) {
      if (current[field1] === this.arrayListadoSucursales[i][field1] && current[field2] === this.arrayListadoSucursales[i][field2]) {
        rowspan++;
      } else {
        break;
      }
    }
    return rowspan;
  }

  getRowSpan(array: any[], field: string) {
    let rowSpanMap = {};
    let lastValue = null;
    let spanCount = 0;

    for (let i = 0; i < array.length; i++) {
      let value = array[i][field];
      if (value === lastValue) {
        spanCount++;
      } else {
        if (lastValue !== null) {
          rowSpanMap[lastValue] = spanCount;
        }
        lastValue = value;
        spanCount = 1;
      }
    }
    if (lastValue !== null) {
      rowSpanMap[lastValue] = spanCount;
    }
    return rowSpanMap;
  }

  shouldDisplayRowspan(array: any[], field: string, index: number) {
    if (index === 0) {
      return true;
    }
    return array[index][field] !== array[index - 1][field];
  }

  getSelectSucursales() {
    let obj = {
      strAccion: 'getSelect_Suc_Oportunidad',
      intID_leads: this.intIDLeads,
      strConexion: this.strConexion
    } /*   this.fnGetSuc(); */

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        this.arraySelectSucursales = result
      });
  }

  fnGetDatosFiscales() {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: (this.strCodigoPostal) ? this.strCodigoPostal : this.myForm.controls['strCodigoPostal'].value
    }).subscribe(
      result => {
        if (result.length > 0) {
          if (this.strCodigoPostal) {
            this.strEstado = result[0].strEstado
            this.strDelegacion = result[0].strDelegacion
            this.arrayColonias = result;
          } else {
            this.myForm.controls['strEstado'].setValue(result[0].strEstado);
            this.myForm.controls['strDelegacion'].setValue(result[0].strDelegacion);
            this.arrayColonias = result;
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });
          this.myForm.controls['strEstado'].setValue('');
          this.myForm.controls['strDelegacion'].setValue('');
          this.arrayColonias = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetDatosFiscalesDetalle(CP: any) {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: CP
    }).subscribe(
      result => {
        if (result.length > 0) {

          this.arrayColonias = result;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });

        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnSetFamilia_Fin() {

    let obj = {
      strAccion: 'SetFamilia_Fin',
      intID_leads: this.intIDLeads,
      strFamilia: this.strFamiliaNueva,
      strConexion: this.strConexion
    } /*   this.fnGetSuc(); */

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strFamiliaNueva = ''
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      });
  }

  fnsetSucursal_Cte() {

    let obj = {
      strAccion: 'setSucursal_Cte',
      strCliente: this.strNombreMostarCliente,
      strCodigoPostal: this.strCodigoPostal,
      strCalle: this.strCalle,
      strNo_exterior: this.strNumeroExterior,
      strEntrecalles: this.strEntreCalles,
      strColonia: this.strColonia,
      strAgente: this.strAgenteLD,
      intID_leads: this.intIDLeads,
      strConexion: this.strConexion
    } /*   this.fnGetSuc(); */

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strNombreMostarCliente = ''
          this.strCalle = ''
          this.strNumeroExterior = ''
          this.strEntreCalles = ''
          this.strColonia = ''
          this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      });
  }


  fnBuscarCliente(cte: string) {
    if (cte.length > 1) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getCte_leads',
        strCliente: cte,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Cotizador').subscribe(
        result => {

          this.arrayBuscarClientes = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigAgentes = [];
    }
  }

  fnGuardarSuc() {
    this.excelData = []
    if (this.strTipoLead == 'FAMILIA') {
      this.excelData.push(this.objSuFamilia)
    } else {
      this.excelData.push(this.objSucCliente)
    }
    this.fnInSertarSucFam();
  }

  fnlimpiarmdlSuc() {
    this.objSuFamilia.RAZON_SOCIAL = ""
    this.objSuFamilia.RFC = ""
    this.objSuFamilia.NOMBRE_SUCURSAL = ""
    this.objSuFamilia.DIRECCION = ""
    this.objSuFamilia.DIRECCION_NUMERO = ""
    this.objSuFamilia.CODIGO_POSTAL = ""
    this.objSuFamilia.COLONIA = ""
    this.objSuFamilia.CONTACTO_NOMBRE = ""
    this.objSuFamilia.CONTACTO_PUESTO = ""
    this.objSuFamilia.CONTACTO_EMAIL = ""
    this.objSuFamilia.CONTACTO_TELEFONO = ""


    this.objSucCliente.NOMBRE_SUCURSAL = ""
    this.objSucCliente.DIRECCION = ""
    this.objSucCliente.DIRECCION_NUMERO = ""
    this.objSucCliente.CODIGO_POSTAL = ""
    this.objSucCliente.COLONIA = ""
    this.objSucCliente.CONTACTO_NOMBRE = ""
    this.objSucCliente.CONTACTO_PUESTO = ""
    this.objSucCliente.CONTACTO_EMAIL = ""
    this.objSucCliente.CONTACTO_TELEFONO = ""
    this.fnformSucursal();
  }

  onKeyDown(event: KeyboardEvent) {
    // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
    const ENTER_KEY = 13;
    // Verificar si se presionó la tecla Delete o Retroceso
    if (event.keyCode === ENTER_KEY) {
      this.fnGetColonias(this.strTipoLead == "FAMILIA" ? this.objSuFamilia.CODIGO_POSTAL : this.objSucCliente.CODIGO_POSTAL);
    }
  }

  blurevent() {
    if (this.objSuFamilia.CODIGO_POSTAL == 5) {
      this.fnGetColonias(this.strTipoLead == "FAMILIA" ? this.objSuFamilia.CODIGO_POSTAL : this.objSucCliente.CODIGO_POSTAL);
    }
  }

  validarNumeros(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
      this.arrayColSuc = [];
    } else {
      let cp = (this.strTipoLead == "FALIMILIA" ? this.objSuFamilia.CODIGO_POSTAL : this.objSucCliente.CODIGO_POSTAL) + event.key
      if (cp.length == 5) {


        this.fnGetColonias(cp);
      } else {
        this.arrayColSuc = [];
      }
    }

  }

  fnGetColonias(cp) {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: cp
    }).subscribe(
      result => {
        if (result.length > 0) {
          if (this.strTipoLead == "FAMILIA" ? this.objSuFamilia.CODIGO_POSTAL : this.objSucCliente.CODIGO_POSTAL) {
            this.arrayColSuc = result;
          } else {
            this.arrayColSuc = result;
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });
          this.arrayColSuc = []
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  fnInSertarSucFam() {
    let objEnv = {
      strAccion: this.strTipoLead == 'FAMILIA' ? 'setLeads_Cte_Suc' : 'Set_Lead_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.strAgente,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          if (this.strTipoLead == 'FAMILIA') { this.fnGetListadoSucursal() } else { this.fnGetListadoSucursal_Sucursal() }
          this.btnCerrarMdlSuc.nativeElement.click()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }


      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnSETFORM_RESPUESTA() {
    let xmlRespuestas: any = []
    this.arraySecciones.forEach((element: any) => {

      element.preguntas.forEach((pregunta: any) => {
        let data = {
          intID_Formulario: pregunta.intID_Formulario,
          strRespuesta: pregunta.strRespuesta
        }

        xmlRespuestas.push(data)
      });
    });
    console.log(this.arraySecciones)


    let objEnv = {
      strAccion: 'SETFORM_RESPUESTA',
      xmlAnexos: xmlRespuestas,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    console.log(objEnv)
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.fnUpdateLead_Nuevo()
          this.fnGetFormulario()
          if (this.banderaPreguntaCambioFase) {
            Swal.fire({
              title: "¿Aplicar Cambio de fase?",
              /* text: "se podra enviar en otra ocacion!", */
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, cambiar de fase!"
            }).then((result) => {
              if (result.isConfirmed) {
                this.fnCambioRecorrido()
                Swal.fire({
                  title: "Cambio de fase exitoso!",
                  text: "",
                  icon: "success"
                });
                this.banderaPreguntaCambioFase = false
              }
            });
          }

        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }
  fnEiminarProductos(indice: number) {
    this.arrayArticuloAgregados.splice(indice, 1);
  }

  blurevent2() {
    this.fnGetDatosFiscales();
  }
  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }
  fnAbreModal_Tarea() {

    this.objAct = this.arrayActividades.find(o => o.intID_Actividad == this.intIDActividad)
    this.contenido = null;
    this.strResultado = '';
    this.limpiarContenido();
  }
  limpiarContenido() {
    $('.summernote').summernote('code', null);
    $('#summernote').summernote('destroy');

  }


  fnFiltrarSegundaLetra() {
    this.arraySubGiro = this.arraySubGiroTotal.filter((b: any) => b.intId_Giro == this.formLead.value.intId_Giro)

    //this.arrayFitroSegunda = this.arraySegundaLetra.filter((b: any) => b.intIDPresencia == this.formLead.value.intIDPresencia && b.intId_Giro == this.formLead.value.intId_Giro)
    this.arrayFitroSegunda = this.arraySegundaLetra.filter((b: any) => b.intIDPresencia == this.intIDPresencia && b.intId_Giro == this.intGiro)
    //this.arrayFiltroTercera = this.arrayTercerLetra.filter((b: any) => b.intIDPresencia == this.formLead.value.intIDPresencia && b.intId_Giro == this.formLead.value.intId_Giro)
    this.arrayFiltroTercera = this.arrayTercerLetra.filter((b: any) => b.intIDPresencia == this.intIDPresencia && b.intId_Giro == this.intGiro)

    const potenciador = this.arrayGiros.filter((b: any) => b.intId_Giro == this.intGiro)
    this.intPotenciador = potenciador ? potenciador[0].intPotenciador : 0;
  }

  fngetSectores() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSector',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySectores = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  toggleMultiple(linea: any) {

    this.arrayPotenciador.forEach(e => {
      if (linea == e.strLinea) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });

  }

  fngetSegundaLetra() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSegundaLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySegundaLetra = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fngetTerceraLetra() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getTerceraLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayTercerLetra = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetSubGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSubGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySubGiroTotal = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  onInput(event: Event) {
    const target = event.target as HTMLElement;
    this.mdlComentarios = target.innerHTML; // Guarda el contenido HTML

  }


  fnBusquedaCte(strBuscarAgente: string) {
    let texto = strBuscarAgente.toUpperCase();
    this.arrayAgente_Filter = [];
    for (const post of this.arrayAgentes_tareas) {
      let texto2 = post.strNombre.toUpperCase();
      if (texto2.indexOf(texto) > -1) {
        this.arrayAgente_Filter.push(post);
      }
    };
  }
  fnSeleccionaAgente(agt: any) {
    this.strAgente = agt.strAgente;
    this.strBuscarAgente = "";/* agt.strAgente+'-'+agt.strNombre  */
    this.arrayAgente_Filter = [];
    let findAgente = this.arrayAgentesActividades.find(agtf => agtf.strAgente == agt.strAgente);
    if (!findAgente) {
      this.arrayAgentesActividades.push(agt)
    } else {
      Swal.fire("Error", "Agente existente, seleccione otro agente.", "error")
    }
  }
  fnEliminarAgenteAct(agt: any) {
    this.arrayAgentesActividades = this.arrayAgentesActividades.filter(item => item !== agt);
  }

  fnInsertComentario() {

    let obj = {
      strAccion: 'setComentario_Leads_Actividad',
      intID_Actividad: this.intIDActividad,
      strAgente: this.strAgente,
      strComentario: this.strComentarioD,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strComentarioD = ''
            this.fngetComentarios();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        }, error => {
          var error = <any>error; console.log(error);
          //this.spinerBool = false
        });
  }

  fngetComentarios() {

    let obj = {
      strAccion: 'getListComentario_Actividad',
      intID_Actividad: this.intIDActividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayComentarios = result
        }, error => {
          var error = <any>error; console.log(error);
          //this.spinerBool = false
        });
  }
  fnFormOportunidades() {
    this.arrayLineasSelect = [];
    this.arrayLineas2.forEach(lin => {
      lin.select = false;
    });
    this.formOportunidades = this.formbuilder.group({
      strTitulo: new FormControl('', [Validators.required]),
      intMonto: new FormControl(0, [Validators.required]),
      strLinea: new FormControl('', []),
      strArticulo: new FormControl('', []),
      strFecha: new FormControl('', [Validators.required]),
      strResponsable: new FormControl('', []),
      strResponsable2: new FormControl('', [Validators.required]),
    });

  }
  onSubmitOportunidades() {
    if (this.formOportunidades.valid) {
      this.fnInsertOportunidad(this.formOportunidades.value);
      // Procesar los datos del formulario

    }
  }
  fnAddLinea(obj: any) {
    obj.select = true;
    let bandera = 0;
    this.arrayLineasSelect.forEach(lin => {
      if (lin.strLinea == obj.strLinea) {
        bandera += 1
      }
    });
    if (bandera == 0) {
      this.arrayLineasSelect.push({ strLinea: obj.strLinea, dblCantidad: 0 })
    } else {
      this.arrayLineasSelect = this.arrayLineasSelect.filter(lin => lin.strLinea !== obj.strLinea);
      obj.select = false;
    }
    this.totalImporteLineas();
  }
  totalImporteLineas(): number {
    let result = this.arrayLineasSelect.reduce((sum, item) => sum + item.dblCantidad, 0)

    this.formOportunidades.patchValue({ intMonto: result });
    return result;
  }
  updateCantidad(event: Event, cet: any): void {
    let inputElement = event.target as HTMLInputElement;
    let valor = parseFloat(inputElement.value);
    if (inputElement.value === 'e' || inputElement.value === '' || isNaN(valor) || valor <= 0) {
      cet.dblCantidad = 0;
      inputElement.value = '0'; // Esto asegura que el valor mostrado en el input sea '0'
    } else {
      cet.dblCantidad = valor;
    }
    this.totalImporteLineas();
  }

  fnAdjuntarArchivosActividad(): void {

    if (this.fileInputActividades) {
      this.arrayAgregarAnexosActividades = [];
      this.fileInputActividades.nativeElement.value = '';
      this.fileInputActividades.nativeElement.click();
    }
  }
  onFileSelectedActividad(event: any): void {
    let files: FileList = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let newTotalSize = file.size;
        if (newTotalSize > 10 * 1024 * 1024) {
          Swal.fire({
            icon: 'warning',
            title: 'Límite excedido',
            text: 'El tamaño total de los archivos no puede superar los 10MB.',
          });
          return;
        }

        let nombreOriginal = file.name.split('.').slice(0, -1).join('.');
        let extension = file.name.split('.').pop();

        this.arrayAgregarAnexosActividades.push({
          nombreArchivo: nombreOriginal,
          extencion: extension,
          archivo: file
        });
      }
    }
  }
  fnEliminarAnexosAct(): void {
    this.fileInputActividades.nativeElement.value = '';
    this.arrayAgregarAnexosActividades = [];
  }


  fnAnexosActividad() {

    if (this.arrayAgregarAnexosActividades.length > 0) {
      let formData = new FormData();
      this.arrayAgregarAnexosActividades.forEach((o, index) => {
        if (o.archivo instanceof File) {
          formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
        } else {
          console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
        }
      });
      this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
        console.log(result)
        this.arrayEnviarAnexosActividades = []
        let arrayRest = result;
        arrayRest.forEach(obj => {
          this.arrayEnviarAnexosActividades.push(
            {
              strTipo: obj.strTipo,
              strRespuesta: obj.file,
              strNombre: obj.strNombre,
              strAgente: this.strAgente,
              strEstatus: ""
            });
        });
        console.log(this.arrayEnviarAnexosActividades[0])
        this.fnEnviarActividad(this.arrayEnviarAnexosActividades[0])
      }, (error) => {
        console.log(error);
      });
    } else {
      this.fnEnviarActividad()
    }
  }

  onFileInsert(event: any): void {
    let files: FileList = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let newTotalSize = file.size;
        if (newTotalSize > 10 * 1024 * 1024) {
          Swal.fire({
            icon: 'warning',
            title: 'Límite de almacenamiento alcanzado',
            text: 'No puedes agregar más archivos, el límite es de 10MB.',
          });
          return;
        }

        const nombreOriginal = file.name.split('.').slice(0, -1).join('.');
        const extension = file.name.split('.').pop();

        this.arrayAnexMdlAdd.push({
          nombreArchivo: nombreOriginal,
          extencion: extension,
          archivo: file
        });

      }
    }
  }

  fnAgregarArchivo() {
    let formData = new FormData();
    this.arrayAnexMdlAdd.forEach((o, index) => {
      if (o.archivo instanceof File) {
        formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
      } else {
        console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
      }
    });

    this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
      console.log(result)
      if (result.length > 0) {
        this.arrayEnviarAnexosActividades = []
        let arrayRest = result;
        let enviar = {}
        arrayRest.forEach(obj => {
          enviar = {
            strAccion: 'setAnexoActividad',
            intID_Leads: this.intIDLeads,
            intID_Actividad: this.intIDActividad,
            strConexion: this.strConexion,
            strTipo_CIERRE: obj.strTipo,
            strUrl_Logo: obj.file,
            strResultado: obj.strNombre,
            strAgente_Crea: this.strAgente,
            strMotivo: ""
          }
        });
        console.log(enviar)
        this.fnGuardarArchivo(enviar);
      } else {
        Swal.fire("Error", "Error al guardar archivo.", "error");
      }
    }, (error) => {
      console.log(error);
    });
  }
  fnElinimarArchivos() {
    this.arrayAnexMdlAdd = []
  }

  fnGuardarArchivo(obj: any) {
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          console.log(result)
          if (result[0].code == 200) {
            Swal.fire("Guardado", result[0].strMensaje, "success")
            this.arrayAnexMdlAdd = [];
            this.fileModal.nativeElement.value = ""
            this.arrayAnexosModal = [];
            this.fnGetAnexosActividades()
          } else {
            Swal.fire("Error", result[0].strMensaje, "error")
          }
        }, error => {
          var error = <any>error; console.log(error);
          // this.spinerBool = false
        });
  }
  fnAjuntarAnexsModal(): void {
    if (this.fileModal) {
      this.arrayAnexMdlAdd = [];
      this.fileModal.nativeElement.value = ""
      this.fileModal.nativeElement.click();
    }
  }

  fnGetAnexosActividades() {
    this.arrayAnexosModal = [];
    let obj = {
      strAccion: 'getActAnexos',
      intID_Actividad: this.intIDActividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          /*   console.log(result) */
          if (result.length > 0) {
            this.arrayAnexosModal = result
            this.arrayAnexosModal.forEach(obj => {
              obj.urlImagen = "https://www.ecodeli.com/" + obj.strUrl_Logo
            });
            /*     console.log(this.arrayAnexosModal); */
          }

        }, error => {
          var error = <any>error; console.log(error);
          // this.spinerBool = false
        });

  }

  fnSeeRow(ind: any, actividad: any) {
    console.log(ind)
    this.fngetSubtareas(actividad.intID_Actividad)
     let valAnt = this.arrayActividades[ind].seeSubAct
    this.arrayActividades.forEach(act => {
      act.seeSubAct = false
    });

    this.arrayActividades[ind].seeSubAct = valAnt == true ? false : true 
  }

  fnEditar_Actividad(objAct: any) {

    this.objEditarActividad = {};
    this.objEditarActividad = objAct;
    this.mdlNombre = this.objEditarActividad.strTitulo;
    let strf = this.objEditarActividad.strFechaRequerida;

    // Dividir la cadena en partes
    const [datePart, timePart] = strf.split(' '); // Divide en fecha y hora
    const [month, day, year] = datePart.split('/'); // Divide la parte de fecha en mes, día y año

    this.strfecha_editar = year + '-' + month + '-' + day;
    let hora1 = this.objEditarActividad.strHoraInicio.slice(0, -3);
    this.mdlHoraInicio = hora1;
    let hora2 = this.objEditarActividad.strHoraFin.slice(0, -3);
    this.mdlHoraFin = hora2;
    this.strAgente = this.objEditarActividad.strAgente;
    this.strBuscarAgente = this.objEditarActividad.strAgente + '-' + this.objEditarActividad.strNombreAgente;
    this.mdlComentarios = this.objEditarActividad.strOpcion;
    this.editableDiv.nativeElement.innerHTML = this.mdlComentarios;

  }

  fnDelete_Tarea(tarea: any) {
    let intID_Actividad = tarea.intID_Actividad;
    Swal.fire({
      title: "¿Estás seguro de eliminar esta Actividad?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Sí, Eliminar",
      denyButtonText: `No, Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        this.fnEliminar_Tarea(intID_Actividad)

      } else if (result.isDenied) {
        Swal.fire("No se guardaron los cambios", "", "info");
      }
    });

  }

  fnEliminar_Tarea(intID_Actividad: number) {

    let objParam = {
      strAccion: 'deleteTarea',
      intID_Actividad: intID_Actividad,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let resultado = result[0];
          if (resultado && resultado.code == 200) {
            Swal.fire("Tarea Eliminada Correctamente", "", "success");
          }
          else {
            Swal.fire("Error al eliminar Tarea", "", "warning");
          }
          this.fnllenarArrayTareas();
        },
        error => {
          var error = <any>error;
          Swal.fire("Error al eliminar Tarea", "", "warning");
          console.log(error);
        }
      );
  }

  fnllenarArrayTareas() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion,
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayActividades = result
          /*     console.log(this.arrayActividades) */

          if (this.arrayActividades.length > 0) {
            this.arrayActividades.forEach(obj => {
              // Paso 1: Convertir strFechaRequerida a un objeto Date
              let fechaRequerida = new Date(obj.strFechaRequerida);
              // Paso 2: Obtener la fecha actual
              let fechaActual = new Date();
              // Paso 3: Calcular la diferencia en milisegundos
              let diferenciaMilisegundos = fechaRequerida.getTime() - fechaActual.getTime();
              // Paso 4: Convertir la diferencia de milisegundos a días
              let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
              obj.diasDiferencia = diferenciaDias
            });
          }
          this.datosTareasCte = new MatTableDataSource(this.arrayActividades);
          this.fnTabletareasCte();
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );

  }

  fnModalRegistrarSubtarea(tipo: string, obj: any) {

    this.objSubtarea = obj
    //this.editableDiv2.nativeElement.innerHTML = '';
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo;
    this.objModal.subTitulo = obj.intID_Leads + '.-' + obj.strTitulo
    this.arrayAgentesActividades = [];
    this.strBuscarAgente = "";
    this.arrayAgregarAnexosActividades = [];
    this.fileInputActividades.nativeElement.value = '';
this.inputComentarios.nativeElement.innerHTML = ""
  }

  fngetSubtareas(intID_Actividad: any) {
    this.arraySubTareas = [];
    let obj = {
      strAccion: 'getSubActividades',
      /* strAgente: this.strAgente,
        strSucursal: this.strSucursal,
       strGerente: this.strGerente, */ 
       intID_Actividad: intID_Actividad,
       intID_Leads:this.intIDLeads,
       strConexion: this.strConexion
    };
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          console.log(result)
          this.arraySubTareas =  result
         /*  this.arraySubTareas.forEach(item => {
            let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
            item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
            item.select = false;
          }); */
        }, error => {
          //this.spinnerTab = false
          console.error('Error al consumir la API:', error);

        });
  }
  fnCleanSearch(){
    this.strBuscarAct = ""
  }

    fnEditarActividad(intID_Actividad: number) {
  
      let objParam = {
        strAccion: 'editTarea',
        strFechaInicioServicio: this.strfecha_editar,
        strComentario: this.mdlComentarios,
        strAgente: this.strAgente,
        intID_Actividad: intID_Actividad,
        strConexion: this.strConexion,
        strTitulo: this.mdlNombre,
        strHoraInicio: this.mdlHoraInicio,
        strHoraFin: this.mdlHoraFin
      }
  
  
      this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            let resultado = result[0];
            if (resultado && resultado.code == 200) {
              Swal.fire("Tarea Editada Correctamente", "", "success");
            }
            else {
              Swal.fire("Error al Editar Tarea", "", "warning");
            }
            this.btnCerrarEdit.nativeElement.click();
            this.fnllenarArrayTareas();
          },
          error => {
            var error = <any>error;
            Swal.fire("Error al eliminar Tarea", "", "warning");
            console.log(error);
          }
        );
    }
   /*  isFechaValida(fecha: string): boolean {
      const parsedDate = new Date(fecha);
      return !isNaN(parsedDate.getTime());
    } */
    isFechaValida(fecha: string): boolean {
      const [day, month, year] = fecha.split('-');
      const parsedDate = new Date(+year, +month - 1, +day);
      return !isNaN(parsedDate.getTime());
    }
    
      fnAddMinuta() {
        this.formMinuta = this.formbuilder.group({
          strTitulo: new FormControl('', [Validators.required]),
          strFecha: new FormControl('', [Validators.required]),
          strHora: new FormControl('', [Validators.required])
        });
      }
      onSubmit() {
        if (this.formMinuta.valid) {
          this.fnSetMinuta(this.formMinuta.value);
          // Procesar los datos del formulario
          /* console.log(this.formMinuta.value); */
        }
      }
        fnSetMinuta(objMin: any) {
          try {
            let objEnviar = {
              strConexion: this.strConexion,
              strAccion: 'setMinuta',
              strTitulo: objMin.strTitulo,
              strFechaInicioServicio: objMin.strFecha,
              strHoraInicio: objMin.strHora,
              intID_Leads: this.intIDLeads,
              strAgente: this.strAgente_Crea
            }
            console.log(objEnviar);
            this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                result => {
                  let rest = result[0]
                  Swal.fire(rest.code == "200" ? "Guardado Correctamente" : "Error", rest.strMensaje, rest.code == "200" ? "success" : "error");
                  if (rest.code == "200") {
                    this.fnGetMinutas();
                    this.btnCerrarMinuta.nativeElement.click()
                  }
                }, error => {
                  var error = <any>error; console.log(error);
                }
              );
          } catch (error) {
            Swal.fire("Error", error.message, "error")
          }
        }
        
          fnGetMinutas() {
            let objMin = {
              intID_Leads: this.intIDLeads,
              strAccion: 'getMinuta',
              strConexion: this.strConexion
            }
            console.log(objMin)
            this._serviciosService.mostrarCotizaciones(objMin, 'sp_ModVentas_Leads')
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                result => {
                  this.arrayMinuta = result;
                  console.log(this.arrayMinuta)
                }, error => {
                  var error = <any>error;
                  console.log(error);
                }
              );
          }
          fnDetalleMinuta(obj: any) {
            console.log(obj)
            this.router.navigate(['/minuta'], { queryParams: { intID_Minuta: obj.intID_Minuta } });
          }
        
            deleteMinuta(objMin: any, event: Event): void {
              event.stopPropagation();
          
              Swal.fire({
                title: "¿Estás seguro de eliminar esta minuta?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Sí, Eliminar",
                denyButtonText: `No, Cancelar`
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  try {
                    let objEnviar = {
                      strConexion: this.strConexion,
                      strAccion: 'deleteMinuta',
                      intID_Minuta: objMin.intID_Minuta
                    }
                    console.log(objEnviar);
                    this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
                      .pipe(takeUntil(this.unsubscribe$))
                      .subscribe(
                        result => {
                          let rest = result[0]
                          Swal.fire(rest.code == "200" ? "Elimina Correctamente" : "Error", rest.strMensaje, rest.code == "200" ? "success" : "error");
                          this.fnGetMinutas();
                        }, error => {
                          var error = <any>error; console.log(error);
                        }
                      );
                  } catch (error) {
                    Swal.fire("Error", error.message, "error")
                  }
          
          
                } else if (result.isDenied) {
                  Swal.fire({
                    title: "No se guardaron los cambios",
                    icon: "info",
                    timer: 2500,  // Tiempo en milisegundos (3 segundos)
                    showConfirmButton: false,  // No mostrar el botón "OK"
                    timerProgressBar: true
                  });
                }
          
              })
            }
          fnActividad(){
            if(this.boolConcluir){
              this.boolConcluir = false;
            }else{
              this.boolConcluir = true;
            }
          }
}
