import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CarroService } from '../servicios/carroservice';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import 'pdfmake/build/vfs_fonts';
import { CurrencyPipe } from '@angular/common';
import * as html2pdf from 'html2pdf.js';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { MatSelectChange } from '@angular/material/select';
import { DataDBService } from '../servicios/data-db.service';

@Component({ 
  selector: 'app-carritoresumen',
  templateUrl: './carritoresumen.component.html',
  styleUrls: ['./carritoresumen.component.css']
}) 
export class CarritoresumenComponent implements OnInit {
  @ViewChild('pdfTableFiniquito', { static: true }) el!: ElementRef
  @ViewChild('btnCerrarModal') btnCerrarModal!: ElementRef;
  @ViewChild('btncerrar') btnModalProspecto!: ElementRef;
  @ViewChild('btnCerrarModalPedido') btnCerrarModalPedido!: ElementRef;
  @ViewChild('btnCerrarModalPolitica') btnCerrarModalPolitica!: ElementRef;
  public fechaInicio: string = '';
  public fechaFin: string = '';
 public archivoSeleccionado: File | null = null;
  public boolNivelFamilia:boolean = false;
  public boolNivelCliente:boolean =false;
 public strNombreConvenio:string='';
 public strObservacionesConvenio:string='';
  public boolMostrarBotonExportar:boolean=true;
  public strAlmacen:string='1';
  public arraySaldou:any=[];
  public btnEnviar_politica :boolean = true;
  public  objDisponible_almacen:any={};
    public strOpcion_Selected:string='';
  public intMuestraActivar_Plantilla: number;
  public boolPrecio_dif_Plantilla:boolean=false;
  public arrayCarritoArts: any[]; 
  public dblSubTotal = 0;  
  public objCliente: any;
  public strNumCte: string;
  public strfechaRequerida: string;
  public disponible:number=0;
  public boolModoPlantilla: boolean;
  public nombreOrdenCompra:any = ''
  public arrayClientes:any =[]
  public objArtModal: any = {
    strArticulo: "", strDescripcion: "", strObservaciones: ""
  }; 
  public strProstectro: string = "";
  public strEmpresaProspecto: string = "";
  public flagProspecto: boolean = true;
  public objectCotizacion: any = {};
  public arrayAccesosPrecios: any;
  public useremail: string = "";
  public userName: string = "";
  public vartri: boolean = false;
  public strCorreoUser: string = "";
 public strUsuario: string = "";
  //public datosuser: any;
  public currentDate = new Date().toLocaleDateString();
 public strAgente: string = "";
  public strReferencia: string = "";
  public strObservaciones: string = "";
  public strOrdenCompra: string = "";
  public strComentarios: string = ""; 
  public strUsuarioInt: string = "";
  public strSucursal: any;
  public strSucursalCte: string ="";
  public strSucursal_cot: any;
  public conp: boolean = false;
  public strAgenteCot: string = "";
  public execede50: boolean = false;
  public execede51: boolean = false;
  public execede100: boolean = false;
  public execede101: boolean = false;
  public boolBtn_preciominimo:boolean=false;
  public ArrayMoneda: any = [
    { strMoneda: 'Pesos', val: 1 },
    { strMoneda: 'Dolares', val: 2 }
  ]
  public strBuscar:string="";
  public dblDisponible_nacional:number=0;
  public  dblReservado_nacional:number=0;
  
  public strNombreCte: string = "";
  public strDirccionCte: string = "";
  public arraySucursalesCte :any=[];
  public strConexion: string = "";
  public tipoCliente: string = "";
  public arrayOpcionesTodo = [];
  public arrayOpciones:any=[];
  public editPlanActive: boolean = false;
  public intIDMovimientoVenta: number;
  public strTipo_Accion_Cotizacion: string = '';
  public formData = new FormData();
  public objCte_original:any={};
  public arrayAccesos: any=[]
  public tagName:string='';
  public arraySaldoUFilter:any =[];
  public objOpcion:any={};
  public boolAgente:boolean= false;
  public boolSucursal:boolean= false;
  public boolGerente:boolean= false;
  public strGerente:string ;
  public  cliente_copia:any;
  public fechaMinima: string;
  public strEstatus_Almacen:string='';
  public btnGuardar_Cotizacion:boolean=true;
  public strCteEnviarA:string='';
  public boolSelectSucursal:boolean=true;
public boolTapete:false;
public strNAcceso :number=0;
public btnPolitica:boolean=false;
  constructor(  private indexedDBService: DataDBService, private servPlantilla: PlantillafinService,elem:ElementRef,
    private carroservice: CarroService,
    private localSt: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private currencyPipe: CurrencyPipe,
    private _httpService: PeticionesService) {
    const hoy = new Date();
    hoy.setDate(hoy.getDate() + 1);
    const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Agregar cero si el mes es menor a 10
    const dia = hoy.getDate().toString().padStart(2, '0'); 
    this.fechaMinima = `${hoy.getFullYear()}-${mes}-${dia}`;
   //obtener niveles de acceso
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    this.strConexion = this.localSt.retrieve('strConexion'); 
    this.strAlmacen=this.localSt.retrieve('strAlmacen')?this.localSt.retrieve('strAlmacen'):'1'; 
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
        this.boolBtn_preciominimo = objAcceso.find((o: any) => o.strAccion == 'btnPrecioMinimo') ? true : false;
        this.btnGuardar_Cotizacion= objAcceso.find((o: any) => o.strAccion == 'btnGuardarCotizacion') ? true : false; 
        this.btnPolitica= objAcceso.find((o: any) => o.strAccion == 'btnPolitica') ? true : false; 
        this.strUsuario = this.arrayAccesos[0].strNombre + ' ' + this.arrayAccesos[0].strApellido;
        this.strUsuarioInt = this.localSt.retrieve('strusuario'); // se usa para guardar en el usuario de los movimientos
        this.strAgenteCot = this.localSt.retrieve('stragente') ? this.localSt.retrieve('stragente') : '';//se usa para guardar el agente de los movimientos
        this.strSucursal_cot= this.localSt.retrieve('strsucursal'); //se usa para la sucursal de la cotizacion
        this.strCorreoUser = this.localSt.retrieve('strEmail');
      }
    }
    /* --this.datosuser = this.localSt.retrieve('arrayaccesos');
   
   
   -- this.strAgente = this.localSt.retrieve('ObjCliente') ? this.localSt.retrieve('ObjCliente').strAgente : ''; //obtenemos el agente relacionado al cliente seleccionado
  
    */ 
    

  }
 
  ngOnInit(): void { 
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
     
      if (this.arrayAccesos.length > 0) { 
        let objAcceso =this.arrayAccesos[0];
        this.intMuestraActivar_Plantilla=objAcceso && objAcceso.strTipoAcceso && (objAcceso.strTipoAcceso ==1017 || objAcceso.strTipoAcceso ==1016
           || objAcceso.strTipoAcceso ==1015|| objAcceso.strTipoAcceso ==1014 || objAcceso.strTipoAcceso ==1013)?0:1;
        this.strCteEnviarA = this.arrayAccesos[0].strCteEnviarA ? this.arrayAccesos[0].strCteEnviarA:'';
        this.strNAcceso =this.arrayAccesos[0].strTipoAcceso ? this.arrayAccesos[0].strTipoAcceso:'';

        this.boolSelectSucursal = objAcceso && objAcceso.strTipoAcceso && (objAcceso.strTipoAcceso ==1017  )?true:false;
      }
      
    });
    this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
      
      this.objCliente = nuevoValor; 
      this.cliente_copia = JSON.parse(JSON.stringify(nuevoValor));
      //hacemos una copia profunda del cliente

       this.strNumCte = nuevoValor.strCliente;
    }); 
    if(this.strNumCte !=''){
      this.fnGetSucursal_CTe();
     }
    this.servPlantilla.objPlantillaEdit$.subscribe((newVal) => {
      this.editPlanActive = newVal.boolActive;
      this.intIDMovimientoVenta = newVal.intIDMovimientoVenta;
      this.intMuestraActivar_Plantilla = newVal.Activar_Plantilla ? newVal.Activar_Plantilla : 1;
      this.strTipo_Accion_Cotizacion = newVal.strTipo_Accion_Cotizacion ? newVal.strTipo_Accion_Cotizacion : '';
    })

    //modo plantilla
    this.carroservice.boolModoPlantilla$.subscribe((nuevoValor) => {
      this.boolModoPlantilla = nuevoValor;
      //this.validarPrePlantillas();
    });
    //------
    if (this.strNumCte && this.strNumCte != "") {
      this.flagProspecto = false;
    } else {
      this.flagProspecto = true;
    }
    this.tipoCliente = this.objCliente.strTipo ? this.objCliente.strTipo : "";
    // Suscribir al array del carrito
    this.carroservice.arrayItems$.subscribe((nuevoValor) => {
      this.arrayCarritoArts = nuevoValor;     
       
       this.validar_precioPlantilla_en_Pedido();
      this.fnGetOpcionTodo();
      if(this.arrayCarritoArts && this.arrayCarritoArts.length>0){
        //validamos los precios
        this.validar_edit_cotizacion();
    }
    

    });
    this.carroservice.dblSubTotalItems$.subscribe((nuevoValor) => {
      
      this.dblSubTotal = nuevoValor;
    });
    const currencyValue = this.currencyPipe.transform(123.45, 'USD', 'symbol', '1.2-2');
    this.fnArtspasarCant();
    this.validarPrePlantillas();
  }

  validarPrePlantillas() { 
    if (this.boolModoPlantilla  ) {
      this.arrayCarritoArts.forEach(xo => {
       // if (xo.dblPrecioSugerido == 0 || xo.dblPrecioSugerido == null || !xo.dblPrecioSugerido ) {
          this.fnEligePrecio(xo.dblPrecioLista, xo, 2);
       // }
      });
    }
  }
  validar_edit_cotizacion() { 
    if(this.arrayCarritoArts && this.arrayCarritoArts.length >0){
      this.arrayCarritoArts.forEach(objArt => {
        let precio_min = objArt.dblPrecioMinimo ?objArt.dblPrecioMinimo :0;
        objArt.dblPrecioSugerido = objArt.dblPrecioSugerido==0?objArt.dblPrecioLista:objArt.dblPrecioSugerido;
        let precio_sug =objArt.dblPrecioSugerido?objArt.dblPrecioSugerido :0;
        if(precio_sug <precio_min){ //VALIDAR EL PRECIO MINIMO
         objArt.precio_plantilla_valido=1; //servira para marcar un icono en el listado de articulo
        } 
        //VALIDAMOS EL PRECIO POLITICA
        let precio_politica = objArt.intPrecioPolitica ?  objArt.intPrecioPolitica:0;
        if(precio_politica >0)
        {
          objArt.precio_politica_valido = 1;
        }
        else {
          objArt.precio_politica_valido = 0;
        }
     });
     this.fnactivarboton();
    }
      
     
  }

  validar_precioPlantilla_en_Pedido(){
    let count_precio=0; 
    this.arrayCarritoArts.forEach(xo => {
      if(!this.btnGuardar_Cotizacion){ //cuando este boton es falso, se trata de perfiles de cliente
        xo.boolPrecioFueraRango=false;
      }
      else if (!xo.intPrecioPlantilla && !xo.intPrecioLista &&  !xo.intPrecioPolitica){
        xo.boolPrecioFueraRango=false;
      }
      else if (xo.dblPrecioLista != xo.intPrecioPlantilla &&  xo.dblPrecioLista !=xo.intPrecioLista && xo.dblPrecioLista != xo.intPrecioPolitica) {
        count_precio=count_precio +1
        xo.boolPrecioFueraRango=true;
      }
     
      else {
        xo.boolPrecioFueraRango=false;
      }
    });
    this.boolPrecio_dif_Plantilla= count_precio>0? false:true;
  }
  fnsetEditPlantilla() { 
    let xmlArticulos = []/* [{strArticulo:'BN',dblPrecio:20}]     editPlanActive
     intIDMovimientoVenta */
    this.arrayCarritoArts.forEach(x => {
      xmlArticulos.push({
        strArticulo: x.strArticulo,
        dblPrecio: x.intPrecioLista,
        dblSugerido: x.dblPrecioSugerido,
        strOpcion: x.strOpcion,
        dblCantidad: x.dblCantidad
      })
    });
    let obj = {
      intIDMovimientoVenta: this.intIDMovimientoVenta,
      strConexion: this.strConexion,
      xmlArticulos: xmlArticulos,
      strAccion: 'setTerminarEdicion_Plantilla'
    } 
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == "200") {
          Swal.fire({
            title: "Terminado correctamente",
            text: result[0].strMensaje,
            icon: "success"
          });
          let redirecciona = this.objCliente.strOrigen2 ? this.objCliente.strOrigen2 : 'carritoresumen';
     
          const url = redirecciona;
          const [path, queryString] = url.split('?');
          const queryParams = queryString ? this.parseQueryParams(queryString) : '';
          let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
          this.servPlantilla.setPlantilla(objPlantilla);
          this.carroservice.setCte({});
          this.carroservice.deleteCarrito2();
          this.carroservice.setModoPlantilla(false);
          this.router.navigate([path], { queryParams });
        } else {
          Swal.fire({
            title: "Ocurrio un error",
            text: result[0].strMensaje,
            icon: "error"
          });
          let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
          this.servPlantilla.setPlantilla(objPlantilla);
          this.carroservice.setCte({});
          this.carroservice.deleteCarrito2();
          this.carroservice.setModoPlantilla(false);
        }
       
      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });

  }

  checkInputLength(event: any, strInp: string) {
    const inputValue = event.target.value;
    if (strInp == 'referencia') {
      this.execede50 = inputValue.length > 49;
    } else if (strInp == 'ordenCompra') {
      this.execede51 = inputValue.length > 49;
    } else if (strInp == 'comentario') {
      this.execede100 = inputValue.length > 99;
    } else if (strInp == 'observacion') {
      this.execede101 = inputValue.length > 99;
    }
  }
  onOpcionSeleccionada(opcion: string) {
    /*  this.objArt.arrayOpciones.forEach(o => {
       if(opcion == o.strOpcion ){
         this.objArt.strNombreOpcion = o.strNombreOpcion
       }
     }); */
  }
  fnDeleteCar(intId: number, objArt: any) {
    this.arrayCarritoArts.splice(intId, 1);
    this.carroservice.deleteArt(objArt);
    this.fnArtspasarCant()
  }

  fnUpdateAmountCar(objArt: any) {
    this.carroservice.updateArt(objArt);
  }

  fnbuttomGroup(objArt: any, tipo: string) {
    if (tipo == '+') {
      objArt.dblCantidad = objArt.dblCantidad + 1;
      objArt.intCantidad = objArt.dblCantidad;
     /*  this.fnUpdateAmountCar(objArt) */
      //  this.fnArtspasarCant()
    } else {
      if (objArt.dblCantidad >= 2) {
        objArt.dblCantidad = objArt.dblCantidad - 1;
        objArt.intCantidad = objArt.dblCantidad;
       /*  this.fnUpdateAmountCar(objArt) */
        //this.fnArtspasarCant()
      }
    }

    if(objArt.strEstatus== "DESCONTINUADO"){
      this.fnGetDisponible(objArt, event,"");
    }else{
      this.fnUpdateAmountCar(objArt)
    }
  }

  fnSubTotal(): number {
    let result = 0;
    result = this.dblSubTotal;
    return result;
  }

  fnSubiva(): number {
    let result = 0;
    if (this.strConexion == "DOMINICANA") {
      result = this.fnSubTotal() * .18;
    } else {
      result = this.fnSubTotal() * .16;
    }

    return result; 
  }
  fnTotal(): number {
    let result = 0;
    result = this.fnSubTotal() + this.fnSubiva();
    return result;
  }
  fnborrarCarrito() {
    let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
    this.servPlantilla.setPlantilla(objPlantilla);
    //this.carroservice.setCte({});

    this.carroservice.setModoPlantilla(false);
    this.carroservice.deleteCarrito2();
    //this.arrayCarritoArts = [];
  }

  redirectToCarrito() {
    this.router.navigate(['/cotizador_Linea']);
  }

  fnmodal(objArt: any, ind: number) {
    this.objArtModal.strArticulo = objArt.strArticulo;
    this.objArtModal.strDescripcion = objArt.strDescripcion1;
    this.objArtModal.indice = ind;
    this.objArtModal.strComentario =objArt.strComentario ? objArt.strComentario :'';
   
  }
  fnguardarComentario() {
    this.arrayCarritoArts[this.objArtModal.indice].strComentario = this.objArtModal.strComentario;
    this.fnUpdateAmountCar(this.objArtModal)
    Swal.fire({
      title: "Comentario",
      text: "Guardado correctamente",
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
      timerProgressBar: true,
    });
    this.btnCerrarModal.nativeElement.click();
  }

  fncreatepdf() {
    const options = {
      margin: 8,
      filename: 'Cotizacion.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    const content = this.el.nativeElement;
    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
       
       const blob = pdf.output('blob');
       const url = URL.createObjectURL(blob); // Crea un objeto URL
         pdf.save("Cotizacion.pdf");
       window.open(url); // Abre el PDF en una nueva pestaña
        if (this.flagProspecto) {
          this.fncerrarmodal()
        }
      });

  }
  fncerrarmodal() {
    this.strProstectro = "";
    this.strEmpresaProspecto = "";
    this.btnModalProspecto.nativeElement.click();
  }
  fnOpenModalPedido() {
  }
  fnInsertarPedido() {
    this.boolMostrarBotonExportar = false;

    let strIDSucursal = this.objCliente.intIDSucursal > 0 ? this.objCliente.intIDSucursal : null;
    let strCliente = this.objCliente.strCliente;
    let strSucursal = this.objCliente.strSucursal;
    let agente = this.objCliente.strAgente;
    let strCondicion = this.objCliente.strCondicion;
    let alm = this.objCliente.strAlmacen;
    let xmlArticulos = [];
    this.arrayCarritoArts.forEach(function (valor, indice, array) {
      console.log("En el índice " + indice + " hay este valor: " + valor);
      xmlArticulos.push({ strArticulo: valor.strArticulo, dblPrecio: valor.dblPrecioLista, intCantidad: valor.dblCantidad, strOpcion: valor.strOpcion });
    });
    const fecha: Date = new Date(this.strfechaRequerida);
    fecha.setDate(fecha.getDate() + 1);
    const dia = fecha.getDate().toString().padStart(2, '0'); // Obtiene el día y lo ajusta al formato 'dd'
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Obtiene el mes (se suma 1 porque los meses son base 0) y lo ajusta al formato 'mm'
    const anio = fecha.getFullYear();
    let strFechaRequerida = dia + '/' + mes + '/' + anio
    console.log("Esta es la fecha requerida que se esta enviando: " + strFechaRequerida);
    console.log("Este es el agente enviando: " + agente);
    console.log("Este es el cliente al que se esta enviando: " + strCliente);
    console.log("Esta es id sucursal que se envia: " + strIDSucursal);
    console.log("Esta es a la sucursal que se envia: " + strSucursal);
    console.log("referencia que se envia: " + this.strReferencia);
    console.log("ordencompra que se envia: " + this.strOrdenCompra);
    console.log("comentarios que se envia: " + this.strComentarios);
    console.log("observaciones que se envia: " + this.strObservaciones);
    console.log("strCondicion que se envia: " + strCondicion);
    console.log("almacen que se envia: " + alm);
    console.log("articulos que se envia: ");
    console.log(xmlArticulos);

    this._httpService.setPedido({
      strAccion: 'setPedido',
      strAgente: agente,
      strCliente: strCliente,
      strSucursal: strSucursal,
      xmlArticulos: xmlArticulos,
      strCteEnviarA: strIDSucursal,
      strFechaRequerida: strFechaRequerida,
      strOrdenCompra: this.strOrdenCompra,
      strReferencia: this.strReferencia,
      strObservaciones: this.strObservaciones,
      strComentarios: this.strComentarios,
      strSistema: (this.strNAcceso == 1014 ||  this.strNAcceso == 1013||  this.strNAcceso == 1017 ||  this.strNAcceso == 1016 ) ? 'AUTORIZACION_CTE': 'COTIZADOR', //PARA LOS ACCESOS 1014 Y 1013 NECESITA PASAR POR AUTORIZACION DE PRESUPUESTO
      strCondicion: strCondicion,
      strAlmacen: alm,
      strArchivo_orden_compra: this.nombreOrdenCompra,
      strConexion: this.strConexion,
      strUsuario: this.strUsuarioInt
    }).subscribe(
      result => {
        let pruebas = [];
        pruebas = result;
        let code = pruebas.length == 0 ? 410 : pruebas[0] ? pruebas[0].code : 410;
        let msj = pruebas.length == 0 ? 'Ocurrió un error al intentar generar el pedido' : pruebas[0] ? pruebas[0].strMensaje : 'Ocurrió un error al intentar generar el pedido';
        let strMensaje = msj;
        let titulo = code == 409 ? 'Pedido Generado SINAFECTAR' : code == 200 ? 'Pedido Generado Correctamente' : 'Error al Intentar Generar Pedido';
        Swal.fire({
          icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
          title: titulo,
          text: msj,
          confirmButtonText: 'Cerrar'
        });
        if (code==200 || code==409){
          this.carroservice.deleteCarrito2();
          this.btnCerrarModalPedido.nativeElement.click();
        }
        else{
          this.boolMostrarBotonExportar = true;
        }
         
       
      },
      error => {
        this.boolMostrarBotonExportar = true;
        var error = <any>error;
        console.log(error);
        // object.spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al insertar pedido'
        });
      }
    );
  } 
  fnGuardarCotizacion() {
    let rest: any = [];
    this.objectCotizacion = {
      strEstatus: this.objCliente.strEstatus_Cotizacion_Leads ? this.objCliente.strEstatus_Cotizacion_Leads : 'POR AUTORIZAR',
      strIDMovimientoVenta: "",
      strComentarios: "NuevoSistemaCotizador",
      strUsuario: this.strUsuarioInt.replace(/^\s+|\s+$/g, ""),
      intSucursal: this.strSucursal_cot,
      strAgente: this.strAgenteCot.replace(/^\s+|\s+$/g, ""),
      strCliente: this.objCliente.strCliente ? this.objCliente.strCliente : "",
      strNombreCliente: this.objCliente.strNombre ? this.objCliente.strNombre : "",
      strOrdenCompra: "",
      strMoneda: this.ArrayMoneda[0].strMoneda,
      strTipoMovimiento: this.objCliente.strTipoMovimiento && this.objCliente.strTipoMovimiento != '' ? this.objCliente.strTipoMovimiento : "COTIZACION",
      listaCarrito: this.arrayCarritoArts,
      strConexion: this.strConexion,
      intId_leads: (this.objCliente.strOrigen == "/detalleLead/" || this.objCliente.strOrigen == "/leads/" || this.objCliente.strOrigen == "/prospectos/" || this.objCliente.strOrigen == "/detoport/") ? this.objCliente.strCliente : '' //Si viene de leads/prospecto debe tener esta propiedad
    }
    this._httpService.insertCotizacion(this.objectCotizacion, "setInsertarMovimientosVentas").subscribe(
      result => {
        rest = result;

        let code = rest.length == 0 ? 410 : rest ? rest.code : 410;
        let msj = rest.length == 0 ? 'Ocurrió un error al intentar guardar la cotización' : rest ? rest.strMensaje : 'Ocurrió un error al intentar guardar cotización';
        let titulo = code == 409 ? 'Cotización error' : code == 200 ? 'Cotización guardada Correctamente' : 'Error al Intentar Guardar Cotización';

        Swal.fire({
          icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
          title: titulo,
          text: msj,
          confirmButtonText: 'Cerrar'
        });
   

        let redirecciona = this.objCliente.strOrigen2 ? this.objCliente.strOrigen2 : 'carritoresumen';
        //this.carroservice.deleteCarrito();
        this.fnborrarCarrito()
        /*  this.router.navigate([redirecciona]); */
        const url = redirecciona;
        const [path, queryString] = url.split('?');
        const queryParams = this.parseQueryParams(queryString);
        this.router.navigate([path], { queryParams });


      });
  }
  fnGuardarPlantilla(tipo: string) {
    let rest: any = [];
    let objectPlantilla = {
      strIDMovimientoVenta: "",
      strComentarios: this.strComentarios,
      strUsuario: this.strUsuarioInt.replace(/^\s+|\s+$/g, ""),
      intSucursal: this.strSucursal_cot,
      strAgente: this.strAgenteCot.replace(/^\s+|\s+$/g, ""),
      strCliente: (this.objCliente.strCliente) ? this.objCliente.strCliente : "",
      strNombreCliente: this.objCliente.strNombre ? this.objCliente.strNombre : "",
      strOrdenCompra: "",
      strMoneda: this.ArrayMoneda[0].strMoneda,
      strTipoMovimiento: tipo == "PROSPECTO" ? this.objCliente.strTipoMovimiento : "PLANTILLA",
      listaCarrito: this.arrayCarritoArts,
      strConexion: this.strConexion,
      intId_leads: (this.objCliente.strOrigen == '/detoport/' || this.objCliente.strOrigen == '/detalleLead/' || this.objCliente.strOrigen == '/leads/' || this.objCliente.strOrigen == '/prospectos/') ? this.objCliente.strCliente : ''
    }
    console.log('-----------------------SE ENVIA EN PLANTILLA-------------------------------------')
  
    this._httpService.insertCotizacion(objectPlantilla, "setInsertarMovimientosVentas").subscribe(
      result => {
        rest = result;
        let code = rest.length == 0 ? 410 : rest ? rest.code : 410;
        let msj = rest.length == 0 ? 'Ocurrió un error al intentar guardar la plantilla' : rest ? rest.strMensaje : 'Ocurrió un error al intentar guardar plantilla';
        let titulo = code == 409 ? 'Plantilla error' : code == 200 ? 'Plantilla guardada Correctamente' : 'Error al Intentar Guardar Plantilla';
        if (tipo == "PROSPECTO") {
          if (code == 409) {
            Swal.fire({
              icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
              title: titulo,
              text: msj,
              confirmButtonText: 'Cerrar'
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: titulo,
              text: msj,
              confirmButtonText: 'Cerrar'
            });
            let redirecciona = this.objCliente.strOrigen2 ? this.objCliente.strOrigen2 : 'carritoresumen';
            this.carroservice.deleteCarrito2();
        
            /*  this.router.navigate([redirecciona]); */
            const url = redirecciona;
            const [path, queryString] = url.split('?');
            const queryParams = this.parseQueryParams(queryString);
            this.router.navigate([path], { queryParams });
          }
        } else {
          Swal.fire({
            icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
            title: titulo,
            text: msj,
            confirmButtonText: 'Cerrar'
          });
          this.fnborrarCarrito()
          //this.carroservice.deleteCarrito(); modificacion vivi
        }


      });

  }

/*   private parseQueryParams(queryString: string): any {
    return queryString.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
  } */
  private parseQueryParams(queryString: string): any {
    // Verifica si queryString es válido y es un string
    if (!queryString || typeof queryString !== 'string') {
      return {}; // Devuelve un objeto vacío si es indefinido, nulo o no es un string
    }
  
    return queryString.split('&').reduce((params: any, param: string) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value) : ''; // Decodifica el valor
      return params;
    }, {});
  }
  fnArtspasarCant() {
    let cantArts = 0
    this.arrayCarritoArts.forEach(o => {
      cantArts = cantArts + o.dblCantidad;
      o.intCantidad = o.dblCantidad
      o.dblPrecio = o.dblPrecioLista
      o.SubTotal = this.fnSubTotal();
      o.totalPorProducto = o.intCantidad * o.dblPrecio
    });
    this.arrayCarritoArts.forEach(o => {
      o.cantidadTotal = cantArts;
    });

  }
  fnimprimirprospecto() {
    Swal.fire({
      title: "Guardar PDF",
      text: "¿Desea agregar prospecto?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si"
    }).then((result) => {
      if (result.isConfirmed) {
        this.conp = true;
      } else {
        this.conp = false;
        this.btnModalProspecto.nativeElement.click();
        this.fncreatepdf();
      }
    });
  }
  activaModoPlantilla() {

    this.arrayCarritoArts.forEach(o => {
      o.dblPrecioSugerido = o.dblPrecioLista;
      o.dblPrecioLista = !this.boolModoPlantilla ? 
      o.dblPrecioPolitica && o.dblPrecioPolitica >0? o.dblPrecioPolitica :
      o.intPrecioPlantilla && o.intPrecioPlantilla >0?o.intPrecioPlantilla:
      o.intPrecioLista : o.dblPrecioLista;
    });
    this.validarPrePlantillas();

    this.carroservice.setModoPlantilla(this.boolModoPlantilla);
  }
  fnEligePrecio(precio: number, objArt: any, opcion: number) { 
    console.log('entra a elige precio ')
    if (opcion == 1) { objArt.dblPrecioSugerido = precio; }
    else if (opcion == 2) { 
      //validar que el precio sea mayor al precio minimo
      let precio_min = objArt.dblPrecioMinimo ?objArt.dblPrecioMinimo :0;
      let nuevo_precio =(objArt.intPrecioPolitica && objArt.intPrecioPolitica >0) ? objArt.intPrecioPolitica : objArt.intPrecioPlantilla>0? objArt.intPrecioPlantilla:  precio>0? precio : objArt.intPrecio;
      
      objArt.dblPrecioSugerido= nuevo_precio; 
      let precio_sug =objArt.dblPrecioSugerido?objArt.dblPrecioSugerido :0;

      if(precio_sug <precio_min){
         objArt.precio_plantilla_valido=1; //servira para marcar un icono en el listado de articulo
      }
      else {
        objArt.precio_plantilla_valido=0;
        objArt.dblPrecioLista = precio; 
        objArt.dblPrecioSugerido = precio 
      }

       //VALIDAMOS EL PRECIO POLITICA
       let precio_politica = objArt.intPrecioPolitica ?  objArt.intPrecioPolitica:0;
       let precio_plantilla = objArt.intPrecioPlantilla ?  objArt.intPrecioPlantilla:0;
       if(precio_politica >0)
       {
         objArt.precio_politica_valido = 1;
         objArt.dblPrecioLista = precio_politica; 
         objArt.dblPrecioSugerido= precio_politica; 
       }
       else if (precio_plantilla >0  && (precio <= precio_plantilla)) // si tiene precio plantilla , no pueden subir plantilla con precio inferior
       {
          objArt.precio_menor_plantilla = 1;
          objArt.dblPrecioLista = precio_plantilla; 
          objArt.dblPrecioSugerido= precio_plantilla; 
       }
       else {
        
        objArt.dblPrecioLista = precio;         
         objArt.precio_politica_valido = 0;
         objArt.precio_menor_plantilla = 0;
       }

     
    }
    this.validar_precioPlantilla_en_Pedido();
    this.fnUpdateAmountCar(objArt);
  }
  fnGetOpcionTodo() {
    let aaryOpciones = []
    this.arrayCarritoArts.forEach(element => {
      if (element.strTipoOpcion == 'Si' && (element.arrayOpciones.length == 0)) {
        let objop = { strArticulo: element.strArticulo }
        aaryOpciones.push(objop);
      }
    });
    if (aaryOpciones.length > 0) {
      let obj = {
        strConexion: this.strConexion,
        strAccion: 'getOpcionTodo',
        xmlArticulos: aaryOpciones
      }

      this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
        result => {
          this.arrayOpcionesTodo = result;
          this.arrayCarritoArts.forEach(element => {
            if (element.strTipoOpcion == 'Si' && (element.arrayOpciones.length == 0)) {
              element.arrayOpciones = this.arrayOpcionesTodo.filter(o => o.strArticulo == element.strArticulo);
              this.carroservice.updateArt(element);

            }
          });
        },
        error => {
          console.error('Error al consumir la API:', error);
          // Puedes manejar el error de alguna manera específica aquí
        });
    }

  }
  fnactivarboton(): boolean {
    let count = 0;
    this.arrayCarritoArts.forEach(art => {
      if (art.dblPrecioSugerido == 0 || art.dblPrecioSugerido == null || art.precio_plantilla_valido==1  || art.precio_politica_valido ==1 ||art.precio_menor_plantilla==1) {//|| art.precio_plantilla_valido==1
        count += 1
      }
    });
    return count == 0 ? false : true;
  }

  agregarOrden(event: any) {
    
    const fileInput = event.target as HTMLInputElement;
    this.formData.append('files[]', fileInput.files[0], fileInput.files[0].name);
    this.formData.append('inputIdentifier[]', 'OrdenCompra/');
    
  }

  subirOrdenCompra(){
    this._httpService.uploadFiles(this.formData).subscribe(
      (response) => {
       
        if(response[0].code == "200"){
          this.nombreOrdenCompra = 'https://www.ecodeli.com/' + response[0].ruta_archivo // cuando devuelve 200 puedes enviar a guardar a la base de datos
         
          Swal.fire({
            icon: 'success',
            title: 'Agregado Correctamente',
            //text: 'Archivo Agregado',
            confirmButtonText: 'Cerrar'
          });
        }else{
          Swal.fire("Error","Error al subir cedula fiscal" + response,"error")
          //this.spinerAll =false;
        }
      },
      (error) => {
        Swal.fire("Error", "No se recibio respuesta del servidor", "error")
       
      });
  }

  
  onSelectionChange(event: MatSelectChange, cte:any): void { 
    // Aquí puedes manejar el cambio, por ejemplo, actualizar datos en tu aplicación
    if(!event.value){
      this.fnllenarInputs(cte,{},2);
    }
    else {
      this.fnllenarInputs(cte,event.value,2);
    }
  }
  fnllenarInputs(cte:any, sucursal:any, opcion:number) {

    this.strSucursalCte = '' 
    
    this.objCliente.strDireccion_cliente = !sucursal ? cte.strDireccion:this.objCliente.strDireccion_cliente;
    this.objCliente.strDireccion =  sucursal && sucursal.intIDSucursal ? sucursal.strDireccion :"" /* this.objCliente.strDireccion_cliente */

    this.objCliente.strNombreSucursal = sucursal && sucursal.intIDSucursal ? sucursal.strNombreSucursal :'';
    this.objCliente.intIDSucursal = sucursal && sucursal.intIDSucursal ?sucursal.intIDSucursal:null;

    this.objCliente.strAgente_cliente = !sucursal ? cte.strAgente:this.objCliente.strAgente_cliente;
    this.objCliente.strAgente = sucursal && sucursal.intIDSucursal? sucursal.strAgente: this.objCliente.strAgente_cliente ;

    this.objCliente.strAlmacen_cliente =!cte.strAlmacen_cliente ?cte.strAlmacen:this.objCliente.strAlmacen_cliente;  // !sucursal.intIDSucursal ? cte.strAlmacen:this.objCliente.strAlmacen_cliente;

   
    this.objCliente.strAlmacen = sucursal && sucursal.intIDSucursal ?sucursal.strAlmacen:  this.objCliente.strAlmacen_cliente;

    this.objCliente.strSucursal_cliente = !cte.strSucursal_cliente?cte.strSucursal :this.objCliente.strSucursal_cliente; //!sucursal ? cte.strSucursal:this.objCliente.strSucursal_cliente;
    this.objCliente.strSucursal = sucursal && sucursal.intIDSucursal?sucursal.strSucursal :this.objCliente.strSucursal_cliente;
  


    this.objCliente.strNombre  = cte.strNombre
    this.objCliente.strCliente = cte.strCliente;
    this.objCliente.strClaveUsoCFDI = cte.strClaveUsoCFDI;
    this.objCliente.strCondicion = cte.strCondicion;
    this.objCliente.strDescripcionUsoCFDI = cte.strDescripcionUsoCFDI;
    this.objCliente.strInfopago = cte.strInfopago;
    this.objCliente.strInfopagoDescripcion = cte.strInfopagoDescripcion;

    
    
    this.objCliente.ClienteSeleccionado = true;

    this.objCliente.strTipo = cte.strTipo;
    this.objCliente.getSucursales = opcion ==2?false:true;
   
    this.carroservice.setCte(this.objCliente); 
      if(opcion!=2){
        this.fnGetSucursal_CTe()
      }
  }
fnGetSucursal_CTe()
{
  this.arraySucursalesCte = [];
  let cliente = {
    strAccion: "getCte_Sucursal",
    strCliente: this.strNumCte,
    strGerente: this.strGerente,
    strSucursal: this.strSucursal,
    strAgente: this.strAgente,
    strConexion: this.strConexion
  };
  this._httpService.getCotizador(cliente).subscribe(
    result => {
      this.arraySucursalesCte = result   
      if(this.strCteEnviarA && this.strCteEnviarA !='' && this.strCteEnviarA !='0'){
        let objSucCTe = this.arraySucursalesCte.find(o=>o.intIDSucursal ==this.strCteEnviarA  );
        this.fnllenarInputs(this.objCliente,objSucCTe,2);
        
       
      }
    });
}

 

  fnBuscarCte(strNumCte: string) {
    //borramos el carrito para evitar que usen precios no autorizados
    this.carroservice.deleteCarrito2();
    this.objCliente = {
      strCliente: "",
      strNombre: "",
      strDireccion: "",
      ClienteSeleccionado: false,
      strNombreSucursal: "",
      intIDSucursal: "",
      strTipo: "",
      strAgente: "",
      strAlmacen: "",
      strClaveUsoCFDI: "",
      strCondicion: "",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: ""
    };
    this.strDirccionCte = ""
    this.strNombreCte = ""
    //this.arraySucursalesCte = [];
    let cliente = {
      strAccion: "getCte_Cotizacion",
      strCliente: strNumCte,
      strGerente: this.strGerente,
      strTipoAcceso: this.localSt.retrieve('intIDRolAcceso'),//intIDRolAcceso
      strSucursal: this.strSucursal,
      strAgente: this.strAgente,
      strConexion: this.strConexion
    }; 
    this._httpService.getCotizador(cliente).subscribe(
      result => {
        this.arrayClientes = result; 
      });
  }

  fnDireccion(x:any){ 
    this.strDirccionCte = x.strDireccion
    //this.strNombreCte = x.strNombre
 
  }
  getNombreOpcion(x: any): string {
    const opcionEncontrada = x.arrayOpciones.find(opcion => opcion.strOpcion === x.strOpcion);
    return opcionEncontrada ? opcionEncontrada.strNombreOpcion : '';
  }

restrictInput(event: Event, obj: any): void {

  const input2 = event.target as HTMLInputElement;
  let currentValue = input2.value;

  let cantAnterior = obj.dblCantidad

  this.dblDisponible_nacional=0;
  this.dblReservado_nacional=0;
  this.disponible =0;

  if(obj.strEstatus== "DESCONTINUADO"){
    this.fnGetDisponible(obj, event,"input");
  }else{
    if (currentValue === '' || currentValue === '0'||currentValue === ' ' || currentValue.toLowerCase() === 'e' ) {
      input2.value = '1';
      obj.dblCantidad = 1;
    } else {
      obj.dblCantidad = parseInt(currentValue, 10); 
    }
    obj.intCantidad = obj.dblCantidad;
            this.fnUpdateAmountCar(obj);
  }


 
}

fnGetDisponible(obj:any,event: Event,tipo:string){

  const input = event.target as HTMLInputElement;
  let currentValue = input.value;


  this.strOpcion_Selected = obj.strOpcion
  this.arraySaldou =[] 
  let articulo = {
    strAccion: "getDisponible",  
    strConexion: this.strConexion,
    strArticulo:obj.strArticulo 
  };
  this._httpService.getCotizador(articulo).subscribe(
    result => { 
        this.arraySaldou = result; 
        this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
        this.dblDisponible_nacional=0
        this.dblReservado_nacional=0
        let nacional_opcion =[]
        nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
        nacional_opcion.forEach(element => {
          let disponible=element && element.dblCantidad ?element.dblCantidad :0;
          let reservado =element && element.dblReservado ?element.dblReservado :0;
          this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
          this.dblReservado_nacional=this.dblReservado_nacional +reservado;
          this.fnFiltrarSaldou()
        });
        this.disponible = this.dblDisponible_nacional - this.dblReservado_nacional
        

        if(tipo == "input"){
          if (currentValue === '' || currentValue === '0'||currentValue === ' ' || currentValue.toLowerCase() === 'e' ) {
            input.value = '1';
            obj.dblCantidad = 1;
          } else {
            obj.dblCantidad = parseInt(currentValue, 10); 
          }
        }
      

        if(obj.dblCantidad <=this.disponible){
          obj.intCantidad = obj.dblCantidad;
          this.fnUpdateAmountCar(obj);
        }else{
          input.value = ""+this.disponible;
          obj.dblCantidad = this.disponible;
          this.fnUpdateAmountCar(obj);
        Swal.fire("Correción","Se coloco una cantidad disponible","info")
        }
        
    });
}








restric(event: KeyboardEvent): void {
  const key = event.key;

  // Permite solo números, backspace, tab, y flechas
  if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
  }
}

 
    fnGetEstatus_Almacen(obj:any){
      let articulo = {
        strAccion: "getEstatusAlm",  
        strConexion: this.strConexion,
        strArticulo:obj.strArticulo ,
        strOpcion:obj.strOpcion ? obj.strOpcion :null,
        strAlmacen:this.strAlmacen
      };
      this._httpService.getCotizador(articulo).subscribe(
        result => {
          this.strEstatus_Almacen=result[0]?result[0].strEstatusAlmacen:'';
          
        });
    }


    fnCambio_opcion(obj:any){ 
      obj.strOpcion= this.strOpcion_Selected;
      this.fnGetEstatus_Almacen(obj);
      this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
      //cambia lo disponible nacional de acuerdo a la opcion
      let nacional_opcion =[]
      nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
      this.dblDisponible_nacional=0;
      this.dblReservado_nacional=0;
      nacional_opcion.forEach(element => {
        let disponible=element.dblCantidad ?element.dblCantidad :0;
        let reservado =element.dblReservado ?element.dblReservado :0;
        this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
        this.dblReservado_nacional=this.dblReservado_nacional +reservado;
       });
       this.objOpcion = this.arrayOpciones.find(x => x.strOpcion == obj.strOpcion);
       this.fnFiltrarSaldou(); 
    }

    fnFiltrarSaldou(){
      this.arraySaldoUFilter = this.strOpcion_Selected !=''?this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) : this.arraySaldou;
    }

   fnPolitica_niveles(tipo:string){

    this.boolNivelCliente=( tipo =='familia' && this.boolNivelCliente==true) ?false:this.boolNivelCliente;
    this.boolNivelFamilia = (tipo =='cliente' && this.boolNivelFamilia==true) ? false:this.boolNivelFamilia;
 /*
    this.boolNivelFamilia = tipo =='cliente' && this.boolNivelCliente==true ? false:true;
    this.boolNivelCliente= tipo =='familia' && this.boolNivelFamilia==true  ? false:true;*/
    this.fnValidarPolitica();

   }
   obtenerFechaActual(): string {
    const ahora = new Date();
    const año = ahora.getFullYear();
    const mes = String(ahora.getMonth() + 1).padStart(2, '0'); // +1 porque enero es 0
    const dia = String(ahora.getDate()).padStart(2, '0');
    const horas = String(ahora.getHours()).padStart(2, '0');
    const minutos = String(ahora.getMinutes()).padStart(2, '0');
    const segundos = String(ahora.getSeconds()).padStart(2, '0');
  
    return `${año}${mes}${dia}_${horas}${minutos}${segundos}`;
  }
  limpiarNombreArchivo(nombre: string): string {
    return nombre
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Quitar acentos
      .replace(/\s+/g, '_') // Reemplazar espacios con "_"
      .replace(/[^a-zA-Z0-9._-]/g, ''); // Eliminar caracteres especiales
  }
   fnEnviarPolitica_docto(){
   

    if (this.archivoSeleccionado) {
       
          // Obtener la fecha y hora formateada
          const fechaFormateada = this.obtenerFechaActual();
      
          // Obtener solo el nombre del archivo sin extensión
          const nombreOriginal = this.archivoSeleccionado.name.split('.').slice(0, -1).join('.');
          const extension = this.archivoSeleccionado.name.split('.').pop(); // Obtener extensión
      
          // Normalizar nombre (quitar acentos y espacios)
          const nombreLimpio = this.limpiarNombreArchivo(nombreOriginal);
      
          // Generar nuevo nombre
          const nuevoNombre = `CONVENIO_${fechaFormateada}_${nombreLimpio}.${extension}`;
      
          // Crear nuevo archivo con el nombre cambiado
          const archivoRenombrado = new File([this.archivoSeleccionado], nuevoNombre, {
            type: this.archivoSeleccionado.type,
          });
      

      this._httpService.subirArchivo( archivoRenombrado).subscribe({
        next: (response) => {
          let msj =response && response.mensaje? response : {mensaje: '409'};
          if(msj.mensaje ==200){
            Swal.fire({
              icon: 'success',
              title: 'Documento Adjuntado Correctamente', 
              confirmButtonText: 'Cerrar'
            });
            this.fnEnviarPolitica(nuevoNombre);
          }
          else {
            Swal.fire({
              title: "Error",
              text: 'Error al intentar adjuntar el documento',
              icon: "error"
            });
          }
          
        },
        error: (error) => {
          console.error('Error al subir el archivo', error);
        }
      });
    }
    else {
      Swal.fire({
        title: "Falta Documento",
        text: 'Es necesario adjuntar documento de convenio',
        icon: "error"
      });
    }
   }
 
   fnEnviarPolitica(nuevoNombre:string){
     // Convertimos a string en formato YYYY-MM-DD
  const fechaInicioSQL = this.formatearFecha(this.fechaInicio);
  const fechaFinSQL = this.formatearFecha(this.fechaFin);
    console.log(this.strObservacionesConvenio)
    console.log(this.boolNivelFamilia)
    console.log(this.boolNivelCliente)
    console.log(this.strNombreConvenio)
    console.log(this.archivoSeleccionado )
    console.log(nuevoNombre);
    console.log( fechaInicioSQL)
    console.log( fechaFinSQL)
    let rest: any = [];
    let objectPlantilla = {
      strIDMovimientoVenta: "",
      strComentarios: this.strObservacionesConvenio,
      strUsuario: this.strUsuarioInt.replace(/^\s+|\s+$/g, ""),
      intSucursal: this.strSucursal_cot,
      strAgente: this.strAgenteCot.replace(/^\s+|\s+$/g, ""),
      strCliente: (this.objCliente.strCliente) ? this.objCliente.strCliente : "",
      strNombreCliente: this.objCliente.strNombre ? this.objCliente.strNombre : "",
      strOrdenCompra: "",
      strMoneda: this.ArrayMoneda[0].strMoneda,

      strTipoMovimiento: 'POLITICA',
      listaCarrito: this.arrayCarritoArts,
      strConexion: this.strConexion ,
      strFamilia:this.objCliente.strFamilia ? this.objCliente.strFamilia :'',
      strAnexo:nuevoNombre,
      intNivelCte:this.boolNivelCliente ==true ? 1:0,
      intNivelFam : this.boolNivelFamilia ==true?1:0,
      strNombre_Politica: this.strNombreConvenio,
      strFecha_Inicio : fechaInicioSQL,
      strFecha_Fin:fechaFinSQL
     }
    console.log('-----------------------SE ENVIA EN POLITICA-------------------------------------')
   
    this._httpService.insertCotizacion(objectPlantilla, "sp_ModVentas_Plantillas_Politica").subscribe(
      result => {
        rest = result;
        let code = rest.length == 0 ? 410 : rest ? rest.code : 410;
        let msj = rest.length == 0 ? 'Ocurrió un error al intentar guardar la política' : rest ? rest.strMensaje : 'Ocurrió un error al intentar guardar Política';
        let titulo = code == 409 ? 'Política error' : code == 200 ? 'Política guardada Correctamente' : 'Error al Intentar Guardar Política';
        
          Swal.fire({
            icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
            title: titulo,
            text: msj,
            confirmButtonText: 'Cerrar'
          });
          
          if(code =200){
            this.btnCerrarModalPolitica.nativeElement.click();
          }
          this.fnborrarCarrito()

          //this.carroservice.deleteCarrito(); modificacion vivi
        


      });

   }
   formatearFecha(fecha: string): string {
    return fecha ? new Date(fecha).toISOString().split('T')[0] : '';
  }
   fnValidarPolitica(){
    let valida_checks = (!this.objCliente.strFamilia || this.objCliente.strFamilia =='')  ? this.boolNivelCliente ==true?true:false:
    (this.boolNivelCliente == true || this.boolNivelFamilia == true )?true:false;

     this.btnEnviar_politica =( this.strNombreConvenio !='' && valida_checks && this.archivoSeleccionado && this.fechaInicio != '' && this.fechaFin  !='' ) ? false:true;
 
   }
   seleccionarArchivo(event: any) {
    if (event.target.files.length > 0) {
      this.archivoSeleccionado = event.target.files[0]; // Guardar archivo seleccionado
      this.fnValidarPolitica();
    }
  }
}


