
<div class="conainer-fluid bgEnueva">
    <div class="container ">
        <div class="overlay" *ngIf="!load">
            <div class="spinner-border text-dark" style="font-size: 70px" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="row " [ngClass]="{'d-none':Acceso == 'EcPreVentas'}">
            <div class="col-lg-12 animated fadeInDown text-center mt-1">
                <div class="navy-line mt-2"></div>
                <h1 class="fw-bold">Bonos y <span class="text-navy"> Comisiones</span></h1>
            </div>
        </div>
       
        <div class="row bg-white bordertop borderbott shadow pb-4 " *ngIf="boolGerente || boolSucursal || boolAgente">
            <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
                <span class="material-symbols-outlined">
                    content_paste_search
                </span>
                <span class="fw-bold">
                    Filtros
                </span>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3   justify-content-center" *ngIf="boolGerente">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                    <mat-select [(ngModel)]="strGerente" class="">
                        <mat-option value=""> --Todos --</mat-option>
                        <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                    <mat-select [(ngModel)]="strSucursal" class="">
                        <mat-option value=""> --Todas --</mat-option>
                        <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
                            [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                    <mat-select [(ngModel)]="strAgente" class="">
                        <mat-option value=""> --Todos --</mat-option>
                        <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                            [value]="x.strAgente">{{x.strNombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
    
            <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
                [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
                <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false" (click)="getDatos(3); fnGetFiltros();"><!--  -->
                    <i class="bi  p-1 fs-4" [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                </button>
            </div>
        </div>
    
        <!-- Parte de la tabla Principal -->
        <div class="row py-3 d-flex justify-content-between alig-items-center ">
            <div class="col-10 mx-auto mb-5 mb-md-0 col-md-4 bg-light shadow brgreen borderRadius" style="min-height: 100%">
                <div class="row py-2 mt-3">
                    <div class="col-12 text-center pb-0">
                        <h1 class=" text-danger fw-bolder" style="font-size:35px;">FELICIDADES</h1>
                    </div>
                </div>
                <div class="row d-flex justify-content-center w-100" style="height:40px;">
                    <div class="col-12 py-2 position-relative px-0 pt-0">
                        <div style="width:120%;" class="ms-2 ps-2 position-absolute top-50 start-50 translate-middle d-flex align-items-center ">
                            <img class="w-100" src="assets/Liston.svg" alt="">
                        </div>
                        <div class=" position-absolute top-50 start-50 translate-middle col-12 d-flex justify-content-center">
                            <span class="my-0 fw-bold h1 text-white text-center"
                                style="font-size:22px;text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);">
                                {{strNombreVendedor}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-md-4">
                        <span class="text-dark fs-6 fw-bolder">
                            Llevas ganado al día de hoy.
                        </span>
                        <br>
                        <img src="assets/loading-25.gif" alt="" style="width:10%;" *ngIf='!load' id='load'>
                        <h2 *ngIf='load' class="fw-bold  mb-0 text-white tamLetrasTrof py-2">
                            {{totalPesos|currency}}
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <img src="assets/cup.png" height="140" width="140" class="img-fluid" alt="Dashboard Ecommerce" />
                    </div>
                </div>
                <div class="row mt-md-5">
                    <div class="col-12 text-center">
                        <span class="text-dark fs-5 fw-bolder">Sueldo:</span> <span class="fs-5 text-white">
                            {{arrayDatosTabla[0]?.dblSueldo|currency}} + BONOS</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-7">
                <div class="row">
                    <div class="col-12 shadow bg-white  borderRadius table-responsive mb-0">
                        <table class="table ">
                            <tbody>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Puesto</td>
                                    <td class="border-end fs-6  fw-bolder text-dark"></td>
                                    <td class=" fw-bold clst text-center"></td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Zona</td>
                                    <td class="border-end fs-6  fw-bolder text-dark"></td>
                                    <td class=" fw-bold clst text-center"></td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">CNSM</td>
                                    <td class="border-end fs-6  fw-bolder text-dark">
                                       </td>
                                    <td class=" fw-bold clst text-center"></td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr class="trc bg-white">
                                    <th></th>
                                    <th class="fw-bold card-title fs-6">Bono Ganado</th>
                                    <th class="fw-bold card-title fs-6">Moneda</th>
                                </tr>
                            </thead>
                            <tbody class="bg-light ctr ">
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Sueldo Bruto</td>
                                    <td class="border-end fs-6  fw-bolder text-dark">$0.00</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Sueldo Neto</td>
                                    <td class="border-end fs-6  fw-bolder text-dark">
                                        {{arrayDatosTabla[0]?.dblSueldo|currency}}</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>

                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Bono Trimestral</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">
                                        {{ObjBonoTriCurrent?.intBonoActual|currency}}
                                    </td>
                                    <td class="fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Comisión Mensual</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">
                                        {{arrayComisionMayoreo[0]?.dblEcomonedas|currency}}</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst text-center">Comision Mensual 0.2 %                                    </td>
                                    <td class="border-end fs-6 fw-bolder text-dark">$0.00</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst text-center">Comision Mensual 0.4 %
                                    </td>
                                    <td class="border-end fs-6 fw-bolder text-dark">$0.00</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst text-center">Comision Mensual 0.5 %
                                    </td>
                                    <td class="border-end fs-6 fw-bolder text-dark">$0.00</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Productos Foco</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">{{( (objdegrapackFoco && objdegrapackFoco.dblEcomonedas ? objdegrapackFoco.dblEcomonedas :0) +(objFoaomFoco && objFoaomFoco.dblEcomonedas ? objFoaomFoco.dblEcomonedas :0)+
                                        (objQuimicosFoco && objQuimicosFoco.dblEcomonedas ?objQuimicosFoco.dblEcomonedas :0)+( objDoveFoco && objDoveFoco.dblEcomonedas ? objDoveFoco.dblEcomonedas :0)) | currency }}</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Clientes Atendidos +
                                        {{objAvanceCte1?.dblFacturacion|currency}}</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">
                                        {{objAvanceCte1.intCumple_objetivo == 1 ?
                                        objAvanceCte1.dblEcomonedas:objAvanceCte2.intCumple_objetivo == 1 ?
                                        objAvanceCte2.dblEcomonedas: objAvanceCte3.dblEcomonedas | currency }}
                                    </td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Clientes Atendidos A + $5,000.00</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">
                                        {{arrayClientesAtendidos5000[0]?.dblEcomonedas |currency}}
                                    </td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Clientes Nuevos 2%</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">
                                        {{ dblClientesNuevos|currency}}
                                    </td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                               
                               
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Ecodeli Premia</td>
                                    <td class="border-end fs-6 fw-bolder text-dark">
                                        {{totalEcomonedasLineas?totalEcomonedasLineas:0 |number:'.0-0'}}</td>
                                    <td class=" fw-bold clst text-center">Ecomonedas</td>
                                </tr>
                                
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Bonus Ecodeli Premia</td>
                                    <td class="border-end fs-6 fw-bolder text-dark text-center">{{(objBonus &&
                                        objBonus.dblEcomonedas ? objBonus.dblEcomonedas:0)|number:'.0-0'}}</td>
                                    <td class=" fw-bold clst text-center">Ecomonedas</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Servicios de limpieza                                   </td>
                                    <td class="border-end fs-6 fw-bolder text-dark">$0.00</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="border-end fs-6 fw-bold clst">Gasolina</td>
                                    <td class="border-end fs-6 fw-bolder text-dark ">
                                        {{arrayDatosTabla[0]?.dblGasolina|currency}}</td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                            </tbody>
                            <tfoot class="">
                                <tr>
                                    <td class="fs-6 fw-bold clst">TOTAL </td>
                                    <td class="fs-6 fw-bolder text-dark text-center">
                                        <img src="assets/loading-25.gif" alt="" style="width:10%;" *ngIf='!load'
                                            id='load'>
                                        <span *ngIf='load' class="fs-6 bgbadge clst shadow p-2 rounded">
                                            {{totalPesos|currency}}
                                        </span>
                                    </td>
                                    <td class=" fw-bold clst text-center">MXN</td>
                                </tr>
                                <tr>
                                    <td class="fs-6 fw-bold clst">TOTAL </td>
                                    <td class=" fw-bolder text-dark text-center">
                                        <img src="assets/loading-25.gif" alt="" class="" style="width:10%;" *ngIf='!load'
                                            id='load'>
                                        <span *ngIf='load' class="fs-5 bgbadge  clst shadow px-3 py-1 rounded ">
                                            {{totalEcomonedas}}
                                        </span>
                                    </td>
                                    <td class="fs-6 fw-bold clst text-center">Ecomonedas</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
                    <!--////////////////////////////////////////////////////////////////////////////////-->
                    <!--/////////////////////////////////////Comienzan las cards////////////////////////-->
                    <!--////////////////////////////////////////////////////////////////////////////////-->
        <!--  -->
        <div class="row">
            <div class="col-12">
                <h2 class="mb-0 text-center fw-bold text-decoration-underline text-shadow">PRODUCTOS FOCO</h2>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-12 d-flex justify-content-center  " style="margin-bottom:10px"  [ngClass]="{'bw':objdegrapackFoco==null || objdegrapackFoco?.dblEcomonedas==0}"><!-- [ngClass]="{'bw':objDegrapackCliente?.intParticipa==0}" -->
                                    <img class="w-100" style="height:45px"  src="assets/Degrapack.jpg" alt="">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="mb-0 fw-bold text-center" [ngClass]="{'text-secondary':objdegrapackFoco==null || objdegrapackFoco?.dblEcomonedas==0}">Comisión productos foco</h2>
                                </div>
                            </div>
                            <div class="row align-items-center mb-2">
                                <div class="col-9">
                                    <h1 class="fs-2 text-end" style="color:#012970; font-weight:600; margin:0; padding:0; " [ngClass]="{'text-secondary':objdegrapackFoco==null || objdegrapackFoco?.dblEcomonedas==0}">{{(objdegrapackFoco && objdegrapackFoco.dblEcomonedas ?objdegrapackFoco.dblEcomonedas :0)|currency }}</h1>  
                                </div>
                                <div class="col-3 text-end">
                                    <span class="material-symbols-outlined fs-2  shadow-sm rounded-circle p-2 clIcon bgButton " data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos" (click)="DatosModal('card2','DEGRAPACK')"><!--  [ngClass]="{'text-secondary':objdegrapackFoco==null || objdegrapackFoco?.dblEcomonedas==0, 'text-warning':objdegrapackFoco?.dblEcomonedas>0}"   -->
                                        emoji_objects
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <span>Importe: <span class="fw-bold">{{(objdegrapackFoco && objdegrapackFoco.dblImporte ?objdegrapackFoco.dblImporte :0)|currency }}</span> </span>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center d-flex flex-column">
                                    <span class="text-dark fs12 fw-bold">
                                        Requisitos para participar 
                                    </span>
                                    <span class="text-dark fs12">
                                        Para poder adquirir comisiones, el objetivo de los agentes debe ser mayor o igual a $5,000 MXN
                                    </span>
                                    <div class="col-10 mx-auto">
                                        <hr>
                                    </div>
                                    <span class="text-dark fs12 ">
                                        *Comisión generada sobre el cobro. Si el cliente se atrasa 60 días con el pago, se cancela la comisión.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ////////////////////////////////////Foaom ////////////////////////// -->
            <!-- foaom foco-->
            <!-- ////////////////////////////////////Foaom ////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-12 d-flex justify-content-center " [ngClass]="{'bw':objFoaomFoco==null || objFoaomFoco?.dblEcomonedas==0}">
                                    <img style="height: 55px;width:65%;" src="assets/FoAomLOGO.png" alt="">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="mb-0 fw-bold text-center" [ngClass]="{'text-secondary':objFoaomFoco==null || objFoaomFoco?.dblEcomonedas==0}">Comisión productos foco</h2>
                                </div>
                            </div>
                            <div class="row align-items-center mb-2">
                                <div class="col-9">
                                    <h1 class="fs-2 text-end" style="color:#012970; font-weight:600; margin:0; padding:0; " [ngClass]="{'text-secondary':objFoaomFoco==null || objFoaomFoco?.dblEcomonedas==0}">{{ (objFoaomFoco && objFoaomFoco.dblEcomonedas ?objFoaomFoco.dblEcomonedas :0)|currency }}</h1>  
                                </div>
                                <div class="col-3 text-end">
                                    <span class="material-symbols-outlined fs-2  shadow-sm rounded-circle p-2 clIcon bgButton"  data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos" (click)="DatosModal('card2','FOAOM')"><!-- [ngClass]="{'text-secondary':objFoaomFoco==null || objFoaomFoco?.dblEcomonedas==0, 'text-warning':objFoaomFoco?.dblEcomonedas>0}"  -->
                                        emoji_objects
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <span>Cajas: <span class="fw-bold">{{ objFoaomFoco && objFoaomFoco.intCantidad ?objFoaomFoco.intCantidad :0 }}</span></span>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center d-flex flex-column">
                                    <span class="text-dark fs12 fw-bold">
                                        Requisitos para participar 
                                    </span>
                                    <span class="text-dark fs12">
                                        Para poder adquirir comisiones, los agentes deben relizar una venta mayor o igual a 25 cajas.
                                    </span>
                                    <div class="col-10 mx-auto">
                                        <hr>
                                    </div>
                                    <span class="text-dark fs12 ">
                                        *Comisión generada sobre el cobro. Si el cliente se atrasa 60 días con el pago, se cancela la comisión.
                                    </span>
                                </div>
                            </div>                   
                        </div>
                    </div>
                </div>
            </div>
            <!-- //////////////////////////////////////////// -->
            <!-- quimicos ecodeli foco-->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center " [ngClass]="{'bw':objQuimicosFoco==null || objQuimicosFoco?.dblEcomonedas==0}" >
                                    <img  style="height:55px;width:75%;" src="assets/QuimicosEcodeli.jpg" alt="">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="mb-0 fw-bold text-center" [ngClass]="{'text-secondary':objQuimicosFoco==null || objQuimicosFoco?.dblEcomonedas==0}">Comisión productos foco</h2>
                                </div>
                            </div>
                            <div class="row align-items-center my-2">
                                <div class="col-9">
                                    <h1 class="fs-2 text-end" style="color:#012970; font-weight:600; margin:0; padding:0; " [ngClass]="{'text-secondary':objQuimicosFoco==null || objQuimicosFoco?.dblEcomonedas==0}">{{ (objQuimicosFoco && objQuimicosFoco.dblEcomonedas ?objQuimicosFoco.dblEcomonedas :0)|currency}}</h1>  
                                </div>
                                <div class="col-3 text-end">
                                    <span class="material-symbols-outlined fs-2  shadow-sm rounded-circle p-2 clIcon bgButton"   data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos" (click)="DatosModal('card2','QUIMICOS')"><!-- [ngClass]="{'text-secondary':objQuimicosFoco ==null || objQuimicosFoco?.dblEcomonedas==0, 'text-warning':objQuimicosFoco?.dblEcomonedas>0}"  -->
                                        emoji_objects
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <span>Importe: <span class="fw-bold">{{ (objQuimicosFoco && objQuimicosFoco.dblImporte ?objQuimicosFoco.dblImporte :0)|currency}}</span></span>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center d-flex flex-column">
                                    <span class="text-dark fs12 fw-bold">
                                        Requisitos para participar 
                                    </span>
                                    <span class="text-dark fs12">
                                        Para poder adquirir comisiones, el objetivo de los agentes debe ser mayor o igual a $25,000 MXN.
                                    </span>
                                    <div class="col-10 mx-auto">
                                        <hr>
                                    </div>
                                    <span class="text-dark fs12 ">
                                        *Comisión generada sobre el cobro. Si el cliente se atrasa 60 días con el pago, se cancela la comisión.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- //////////////////////////////////////////// -->
            <!-- DOVE foco-->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card ">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-12 d-flex justify-content-center " [ngClass]="{'bw':objDoveFoco==null || objDoveFoco?.dblEcomonedas==0}" >
                                    <img  style="height:55px;width:59%;" src="assets/Dove-Logo.jpg" alt="">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="mb-0 fw-bold text-center" [ngClass]="{'text-secondary':objDoveFoco==null || objDoveFoco?.dblEcomonedas==0}">Comisión productos foco</h2>
                                </div>
                            </div>
                            <div class="row align-items-center mb-2">
                                <div class="col-9">
                                    <h1 class="fs-2 text-end" style="color:#012970; font-weight:600; margin:0; padding:0; " [ngClass]="{'text-secondary':objDoveFoco==null || objDoveFoco?.dblEcomonedas==0}" >{{ (objDoveFoco && objDoveFoco.dblEcomonedas ?objDoveFoco.dblEcomonedas :0)|currency }}</h1>  
                                </div>
                                <div class="col-3 text-end">
                                    <span class="material-symbols-outlined fs-2 shadow-sm rounded-circle p-2 clIcon bgButton"    data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos" (click)="DatosModal('card2','DOVE')"><!-- [ngClass]="{'text-secondary':objDoveFoco ==null || objDoveFoco?.dblEcomonedas==0, 'text-warning':objDoveFoco?.dblEcomonedas>0}"  -->
                                        emoji_objects
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <span>Importe: <span class="fw-bold">{{ (objDoveFoco && objDoveFoco.dblImporte ?objDoveFoco.dblImporte :0)|currency }}</span></span>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center d-flex flex-column">
                                    <span class="text-dark fs12 fw-bold">
                                        Requisitos para participar 
                                    </span>
                                    <span class="text-dark fs12">
                                        Para poder adquirir comisiones, el objetivo de los agentes debe ser mayor o igual a $15,000 MXN.
                                    </span>
                                    <div class="col-10 mx-auto">
                                        <hr>
                                    </div>
                                    <span class="text-dark fs12 ">
                                        *Comisión generada sobre el cobro. Si el cliente se atrasa 60 días con el pago, se cancela la comisión.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-12">
                <h2 class="mb-0 text-center fw-bold text-decoration-underline">ECODELI PREMIA</h2>
            </div>
            <!--/////////////////////////////////////////////////////////////////-->
            <!--/////////////////////Crds ecodeli premia////////////////////////  -->
            <!--////////////////////////////////////////////////////////////////-->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-10 d-flex justify-content-center  " style="margin-bottom:10px"  [ngClass]="{'bw':objDegrapackCliente?.intParticipa==0}"><!-- [ngClass]="{'bw':objDegrapackCliente?.intParticipa==0}" -->
                                    <img class="w-100" style="height:45px"  src="assets/Degrapack.jpg" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center">
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow-sm d-flex align-items-center p-2" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalleArticulos('getArtDeg')">
                                        <span class="material-symbols-outlined cstar" [ngClass]="{'text-secondary':objDegrapackCliente?.intParticipa==0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos">{{objDegrapackVenta?.dblEcomonedas|number:'1.0-0'}}</span> 
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column   ">
                                        <div class="col-12  d-flex justify-content-center">
                                            <div class="col-4" [ngClass]="{'bw':objDegrapackCliente?.intParticipa==0}">
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em" [ngClass]="{'text-secondary':objDegrapackCliente?.intParticipa==0}">Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-secondary':objDegrapackCliente?.intParticipa==0}">Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3" style="color:#012970; font-weight:700; margin:0; padding:0; " [ngClass]="{'text-secondary':objDegrapackCliente?.intParticipa==0}">{{objDegrapackCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress ">
                                                <div class="progress-bar progress-bar-striped  progress-bar-animated " [ngClass]="{'bg-secondary':objDegrapackCliente?.intParticipa == 0 ,'bg-danger': (objDegrapackCliente?.dblPorcentajeAvance > 0 && objDegrapackCliente?.dblPorcentajeAvance < 50) && objDegrapackCliente?.intParticipa == 1 ,'bg-warning': (objDegrapackCliente?.dblPorcentajeAvance >= 50 && objDegrapackCliente?.dblPorcentajeAvance < 90) && objDegrapackCliente?.intParticipa == 1 ,'bg-success': (objDegrapackCliente?.dblPorcentajeAvance >= 90 && objDegrapackCliente?.dblPorcentajeAvance <= 100) && objDegrapackCliente?.intParticipa == 1  }" role="progressbar" [style.width.%]="objDegrapackCliente?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted  pt-1 fw-bold float-end fs12">{{objDegrapackCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted  pt-2 fw-bold ps-1 float-start ">
                                                        <span class="fs12 fw-bold">
                                                            {{objDegrapackCliente?.dblAvance |number:'1.0-0'}}
                                                        </span>  
                                                        <span class="fs10"> Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div> 
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class="card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objDegrapackCliente?.dblPorcentajeAvance < 100 || objDegrapackCliente.intParticipa==0 } ">Objetivo de Venta </h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objDegrapackCliente?.dblPorcentajeAvance < 100 || objDegrapackCliente?.intParticipa == 0}" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objDegrapackVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex align-items-center">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress mt-3">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated " [ngClass]="{'bg-secondary':objDegrapackCliente?.dblPorcentajeAvance < 100 || objDegrapackCliente?.intParticipa==0 ,'bg-danger': ((objDegrapackVenta?.dblPorcentajeAvance  > 0 && objDegrapackVenta?.dblPorcentajeAvance < 50) &&  objDegrapackCliente?.dblPorcentajeAvance > 99) && objDegrapackCliente?.intParticipa == 1,'bg-warning': ((objDegrapackVenta?.dblPorcentajeAvance >= 50 && objDegrapackVenta?.dblPorcentajeAvance < 90) &&  objDegrapackCliente?.dblPorcentajeAvance > 99) && objDegrapackCliente?.intParticipa == 1 ,'bg-success': ((objDegrapackVenta?.dblPorcentajeAvance >= 90 && objDegrapackVenta?.dblPorcentajeAvance <= 100 ) &&  objDegrapackCliente?.dblPorcentajeAvance > 99) && objDegrapackCliente?.intParticipa == 1 }" role="progressbar"  [style.width.%]="objDegrapackVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted  pt-1 fw-bold float-end fs12">
                                                            {{objDegrapackVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %
                                                        </span> 
                                                        <span class="text-muted  pt-2  ps-1 float-start">
                                                            <span class="fs12 fw-bold">
                                                                {{objDegrapackVenta?.dblAvance | currency }}
                                                            </span>  
                                                            <span class="fs10 fw-bold">
                                                                 Venta Actual
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center">
                                    <span class="text-dark fs12">
                                        Al participar con cada $1,000.00 de ventas, te ganaras 3 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <!-- ////////////////////////////////////Foaom ////////////////////////// -->
            <!-- Foaom premia -->
            <!-- ////////////////////////////////////Foaom ////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-10 d-flex justify-content-center " [ngClass]="{'bw':objFoaomCliente?.intParticipa == 0 }">
                                    <img  style="height: 55px;width:65%;" src="assets/FoAomLOGO.png" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center">
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow-sm p-2 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalleArticulos('getArtFoam')">
                                        <span class="material-symbols-outlined cstar" [ngClass]="{'text-secondary':objFoaomCliente?.intParticipa == 0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos">{{objFoaomVenta?.dblEcomonedas|number:'1.0-0'}}</span>  
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column  ">
                                        <div class="col-12  d-flex justify-content-center">
                                            <div class="col-4" [ngClass]="{'bw':objFoaomCliente?.intParticipa == 0}">
                                                <img class="w-100" src="assets/icon_monedas.png"  alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" [ngClass]="{'text-secondary':objFoaomCliente?.intParticipa == 0}" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em">Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-secondary':objFoaomCliente?.intParticipa==0}">Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3"[ngClass]="{'text-secondary':objFoaomCliente?.intParticipa==0}" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objFoaomCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress ">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objFoaomCliente?.intParticipa == 0 ,'bg-danger': (objFoaomCliente?.dblPorcentajeAvance > 0 && objFoaomCliente?.dblPorcentajeAvance < 50)&& objFoaomCliente?.intParticipa == 1 ,'bg-warning': (objFoaomCliente?.dblPorcentajeAvance >= 50 && objFoaomCliente?.dblPorcentajeAvance < 90)&& objFoaomCliente?.intParticipa == 1 ,'bg-success': (objFoaomCliente?.dblPorcentajeAvance >= 90 && objFoaomCliente?.dblPorcentajeAvance <= 100)&& objFoaomCliente?.intParticipa == 1  }" role="progressbar" [style.width.%]="objFoaomCliente?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted  pt-1 fw-bold float-end fs12">{{objFoaomCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted  pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">
                                                            {{objFoaomCliente?.dblAvance |number:'1.0-0'}}
                                                        </span>  
                                                        <span class="fs10" > Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
    
    
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row"> 
                                <div class="col-12">
                                    <h5 class="card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objFoaomCliente?.dblPorcentajeAvance < 100 || objFoaomCliente?.intParticipa == 0 }">Objetivo de Venta</h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objFoaomCliente?.dblPorcentajeAvance < 100|| objFoaomCliente?.intParticipa == 0 }" style="font-size: 28px; color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objFoaomVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress mt-3">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objFoaomCliente?.dblPorcentajeAvance < 100 || objFoaomCliente?.intParticipa == 0 ,'bg-danger': ((objFoaomVenta?.dblPorcentajeAvance  > 0 && objFoaomVenta?.dblPorcentajeAvance < 50) &&  objFoaomCliente?.dblPorcentajeAvance > 99) &&  objFoaomCliente?.intParticipa == 1 ,'bg-warning': ((objFoaomVenta?.dblPorcentajeAvance >= 50 && objFoaomVenta?.dblPorcentajeAvance < 90) &&  objFoaomCliente?.dblPorcentajeAvance > 99) &&  objFoaomCliente?.intParticipa == 1  ,'bg-success': ((objFoaomVenta?.dblPorcentajeAvance >= 90 && objFoaomVenta?.dblPorcentajeAvance <= 100 ) &&  objFoaomCliente?.dblPorcentajeAvance > 99) &&  objFoaomCliente?.intParticipa == 1  }" role="progressbar" [style.width.%]="objFoaomVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted  pt-1 fw-bold float-end fs12">{{objFoaomVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %</span> 
                                                        <span class="text-muted  pt-2 fw-bold ps-1 float-start ">
                                                            <span class="fs12 fw-bold"> {{objFoaomVenta?.dblAvance | currency }} </span> 
                                                            <span class="fs10 fw-bold"> Venta Actual</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center ">
                                    <span class="text-dark fs12">
                                        Al participar con cada $1,000.00 de ventas, te ganaras 2 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                           
                           
    
                            
                            
                       
                        </div>
                    </div>
                </div>
            </div>
            <!-- //////////////////////////////////////////// -->
            <!-- quimicos ecodeli premia -->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-10 d-flex justify-content-center " [ngClass]="{'bw':objQuimicosEcodeliCliente?.intParticipa==0}" >
                                    <img  style="height:55px;width:75%;" src="assets/QuimicosEcodeli.jpg" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center" >
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow-sm d-flex align-items-center p-2" data-bs-toggle="modal" data-bs-target="#exampleModal"  (click)="fnGetDetalleArticulos('getArtQuim')">
                                        <span class="material-symbols-outlined cstar" [ngClass]="{'text-secondary':objQuimicosFoco==null || objQuimicosFoco?.dblEcomonedas==0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos" >{{objQuimicosEcodeliVenta?.dblEcomonedas|number:'1.0-0'}}</span> 
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column  ">
                                        <div class="col-12  d-flex justify-content-center align-items-center">
                                            <div class="col-4" [ngClass]="{'bw':objQuimicosFoco==null || objQuimicosFoco?.dblEcomonedas==0}">
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" [ngClass]="{'text-secondary':objQuimicosFoco==null || objQuimicosFoco?.dblEcomonedas==0}" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em" >Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-secondary':objQuimicosEcodeliCliente?.intParticipa==0}">Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;" [ngClass]="{'text-secondary':objQuimicosEcodeliCliente?.intParticipa==0}">{{objQuimicosEcodeliCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated" [ngClass]="{'bg-secondary':objQuimicosEcodeliCliente?.intParticipa == 0 ,'bg-danger': (objQuimicosEcodeliCliente?.dblPorcentajeAvance > 0 && objQuimicosEcodeliCliente?.dblPorcentajeAvance < 50)&& objQuimicosEcodeliCliente?.intParticipa == 1 ,'bg-warning': (objQuimicosEcodeliCliente?.dblPorcentajeAvance >= 50 && objQuimicosEcodeliCliente?.dblPorcentajeAvance < 90)&& objQuimicosEcodeliCliente?.intParticipa == 1 ,'bg-success': (objQuimicosEcodeliCliente?.dblPorcentajeAvance >= 90 && objQuimicosEcodeliCliente?.dblPorcentajeAvance <= 100)&& objQuimicosEcodeliCliente?.intParticipa == 1  }" role="progressbar" [style.width.%]="objQuimicosEcodeliCliente?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted pt-1 fw-bold float-end fs12">{{objQuimicosEcodeliCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">{{objQuimicosEcodeliCliente?.dblAvance |number:'1.0-0'}}</span>  
                                                        <span class="fs10" > Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class=" card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objQuimicosEcodeliCliente?.dblPorcentajeAvance < 100 || objQuimicosEcodeliCliente?.intParticipa == 0}">Objetivo de Venta </h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objQuimicosEcodeliCliente?.dblPorcentajeAvance < 100 || objQuimicosEcodeliCliente?.intParticipa == 0}" style="font-size: 28px; color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objQuimicosEcodeliVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress mt-3">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objQuimicosEcodeliCliente?.dblPorcentajeAvance < 100|| objQuimicosEcodeliCliente.intParticipa == 0,'bg-danger': ((objQuimicosEcodeliVenta?.dblPorcentajeAvance  > 0 && objQuimicosEcodeliVenta?.dblPorcentajeAvance < 50) &&  objQuimicosEcodeliCliente?.dblPorcentajeAvance > 99)&& objQuimicosEcodeliCliente?.intParticipa == 1,'bg-warning': ((objQuimicosEcodeliVenta?.dblPorcentajeAvance >= 50 && objQuimicosEcodeliVenta?.dblPorcentajeAvance < 75) &&  objQuimicosEcodeliCliente?.dblPorcentajeAvance > 99 )&& objQuimicosEcodeliCliente?.intParticipa == 1,'bg-success': ((objQuimicosEcodeliVenta?.dblPorcentajeAvance >= 75 && objQuimicosEcodeliVenta?.dblPorcentajeAvance <= 100 ) &&  objQuimicosEcodeliCliente?.dblPorcentajeAvance > 99)&& objQuimicosEcodeliCliente?.intParticipa == 1 }" role="progressbar" [style.width.%]="objQuimicosEcodeliVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted  pt-1 fw-bold float-end fs12">{{objQuimicosEcodeliVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %</span> 
                                                        <span class="text-muted  pt-2 fw-bold ps-1 float-start ">
                                                            <span class="fs12 fw-bold"> 
                                                                {{objQuimicosEcodeliVenta?.dblAvance | currency }}
                                                            </span> 
                                                            <span class="fs10 fw-bold" >
                                                                 Venta Actual
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center">
                                    <span class="text-dark fs12">
                                        Al participar con cada $1,000.00 de ventas, te ganaras 3 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                            
                            
                          
                        </div>
                    </div>
                </div>
            </div>
             <!-- //////////////////////////////////////////// -->
            <!-- DOVE premia-->
            <!-- //////////////////////////////////////////// -->

<!-- 
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card ">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-10 d-flex justify-content-center bw"  >
                                    <img  style="height:55px;width:65%;" src="assets/Dove-Logo.jpg" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center" >
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow-sm d-flex align-items-center p-2" data-bs-toggle="modal" data-bs-target="#exampleModal"  (click)="fnGetDetalleArticulos('getArtQuim')">
                                        <span class="material-symbols-outlined cstar text-secondary" >star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos" >0</span> 
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column  ">
                                        <div class="col-12  d-flex justify-content-center align-items-center">
                                            <div class="col-4 bw" >
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500 text-secondary"  style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em" >Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0 text-secondary" >Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3 text-secondary" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;" >0</h2>
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar" [style.width.%]="0" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted pt-1 fw-bold float-end fs12">0 %</span> 
                                                    <span class="text-muted pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">0</span> 
                                                        <span class="fs10" > Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class=" card-title d-flex align-items-center justify-content-center pt-1 mb-0 text-muted">Objetivo de Venta </h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4 text-muted" style="font-size: 28px; color: #012970 ;font-weight: 700;margin: 0; padding: 0;">0</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress mt-3">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated"  role="progressbar" [style.width.%]="0" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted  pt-1 fw-bold float-end fs12">0%</span>
                                                        <span class="text-muted  pt-2 fw-bold ps-1 float-start ">
                                                            <span class="fs12 fw-bold"> 0
                                                             
                                                            </span> 
                                                            <span class="fs10 fw-bold" >
                                                                 Venta Actual
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
    
    
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius py-4 px-2 text-center">
                                    <span class="text-dark fs12 ">
                                     
                                    </span>
                                </div>
                            </div>
    
    
    
    
                           
                        
                           
                        </div>
                    </div>
                </div>
            </div> -->
             <!-- //////////////////////////////////////////// -->
            <!-- TORK -->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-10 d-flex justify-content-center pb-3" [ngClass]="{'bw':objTorkCliente?.intParticipa==0}">
                                    <img class="w-50" style="height:55px;" src="assets/tork.png" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center" >
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalleArticulos('getArtTork')">
                                        <span class="material-symbols-outlined cstar " [ngClass]="{'text-muted':objTorkCliente?.intParticipa==0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex justify-content-end">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos">{{objTorkVenta?.dblEcomonedas|number:'1.0-0'}}</span> 
                                </div>
                                <div class="col-6 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column   ">
                                        <div class="col-12  d-flex justify-content-center">
                                            <div class="col-4" [ngClass]="{'bw':objTorkCliente?.intParticipa==0}">
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" [ngClass]="{'text-muted':objTorkCliente?.intParticipa==0}" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em">Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-muted':objTorkCliente?.intParticipa==0}">Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;" [ngClass]="{'text-muted':objTorkCliente?.intParticipa==0}">{{objTorkCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{ 'bg-secondary':objTorkCliente?.intParticipa==0,'bg-danger': (objTorkCliente?.dblPorcentajeAvance > 0 && objTorkCliente?.dblPorcentajeAvance < 50) && objTorkCliente?.intParticipa==1 ,'bg-warning': (objTorkCliente?.dblPorcentajeAvance >= 50 && objTorkCliente?.dblPorcentajeAvance < 90) && objTorkCliente?.intParticipa==1 ,'bg-success': (objTorkCliente?.dblPorcentajeAvance >= 90 && objTorkCliente?.dblPorcentajeAvance <= 100) && objTorkCliente?.intParticipa==1}" role="progressbar" [style.width.%]="objTorkCliente?.dblPorcentajeAvance"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted pt-1 fw-bold float-end fs12">{{objTorkCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">{{objTorkCliente?.dblAvance |number:'1.0-0'}}</span>  
                                                        <span class="fs10"> Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class="card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objTorkCliente?.dblPorcentajeAvance < 100 || objTorkCliente?.intParticipa == 0}">Objetivo de Venta</h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objTorkCliente?.dblPorcentajeAvance < 100 || objTorkCliente?.intParticipa == 0}" style="font-size: 28px; color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objTorkVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress mt-3">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objTorkCliente?.dblPorcentajeAvance < 100 || objTorkCliente.intParticipa == 0,'bg-danger': ((objTorkVenta?.dblPorcentajeAvance  > 0 && objTorkVenta?.dblPorcentajeAvance < 50) &&  objTorkCliente?.dblPorcentajeAvance > 99)&& objTorkCliente?.intParticipa == 1,'bg-warning': ((objTorkVenta?.dblPorcentajeAvance >= 50 && objTorkVenta?.dblPorcentajeAvance < 90) &&  objTorkCliente?.dblPorcentajeAvance > 99)&& objTorkCliente?.intParticipa == 1 ,'bg-success': ((objTorkVenta?.dblPorcentajeAvance >= 90 && objTorkVenta?.dblPorcentajeAvance <= 100 ) &&  objTorkCliente?.dblPorcentajeAvance > 99)&& objTorkCliente?.intParticipa == 1 }" role="progressbar" [style.width.%]="objTorkVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted pt-1 fw-bold float-end fs12">{{objTorkVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %</span> 
                                                        <span class="text-muted  pt-2 fw-bold ps-1 float-start ">
                                                            <span class="fs12 fw-bold">{{objTorkVenta?.dblAvance | currency }}</span> 
                                                            <span class="fs10" > Venta Actual</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center">
                                    <span class="text-dark fs12">
                                        Al participar y cumplir con los 2 objetivos de venta como de clientes, te ganaras 50 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //////////////////////////////////////////// -->
            <!-- ////////////////Accesorios////////////////// -->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-10 d-flex justify-content-center pb-3" [ngClass]="{'bw':objAccesoriosCliente?.intParticipa==0}">
                                    <img class="w-100" style="height:45px; margin-bottom:10px" src="assets/ACC.png" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center">
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalleArticulos('getArtAccesorios')">
                                        <span class="material-symbols-outlined cstar" [ngClass]="{'text-muted':objAccesoriosCliente?.intParticipa==0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex justify-content-end">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos">{{objAccesoriosVenta?.dblEcomonedas|number:'1.0-0'}}</span> 
                                </div>
                                <div class="col-6 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column   ">
                                        <div class="col-12  d-flex justify-content-center">
                                            <div class="col-4" [ngClass]="{'bw':objAccesoriosCliente?.intParticipa==0}">
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" [ngClass]="{'text-muted':objAccesoriosCliente?.intParticipa==0}" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em">Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-muted':objAccesoriosCliente?.intParticipa==0}">Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3" [ngClass]="{'text-muted':objAccesoriosCliente?.intParticipa==0}" style=" color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objAccesoriosCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated" [ngClass]="{'bg-secondary':objAccesoriosCliente?.intParticipa == 0,'bg-danger': (objAccesoriosCliente?.dblPorcentajeAvance > 0 && objAccesoriosCliente?.dblPorcentajeAvance < 50) && objAccesoriosCliente?.intParticipa == 1,'bg-warning': (objAccesoriosCliente?.dblPorcentajeAvance >= 50 && objAccesoriosCliente?.dblPorcentajeAvance < 90) && objAccesoriosCliente?.intParticipa == 1,'bg-success': (objAccesoriosCliente?.dblPorcentajeAvance >= 90 && objAccesoriosCliente?.dblPorcentajeAvance <= 100) && objAccesoriosCliente?.intParticipa == 1}" role="progressbar" [style.width.%]="objAccesoriosCliente?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted  pt-1 fw-bold float-end fs12">{{objAccesoriosCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted  pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">{{objAccesoriosCliente?.dblAvance |number:'1.0-0'}}</span>
                                                        <span class="fs10" > Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class="card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objAccesoriosCliente?.dblPorcentajeAvance < 100 || objAccesoriosCliente?.intParticipa == 0}">Objetivo de Venta</h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objAccesoriosCliente?.dblPorcentajeAvance < 100 || objAccesoriosCliente?.intParticipa == 0}" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objAccesoriosVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objAccesoriosCliente?.dblPorcentajeAvance < 100 || objAccesoriosCliente?.intParticipa == 0,'bg-danger': ((objAccesoriosVenta?.dblPorcentajeAvance  > 0 && objAccesoriosVenta?.dblPorcentajeAvance < 50) &&  objAccesoriosCliente?.dblPorcentajeAvance > 99 ) && objAccesoriosCliente?.intParticipa == 1,'bg-warning': ((objAccesoriosVenta?.dblPorcentajeAvance >= 50 && objAccesoriosVenta?.dblPorcentajeAvance < 90) &&  objAccesoriosCliente?.dblPorcentajeAvance > 99 ) && objAccesoriosCliente?.intParticipa == 1,'bg-success': ((objAccesoriosVenta?.dblPorcentajeAvance >= 90 && objAccesoriosVenta?.dblPorcentajeAvance <= 100 ) &&  objAccesoriosCliente?.dblPorcentajeAvance > 99) && objAccesoriosCliente?.intParticipa == 1}" role="progressbar"  [style.width.%]="objAccesoriosVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted  pt-1 fw-bold float-end fs12">{{objAccesoriosVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %</span> 
                                                        <span class="text-muted  pt-2 fw-bold ps-1 float-start">
                                                            <span class="fs12 fw-bold">{{objAccesoriosVenta?.dblAvance | currency }}</span> 
                                                            <span class="fs10" > Venta Actual</span>
                                                        </span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center">
                                    <span class="text-dark fs12">
                                        Al participar y cumplir con los 2 objetivos de venta como de clientes, te ganaras 75 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //////////////////////////////////////////// -->
            <!-- Rubermide -->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-10 d-flex justify-content-center pb-3" [ngClass]="{'bw':objRubermideCliente?.intParticipa==0}">
                                    <img class="w-75" style="height:55px;" src="assets/Rubber.png" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center">
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalleArticulos('getArtRubbermaid')">
                                        <span class="material-symbols-outlined cstar" [ngClass]="{'text-muted':objRubermideCliente?.intParticipa==0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex justify-content-end">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos">{{objRubermideVenta?.dblEcomonedas|number:'1.0-0'}}</span> 
                                </div>
                                <div class="col-6 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column   ">
                                        <div class="col-12  d-flex justify-content-center">
                                            <div class="col-4" [ngClass]="{'bw':objRubermideCliente?.intParticipa==0}">
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em">Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-muted':objRubermideCliente?.intParticipa==0}" >Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;" [ngClass]="{'text-muted':objRubermideCliente?.intParticipa==0}">{{objRubermideCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objRubermideCliente?.intParticipa == 0, 'bg-danger': (objRubermideCliente?.dblPorcentajeAvance > 0 && objRubermideCliente?.dblPorcentajeAvance < 50) && objRubermideCliente?.intParticipa == 1 ,'bg-warning': (objRubermideCliente?.dblPorcentajeAvance >= 50 && objRubermideCliente?.dblPorcentajeAvance < 90) && objRubermideCliente?.intParticipa == 1,'bg-success': (objRubermideCliente?.dblPorcentajeAvance >= 90 && objRubermideCliente?.dblPorcentajeAvance <= 100) && objRubermideCliente?.intParticipa == 1}" role="progressbar" [style.width.%]="objRubermideCliente?.dblPorcentajeAvance"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted  pt-1 fw-bold float-end fs12">{{objRubermideCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted  pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">{{objRubermideCliente?.dblAvance |number:'1.0-0'}}</span>  
                                                        <span class="fs10" > Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class="card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objRubermideCliente?.dblPorcentajeAvance < 100 || objRubermideCliente?.intParticipa == 0 }">Objetivo de Venta</h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objRubermideCliente?.dblPorcentajeAvance < 100 || objRubermideCliente?.intParticipa == 0 }" style="font-size: 28px; color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objRubermideVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objRubermideCliente?.dblPorcentajeAvance < 100 || objRubermideCliente?.intParticipa == 0,'bg-danger': ((objRubermideVenta?.dblPorcentajeAvance  > 0 && objRubermideVenta?.dblPorcentajeAvance < 50) &&  objRubermideCliente?.dblPorcentajeAvance > 99) && objRubermideCliente?.intParticipa == 1,'bg-warning': ((objRubermideVenta?.dblPorcentajeAvance >= 50 && objRubermideVenta?.dblPorcentajeAvance < 90) &&  objRubermideCliente?.dblPorcentajeAvance > 99) && objRubermideCliente?.intParticipa == 1,'bg-success': ((objRubermideVenta?.dblPorcentajeAvance >= 90 && objRubermideVenta?.dblPorcentajeAvance <= 100 ) &&  objRubermideCliente?.dblPorcentajeAvance > 99) && objRubermideCliente?.intParticipa == 1}" role="progressbar" [style.width.%]="objRubermideVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted pt-1 fw-bold float-end fs12">{{objRubermideVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %</span> 
                                                        <span class="text-muted pt-2 fw-bold ps-1 float-start">
                                                            <span class="fs12 fw-bold">{{objRubermideVenta?.dblAvance | currency }}</span>
                                                            <span class="fs10" > Venta Actual</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-11 bg-light borderRadius p-2 text-center">
                                    <span class="text-dark fs12">
                                        Al participar y cumplir con los 2 objetivos de venta como de clientes, te ganaras 75 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //////////////////////////////////////////// -->
            <!-- KimberlyKarc -->
            <!-- //////////////////////////////////////////// -->
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3  my-4 d-flex">
                <div class="col-12 d-flex">
                    <div class="shadow card info-card customers-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-10 d-flex justify-content-center pb-3" [ngClass]="{'bw':objKimberlyCliente?.intParticipa==0}">
                                    <img class="w-75" style="height:55px;" src="assets/kimberly.png" alt="">
                                </div>
                                <div class="col-2 text-end d-flex align-items-start justify-content-center">
                                    <button class="btn  rounded-circle tooltip-test btn-light shadow" data-bs-toggle="modal"  data-bs-target="#exampleModal" (click)="fnGetDetalleArticulos('getArtKimberly')">
                                        <span class="material-symbols-outlined cstar" [ngClass]="{'text-muted': objKimberlyCliente?.intParticipa == 0}">star_rate</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex justify-content-end">
                                    <img src="assets/loading-25.gif" alt="" class="w-100" *ngIf='!load'  id='load'>
                                    <span  *ngIf='load' class="fsPuntos">{{objKimberlyVenta?.dblEcomonedas|number:'1.0-0'}}</span> 
                                </div>
                                <div class="col-6 d-flex align-items-center">
                                    <div class="col-6 d-flex flex-column   ">
                                        <div class="col-12  d-flex justify-content-center">
                                            <div class="col-4" [ngClass]="{'bw':objKimberlyCliente?.intParticipa==0}">
                                                <img class="w-100" src="assets/icon_monedas.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <span class="fs22 ngro fw500" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em" [ngClass]="{'text-muted':objKimberlyCliente?.intParticipa == 0}">Ecomonedas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h5 class="card-title h3 justify-content-center d-flex align-items-center py-2 m-0" [ngClass]="{'text-muted':objKimberlyCliente?.intParticipa == 0}">Objetivo Clientes Atendidos</h5>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="col-4 d-flex align-items-end">
                                        <div class="col-12 text-center">
                                            <h2 class="fs-3" style="color: #012970 ;font-weight: 700;margin: 0; padding: 0;" [ngClass]="{'text-muted':objKimberlyCliente?.intParticipa == 0}">{{objKimberlyCliente?.dblObjetivo |number:'1.0-0'}}</h2>  
                                        </div>
                                   </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex ">
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                        <div class="col-12">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objKimberlyCliente?.intParticipa == 0,'bg-danger': (objKimberlyCliente?.dblPorcentajeAvance > 0 && objKimberlyCliente?.dblPorcentajeAvance < 50) && objKimberlyCliente?.intParticipa == 1 ,'bg-warning': (objKimberlyCliente?.dblPorcentajeAvance >= 50 && objKimberlyCliente?.dblPorcentajeAvance < 90) && objKimberlyCliente?.intParticipa == 1 ,'bg-success': (objKimberlyCliente?.dblPorcentajeAvance >= 90 && objKimberlyCliente?.dblPorcentajeAvance <= 100) && objKimberlyCliente?.intParticipa == 1}" role="progressbar" [style.width.%]="objKimberlyCliente?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-muted pt-1 fw-bold float-end fs12">{{objKimberlyCliente?.dblPorcentajeAvance|number:'1.2-2' }} %</span> 
                                                    <span class="text-muted pt-2 fw-bold ps-1 float-start">
                                                        <span class="fs12 fw-bold">{{objKimberlyCliente?.dblAvance |number:'1.0-0'}}</span>  
                                                        <span class="fs10"> Clientes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr class="my-2">
                                </div>
                            </div>
                            <div class="row " >
                                <div class="col-12"  >
                                    <h5 class="card-title d-flex align-items-center justify-content-center pt-1 mb-0" [ngClass]="{'text-muted':objKimberlyCliente?.dblPorcentajeAvance < 100 || objKimberlyCliente?.intParticipa == 0}">Objetivo de Venta</h5>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center" >
                                    <h2 class="text-center fw-bold fs-4" [ngClass]="{'text-muted':objKimberlyCliente?.dblPorcentajeAvance < 100}" style="font-size: 28px; color: #012970 ;font-weight: 700;margin: 0; padding: 0;">{{objKimberlyVenta?.dblObjetivo | currency}}</h2>
                                </div>
                                <div class="col-12 d-flex" >
                                    <div class="col-12 d-flex  align-items-center ">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped  progress-bar-animated" [ngClass]="{'bg-secondary':objKimberlyCliente?.dblPorcentajeAvance < 100 || objKimberlyCliente?.intParticipa == 0,'bg-danger': ((objKimberlyVenta?.dblPorcentajeAvance  > 0 && objKimberlyVenta?.dblPorcentajeAvance < 50) &&  objKimberlyCliente?.dblPorcentajeAvance > 99) && objKimberlyCliente?.intParticipa == 1,'bg-warning': ((objKimberlyVenta?.dblPorcentajeAvance >= 50 && objKimberlyVenta?.dblPorcentajeAvance < 75) &&  objKimberlyCliente?.dblPorcentajeAvance > 99) && objKimberlyCliente?.intParticipa == 1,'bg-success': ((objKimberlyVenta?.dblPorcentajeAvance >= 75 && objKimberlyVenta?.dblPorcentajeAvance <= 100 ) &&  objKimberlyCliente?.dblPorcentajeAvance > 99) && objKimberlyCliente?.intParticipa == 1}" role="progressbar" [style.width.%]="objKimberlyVenta?.dblPorcentajeAvance" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="text-muted pt-1 fw-bold float-end fs12">{{objKimberlyVenta?.dblPorcentajeAvance | number:'1.2-2'  }} %</span> 
                                                        <span class="text-muted pt-2 fw-bold ps-1 float-start">
                                                            <span class="fs12 fw-bold">{{objKimberlyVenta?.dblAvance | currency }}</span> 
                                                            <span class="fs10" >  Venta Actual</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                         
                                </div>                                     
                            </div>
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-5 border-top"></div>
                            </div>
                            <div class="row d-flex justify-content-center ">
                                <div class="col-11 bg-light borderRadius p-2 text-center">
                                    <span class="text-dark fs12">
                                        Al participar y cumplir con los 2 objetivos de venta como de clientes, te ganaras 50 valiosas Ecomonedas.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <!-- Modal lineas --> 
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title text-dark mb-0" id="exampleModalLabel">Articulos Participantes {{strTituloModal}}</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body px-3 pt-0 bg-light">
                         
                            <div class="row">
                                <div class="col-12 py-2">
                                    <h1 class="fs-5 mb-0">Requisito para participar en {{strTituloModal}}</h1>
                                    <ol>
                                        <li>* {{arrayMostrarModal[0]?.strIndicador1}}</li>
                                    </ol>
                                    <h1 class="fs-5 mb-0">Ganando Ecomonedas: ¿Cómo Funciona?</h1>
                                    <ol>
                                        <li >{{arrayMostrarModal[0]?.strEcomonedas}}</li> <!-- <li ng-bind-html="formatText(arrayMostrarModal[0].strEcomonedas)"></li> -->
                                    </ol>
                                </div>                                    
                            </div>
                            <div class="row">
                            <div class="col-12 table-responsive" style="max-height:70vh;overflow-y:auto;">
                                <table class="table table-bordered sticky-top">
                                    <thead>
                                        <tr>
                                            <th scope="col">Imagen</th>
                                            <th scope="col"><strong>Articulo</strong></th>
                                            <th scope="col">Descripcion</th>
                                            <th scope="col" class="text-center">Marca</th>
                                            <th scope="col" class="text-center">Linea</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white" *ngFor="let articulo of arrayDetArt">
                                            <th scope="col" style="width:20%;"><img src="https://www.cleanclean.mx/imagenes/{{articulo.strArticulo}}.JPG" alt="" style="width:90%;" ></th>
                                            <th scope="col"><strong>{{articulo.strArticulo}}</strong></th>
                                            <th scope="col">{{articulo.strDescripcion}}</th>
                                            <th scope="col" class="text-center">
                                                {{articulo.strFamilia}}
                                            </th>
                                            <th scope="col" class="text-right">
                                                {{articulo.strLinea}}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                      </div>
                    </div>
                </div>
            </div>
    <!-- Modal lineas -->
            <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="exampleModalLabel">Información BONUS</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body px-3">
                            <div class="col-12 d-flex justify-content-center" >
                                <div class="col-10 d-flex justify-content-center">
                                    <img class="w-50" style="height:55px;" src="assets/EcodeliPremia.jpg" alt="">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                   <h1> Para ganar bonus debe de completar los objetivos de todas las lineas!</h1>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--//////////////////////////////////////-->
        <!-- Parte de la tabla Principal termina -->
        <!-- ////////////////////////////////////  -->
        <div class="row my-5 d-flex justify-content-between  mx-0 px-">
            <div class="col-12 mt-4 d-flex justify-content-center mt-lg-0 col-lg-8  bg-white shadow  borderRadius py-3">
                <div class="row w-100 justify-content-center ">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class=" py-0  d-flex justify-content-center align-items-start">
                                <button class="btn fw-bolder rounded-circle d-flex p-1 m-0  clst1" data-bs-toggle="modal"
                                    data-bs-target="#modalCtesAtendidos" (click)="DatosModal('infoCtes7500')">
                                    <span class="material-symbols-outlined">
                                        info
                                    </span>
                                </button>
                                <h5 class='pt-0 card-title fw-bolder'>
                                    Clientes Atendidos + {{objAvanceCte1.dblFacturacion|currency}}
                                    <br>
                                    Margen + a 26%
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-10 ">
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center d-flex align-items-center">
                            <div class="col-6 border-end d-flex justify-content-center align-items-center">
                                <span class="fs-4 clsm mx-2 fhand fw-bold" data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos" (click)="DatosModal('card1')"> {{objAvanceCte1?.dblAvance > 0 ? objAvanceCte1.dblAvance:objAvanceCte2?.dblAvance > 0 ? objAvanceCte2?.dblAvance: objAvanceCte3?.dblAvance }}
                                </span>
                                <span class="fs-6 clst fw-bolder">
                                    Clientes <br> Atendidos
                                </span>
                            </div>
                            <div class="ms-2 col-6 d-flex align-items-center justify-content-center">
                                <span class="fs-4 fw-bold clsm mx-2 "> {{objAvanceCte1.intCumple_objetivo == 1 ? objAvanceCte1.dblEcomonedas:objAvanceCte2.intCumple_objetivo == 1 ? objAvanceCte2.dblEcomonedas: objAvanceCte3.dblEcomonedas | currency }}
                                </span>
                                <span class="fs-6 clst fw-bolder">
                                    Bono <br> Ganado
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <hr>
                        </div>
                    </div>
                    <div class="row mb-5 mt-3 d-flex justify-content-center">
                        <div class="col-11 d-flex align-items-center position-relative" style="height:100px">
                            <div class="progress  mx-auto" style="width:100%;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="objAvanceCte3.dblPorcentajeAvance">
                                </div>
                            </div>
                            <span style="background:#89a08d;" class="material-symbols-outlined  ms-1 position-absolute top-0 start-0  p-1 rounded-circle shadow border border-3 text-light">
                                flag
                            </span>
                            <span class="material-symbols-outlined  position-absolute top-50 start-0 translate-middle-y ms-2 bg-light rounded shadow">
                                counter_0
                            </span>
                            <div class=" ms-1 mt-5 position-absolute top-50 start-0 translate-middle-y d-flex flex-column justify-content-center ">
                                <span class="fs12  text-center clst fw-bold">
                                    INICIO
                                </span>
                            </div>
                            <span style="background:#49DEB2;" class="material-symbols-outlined  m32 position-absolute top-0 start-0 p-1 rounded-circle shadow border border-3 text-light ">
                                military_tech
                            </span>
                            <span class="material-symbols-outlined m33 position-absolute top-50 start-0 translate-middle-y  bg-light rounded shadow">
                                looks_one
                            </span>
                            <div class="m35  position-absolute top-100 start-0 translate-middle d-flex flex-column justify-content-center">
                                <span class="fs12 text-center clst fw-bold">
                                    BONO
                                </span>
                                <span class="fs12 fw-bold clsm text-center">
                                    {{objAvanceCte1.dblObjetivo|currency}}
                                </span>
                                <span class="fs12 fw-bold text-center clsb">
                                    {{objAvanceCte1.intMin_cte}} - {{objAvanceCte1.intMax_cte}} clientes
                                </span>
                            </div>
                            <span style="background:#00C3AF;" class="m65 material-symbols-outlined position-absolute top-0 start-0 p-1 rounded-circle shadow border border-3 text-light">
                                workspace_premium
                            </span>
                            <span
                                class="m66 material-symbols-outlined position-absolute top-50 start-0 translate-middle-y bg-light rounded shadow">
                                looks_two
                            </span>
                            <div class="m68 position-absolute top-100 start-0 translate-middle d-flex flex-column justify-content-center titanOne ">
                                <span class="fs12 fw-bold text-center clst">
                                    BONO
                                </span>
                                <span class="fs12 fw-bold clsm text-center">
                                    {{objAvanceCte2.dblObjetivo|currency}}
                                </span>
                                <span class="fs12 fw-bold text-center clsb">
                                    {{objAvanceCte2.intMin_cte}} - {{objAvanceCte2.intMax_cte}} clientes
                                </span>
                            </div>
                            <i class="bi bi-trophy position-absolute top-0 end-0 py-2 px-2 m-0 rounded-circle shadow border border-3 text-light d-flex align-items-center" style="background:#49BCF6;" ></i>
                           <!--  <span class="material-symbols-outlined position-absolute top-0 end-0 me-2 p-1  rounded-circle shadow border border-3 text-light">
                                rewarded_ads
                            </span> -->
                            <span class="material-symbols-outlined position-absolute top-50 end-0 translate-middle-y me-2 bg-light rounded shadow">
                                looks_3
                            </span>
                            <div class="position-absolute top-100 start-100 translate-middle d-flex flex-column justify-content-center titanOne pe-5">
                                <span class="fs12 fw-bold text-center clst">
                                    BONO
                                </span>
                                <span class="fs12 fw-bold clsm">
                                    {{objAvanceCte3.dblObjetivo|currency}}
                                </span>
                                <span class="fs12 fw-bold text-center clsb">
                                    {{objAvanceCte3.intMin_cte}} - {{objAvanceCte3.intMax_cte}} clientes
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Lado derecho comision foco, ctes atendidos 5,000 -->
            <div class="col-12  col-lg-4  mt-4 mt-lg-0">
                <div class="col-11 ms-auto">
                   <!--  <div class="row shadow bg-white py-3 borderRadius">
                        <div class="col-12">
                            <div class="row mt-1 mb-2 justify-content-center">
                                <div class="col-11 borderRadius fw-bold cardFocoProd fs12">
                                    Para Poder Participar Debe Cubrir Margen Global del 26% y Facturas Cobradas.
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center align-items-center mb-0">
                                <div class="col-2">
                                    <button class="btn p-1  rounded-circle bgButton shadow d-flex align-items-center"
                                        data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos"
                                        (click)="DatosModal('card2')">
                                        <span class="material-symbols-outlined clIcon">
                                            emoji_objects
                                        </span>
                                    </button>
                                </div>
                                <div class="col-9">
                                    <span class="fs-6 mb-0 fw-bold">
                                        Comisión Productos Foco
                                    </span>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-10 text-center">
                                    <h1 class="mb-0">
                                        <span class="fs-3  mb-0 fw-bold clst">
                                            {{ ComisionPF !== undefined && ComisionPF !== null ? ComisionPF : 0 | currency }}
                                        </span>
                                        <span class="fs-6 fw-bold clmxn ms-1">
                                            MXN
                                        </span>
                                    </h1>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-10">
                                    <div class="progress position-relative">
                                        <span class="position-absolute top-50 start-50 translate-middle  fw-bolder"
                                            [ngClass]="{'text-dark':ComisionPFPorcentaje < 70,'text-white':ComisionPFPorcentaje >= 70}">{{ComisionPFPorcentaje|number:'1.2-2'}}%</span>
                                        <div class="progress-bar progress-bar-striped progress-bar-animated "
                                            [ngClass]="{'bg-danger':ComisionPFPorcentaje < 50,'bg-warning':ComisionPFPorcentaje >= 50 && ComisionPFPorcentaje < 95,'bg-success':ComisionPFPorcentaje >= 95}"
                                            role="progressbar" [attr.aria-valuenow]="ComisionPFPorcentaje" aria-valuemin="0"
                                            aria-valuemax="100" [style.width.%]="ComisionPFPorcentaje"> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mb-5">
                                <div class="col-10 position-relative">
                                    <div class="mt-4 position-absolute top-50 start-0 translate-middle-y">
                                        <div class="row mt-3">
                                            <div class="col-12 fs12 text-start fw-bolder px-0 clsFoco2">
                                                {{ComisionPFAvance| currency}}
                                            </div>
                                            <div class="fs12 col-12 text-start fw-bold px-0 clsFoco">
                                                Venta
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 position-absolute top-50 end-0 translate-middle-y">
                                        <div class="row mt-3">
                                            <div class="col-12 fs12 text-end fw-bolder px-0 clsFoco2 ">
                                                {{ComisionPFObjetivo| currency}}
                                            </div>
                                            <div class="col-12 fs12 text-end fw-bold px-0 clsFoco">
                                                Objetivo
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row mt-3">
                        <div class="col-12 shadow bg-white py-4 borderRadius ">
                            <div class="row">
                                <div class="col-12 text-start">
                                    <h4 class=" fw-bold d-flex align-items-center mb-0 text-nowrap">
                                        Clientes Atendidos + A $5,000.00
                                        <button class="btn  rounded-circle d-flex align-items-center p-1 m-0  clst1"
                                            data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos"
                                            (click)="DatosModal('infoCtes5000')">
                                            <span class="material-symbols-outlined">
                                                info
                                            </span>
                                        </button>
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="col-12 py-3 position-relative d-flex justify-content-center">
                                        <div class="barhover h200 my-1 position-relative"
                                            [style.background]="getBackgroundStyle()">
                                            <span class="barhov fs-3 fw-bolder bgcardText1 position-absolute top-50 start-50 translate-middle">
                                                {{porcentajeCtes5000|number:'1.2-2'}} % 
                                            </span>
                                        </div>
                                        <div class="barhovv position-absolute top-50 start-50 translate-middle">
                                            <strong> 
                                                <span class="fs-5 fw-bolder bgcardText1">
                                                    {{arrayClientesAtendidos5000[0]?.dblEcomonedas| currency}}
                                                </span>
                                            </strong>
                                        </div>
                                        <div class="barhovv mt-5 position-absolute top-50 start-50 translate-middle">
                                            <span class="fs12 fw-bold text-nowrap">Bono Ganado</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col-12  bgcard py-2 borderRadius ">
                                        <div class="row px-2">
                                            <div class="col-12">
                                                <span class=" fw-bolder bgcardText1 fhand" data-bs-toggle="modal"
                                                    data-bs-target="#modalCtesAtendidos"
                                                    (click)="DatosModal('card3')">{{arrayClientesAtendidos5000[0]?.dblAvance}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs10 bgcardText2 fw-bold">Clientes Atendidos</span>
                                            </div>
                                        </div>
                                        <div class="row px-2 my-2">
                                            <div class="col-12">
                                                <span
                                                    class=" fw-bolder bgcardText1 ">{{arrayClientesAtendidos5000[0]?.dblObjetivo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs10 bgcardText2 fw-bold">Siguiente Nivel</span>
                                            </div>
                                        </div>
                                        <div class="row px-2">
                                            <div class="col-12">
                                                <span
                                                    class=" fw-bolder bgcardText1">{{arrayClientesAtendidos5000[0]?.dblBono_Proximo|currency
                                                    }}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs10 bgcardText2 fw-bold">Bono Siguiente Nivel</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-12 my-3 bg-white py-2  shadow borderRadius ">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 text-center px-0">
                                        <h2 class=" fw-bold mb-0">Clientes Nuevos</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-between">
                               
                                <div class="col-3 d-flex align-items-center">
                                    <button class="btn clBtnClient rounded-circle d-flex align-items-center p-2 shadow"
                                        data-bs-toggle="modal" data-bs-target="#mdlClientesNuevos" (click)="DatosModalCtesNuevos()">
                                        <span class="material-symbols-outlined cliconClient">
                                            supervisor_account
                                        </span>
                                    </button>
                                </div>
                                <div class="col-6 ">
                                    
                                    <div class="row">
                                        <div class="col-12 d-flex align-items-center">
                                            <strong>
                                                <h2 class="fw-bold clst mb-0">
                                                    {{dblClientesNuevos|currency}}
                                                </h2>
                                            </strong>
                                            <span class="fs-6 fw-bold clmxn">
                                                mxn
                                            </span>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    
        <div class="row d-flex justify-content-between   mb-5">
            <div class="col-12 col-lg-3 ">
                <div class="row mb-2  bg-white py-3  shadow borderRadius ">
                    <div class="col-12">
                        <div class="row pb-2 ">
                            <div class="col-12 text-center">
                                <h3 class="clst fw-bold mb-0">Bono Actual Trimestral</h3>
                            </div>
                        </div>
                        <div class="row  mb-3 d-flex justify-content-center  ">
                            <div class="col-10 text-center py-3 shadow borderRadius bg-whites">
                                <span class="fs-5 fw-bold text-dark">
                                    {{ObjBonoTriCurrent?.intBonoActual|currency}}
                                </span>
                                <br>
                                <span class="fs12 fw-bold clsbonos">
                                    Bono Actual
                                </span>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center pb-3 ">
                            <div class="col-10 text-center shadow borderRadius py-3 bg-whites">
                                <span class="fs-5 fw-bold text-dark">
                                    {{ObjBonoTriCurrent?.intPeriodoTriAnt|currency}}
                                </span>
                                <br>
                                <span class="fs12 fw-bold clsbonos">
                                    Promedio Anterior Con Margen + 22%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row my-3 bg-white py-2  shadow borderRadius ">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 text-center px-0">
                                <h2 class=" fw-bold mb-0">Comisión Mensual</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col-3 d-flex align-items-center pb-3">
                            <button class="btn clBtn rounded-circle d-flex align-items-center p-2 shadow"
                                data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos" (click)="DatosModal('card4')">
                                <span class="material-symbols-outlined clicons">
                                    fact_check
                                </span>
                            </button>
                        </div>
                        <div class="col-8 ">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center">
                                    <strong>
                                        <h2 class="fw-bold clst mb-0">
                                            {{arrayComisionMayoreo[0]?.dblEcomonedas|currency}}
                                        </h2>
                                    </strong>
                                    <span class="fs-6 fw-bold clmxn">
                                        mxn
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-lg-9 d-flex justify-content-center">
                <div class="col-11 mx-0 px-0">
                    <div class=" mx-0 px-0 row justify-content-center bg-white  shadow borderRadius ">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <h2 class="mb-0 mt-3 clst fw-bold me-3">Bono Trimestral</h2>
                                <button class="btn  rounded-circle d-flex align-items-center p-1 m-0  clst1"
                                    data-bs-toggle="modal" data-bs-target="#modalCtesAtendidos"
                                    (click)="DatosModal('card5')">
                                    <span class="material-symbols-outlined">
                                        info
                                    </span>
                                </button>
                            </div>
    
                        </div>
                        <div class="row mt-2 ">
                            <div class="col-6 text-center">
                                <span class="fs-6 fw-bold">
                                    Promedio Venta
                                </span>
                                <br>
                                <h3 class="fs-5 fw-bold text-dark p-0 m-0">
                                    {{ObjBonoTriCurrent?.intPromedioVenta|currency}}
                                </h3>
                                <div class="col-12 d-flex justify-content-center mt-2">
                                    <div class="col-9 border-bottom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-center ">
                                <span class="fs-6 fw-bold">
                                    Bono
                                </span>
                                <br>
                                <h3 class="fs-5 h2 fw-bolder text-dark">
                                    {{ObjBonoTriCurrent?.intBonoTri|currency}}
                                </h3>
                                <div class="col-12 d-flex justify-content-center mt-2">
                                    <div class="col-9 border-bottom">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Parte de arriba del card -->
                        <div class="row my-5 d-flex justify-content-center justify-content-md-around">
                            <div class="col-8 col-md-3  bg-whites1  shadow borderRadius py-3">
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-center">
                                        <span class="material-symbols-outlined iconCol1">
                                            local_atm
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <span class="fs-6 fw-bold clst">
                                            {{ObjBonoTriCurrent?.intMes1}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <span class="fs-5 fw-bold clst">
                                            {{ObjBonoTriCurrent?.intVentaMes1|currency}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 my-4 my-md-0 col-md-3  bg-whites2  shadow borderRadius py-3">
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-center">
                                        <span class="material-symbols-outlined iconCol2">
                                            paid
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <span class="fs-6 fw-bold clst">
                                            {{ObjBonoTriCurrent?.intMes2}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <span class="fs-5 fw-bold clst">
                                            {{ObjBonoTriCurrent?.intVentaMes2|currency}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-md-3  bg-whites3  shadow borderRadius py-3">
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-center">
                                        <span class="material-symbols-outlined iconCol3">
                                            finance_chip
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <span class="fs-6 fw-bold clst">
                                            {{ObjBonoTriCurrent?.intMes3}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center ">
                                        <span class="fs-5 fw-bold clst">
                                            {{ObjBonoTriCurrent?.intVentaMes3|currency}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Parte de abajo del card -->
                        <div class="row mb-4">
                            <div class="col-md-6 col-12 text-center">
                                <span class="fs-6 fw-bold">
                                    Meta Siguiente Bono
                                </span>
                                <br>
                                <h3 class="fs-5 fw-bold text-dark mb-0">
                                    {{ObjBonoTriCurrent?.intMetaNextBono|currency}}
                                </h3>
                                <div class="row d-flex justify-content-center">
                                    <div class="col-1  bordecls">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 text-center mt-5 mb-3 my-md-0">
                                <span class="fs-6 fw-bold">
                                    Bono Siguiente
                                </span>
                                <br>
                                <h3 class="fs-5 fw-bold text-dark mb-0">
                                    {{ObjBonoTriCurrent?.intNextBono|currency}}
                                </h3>
                                <div class="row d-flex justify-content-center">
                                    <div class="col-1 bordecls">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //////////////////////////////////// -->
        <!-- <div class="row">
            <div class="col-lg-12  text-center ">
                <div class="navy-line mt-0 "></div>
                <h1 class="mb-0" style="color: #2b3030; text-shadow: #b1b3b3 0.1em 0.1em 0.2em">
                    <span class="fs-2">
                        Ecodeli <span class="text-primary"> Premia</span>
                    </span>
                </h1>
            </div>
        </div>
        <div class="row d-flex justify-content-center bg-white pb-4 shadow borderRadius my-3">
            <div class="col-11 text-center pt-2">
                <div class="row">
                    <div class="col-12 col-md-4 d-flex align-items-center justify-content-center">
                        <img class="w-75" src="assets/BONUS.png" alt="">
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center justify-content-center">
                        <span *ngIf='load'
                            class="fsPuntos ms-3 me-2">{{objDegrapackVenta?.dblEcomonedas|number:'1.2-2'}}</span>
                        <img style="width:9%" src="assets/icon_monedas.png" alt="">
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center my-2">
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-11  bg-light borderRadius p-2">
                                <span class="text-dark fw-bold fs12">
                                    Ganarás 500 valiosas ecomonedas al cumplir los objetivos de clientes y ventas de todas
                                    las líneas participantes.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-11  my-3">
                <div class="row d-flex justify-content-between">
                    <div class="col-12 col-lg-5 d-flex">
                        <div class="row ">
                            <div class="col-4 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-75 " src="assets/Degrapack.jpg" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle  ">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objDegrapackVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
                            <div class="col-4 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-50 " src="assets/FoAomLOGO.png" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objFoaomVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
                            <div class="col-4 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-75 " src="assets/QuimicosEcodeli.jpg" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objQuimicosEcodeliVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-3 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-50" src="assets/tork.png" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objTorkVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
    
                            <div class="col-3 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-75 " src="assets/ACC.png" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objAccesoriosVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
                            <div class="col-3 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-75 " src="assets/logos/Rubber.png" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objRubermideVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
                            <div class="col-3 my-3 d-flex align-items-center  shadow borderRadius position-relative">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 py-4 d-flex justify-content-center">
                                        <img class="w-75 " src="assets/kimberly.png" alt="">
                                    </div>
                                </div>
                                <div class="position-absolute top-100 start-50 translate-middle">
                                    <span class="material-symbols-outlined vf mt-3"
                                        [ngClass]="{'text-muted':objKimberlyVenta?.dblEcomonedas<1}">
                                        verified
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row w-100 mt-1 mb-2 justify-content-center">
                <div class="col-9 borderRadius fw-bold cardFocoProd fs12">
                    Le solicitamos amablemente que haga clic en el icono de la estrella para acceder a las normativas de
                    participación correspondientes a cada una de las
                    categorías de Ecodeli Premia.
                </div>
            </div>
        </div> -->
    <!-- Inicia el div container -->
    
        <!-- termina el div container -->
    </div>
</div>








   <!-- /////////////////////////////Aqui comienza el modal de ctesAtendidos///////////////////////////// -->
   <div class="modal fade" id="modalCtesAtendidos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" [ngClass]="{'modal-xl' :ObjCtsModal.card == 'infoCtes7500' }">
        <div class="modal-content">
            <div class="modal-header" [ngClass]="{'d-none' :ObjCtsModal.card == 'infoCtes7500'}">
                <h3 class="modal-title fw-bold text-center col-11 text-dark mb-0" id="exampleModalLabel">{{ObjCtsModal.titulo}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body " [ngClass]="{'altModal':ObjCtsModal.card != 5}">                    
                <div *ngIf="ObjCtsModal.card == '1'||ObjCtsModal.card == '3'">
                    <div class="col-12 border bg-white  borderRadius table-responsive tabresy">
                        <table class="table my-0">
                            <thead style="position: sticky; top: 0; background-color: white;">
                                <tr class="trc bg-white">
                                    <th class="fs12 fw-bold card-title">Cliente</th>
                                    <th class="fs12 fw-bold card-title">Nombre</th>
                                    <th class="fs12 fw-bold card-title">Margen</th>
                                    <th class="fs12 fw-bold card-title">Importe</th>
                                </tr>
                            </thead>
                            <tbody class="bg-light ctr ">
                                <tr *ngFor="let x of ArrayCtsModal">
                                    <td class="border-end fs12 fw-bold  text-center">{{x.strCliente}}</td>
                                    <td class="border-end fs10 fw-bold ">{{x.strNombre}}</td>
                                    <td class="border-end fs12 fw-bold ">{{x.dblMargen| number:'1.2-2' }}%</td>
                                    <td class="fs12 fw-bold  text-dark">{{x.dblFacturacion|currency}}</td>
                                </tr>
                            </tbody>
                            <tfoot  style="position: sticky; bottom: 0; background-color: white;">
                                <tr>
                                    <td class="fs12 fw-bold clst">TOTAL </td>
                                    <td></td>
                                    <td colspan="2" class="fs12 fw-bold clst text-end"> 
                                        <span  class="me-1">
                                            {{ObjCtsModal.totalFacturacion |currency }}
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div *ngIf="ObjCtsModal.card == '2'">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="mb-0 fw-bold text-center">
                                Linea {{selectLineaArt}}
                            </h3>
                           <!--  <div class="input-group input-group-lg mb-1 ">
                                <label class="input-group-text fs12" for="inputGroupSelect01">
                                    Linea {{selectLineaArt}}
                                </label>
                                <select class="form-select fs12" (change)="filtroTabProdFoco(selectLineaArt)"  [(ngModel)]="selectLineaArt" ng-init="selectLineaArt" id="inputGroupSelect01" >
                                    <option value="" selected>--Todas--</option>
                                    <option value="{{x}}" *ngFor="let x of ObjCtsModal.arrayLineas ">{{x}}</option>
                                </select> 
                            </div> -->
                        </div>
                        
                    </div>
                    <div class="row">
                       <div class="col-12 text-center d-flex justify-content-center" *ngIf="spinerFoco">
                        <mat-spinner></mat-spinner>
                       </div>
                        <div class="col-12 table-responsive border bg-white  borderRadius" style="max-height:68vh;overflow-y:auto;" *ngIf="!spinerFoco">
                            <table class="table  ">
                                <thead style="position: sticky; top: 0; background-color: white;">
                                    <tr>
                                        <th scope="col"  class="text-center" >Imagen</th>
                                        <th scope="col"   class="text-center">Articulo</th>
                                        <th scope="col"  class="text-center" >Descripcion</th>
                                        <th scope="col"  class="text-center">Linea</th> 
                                        <th scope="col"  class="text-center">Plista</th> 
                                        <th scope="col"  class="text-center">Precio2</th> 
                                        <th scope="col"  class="text-center">Precio3</th> 
                                        <th scope="col"  class="text-center">Precio4</th> 
                                        <th scope="col" class="text-center">menor a Precio4</th>
                                    </tr>
                                </thead>
                                <tbody class="ctr">
                                    <tr *ngFor="let articulo of arrayPreciosArt |filtersearch:selectLineaArt:['strLinea']">
                                        <td scope="col" class="border-end align-middle" style="width:20%;"><img src="https://www.cleanclean.mx/imagenes/{{articulo.strArticulo}}.JPG" alt="" style="width:90%;" ></td>
                                        <td scope="col" class="text-center border-end align-middle fs12 fw-bold" >{{articulo.strArticulo}}</td>
                                        <td scope="col"  class="text-center border-end align-middle fs10">{{articulo.strNombre}}</td>
                                        <td scope="col"   class="text-center border-end align-middle fs12 fw-bold">
                                         
                                            {{articulo.strLinea}}
                                         
                                        </td>
                                        <td scope="col"   class="text-center border-end align-middle fs12 fw-bold">
                                            <div class="d-flex flex-column">
                                                <span>{{articulo.intPrecioLista|currency}}</span> 
                                                <span class="text-primary">Comisión del 7%</span>
                                             </div>
                                            
                                        </td>
                                        <td scope="col"   class="text-center border-end align-middle fs12 fw-bold">
                                           
                                            <div class="d-flex flex-column">
                                                <span>{{articulo.intPrecio2|currency}}</span> 
                                                <span class="text-primary">Comisión del 6%</span>
                                             </div>
                                        </td>
                                        <td scope="col"   class="text-center border-end align-middle fs12 fw-bold">
                                            <div class="d-flex flex-column">
                                                <span>{{articulo.intPrecio3|currency}}</span> 
                                                <span class="text-primary">Comisión del 5%</span>
                                             </div>
                                            
                                        </td>
                                        <td scope="col"   class="text-center border-end align-middle fs12 fw-bold">
                                            <div class="d-flex flex-column">
                                                <span>{{articulo.intPrecio4|currency}}</span> 
                                                <span class="text-primary">Comisión del 4%</span>
                                             </div>
                                        </td>
                                        <td scope="col"   class="text-center border-end align-middle fs12 fw-bold">
                                            <div class="d-flex flex-column">
                                            <span class="text-danger">Comisión</span>
                                            <span class="text-danger">del 0%</span>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="ObjCtsModal.card == '4'">
                    <div class="row">
                        <div class="col-12 table-responsive  bg-white  " style="max-height:70vh;overflow-y:auto;">

                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    <i class="bi bi-arrow-down-left-circle"></i> 13.99%
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" 
                                    aria-selected="false">
                                        14% <i class="bi bi-arrows-angle-contract text-dark"></i> 25.99%
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="new-tab" data-bs-toggle="tab" data-bs-target="#new" type="button" role="tab" aria-controls="new" 
                                    aria-selected="false">
                                       <i class="bi bi-arrow-up-left-circle"></i> 26%
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row text-center mt-2">
                                        <h3 class="mb-2">Clientes con margen menor al 13.99%</h3>
                                    </div>
                                    <table class="table  border borderRadius">
                                        <thead style="position: sticky; top: 0; background-color: white;">
                                            <tr>
                                                <th scope="col"  class="text-center fs-6" >Cliente</th>
                                                <th scope="col"   class="text-center fs-6">Nombre</th>
                                                <th scope="col"  class="text-center fs-6" >Importe</th>
                                                <th scope="col"  class="text-center fs-6">Margen</th>
                                                <th scope="col"  class="text-center fs-6">Comisión</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ctr">
                                            <ng-container  *ngFor="let articulo of arrayComisionMayoreoDet "> 
                                                <tr  *ngIf="articulo.dblMargen < 12 ">
                                                    <td scope="col" class="text-center fs12 border-end  align-middle" >{{articulo.strCliente}}</td>
                                                    <td scope="col" class="text-center fs10 border-end align-middle" >{{articulo.strNombre}}</td>
                                                    <td scope="col"  class="text-center fs12 border-end align-middle">{{articulo.dblImporte|currency}}</td>
                                                    <td scope="col"   class="text-center fs12 border-end align-middle">{{articulo.dblMargen| number:'1.2-2'}}%</td>
                                                    <td scope="col"   class="text-center fs12  align-middle">{{articulo.dblEcomonedas| currency}}</td>
                                                </tr>
                                            </ng-container >
                                        </tbody>
                                        <tfoot  style="position: sticky; bottom: 0; background-color: white;">
                                            <tr>
                                                <td class="fs12 fw-bold clst">TOTAL </td>
                                                <td></td>
                                                <td></td>
                                                <td colspan="2" class="fs12 fw-bold clst text-end"> 
                                                    <span  class="me-1">
                                                        {{intComMen|currency}} 
                                                    </span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="row text-center mt-3">
                                        <h3>Clientes con margen entre 14% y 25.99%</h3>
                                    </div>
                                    <table class="table  border borderRadius">
                                        <thead style="position: sticky; top: 0; background-color: white;">
                                            <tr>
                                                <th scope="col"  class="text-center fs-6" >Cliente</th>
                                                <th scope="col"   class="text-center fs-6">Nombre</th>
                                                <th scope="col"  class="text-center fs-6" >Importe</th>
                                                <th scope="col"  class="text-center fs-6">Margen</th>
                                                <th scope="col"  class="text-center fs-6">Comisión</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ctr ">
                                            <ng-container  *ngFor="let articulo of arrayComisionMayoreoDet">
                                                <tr  *ngIf="articulo.dblMargen>=12 && articulo.dblMargen<26">
                                                    <td scope="col" class="text-center fs12 border-end  align-middle" >{{articulo.strCliente}}</td>
                                                    <td scope="col" class="text-center fs10 border-end align-middle" >{{articulo.strNombre}}</td>
                                                    <td scope="col"  class="text-center fs12 border-end align-middle">{{articulo.dblImporte|currency}}</td>
                                                    <td scope="col"   class="text-center fs12 border-end align-middle">{{articulo.dblMargen| number:'1.2-2' }}%</td>
                                                    <td scope="col"   class="text-center fs12  align-middle">{{articulo.dblEcomonedas| currency}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                        <tfoot  style="position: sticky; bottom: 0; background-color: white;">
                                            <tr>
                                                <td class="fs12 fw-bold clst">TOTAL </td>
                                                <td></td>
                                                <td></td>
                                                <td colspan="2" class="fs12 fw-bold clst text-end"> 
                                                    <span  class="me-1">
                                                        {{intComMay|currency}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="tab-pane fade" id="new" role="tabpanel" aria-labelledby="new-tab">
                                    <div class="row text-center mt-3">
                                        <h3>Clientes con margen mayor al 26%</h3>
                                    </div>
                                    <table class="table  border borderRadius">
                                        <thead style="position: sticky; top: 0; background-color: white;">
                                            <tr>
                                                <th scope="col"  class="text-center fs-6" >Cliente</th>
                                                <th scope="col"   class="text-center fs-6">Nombre</th>
                                                <th scope="col"  class="text-center fs-6" >Importe</th>
                                                <th scope="col"  class="text-center fs-6">Margen</th>
                                                <th scope="col"  class="text-center fs-6">Comisión</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ctr ">
                                            <ng-container  *ngFor="let articulo of arrayComisionMayoreoDet">
                                                <tr  *ngIf="articulo.dblMargen>=26">
                                                    <td scope="col" class="text-center fs12 border-end  align-middle" >{{articulo.strCliente}}</td>
                                                    <td scope="col" class="text-center fs10 border-end align-middle" >{{articulo.strNombre}}</td>
                                                    <td scope="col"  class="text-center fs12 border-end align-middle">{{articulo.dblImporte|currency}}</td>
                                                    <td scope="col"   class="text-center fs12 border-end align-middle">{{articulo.dblMargen| number:'1.2-2' }}%</td>
                                                    <td scope="col"   class="text-center fs12  align-middle">{{articulo.dblEcomonedas| currency}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                        <tfoot  style="position: sticky; bottom: 0; background-color: white;">
                                            <tr>
                                                <td class="fs12 fw-bold clst">TOTAL </td>
                                                <td></td>
                                                <td></td>
                                                <td colspan="2" class="fs12 fw-bold clst text-end"> 
                                                    <span  class="me-1">
                                                        {{intComMay26|currency}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                <div *ngIf="ObjCtsModal.card == '5'">
                    <div class="row">
                        <div class="col-12 table-responsive  clst text-center" style="max-height:68vh;overflow-y:auto;">
                         <h3 class="fw-bolder text-center">
                         {{ObjCtsModal.participa}}
                         </h3>
                        </div>
                        <div class="text-center d-none">
                            <h3 class="fw-bolder ltclm"  >Su margen actual es de:</h3>
                            <h1 class="fw-bolder ltclm" >{{ObjBonoTriCurrent.dblMargenSig|number:'1.2-2'}}%</h1> 
                        </div>
                    </div>
                </div>
                <div *ngIf="ObjCtsModal.card == 'infoCtes5000'">
                    <div class="row">
                        <div class="col-12 table-responsive  clst text-center" style="max-height:68vh;overflow-y:auto;">
                            <table class="table rounded">
                                <thead>
                                    <tr class="sticky">
                                        <th >
                                          Cliente
                                        </th>
                                        <th >
                                            Nombre
                                        </th>
                                        <th >
                                            Facturación
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let g of ArrayCtsModal">
                                        <td class="text-start border-start border-end">
                                            <div class="d-flex align-items-center h-100">
                                                <i class="bi bi-person-video clblue fs-4 me-2"></i> 
                                                <span class="fw-bold fs12">{{g.strCliente}}</span>
                                            </div> 
                                        </td>
                                        <td class="fs10">
                                            {{g.strNombre}}
                                        </td>
                                        <td class="fs12 text-end border-start border-end">
                                           <b>{{g.dblFacturacion|currency}}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
                <div *ngIf="ObjCtsModal.card == 'infoCtes7500'">
                    <div class="row">
                        <div class="col-12">
                            <ul class="nav nav-tabs d-flex justify-content-between" id="nav-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active fw-bold" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        <span class="d-none d-lg-block">MAYOR A 5000 CON MARGEN MENOR AL 26%</span> 
                                        <span class="d-block d-lg-none">5000<i class="bi bi-arrow-down-left"></i>AL 26%</span>
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-bold" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <span class="d-none d-lg-block"> MAYOR A 5000 CON MARGEN MAYOR AL 26%</span>
                                        <span class="d-block d-lg-none">5000<i class="bi bi-arrow-up-right"></i>AL 26%</span>
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-bold" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                        <span class="d-none d-lg-block">MAYOR A 7500 CON MARGEN MENOR AL 26%</span>
                                        <span class="d-block d-lg-none">7500<i class="bi bi-arrow-down-left"></i>AL 26%</span>
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content mt-3" id="nav-tabContent">
                                <div class="tab-pane fade show active col-12 table-responsive" id="home" role="tabpanel" aria-labelledby="home-tab" style="max-height:68vh;overflow-y:auto;">
                                    <div class="alert alert-danger text-center" role="alert" *ngIf="Array5000abaj26.length < 1">
                                        SIN DATOS
                                    </div>
                                    <table class="table rounded" *ngIf="Array5000abaj26 && Array5000abaj26.length > 0">
                                        <thead>
                                            <tr class="sticky">
                                                <th class="fs12"  >Cliente</th>
                                                <th class="fs12" >Nombre</th>
                                                <th class="fs12">Descripcion</th>
                                                <th class="fs12">Margen</th>
                                                <th class="fs12" >Facturación</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  *ngFor="let g of Array5000abaj26">
                                                <td class="text-start border-start border-end ">
                                                    <div class="d-flex align-items-center h-100 fs12 fw-bold"><i class="bi bi-person-video clblue fs-4 me-2"></i> {{g.strCliente}}</div> 
                                                </td>
                                                <td>{{g.strNombre}}</td>
                                                <td class="border-start border-end fs10">{{g.strLinea}}</td>
                                                <td>{{g.dblMargen|number:'1.2-2'}}%</td>
                                                <td class=" text-end border-start border-end fs12"><b>{{g.dblFacturacion|currency}}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tab-pane fade col-12 table-responsive" id="profile" role="tabpanel" aria-labelledby="profile-tab" style="max-height:68vh;overflow-y:auto;">
                                    <div class="alert alert-danger text-center" role="alert" *ngIf="Array5000arrib26.length < 1">
                                        SIN DATOS
                                    </div>
                                    <table class="table rounded" *ngIf="Array5000arrib26 && Array5000arrib26.length > 0">
                                        <thead>
                                            <tr class="sticky">
                                                <th >Cliente</th>
                                                <th >Nombre</th>
                                                <th>Descripcion</th>
                                                <th>Margen</th>
                                                <th >Facturación</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  *ngFor="let g of Array5000arrib26">
                                                <td class="text-start border-start border-end">
                                                    <div class="d-flex align-items-center h-100"><i class="bi bi-person-video clblue fs-4 me-2"></i> {{g.strCliente}}</div> 
                                                </td>
                                                <td>{{g.strNombre}}</td>
                                                <td class="border-start border-end">{{g.strLinea}}</td>
                                                <td>{{g.dblMargen|number:'1.2-2'}}%</td>
                                                <td class=" text-end border-start border-end"><b>{{g.dblFacturacion|currency}}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tab-pane fade col-12 table-responsive" id="contact" role="tabpanel" aria-labelledby="contact-tab" style="max-height:68vh;overflow-y:auto;">
                                    <div class="alert alert-danger text-center" role="alert" *ngIf="Array7500abaj26.length < 1">
                                        SIN DATOS 
                                    </div>
                                    <table class="table rounded" *ngIf="Array7500abaj26 && Array7500abaj26.length > 0">
                                        <thead>
                                            <tr class="sticky">
                                                <th>Cliente</th>
                                                <th>Nombre</th>
                                                <th>Descripcion</th>
                                                <th>Margen</th>
                                                <th>Facturación</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  *ngFor="let g of Array7500abaj26">
                                                <td class="text-start border-start border-end">
                                                    <div class="d-flex align-items-center h-100"><i class="bi bi-person-video clblue fs-4 me-2"></i> {{g.strCliente}}</div> 
                                                </td>
                                                <td>{{g.strNombre}}</td>
                                                <td class="border-start border-end">{{g.strLinea}}</td>
                                                <td>{{g.dblMargen|number:'1.2-2'}}%</td>
                                                <td class=" text-end border-start border-end"><b>{{g.dblFacturacion|currency}}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="mdlClientesNuevos" tabindex="-1" aria-labelledby="mdlClientesNuevosLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title mb-0 mx-auto fw-bold" id="mdlClientesNuevosLabel">CLIENTES NUEVOS</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h3 clasS="mb-0 fw-bold">{{objClientesNuevos.titulo}}</h3>
                    </div>
                    <div class="col-12 mt-3 fs13 text-secondary fw-bold">
                        {{objClientesNuevos.strDescripcion1}}
                    </div>
                    <div class="col-12 text-secondary my-3">
                        <ul class="mb-0">
                            <li class="my-2" *ngFor="let desc of objClientesNuevos.array; let ind = index">
                                {{desc}}
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 fs13 text-secondary fw-bold">
                        {{objClientesNuevos.strDescripcion2}}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <!--   <button type="button" class="btn btn-primary">Guardar</button> -->
        </div>
      </div>
    </div>
  </div>