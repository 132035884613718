<div class="body container-fluid mt-3">
    <button class="d-none" id="botonActividades" data-bs-toggle="modal" data-bs-target="#actividades"></button>
    <button class="d-none" id="btnConcluirCotizaciones" data-bs-toggle="modal"
        data-bs-target="#ConcluirCotizaciones"></button>
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="px-0 ">
                <ol class="breadcrumb d-flex align-items-center mb-0">
                    <li class="breadcrumb-item"><a [routerLink]="['/tableroLeads']">Tablero Prospectos</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Detalle Prospectos</li>
                </ol>
            </nav>
        </div>
    </div>
    <button class="d-none" id="botonLead" data-bs-toggle="modal" data-bs-target="#lead"></button>
    <button class="d-none" id="botonLead" data-bs-toggle="modal" data-bs-target="#lead"></button>
    <div class="row mt-2 d-flex">

        <div class="card m-auto" style="width: 98%;">
            <div class="col-11 mx-auto mb-4 mt-2 f-dlex">
                <div class="row m-auto d-flex justify-content-center align-items-center">
                    <div class="col-2 borderleft mt-2 d-flex  py-2  border-end-0   cursor"
                        [ngClass]="{'clsActive cardisActive': strEtapa === 'POR CONTACTAR', 'clsTerminado cardis':  strEtapa != 'POR CONTACTAR'}">
                        <div class="col-12 text-center textTitulo m-auto">Por Contactar</div>
                        <!-- <div class="col text-center textTitulo">0 Días</div> -->
                    </div>

                    <div class="col-2  mt-2 py-2 d-flex border-start-0 border-end-0 cursor"
                        [ngClass]="{'clsActive cardisActive': strEtapa=='PRESENTACION', 'clsTerminado2 cardisTerminado2': (strEtapa== 'RECORRIDO'||strEtapa== 'PROPUESTA ECONOMICA'||strEtapa== 'NEGOCIACION'||strEtapa== 'GANADO' || strEtapa== 'PERDIDO'),'clsDesactive2 carddeactive2': strEtapa== 'POR CONTACTAR'}"
                        (click)="fnCheckValidaciones('presentacion')">
                        <div class="col-12 text-center textTitulo m-auto">Presentación</div>
                        <!-- <div class="col- text-center textTitulo">1 Días</div> -->
                    </div>
                    <div class="col-2 mt-2  py-2 d-flex border-start-0 border-end-0 cursor"
                        [ngClass]="{'clsActive car3Active': strEtapa=='RECORRIDO','clsTerminado cardis': (strEtapa== 'PROPUESTA ECONOMICA'||strEtapa== 'NEGOCIACION'||strEtapa== 'GANADO' || strEtapa== 'PERDIDO'), 'clsDesactive car3': (strEtapa== 'POR CONTACTAR'||strEtapa== 'PRESENTACION')}"
                        (click)="fnCheckValidaciones('recorrido')">
                        <div class="col-12 text-center textTitulo m-auto">Cotización / Recorrido</div>
                        <!-- <div class="col- text-center textTitulo">1 Días</div> -->
                    </div>
                    <div class="col-2 mt-2  py-2 d-flex  border-start-0 border-end-0 cursor"
                        [ngClass]="{'clsActive cardisActive': strEtapa=='PROPUESTA ECONOMICA','clsTerminado2 cardisTerminado2':(strEtapa== 'NEGOCIACION'||strEtapa== 'GANADO' || strEtapa== 'PERDIDO'),'carddeactive2 clsDesactive2':  (strEtapa =='PRESENTACION'||strEtapa =='RECORRIDO'||strEtapa =='POR CONTACTAR')}"
                        (click)="fnCheckValidaciones('propuesta')">
                        <div class="col-12 text-center m-auto textTitulo">Propuesta Economica</div>
                        <!--  <div class="col- text-center textTitulo">1 Días</div> -->
                    </div>
                    <div class="col-2 mt-2 d-flex  py-2  border-start-0 border-end-0 cursor"
                        [ngClass]="{'clsActive cardisActive': strEtapa=='NEGOCIACION', 'clsTerminado cardis': strEtapa== 'GANADO' || strEtapa== 'PERDIDO','clsDesactive car3':  (strEtapa =='PROPUESTA ECONOMICA'||strEtapa =='RECORRIDO'||strEtapa =='PRESENTACION'||strEtapa =='POR CONTACTAR')}"
                        (click)="fnCheckValidaciones('negociacion')">
                        <div class="col-12 text-center textTitulo m-auto">Negociación</div>
                        <!-- <div class="col- text-center textTitulo">1 Días</div> -->
                    </div>
                    <div class="col-2 mt-2 d-flex py-2  border-start-0 border-end-0 position-relative"
                        [ngClass]="{'clsActive cardisActive': strEtapa=='GANADO' || strEtapa== 'PERDIDO', 'clsDesactive2 carddeactive2 ':  strEtapa !='GANADO' && strEtapa!= 'PERDIDO'}"
                        (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">

                        <div class="col-12 text-center m-auto">Cierre</div>
                        <!-- <div class="col- text-center">1 Días</div> -->
                        <div class="card d-flex flex-column position-absolute top-100 start-50 translate-middle mt-4 p-2 bg-ligth zind "
                            *ngIf="inthov &&  strEtapa!='GANADO' || strEtapa== 'PERDIDO'" (click)="fnModalCerrar();"
                            style="z-index: 1000">
                            <button (click)="fnEstatusCierre('GANADO')"
                                class="btn btn-outline-primary text-nowrap fs11 mb-2" >
                                <i class="bi bi-hand-thumbs-up-fill"></i> PROSPECTO GANADO
                            </button><!--  *ngIf="intFlagAvanza4 == 1" -->
                            <button class="btn btn-outline-primary text-nowrap fs11" data-bs-toggle="modal"
                                data-bs-target="#modalCierre" (click)="fnEstatusCierre('PERDIDO')">
                                <i class="bi bi-hand-thumbs-down-fill"></i> PROSPECTO PERDIDO
                            </button>

                            <button data-bs-toggle="modal"
                            data-bs-target="#modalCierre" id="btnCierreGanado" class="d-none">
                                boton de click cierre
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="d-none" id="botonConcluir" data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
        (click)="fnAbreModal_Tarea()"></button>
    <div class="row mt-2 d-flex">
        <div class="col-12 col-lg-3 col-md-6  d-flex flex-column">
            <div class="text-center mb-4 d-flex">
                <img *ngIf="strDBRutaLogo == ''" src="assets/detoportunidad/noDisponible.jpg" alt="Logotipo del Lead"
                    class=" rounded-circle w-50 m-auto ">
                <img *ngIf="strRutaLogo != ''" [src]="strRutaLogo" alt="Logotipo del Lead" class="m-auto"
                    style="object-fit: cover; border-radius: 15px;" width="150px">
                <img *ngIf="strDBRutaLogo != '' && strRutaLogo == ''" src="https://www.ecodeli.com/{{strDBRutaLogo}}"
                    alt="Logotipo del Lead" width="180px" style="object-fit: cover; border-radius: 15px;"
                    class="m-auto">
            </div>
            <div class="col-12 d-flex">
                <label class="btn btn-outline-secondary btn-sm btn-file text-sm w-25 m-auto mb-3">
                    <i class="fas fa-cloud-upload-alt mx-1 fs-5"></i>
                    <input (change)="cambiarImgLogo($event)" #fileLogo type="file" style="display: none;" required
                        accept="application/pdf,image/*">
                </label>
                <button (click)="fnAnexoMov()" class="btn btn-primary w-50 m-auto btn-sm mb-3"
                    *ngIf="strRutaLogo != ''">
                    <span class="material-symbols-outlined mt-1 mx-1 fs-6"> save </span> Guardar
                </button>
            </div>
            <div class="list-group col-12 mx-auto">
                <div class="card h-100">
                    <div class="card-header fw-bold">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>Información General</span>
                            <i class="bi bi-pencil-fill iconoEditar" *ngIf="intEditInfoGeneral==0"
                                (click)="editarInformacionGeneral()"></i>
                            <i class="fa fa-save fa-xs mx-2" *ngIf="intEditInfoGeneral==1" aria-hidden="true"
                                title="Guardar" (click)="saveContacto()"></i>
                            <i class="fa fa-trash fa-xs mx-2" *ngIf="intEditInfoGeneral==1" aria-hidden="true"
                                (click)="resetForm()"></i>
                        </div>
                    </div>
                    <div class="card-body bg-white" #ingoGralDiv>
                        <div class="row">
                          
                            <ol class="list-unstyled">
                                <li class="d-flex mb-3">
                                    <span class="me-auto">
                                        <i class="bi bi-clipboard mx-1"></i>
                                        <strong class="text-primary">Folio:</strong>
                                    </span>
                                    <span class="ms-auto text-dark fw-bold">
                                        {{intIDLeads}}
                                    </span>
                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-tag mx-1"></i><strong
                                            class="text-success">Clasificación:</strong></span>
                                    <span class="ms-auto text-dark fw-bold">{{strClasificacion}}</span>
                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-buildings mx-1"></i>Nombre Empresa:</span>
                                    <span class="ms-auto text-dark">{{strNombreEmpresa}}</span>
                                </li>
                                <form [formGroup]="myFormConctacto">
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-person mx-1"></i>Nombre Contacto:</span>


                                    <div *ngIf="intEditInfoGeneral==0">
                                        <span class="ms-auto text-dark">{{arrayContactosDetalle[0].strNombreContacto}}</span>
                                    </div>
                                    <div *ngIf="intEditInfoGeneral==1">
                                        <input type="text" placeholder="Nombre Contacto" class="form-control ms-auto"
                                            formControlName="strContacto_Nombre" (keydown)="fnBuscarContacto1($event)"
                                            (keypress)="fnBuscarContacto1($event)">
                                        <ul *ngIf="arrayAsigContacto1.length > 0" class="list-group mt-1 overflowy">
                                            <li class="list-group-item cursorPoint hver py-1 fs10"
                                                *ngFor="let contacto of arrayAsigContacto1"
                                                (click)="fnselecContacto(contacto)">
                                                {{contacto.strContacto_Nombre}} - {{ "<"+contacto.strContacto_Email+"> "}} - {{ "("+contacto.strContacto_Telefono+")" }}
                                            </li>
                                        </ul>
                                        <mat-error
                                            *ngIf="myFormConctacto.get('strContacto_Nombre').hasError('required') && myForm.get('strContacto_Nombre').touched"
                                            class="fs10">Campo Obligatorio</mat-error>
                                        <mat-error *ngIf="myFormConctacto.get('strContacto_Nombre').hasError('maxlength')"
                                            class="fs10">
                                            son como maximo 50 caracteres
                                        </mat-error>
                                    </div>

                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-person-check-fill mx-1"></i>Puesto:</span>
                                    <div *ngIf="intEditInfoGeneral==0">
                                        <span class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoPuesto}}</span>
                                    </div>
                                    <div *ngIf="intEditInfoGeneral==1">
                                        <input type="text" placeholder="Puesto Contacto" class="form-control  m-auto"
                                            formControlName="strContacto_Puesto">
                                        <mat-error
                                            *ngIf="myFormConctacto.get('strContacto_Puesto').hasError('required') && myForm.get('strContacto_Puesto').touched"
                                            class="fs10">Campo Obligatorio</mat-error>
                                        <mat-error *ngIf="myFormConctacto.get('strContacto_Puesto').hasError('maxlength')"
                                            class="fs10">
                                            son como maximo 50 caracteres
                                        </mat-error>
                                    </div>


                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-telephone-fill mx-1"></i>Teléfono:</span>
                                    <div *ngIf="intEditInfoGeneral==0">
                                        <span class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoTelefono}}</span>
                                    </div>
                                    <div *ngIf="intEditInfoGeneral==1">
                                        <input type="text" placeholder="Telefono Contacto" class="form-control ms-auto"
                                            formControlName="strContacto_Telefono" [maxlength]="10"
                                            (keydown)="fnBuscarContacto2($event)"
                                            (keypress)="fnBuscarContacto2($event)">
                                        <ul *ngIf="arrayAsigContacto2.length > 0" class="list-group mt-1 overflowy">
                                            <li class="list-group-item cursorPoint hver py-1 fs10"
                                                *ngFor="let contacto of arrayAsigContacto2"
                                                (click)="fnselecContacto(contacto)">
                                                {{contacto.strContacto_Nombre}} - {{ "<"+contacto.strContacto_Email+"> "}} - {{ "("+contacto.strContacto_Telefono+")" }}
                                            </li>
                                        </ul>
                                        <mat-error
                                            *ngIf="myFormConctacto.get('strContacto_Telefono').hasError('required') && myForm.get('strContacto_Telefono').touched"
                                            class="fs10">Campo Obligatorio</mat-error>
                                        <mat-error *ngIf="myFormConctacto.get('strContacto_Telefono').hasError('maxlength')"
                                            class="fs10"> son como maximo 10 caracteres
                                        </mat-error>
                                    </div>

                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-envelope mx-1"></i>E-mail:</span>
                                    <div *ngIf="intEditInfoGeneral==0">
                                        <span class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoEmail}}</span>
                                    </div>
                                    <div *ngIf="intEditInfoGeneral==1">
                                        <input type="email" placeholder="Email Contacto" class="form-control  m-auto"
                                            formControlName="strContacto_Email" (keydown)="fnBuscarContacto3($event)"
                                            (keypress)="fnBuscarContacto3($event)">
                                        <ul *ngIf="arrayAsigContacto3.length > 0" class="list-group mt-1 overflowy">
                                            <li class="list-group-item cursorPoint hver py-1 fs10"
                                                *ngFor="let contacto of arrayAsigContacto3"
                                                (click)="fnselecContacto(contacto)">
                                                {{contacto.strContacto_Nombre}} - {{ "<"+contacto.strContacto_Email+">"}} - {{ "("+contacto.strContacto_Telefono+")" }} </li>
                                        </ul>
                                        <mat-error
                                            *ngIf="myFormConctacto.get('strContacto_Email').hasError('required') && myForm.get('strContacto_Email').touched"
                                            class="fs10">Campo Obligatorio</mat-error>
                                        <mat-error *ngIf="myFormConctacto.get('strContacto_Email').hasError('maxlength')"
                                            class="fs10">
                                            son como maximo 50 caracteres
                                        </mat-error>
                                    </div>
                                </li>
                            </form>
                                <!-- <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-person mx-1"></i>Nombre Contacto:</span>
                                    <span
                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strNombreContacto}}</span>
                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-person-check-fill mx-1"></i>Puesto:</span>
                                    <span
                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoPuesto}}</span>
                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-telephone-fill mx-1"></i>Teléfono:</span>
                                    <span
                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoTelefono}}</span>
                                </li>
                                <li class="d-flex mb-3">
                                    <span class="me-auto"><i class="bi bi-envelope mx-1"></i>E-mail:</span>
                                    <span class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoEmail}}</span>
                                </li>
                                 --><li class="d-flex align-items-center mb-3">
                                    <span class="me-auto"><i class="bi bi-person-fill-check mx-1"></i>Agente:</span>
                                    <span class="ms-auto text-dark w-75">
                                        <select [disabled]="!boolAgente" name="agente" id="agente"
                                            class="form-select m-auto" [(ngModel)]="strAgenteLD">
                                            <option value="undefined">-Selecciona un Agente- </option>
                                            <option *ngFor="let item of arrayAgentes; let i = index"
                                                value="{{item.strAgente}}">{{item.strNombre}}</option>
                                        </select>
                                    </span>
                                </li>
                                <li class="d-flex align-items-center  mb-3">
                                    <span class="me-auto">
                                        <span class="material-symbols-outlined fs-6 mx-1">
                                            family_restroom
                                        </span>Familia:
                                    </span>
                                    <span class="ms-auto text-dark w-75 d-flex justify-content-center">
                                        <select name="familia" id="familia" class="form-select m-auto"
                                            [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                            [(ngModel)]="intID_Familia">
                                            <option value="0">-Selecciona un tipo-- </option>
                                            <option *ngFor="let item of arrayFamilias; let i = index"
                                                value="{{item.intID_Familia}}">{{item.strFamilia}} </option>
                                        </select>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Pendiente" *ngIf="intValidacionFamilia == 1">
                                            <i class="bi bi-exclamation-octagon-fill fs-5 text-warning"></i>
                                        </button>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Aceptado" *ngIf="intValidacionFamilia == 2">
                                            <i class="bi bi-check-circle-fill fs-5 text-success"></i>
                                        </button>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Cancelado" *ngIf="intValidacionFamilia == 3">
                                            <i class="bi bi-x-circle-fill fs-5 text-danger"></i>
                                        </button>
                                    </span>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <span class="me-auto">
                                        <i class="far fa-file-alt mx-1"></i>Tipo:
                                    </span>
                                    <span class="ms-auto text-dark w-75 d-flex justify-content-center">
                                        <select name="tipoLead" id="tipoLead" class="form-select m-auto"
                                            [disabled]="false" [(ngModel)]="strTipoLead">
                                            <option value="">-Selecciona un tipo-- </option>
                                            <option value="FAMILIA">FAMILIA </option>
                                            <option value="CLIENTE">CLIENTE </option>
                                            <option value="SUCURSAL">SUCURSAL </option>
                                        </select>
                                    </span>
                                </li>
                                <li class="d-flex align-items-center mb-1">
                                    <button class="btn btn-primary w-100" (click)="fuActualizarTipoLead()"> <span
                                            class="material-symbols-outlined mt-1 mx-1 fs-6 ">
                                            save </span>Guardar</button>
                                </li>
                                <li class="d-flex align-items-center mb-1">
                                    <button type="button" class="btn btn-primary mt-4 w-100" data-bs-toggle="modal"
                                        id="botonFormF" data-bs-target="#formModal" (click)="fnGetFormulario()">
                                        Llenar Formulario
                                    </button>
                                </li>
                            </ol>
                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <button class="d-none" id="botonCotizaciones" data-bs-toggle="modal" data-bs-target="#cotizacion"></button>
        <div class="col-md-6 col-12">
            <div class="card h-100">
                <ul class="nav nav-pills mb-3 clsNavBg pt-2" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link  active custom-inactive fs13" id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">Timeline</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link custom-inactive fs13" id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false" (click)="fnCleanSearch()">Actividades</button> <!--(click)="fngetActividades(intIDLeads);"-->
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link custom-inactive fs13" id="minutasTap-tab" data-bs-toggle="pill"
                            data-bs-target="#minutasTap" type="button" role="tab" aria-controls="minutasTap"
                            aria-selected="true">Minutas</button>
                    </li>
                    <!-- <li class="nav-item" role="presentation"> <button class="nav-link custom-inactive fs13" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Control de cambios</button> </li> -->
                </ul>
                <div class="tab-content px-3" id="pills-tabContent">
                    <div class="tab-pane fade show active " id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row mb-3 h-100">
                            <div class="col-12 d-flex align-items-center ">
                                <span class="clsInact pe-2">Registrar</span>
                                <span class="pe-1">
                                    <button
                                        class="btn btn-outline-primary  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrarAct('Correo');">
                                        <i class="bi bi-envelope pe-2"></i> <!--[disabled]="intFlagActividad === 0"-->
                                        Correo
                                    </button>
                                </span>
                                <span class="pe-1">
                                    <button
                                        class="btn btn-outline-primary d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrarAct('Llamada');">
                                        <i class="bi bi-telephone-fill pe-2"></i>
                                        Llamada
                                    </button>
                                </span>
                                <span class="pe-1">
                                    <button
                                        class="btn btn-outline-primary  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrarAct('Visita');">
                                        <span class="material-symbols-outlined fs13">
                                            handshake
                                        </span>
                                        Reunion
                                    </button>
                                </span>
                                <span>
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrarAct('Tarea');">
                                        <span class="material-symbols-outlined fs13">
                                            edit_note
                                        </span>
                                        Tarea
                                    </button>
                                </span>
                                <!--  <span class="ms-auto col-3 position-relative">
                                            <input type="text" class="form-control ps-4 fs12"
                                                placeholder="Filtrar Timeline">
                                            <i
                                                class="bi bi-funnel-fill position-absolute top-50 start-0 translate-middle-y ms-2"></i>
                                        </span> -->
                            </div>
                        </div>
                        <div class="timelinescroll mt-5">
                            <div class="col-12 mb-3" *ngIf="arrayOportTimeLine.length == 0">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <span class="fw-bold">SIN ACTIVIDADES</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-auto d-flex" *ngFor="let obj of arrayOportTimeLine; let ind = index;"
                                [ngClass]="{'mb-3':ind==(arrayOport.length - 1),'mt-3':ind == 0 }">
                                <div class="col-12 d-flex" data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                    (click)="fnmDLConAct(obj)"
                                    *ngIf="obj.strTipo!='Oportunidad' && obj.strTipo != 'Actualizacion'">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center"
                                                [ngClass]="{'clsbordeOport':obj.strTipo=='Oportunidad'&& obj.strEstatus!='POR AUTORIZAR','clsbordeTarea':obj.strTipo!=='Oportunidad'&& obj.strEstatus!='POR AUTORIZAR','border-warning':obj.strEstatus=='POR AUTORIZAR'}">
                                                <i class="bi px-2 py-1"
                                                    [ngClass]="{'clsIconOport bi-cash ':obj.strTipo=='Oportunidad'&& obj.strEstatus!='POR AUTORIZAR','clsIconTarea bi-check-square':obj.strTipo!=='Oportunidad'&& obj.strEstatus!='POR AUTORIZAR','text-warning bi-bell':obj.strEstatus=='POR AUTORIZAR'}"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                {{obj.strTitulo}}
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha Cierre:
                                                    {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                            <!--<div class="col-12 fs12">
                                                    {{obj.agente}}
                                                </div>
                                                <div class="col-12 fs12 clsInact">
                                                    {{obj.hora}}
                                                </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex"
                                    *ngIf="obj.strTipo =='Oportunidad' || obj.strTipo == 'Actualizacion'">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center"
                                                [ngClass]="{'clsbordeOport':obj.strTipo=='Oportunidad','clsbordeTarea':obj.strTipo!=='Oportunidad'&&obj.strEstatus!='POR AUTORIZAR','border-warning':obj.strEstatus=='POR AUTORIZAR'}">
                                                <i class="bi px-2 py-1"
                                                    [ngClass]="{'clsIconOport bi-cash ':obj.strTipo=='Oportunidad','clsIconTarea bi-check-square':obj.strTipo!=='Oportunidad'&&obj.strEstatus!='POR AUTORIZAR','text-warning bi-bell':obj.strEstatus=='POR AUTORIZAR' }"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                {{obj.strTitulo}}
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha Cierre:
                                                    {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                            <!--     <div class="col-12 fs12">
                                                    {{obj.agente}}
                                                </div>
                                                <div class="col-12 fs12 clsInact">
                                                    {{obj.hora}}
                                                </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                        <div class="col-12 mb-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Buscar</mat-label>
                                <input matInput [(ngModel)]="strBuscarAct" (keyup)="applyFilterTareasCte($event)" placeholder="ID,Tarea,etc..."
                                    #input>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="table-responsive mt-3">
                            <table class="table">
                                <thead>
                                    <tr class="encabezado">
                                        <th></th>
                                        <th></th>
                                        <th>Título</th>
                                        <th>Responsable</th>
                                        <th>Origen</th>
                                        <th>Fecha</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="arrayActividades.length == 0">
                                        <tr>
                                            <td colspan="7">
                                                <div class="card"
                                                    style="padding: 8px;text-align: center; font-weight: 600;">
                                                    <span>SIN DATOS</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngFor="let actividad of arrayActividades | filtersearch: strBuscarAct: ['strEstatus','strTitulo','strAgente','strNombreAgente','strOrigen']; let ind = index">
                                        <!-- Fila principal -->
                                        <tr>
                                            <td>
                                                <button class="btn btn-secondary py-0 px-2 ms-auto"
                                                    (click)="fnSeeRow(ind,actividad)">
                                                    <i class="bi bi-caret-down-fill fs13"
                                                        *ngIf="!actividad.seeSubAct"></i>
                                                    <i class="bi bi-caret-up-fill fs13"
                                                        *ngIf="actividad.seeSubAct"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <div class=" "
                                                        *ngIf="actividad.strEstatus != 'POR AUTORIZAR' && actividad.strEstatus != 'PROCESO'">
                                                        <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                            disabled [checked]="actividad.strEstatus == 'CONCLUIDO'"
                                                            type="checkbox" id="customRadioCheckbox">
                                                    </div>
                                                    <div class=" text-center" *ngIf="actividad.strEstatus == 'POR AUTORIZAR'">
                                                        <button type="button"
                                                            class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                class="fa-solid fa-bell"></i></button>
                                                    </div>
                                                    <div class=" text-center" *ngIf="actividad.strEstatus == 'PROCESO'">
                                                        <button type="button"
                                                            class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                            title="En Proceso"><i
                                                                class="fa-solid fa-circle-half-stroke"></i></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(actividad)">
                                                <span class="mx-auto flex-grow-1 text-center fw-bold">{{ actividad.strTitulo
                                                    }}</span>
                                            </td>
                                            <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(actividad)">
                                                <span class="rounded-circle p-2 fs11 fw-bold text-center"
                                                    [ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                    {{actividad.strAgente}}

                                                </span>
                                                <span class="ps-1 fs11 fw-bold">
                                                    {{actividad.strNombreAgente}}
                                                </span>
                                            </td>
                                            <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(actividad)" class="fs12 fw-bold">{{ actividad.strOrigen }}</td>
                                            <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(actividad)">
                                                <span class="fw-bold px-2 d-flex fw-bold"
                                                    matTooltip="{{actividad.strEstatus}}"
                                                    [ngClass]="{'badFecha':actividad.diasDiferencia>-1 || (actividad.diasDiferencia<0 && actividad.strEstatus != 'PENDIENTE'),'badFechamenor':actividad.diasDiferencia<0 && actividad.strEstatus == 'PENDIENTE'}">
                                                    <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                    {{actividad.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                </span>
                                            </td>
                                            <td>

                                                <button class="btn  my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                    <i class="bi bi-three-dots-vertical"></i>
                                                  </button>
                                                  <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                    <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                    <button mat-menu-item data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                                    (click)="fnModalRegistrarSubtarea('Subtarea', actividad);">
                                                      <mat-icon class="text-success">note_add</mat-icon>
                                                      <span class="text-success">SubTarea </span>
                                                    </button>
                                                    <button mat-menu-item  data-bs-toggle="modal" data-bs-target="#mdlEditar"
                                                    (click)="fnEditar_Actividad(actividad)">
                                                      <mat-icon class="txtInfo">edit</mat-icon>
                                                      <span class="txtInfo">Editar</span>
                                                    </button>
                                                    <button mat-menu-item *ngIf="actividad.strEstatus == 'PENDIENTE'"
                                                    (click)="fnDelete_Tarea(actividad)">
                                                
                                                        <mat-icon color="warn">delete</mat-icon>
                                                        <span class="text-danger">Eliminar</span>
                                                      </button>
                                                  </mat-menu>
                                            </td>
                                        </tr>

                                        <!-- Tabla secundaria cuando seeSubAct es true -->
                                        <tr *ngIf="actividad.seeSubAct">
                                            <td colspan="7" class="p-0">
                                                <div class="container-fluid bg-light">
                                                    <div class="row justify-content-end">
                                                        <div class="col-11 py-2 ">
                                                            <div class="table-responsive mb-0">
                                                                <table class="table text-center table-striped">
                                                                    <thead>
                                                                        <tr class="subActivid">
                                                                       
                                                                            <th></th>
                                                                            <th>Título</th>
                                                                            <th>Responsable</th>
                                                                            <th>Origen</th>
                                                                            <th>Fecha</th>
                                                                            <th>Acciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <ng-container *ngFor="let subActividad of arraySubTareas; let inds= index">
                                                                            <tr>
                                                                              <!--   <td data-bs-toggle="modal"
                                                                                    data-bs-target="#MConcluirActividades"
                                                                                    (click)="fnmDLConAct(subActividad)">
                                                                                    <button
                                                                                        class="btn btn-warning text-white py-0 px-2">
                                                                                        <i class="bi bi-dash"></i>
                                                                                    </button>
                                                                                </td> -->
            
                                                                                <td>
                                                                                    <div class="d-flex justify-content-center">
                                                                                        <div class=" "
                                                                                            *ngIf="subActividad.strEstatus != 'POR AUTORIZAR' && subActividad.strEstatus != 'PROCESO'">
                                                                                            <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                                                disabled [checked]="subActividad.strEstatus == 'CONCLUIDO'"
                                                                                                type="checkbox" id="customRadioCheckbox">
                                                                                        </div>
                                                                                        <div class=" text-center" *ngIf="subActividad.strEstatus == 'POR AUTORIZAR'">
                                                                                            <button type="button"
                                                                                                class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                                                    class="fa-solid fa-bell"></i></button>
                                                                                        </div>
                                                                                        <div class=" text-center" *ngIf="subActividad.strEstatus == 'PROCESO'">
                                                                                            <button type="button"
                                                                                                class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                                                title="En Proceso"><i
                                                                                                    class="fa-solid fa-circle-half-stroke"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
            
                                                                                
                                                                                <td data-bs-toggle="modal"
                                                                                    data-bs-target="#MConcluirActividades"
                                                                                    (click)="fnmDLConAct(subActividad)">{{
                                                                                    subActividad.strTitulo }}</td>
                                                                                <td data-bs-toggle="modal"
                                                                                    data-bs-target="#MConcluirActividades"
                                                                                    (click)="fnmDLConAct(subActividad)">
                                                                                    <span
                                                                                        class="rounded-circle p-2 fs11 fw-bold text-center"
                                                                                        [ngClass]="{'res1':inds % 2 ==0,'res2':inds % 2 !=0}">
                                                                                        {{subActividad.strAgente}}
                                                                                    </span>
                                                                                    <span class="ps-1 fs11">
                                                                                        {{subActividad.strNombreAgente}}
                                                                                    </span>
                                                                                </td>
                                                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades" class="fs11" (click)="fnmDLConAct(subActividad)">{{subActividad.strOrigen }}</td>
                                                                                <td data-bs-toggle="modal"
                                                                                    data-bs-target="#MConcluirActividades"
                                                                                    (click)="fnmDLConAct(subActividad)">
                                                                                    <span class="fw-bold px-2 d-flex fs11"
                                                                                        matTooltip="{{subActividad.strEstatus}}"
                                                                                        [ngClass]="{'badFecha':subActividad.diasDiferencia>-1 || (subActividad.diasDiferencia<0 && subActividad.strEstatus != 'PENDIENTE'),'badFechamenor':subActividad.diasDiferencia<0 && subActividad.strEstatus == 'PENDIENTE'}">
                                                                                        <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                                                        {{subActividad.strFechaRequerida|date:'dd/MM/yyyy'}}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <button class="btn my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                                                        <i class="bi bi-three-dots-vertical"></i>
                                                                                      </button>
                                                                                      <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                                                        <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                                                        <button mat-menu-item  data-bs-toggle="modal"
                                                                                        data-bs-target="#mdlEditar"
                                                                                        (click)="fnEditar_Actividad(subActividad)">
                                                                                          <mat-icon class="txtInfo">edit</mat-icon>
                                                                                          <span class="txtInfo">Editar</span>
                                                                                        </button>
                                                                                        <button mat-menu-item *ngIf="subActividad.strEstatus == 'PENDIENTE'"
                                                                                        (click)="fnDelete_Tarea(subActividad)">
                                                                                    
                                                                                            <mat-icon color="warn">delete</mat-icon>
                                                                                            <span class="text-danger">Eliminar</span>
                                                                                          </button>
                                                                                      </mat-menu>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <!-- <div class="col-12">

                            <div class="mb-0 table-responsive">
                                <div class="mat-elevation-z8 mb-0 mt-3 shadow rounded">
                                    <table mat-table [dataSource]="datosTareasCte" class="table table-hover" matSort
                                        #sortTareasCte="matSort">
                                        
                                        <ng-container matColumnDef="strEstatus">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13">
                                            </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="px-2 fs11 text-center align-middle">
                                                <div class="d-flex justify-content-center">
                                                    <div class=" "
                                                        *ngIf="row.strEstatus != 'POR AUTORIZAR' && row.strEstatus != 'PROCESO'">
                                                        <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                            disabled [checked]="row.strEstatus == 'CONCLUIDO'"
                                                            type="checkbox" id="customRadioCheckbox">
                                                    </div>
                                                    <div class=" text-center" *ngIf="row.strEstatus == 'POR AUTORIZAR'">
                                                        <button type="button"
                                                            class="btn btn-outline-warning px-2 py-1 shadow-sm"
                                                            title="Por Autorizar"><i
                                                                class="fa-solid fa-bell"></i></button>
                                                    </div>
                                                    <div class=" text-center" *ngIf="row.strEstatus == 'PROCESO'">
                                                        <button type="button"
                                                            class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                            title="En Proceso"><i
                                                                class="fa-solid fa-circle-half-stroke"></i></button>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                       
                                        <ng-container matColumnDef="strTitulo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13">
                                                Titulo </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="mostDet fw-bold ps-0 fs13 text-start align-middle">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span>{{row.strTitulo|uppercase}}</span>
                                                    <div class="mostDet1 me-2">
                                                        <span class="d-flex align-items-center">
                                                            <mat-icon class="text-secondary">more_vert</mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                      
                                        <ng-container matColumnDef="strOrigen">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13"> Origen
                                            </th>
                                            <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle">
                                                {{row.strOrigen}} </td>
                                        </ng-container>
                                      
                                        <ng-container matColumnDef="strAgente">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13">
                                                Responsable </th>
                                            <td mat-cell *matCellDef="let row;let ind=index"
                                                class="fs12 text-start align-middle">
                                                <div class="d-flex align-items-center">
                                                    <span class="rounded-circle p-2 fs11 fw-bold text-center"
                                                        [ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                        {{row.strAgente}}
                                                       
                                                    </span>
                                                    <span class="ps-1 fs11">
                                                        {{row.strNombreAgente}}
                                                    </span>
                                                </div>
                                            </td>
                                        </ng-container>
                                      
                                        <ng-container matColumnDef="strFechaRequerida">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0">*
                                                Fecha </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="fs12 text-center align-middle pe-0">
                                                <div class="col-12 d-flex align-items-center justify-content-center">
                                                    <span class="fw-bold px-2 d-flex" matTooltip="{{row.strEstatus}}"
                                                        [ngClass]="{'badFecha':row.diasDiferencia>-1 || (row.diasDiferencia<0 && row.strEstatus != 'PENDIENTE'),'badFechamenor':row.diasDiferencia<0 && row.strEstatus == 'PENDIENTE'}">
                                                        <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                        {{row.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                    </span>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row class="rounded" *matHeaderRowDef="columnsTareasCte"></tr>
                                        <tr mat-row *matRowDef="let row; columns: columnsTareasCte;"
                                            data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                            (click)="fnmDLConAct(row)"></tr>
                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="6">No se encontraron datos de:
                                                "{{input.value}}"</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50, 100]"
                                aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
                        </div> -->

                    </div>
                    <div class="tab-pane fade" id="minutasTap" role="tabpanel" aria-labelledby="minutasTap-tab">
                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <button type="button" class="btn btn-minuta col-12 mb-3" data-bs-toggle="modal" data-bs-target="#modalAddMinuta" (click)="fnAddMinuta()">
                                    <i class="fa-solid fa-plus"></i> 
                                    Crear Nueva Minuta
                                </button>
                                
                                <div class="mt-3 fs14 d-flex flex-column">
                                    <b>Bienvenido</b>
                                    <span class="mt-3">
                                        Minutas Ecodeli, permite documentar los temas más relevantes de las reuniones.
                                    </span>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div *ngFor="let minuta of arrayMinuta;let ind = index" class="col-12 col-xxl-6">
                                        <div class="bordertop borderbott mb-2 shadow-sm p-3 border borderMinBlue pointer" (click)="fnDetalleMinuta(minuta)" [ngClass]="{'mt-2':ind>1,'borderMinBlue': ind % 2 === 0, 'borderMinYellow': ind % 2 !== 0}"><!-- [ngClass]="{ 'par-class': i % 2 === 0, 'impar-class': i % 2 !== 0 }" style="width: 18rem;"  -->
                                            <div class="">
                                                <div class="col-12 text-center">
                                                    <p class="card-text fw-bold text-dark text-center fs14">{{minuta.strTitulo}}</p>
                                                </div> 
                                                <div class="col-12 d-flex justify-content-between my-3">
                                                    <div class="col-6 d-flex flex-column">
                                                        <h4 class="mb-0">Fecha Inicio</h4>
                                                        <h4 class="card-title fw-bold mb-0">{{minuta.strFechaInicioServicio }}</h4> 
                                                    </div>
                                                    <div class="col-6 d-flex flex-column text-end">
                                                        <h4 class="mb-0">Hora Inicio</h4>
                                                        <h4 class="mb-0 text-secondary fw-bold">{{minuta.strHoraInicio}}</h4>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between">
                                                    <button class="btn btn-outline-info py-1 px-2">
                                                        <i class="bi bi-pencil-square"></i>
                                                    </button>
                                                    <button class="btn btn-outline-danger py-1 px-2" (click)="deleteMinuta(minuta, $event)">
                                                        <i class="fa-solid fa-trash-can "></i>
                                                    </button>
                                                </div>
                                               <!--  <a href="#" class="card-link me-2">Card link</a>
                                                <a href="#" class="card-link bg-danger btn"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-12 d-flex">
            <div class="card border-0 mb-2 mx-auto d-flex p-1 h-100" style="background: #fafafa;">
                <div class="card mb-3 p-3 d-flex"
                    *ngIf="strEtapa == 'GANADO' && strTipoLead != '' && strFase != 'CONCLUIDO'"
                    style="background: linear-gradient(135deg, #485f7d, #6c8fb5); border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border: 1px solid rgb(226, 226, 226)">
                    <div class="d-flex align-items-center">
                        <span class="me-auto text-white fw-bold">GENERAR ALTA DE {{strTipoLead}}</span>
                        <ng-container *ngIf='strTipoLead == "CLIENTE"'>
                            <button (click)="fnEnviarAltaCliente()"
                                class="btn btn-lg text-dark btn-outline-light alta ms-auto btn-sm mx-1 px-4 py-2">Ir</button>
                        </ng-container>
                        <ng-container
                            *ngIf='(strTipoLead == "SUCURSAL" || strTipoLead == "FAMILIA") && strFase != "CONCLUIDO"'>
                            <button data-bs-toggle="modal" data-bs-target="#enviarAlta"
                                class="btn btn-lg text-dark btn-outline-light alta ms-auto btn-sm mx-1 px-4 py-2">Ir</button>
                        </ng-container>
                    </div>
                </div>

                <!--<i class="fa fa-user mx-1"></i>-->
                <div class="d-flex align-items-center">
                    <h4 class="clsInact ps-3 pt-2 mb-0 fw-bold me-auto">SUEÑOS</h4>
                    <button class="btn btn-primary ms-auto btn-sm mx-1" data-bs-toggle="modal"
                        data-bs-target="#nuevaOportunidad" (click)="fnFormOportunidades()"><i
                            class="fas fa-plus mx-1"></i>Agregar</button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 scrollOportunidades">
                            <div class="card" *ngIf="arrayOportunidades.length == 0">
                                <div class="card-header text-center">
                                    <span class="fw-bold">SIN DATOS</span>
                                </div>
                            </div>
                            <div class="col-12 " [style.max-height.px]="altInfo" style="overflow-y: auto;"
                                *ngIf="arrayOportunidades.length > 0">
                                <div class="card mb-2 shadow-sm cursor"
                                    (click)="enviarDetalleOporturnidad(item.strEstatus,item)"
                                    *ngFor="let item of arrayOportunidades; let i = index">
                                    <div class="card-header">
                                        <span class="fw-bold">{{item.strTitulo}}</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 d-flex">
                                                <span class="badge bg-primary ms-auto">{{item.strEstatus}}</span>
                                            </div>
                                            <div class="col-3 d-flex">
                                                <div class="circle">
                                                    <span
                                                        class="text">{{item.strABNombre}}</span><!-- *ngIf="item.strAgente == ''" -->
                                                </div>
                                            </div>
                                            <div class="col-9 text-center row d-flex">
                                                <h5 class="fw-bold text-secondary m-auto"
                                                    style="white-space: initial; font-size: 11px">
                                                    {{item.strNombreAgente}}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-12 d-flex">
                                                <div class="me-auto">
                                                    <span>{{item.dblImporte | currency:'':'symbol':'1.2-2' }}</span>
                                                </div>
                                                <div class="ms-auto">
                                                    <span><i class="fas fa-calendar-alt"></i>
                                                        {{(item.strFechaCierre_Estimada).split(' ')[0]}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row mt-3 mb-5 d-flex">
        <div class="col-12 card m-auto">
            <mat-tab-group>
                <!--  <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-info-square mx-2 iconoTabs"></i> <span class="m-auto">Leads</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid p-4 rounded">
                        <form [formGroup]="formLead">
                            <div class="row mt-3">
                                <div class="col-md-4 col-lg-4 col-12 p-2">
                                    <span class="fw-bold fs11">
                                        <span class="text-danger">*</span> Nombre Empresa:
                                    </span>
                                    <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                        [(ngModel)]="strNombreEmpresa" formControlName="strNombreEmpresa">
                                    <mat-error *ngIf="formLead.get('strNombreEmpresa').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11">
                                        <span class="text-danger">*</span> Nombre Corto:
                                    </span>
                                    <input type="text" placeholder="Ingresa Nombre Corto" class="form-control"
                                        formControlName="strNombreCorto" [(ngModel)]="strNombreCorto">
                                    <mat-error
                                        *ngIf="formLead.get('strNombreCorto').hasError('required') && formLead.get('strNombreCorto').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="formLead.get('strNombreCorto').hasError('maxlength')"
                                        class="fs10">
                                        son como maximo 60 caracteres</mat-error>
                                </div>

                                <div class="col-sm-12 d-block p-2"
                                    [ngClass]="{'col-md-4': arraySubGiro.length < 2, 'col-md-6': arraySubGiro.length > 1}">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Sector :
                                    </span>
                                    <select name="sector" id="sector" class="form-select" formControlName="strSector"
                                        [(ngModel)]="strSector">
                                        <option selected value="">-Selecciona una opcion-</option>
                                        <option [value]="s.strSector" *ngFor="let s of arraySectores; let i = index">
                                            {{s.strSector}} ({{s.strClasificacion}})</option>
                                    </select>
                                    <mat-error
                                        *ngIf="formLead.get('strSector').hasError('required') && formLead.get('strSector').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="formLead.get('strSector').hasError('maxlength')" class="fs10">
                                        son
                                        como maximo 20 caracteres</mat-error>
                                </div>
                                <div class="col-sm-12 d-block p-2"
                                    [ngClass]="{'col-md-4': arraySubGiro.length < 2, 'col-md-6': arraySubGiro.length > 1}">
                                    <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> Giro: </span>
                                    <select name="giro" id="giro" class="form-select" formControlName="intId_Giro"
                                        [(ngModel)]="intGiro" (change)="fnFiltrarSegundaLetra()">
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option *ngFor="let item of arrayGiros; let i = index"
                                            [value]="item.intId_Giro">
                                            {{item.strGiro}}
                                        </option>
                                    </select>
                                    <mat-error
                                        *ngIf="formLead.get('intId_Giro').hasError('required') && formLead.get('intId_Giro').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2" *ngIf="arraySubGiro.length > 1">
                                    <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> SubGiro:
                                    </span>
                                    <select name="giro" id="giro" class="form-select" formControlName="intId_subGiro">
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option *ngFor="let item of arraySubGiro; let i = index"
                                            [value]="item.intId_subGiro">
                                            {{item.strTitulo}}
                                        </option>
                                    </select>
                                    <mat-error
                                        *ngIf="formLead.get('intId_subGiro').hasError('required') && formLead.get('intId_subGiro').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Presencia en el
                                        pais: </span>
                                    <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                        (change)="fnFiltrarSegundaLetra()" [(ngModel)]="intIDPresencia"
                                        formControlName="intIDPresencia">
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option [value]="item.intIDPresencia"
                                            *ngFor="let item of arrayPresencia; let i = index">
                                            {{item.strPresencia}}
                                        </option>
                                    </select>
                                    <mat-error
                                        *ngIf="formLead.get('intIDPresencia').hasError('required') && formLead.get('intIDPresencia').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2"
                                    *ngIf="arrayFitroSegunda.length > 0 || intId_segunda || intId_segunda > 0">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Numero de Habitaciones
                                    </span>
                                    <input type="text" class="form-control" formControlName="intId_segunda"
                                        placeholder="Ingresa Cantidad de habitaciones" [(ngModel)]="intId_segunda">
                                    <mat-error
                                        *ngIf="formLead.get('intId_segunda').hasError('required') && formLead.get('intId_segunda').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2"
                                    *ngIf="arrayFiltroTercera.length > 0 || intId_tercera || intId_tercera > 0">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Numero de Ubicaciones
                                    </span>
                                    <input type="text" class="form-control" formControlName="intId_tercera"
                                        placeholder="Ingresa Cantidad de Ubicaciones" [(ngModel)]="intId_tercera">
                                    <mat-error
                                        *ngIf="formLead.get('intId_tercera').hasError('required') && formLead.get('intId_tercera').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>

                                <div class="col-md-4 col-sm-12 d-block p-2"
                                    *ngIf="intPotenciador == 1 || strPotencializador != ''">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Lineas:</span>
                                    <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Seleccione:
                                    </button>
                                    <ul class="dropdown-menu w-25">
                                        <li *ngFor="let j of arrayPotenciador">
                                            <div class="form-check ms-1">
                                                <input class="form-check-input" [checked]="j.Seleccionado"
                                                    type="checkbox" [value]="j.intID_linea"
                                                    (change)="toggleMultiple(j.strLinea)" id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {{j.strLinea}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngFor="let select of arrayPotenciador ; let i = index">
                                        <span *ngIf="select.Seleccionado"
                                            class="badge bg-primary text-white p-1 mb-2 mx-1">{{select.strLinea}}</span>
                                    </ng-container>
                                </div>


                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Familia
                                    </span>
                                    <div class="d-flex align-items-center">
                                        <select [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                            class="form-select" [(ngModel)]="intID_Familia"
                                            formControlName="intID_Familia">
                                            <option value="0">--Selecciona una opción--</option>
                                            <option *ngFor="let item of arrayFamilias; let i = index"
                                                [value]="item.intID_Familia">{{item.strFamilia}}</option>
                                        </select>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Pendiente" *ngIf="intValidacionFamilia == 1">
                                            <i class="bi bi-exclamation-octagon-fill fs-5 text-warning"></i>
                                        </button>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Aceptado" *ngIf="intValidacionFamilia == 2">
                                            <i class="bi bi-check-circle-fill fs-5 text-success"></i>
                                        </button>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Cancelado" *ngIf="intValidacionFamilia == 3">
                                            <i class="bi bi-x-circle-fill fs-5 text-danger"></i>
                                        </button>
                                    </div>
                                    <mat-error *ngIf="formLead.get('intID_Familia').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Fecha Estimada Cierre
                                    </span>
                                    <input type="date" class="form-control" min="{{strFechaEstimadaCierreInput}}"
                                        value="{{strFechaEstimadaCierreInput}}" (change)="cambiarFecha($event)">
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Razon Social
                                    </span>
                                    <input type="text" placeholder="Razón Social" class="form-control"
                                        [(ngModel)]="strRazonSocial" formControlName="strRazonSocial">
                                    <mat-error *ngIf="formLead.get('strRazonSocial').hasError('required')"
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> RFC
                                    </span>
                                    <input type="text" placeholder="RFC" class="form-control" [(ngModel)]="strRFC"
                                        formControlName="strRFC" [maxlength]="13">
                                    <mat-error *ngIf="formLead.get('strRFC').hasError('required')"
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <label class="form-label">Tipo Cotización:</label> <span
                                        *ngIf="!banderaCotizaciones" class="text-sm text-danger">* Campo
                                        Obligatorio</span>
                                    <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Seleccione:
                                    </button>
                                    <ul class="dropdown-menu w-25">
                                        <li *ngFor="let j of arrayTipoCotizacion">
                                            <div class="form-check ms-1">
                                                <input class="form-check-input" [checked]="j.Seleccionado"
                                                    type="checkbox" [value]="j.Seleccionado"
                                                    (change)="toggleCotizacion(j.strTipoCotizacion)"
                                                    id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {{j.strTipoCotizacion}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngFor="let j of arrayTipoCotizacion; let i = index">
                                        <span *ngIf="j.Seleccionado"
                                            class="badge bg-primary text-white p-1 mb-2 mx-1">{{j.strTipoCotizacion}}</span>
                                    </ng-container>
                                    <mat-error *ngIf="arrayTipoCotizacion.length == 0 " class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">

                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> ¿Por cuál medio nos conoció?
                                    </span>
                                    <input type="text" placeholder="VENDEDOR" class="form-control" readonly
                                        [(ngModel)]="strMedioConocio" formControlName="strMedioConocio">
                                    <mat-error *ngIf="formLead.get('strMedioConocio').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-12 d-flex">
                                    <button type="button"
                                        class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                        (click)="SubirAnexos()" [disabled]="banderaContacto">
                                        <span class="material-icons-outlined me-1">save</span>
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab> -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels">
                                <i class="bi bi-geo-alt mx-2 iconoTabs"></i> <span>Dirección</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-4">
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">RFC: </label>
                                <input type="text" placeholder="ingrese RFC" class="form-control m-auto"
                                    [(ngModel)]="strRFC" >
                            </div>
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Codigo Postal: </label>
                                <input type="text" placeholder="codigo postal" class="form-control m-auto"
                                    [(ngModel)]="strCodigoPostal" (keyup.enter)="fnGetDatosFiscales()">
                            </div>
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Estado: </label>
                                <input [readonly]="true" type="text" placeholder="Estado" class="form-control  m-auto"
                                    [(ngModel)]="strEstado">
                            </div>
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Delegación: </label>
                                <input readonly [(ngModel)]="strDelegacion" type="text" placeholder="Delegación"
                                    class="form-control  m-auto">
                            </div>
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Colonia: </label>
                                <select name="probabilidad" id="probabilidad" class="form-select"
                                    [(ngModel)]="strColonia">
                                    <option *ngFor="let item of arrayColonias; let i = index"
                                        value="{{item.strColonia}}">{{item.strColonia}}
                                    </option>
                                    <option value="otraColonia">Otra</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Calle: </label>
                                <input type="text" placeholder="Calle" class="form-control  m-auto"
                                    [(ngModel)]="strCalle">
                            </div>
                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Entre Calles: </label>
                                <input [(ngModel)]="strEntreCalles" type="text" placeholder="Entre Calles"
                                    class="form-control  m-auto">
                            </div>

                            <div class="col-md-4 col-sm-12 mb-3">
                                <label class="form-label">Numero Exterior: </label>
                                <input type="text" placeholder="No. Exterior " class="form-control  m-auto"
                                    [(ngModel)]="strNumeroExterior">
                            </div>


                            <div class="col-md-4 col-sm-12 mb-3">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="form-label">Telefono: </label>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" placeholder="Lada" class="form-control"
                                            [(ngModel)]="strLadaEmpresa">
                                    </div>
                                    <div class="col-9">
                                        <input type="text" placeholder="Telefono" class="form-control"
                                            [(ngModel)]="strTelefonoEmpresa">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12 mb-2">
                                <label class="form-label">Zona: </label>
                                <select name="zona" id="zona" class="form-select" [(ngModel)]="strZona">
                                    <option *ngFor="let item of arrayZonas; let i = index" value="{{item.strZona}}">
                                        {{item.strZona}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-sm-12" *ngIf="banderaColonia">
                                <label class="form-label">Otra colonia: </label>
                                <input type="text" placeholder="Otra colonia" class="form-control  m-auto"
                                    [(ngModel)]="strOtraColonia">
                            </div>
                            <div class="col-12 d-flex mb-4">
                                <button type="button"
                                    class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                    (click)="fnUpdateLead_Nuevo()" [disabled]="banderaContacto">
                                    <span class="material-icons-outlined me-1">save</span>
                                    <span>Guardar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels">
                                <i class="bi bi-currency-dollar mx-2 iconoTabs"></i> <span>Cotizaciones</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="d-flex align-items-center mt-3">
                        <span (click)="fnCotizador()" data-bs-dismiss="modal"
                            class="btn btn-primary d-flex align-items-center mx-3">
                            <i class="fa-solid fa-plus"></i> Nueva Cotización
                        </span>
                    </div>
                    <div class="table-responsive mt-3">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr style="background: #071c34 !important; color: white;">
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>FECHA</th>
                                    <th>MONTO</th>
                                    <th>ESTATUS</th>
                                    <th *ngIf="strEtapa != 'PERDIDO'">ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayCotizaciones.length == 0">
                                    <td colspan="5" class="text-center">SIN DATOS</td>
                                </tr>
                                <tr *ngFor="let item of arrayCotizaciones; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td data-bs-toggle="modal" data-bs-target="#detalleCotizacion"
                                        class="fw-bold cursor d-flex" (click)="fnGetDetalleCotizacion(item)">
                                        <span class="m-auto d-flex">
                                            <i class="bi bi-lightning-fill text-warning fs-6"
                                                *ngIf="item.strEstatus == 'POR AUTORIZAR'"></i>
                                            <i class="bi bi-x-circle-fill text-danger fs-6"
                                                *ngIf="item.strEstatus == 'RECHAZADO'"></i>
                                            <i class="bi bi-check-circle-fill text-success fs-6"
                                                *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                            <span class="mx-2">{{item.intIDMovimientoVenta}}</span>
                                        </span>

                                    </td>
                                    <td>{{ item.strFecha }}</td>
                                    <td>{{ item.dblImporte | currency }}</td>
                                    <td><span class="badge " [ngClass]="{
                                        'text-bg-danger': item.strEstatus === 'RECHAZADO',
                                        'text-bg-success': item.strEstatus === 'AUTORIZADO',
                                        'text-bg-secondary': item.strEstatus === 'POR AUTORIZAR'    }">
                                            {{ item.strEstatus }}
                                        </span></td>

                                    <td class="d-flex" *ngIf="strEtapa != 'PERDIDO'">
                                        <!--[ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"-->
                                        <div class="m-auto">

                                            <button
                                                *ngIf="((strEtapa != 'NEGOCIACION') && item.strEstatus == 'AUTORIZADO' ) || (strEtapa == 'NEGOCIACION' && (item.strEstatus == 'AUTORIZADO' ))"
                                                [disabled]="item.strEstatusCliente == 'ACEPTADO' || item.strEstatusCliente == 'RECHAZADO'"
                                                [ngClass]="{'btn-outline-success': item.strEstatusCliente == 'ACEPTADO', 
                                                ' btn-outline-danger': item.strEstatusCliente == 'RECHAZADO',
                                                 ' btn-outline-dark': (!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')}"
                                                class=" dropdown-toggle text-sm btn " data-bs-toggle="dropdown"
                                                role="button" aria-haspopup="true" aria-expanded="false">
                                                <i class="bi bi-x-circle"
                                                    *ngIf="item.strEstatusCliente == 'RECHAZADO'"></i>
                                                <!--<i class="bi bi-check-circle"   *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>-->
                                                <i class="fa-solid fa-circle-check"
                                                    *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>

                                                <i class="fa-regular fa-circle-question"
                                                    *ngIf="(!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')"></i>
                                            </button>
                                            <!--(click)="stopPropagation4(i, 'miDropdownMenu')"-->
                                            <div #dropdownMenu (click)="stopPropagation2($event)"
                                                id="miDropdownMenu{{i}}"
                                                class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh">
                                                <div class="container-fluid">
                                                    <div class="row mt-2">
                                                        <div class="col-12 mt-1">
                                                            <div class="row">
                                                                <div class="col-8 d-flex justify-content-between">

                                                                    <select name="accioness"
                                                                        [(ngModel)]="strEstatusCotizacion" id="acciones"
                                                                        class="form-select" [(ngModel)]="strResultado">
                                                                        <option value="">
                                                                            --Selecciona una opción--</option>
                                                                        <option value="ACEPTADO">
                                                                            ACEPTADO</option>
                                                                        <option value="RECHAZADO">
                                                                            RECHAZADO
                                                                        </option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-3 d-flex">
                                                                    <button
                                                                        class="btn btn-primary btn-sm  text-white ms-auto mt-auto fs-6 d-flex justify-content-center alight-items-center "
                                                                        (click)="fnsetEstatus_Cotizacion(item.intIDMovimientoVenta)">
                                                                        <span
                                                                            class="material-symbols-outlined mt-1 fs-6">
                                                                            save </span>Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- COPIADO DE COTIZACION-->
                                            <button
                                                *ngIf="item.strEstatus=='AUTORIZADO' || item.strEstatus=='RECHAZADO'"
                                                class="btn btn-outline-primary  mx-2"
                                                (click)="fnAddCar_Cotizacion(item,'COPIA')" title="Copiar Cotización">
                                                <i class="fa-solid fa-copy"></i>
                                            </button>
                                            <button *ngIf="item.strEstatusCliente=='POR AUTORIZAR' "
                                                class="btn btn-outline-info  mx-2"
                                                (click)="fnAddCar_Cotizacion(item,'EDITAR')" title="Editar Cotización">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button *ngIf="item.strEstatus=='AUTORIZADO' "
                                                class="btn btn-outline-success  mx-2"
                                                (click)="fnAddCar_Cotizacion(item,'VER')" title="Ver Cotización">
                                                <i class="bi bi-eye-fill"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>

                <mat-tab label="Sucursales" *ngIf="strTipoLead == 'FAMILIA' || strTipoLead == 'CLIENTE'">
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels">
                                <i class="bi bi-building mx-2 iconoTabs"></i> <span>Sucursales</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid mb-5">
                        <div class="row">
                            <div class="col-12 d-flex mt-2 mb-0 ">
                                <ul class="nav nav-pills align-items-center d-flex col-12" id="pills-tab"
                                    role="tablist">
                                    <li class="nav-item " role="presentation">
                                        <button class="nav-link active me-3 shadow-sm" id="pills-home-tab"
                                            data-bs-toggle="pill" data-bs-target="#ctes" type="button" role="tab"
                                            aria-controls="pills-home" aria-selected="false"
                                            aria-selected="true">Listado Sucursales</button>
                                    </li>

                                    <li class="nav-item " role="presentation">
                                        <button class="nav-link shadow-sm" id="prospectos-tab" data-bs-toggle="pill"
                                            data-bs-target="#prospectos" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">Subir
                                            Sucursales</button>
                                    </li>
                                    <li class="nav-item ms-auto" role="presentation">
                                        <button class="btn shadow-sm btn-outline-primary" type="button"
                                            (click)="fnlimpiarmdlSuc()" data-bs-toggle="modal"
                                            data-bs-target="#mdlManualSuc">
                                            <i class="bi bi-plus-circle-fill"></i>
                                            Sucursal
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 px-0 mx-0 ">
                                <div class="tab-content" id="pills-tabContent">
                                    <!-- comienza la tab de listado de sucursales -->
                                    <div class="tab-pane fade container-fluid show active" id="ctes" role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        <div class="row mt-4">
                                            <div class="col-12 table-responsive">
                                                <table class="table table-bordered"
                                                    style="background: white; font-size: 14px;">
                                                    <thead style="background: #071C34; color: white">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col" *ngIf="strTipoLead == 'FAMILIA'">Razon
                                                                Social</th>
                                                            <th scope="col" *ngIf="strTipoLead == 'FAMILIA'">Agente</th>
                                                            <!--  <th scope="col">NombreAgente</th> -->
                                                            <!-- <th scope="col">Tipo_Cliente</th> -->
                                                            <th scope="col">Nombre Sucursal</th>
                                                            <th scope="col">Agente Sucursal</th>
                                                            <!-- <th scope="col">Agente Sucursal</th> -->
                                                            <!-- <th scope="col">Tipo_Sucursal</th> -->

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="arrayListadoSucursales.length == 0">
                                                            <td colspan="13">
                                                                <div class="card">
                                                                    <div class="card-body d-flex">
                                                                        <span class="m-auto">SIN DATOS</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr *ngFor="let item of arrayListadoSucursales; let i = index">
                                                            <th>{{ i + 1 }}</th>
                                                            <td *ngIf="strTipoLead == 'FAMILIA' && shouldDisplayRowspan(arrayListadoSucursales, 'strNombreEmpresa_Cliente', i)" 
                                                                [attr.rowspan]="getRowSpan(arrayListadoSucursales, 'strNombreEmpresa_Cliente')[item.strNombreEmpresa_Cliente]">
                                                              {{ item.strNombreEmpresa_Cliente }}
                                                            </td>
                                                            <td *ngIf="strTipoLead == 'FAMILIA' && shouldDisplayRowspan(arrayListadoSucursales, 'strNombreAgente', i)" 
                                                                [attr.rowspan]="getRowSpan(arrayListadoSucursales, 'strNombreEmpresa_Cliente')[item.strNombreEmpresa_Cliente]">
                                                              ({{ item.strAgente_sucursal }}) {{ item.strNombreAgente }}
                                                            </td>
                                                            <td *ngIf="shouldDisplayRowspan(arrayListadoSucursales, 'strNombreEmpresa_Sucursal', i)" 
                                                                [attr.rowspan]="getRowSpan(arrayListadoSucursales, 'strNombreEmpresa_Sucursal')[item.strNombreEmpresa_Sucursal]">
                                                              {{ item.strNombreEmpresa_Sucursal }}
                                                            </td>
                                                            <td *ngIf="shouldDisplayRowspan(arrayListadoSucursales, 'strNombreAgente_sucursal', i)" 
                                                                [attr.rowspan]="getRowSpan(arrayListadoSucursales, 'strNombreEmpresa_Cliente')[item.strNombreEmpresa_Cliente]">
                                                              {{ item.strNombreAgente_sucursal }}
                                                            </td>
                                                          </tr> -->
                                                        <tr *ngFor="let item of arrayListadoSucursales; let i = index">
                                                            <th>
                                                                {{ i + 1 }}
                                                            </th>
                                                            <td *ngIf="strTipoLead == 'FAMILIA'">
                                                                {{ item.strNombreEmpresa_Cliente }}
                                                            </td>
                                                            <td *ngIf="strTipoLead == 'FAMILIA'">
                                                                ({{ item.strAgente_sucursal }}) {{ item.strNombreAgente
                                                                }}
                                                            </td>
                                                            <td>{{ item.strNombreEmpresa_Sucursal }}</td>
                                                            <td>
                                                                ({{ item.strAgente_sucursal }}) {{
                                                                item.strNombreAgente_sucursal }}
                                                            </td>
                                                        </tr>
                                                        <!-- <tr *ngFor="let item of arrayListadoSucursales; let i = index">
                                                            <th *ngIf="shouldDisplayField2(i, 'strNombreEmpresa_Cliente', 'strAgente_Cliente')"
                                                                [attr.rowspan]="calculateRowspan(i, 'strNombreEmpresa_Cliente', 'strAgente_Cliente')">
                                                                {{ i + 1 }}
                                                            </th>
                                                            <td *ngIf="shouldDisplayField2(i, 'strNombreEmpresa_Cliente', 'strNombreEmpresa_Cliente')"
                                                                [attr.rowspan]="calculateRowspan(i, 'strNombreEmpresa_Cliente', 'strNombreEmpresa_Cliente')">
                                                                {{ item.strNombreEmpresa_Cliente }}
                                                            </td>
                                                            <td *ngIf="shouldDisplayField2(i, 'strNombreEmpresa_Cliente', 'strAgente_Cliente')"
                                                                [attr.rowspan]="calculateRowspan(i, 'strNombreEmpresa_Cliente', 'strAgente_Cliente')">
                                                                ({{ item.strAgente_sucursal }}) {{ item.strNombreAgente
                                                                }}
                                                            </td>
                                                            <td rowspan="1">{{ item.strNombreEmpresa_Sucursal }}</td>
                                                            <td *ngIf="shouldDisplayField2(i, 'strAgente_sucursal', 'strNombreAgente_sucursal')"
                                                                [attr.rowspan]="calculateRowspan(i, 'strAgente_sucursal', 'strNombreAgente_sucursal')">
                                                                ({{ item.strAgente_sucursal }}) {{
                                                                item.strNombreAgente_sucursal }}
                                                            </td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- finaliza tab de listado de sucursales -->
                                    <!-- aqui comienza la tab de prospecto  -->
                                    <div class="tab-pane fade container-fluid" id="prospectos" role="tabpanel"
                                        aria-labelledby="prospectos-tab">
                                        <div class="row" *ngIf="strTipoLead === ''">
                                            <div class="col-12">
                                                <div class="card-header d-flex justify-content-center pt-0">
                                                    <span class="text-bold text-sm text-danger">Es necesario seleccionar
                                                        un tipo de lead</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="this.strTipoLead !== ''">
                                            <div class="col-md-6 col-12 mt-4  ">
                                                <button class="btn btn-primary my-2 form-control hob-text w-50"
                                                    *ngIf="excelData.length > 0" (click)="fileInput.click()">
                                                    <i class="fas fa-cloud-upload-alt mx-1"></i>
                                                    <span class="fw-bolder">Cargar Archivo..</span>
                                                </button>
                                                <input (change)="handleFileInput($event)" type="file" class="d-none"
                                                    accept=".xlsx, .xls, .xml" #fileInput />
                                            </div>
                                            <div class="col-md-6 col-12 mt-4 d-flex ">
                                                <div class="ms-auto" *ngIf="strTipoLead == 'FAMILIA'">
                                                    <button class="btn btn-success ms-auto"
                                                        (click)="fnDescargarFormatoFamilia()">
                                                        <i class="fas fa-cloud-download-alt mx-1"></i> Layout
                                                        Sucursales</button>
                                                </div>
                                                <div class="ms-auto" *ngIf="strTipoLead == 'CLIENTE'">
                                                    <button class="btn btn-success ms-auto"
                                                        (click)="fnDescargarFormatoCliente()">
                                                        <i class="fas fa-cloud-download-alt mx-1"></i> Layout
                                                        Sucursales</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex justify-content-center mt-5"
                                            *ngIf="excelData.length == 0">
                                            <div id="drop-area" (drop)="handleDrop($event)"
                                                (dragover)="handleDragOver($event)"
                                                (dragleave)="handleDragLeave($event)"
                                                class="col-7 bordeslin text-center"
                                                style="background: #f8f9fa; border-radius: 10px; padding: 30px;">
                                                <span class="material-symbols-outlined fCloud text-success"
                                                    [ngClass]="{ 'd-none': excelData?.length < 1 }">
                                                    done_outline
                                                </span>
                                                <span class="material-symbols-outlined fCloud text-secondary">
                                                    cloud_upload
                                                </span>
                                                <div class="col-12 d-flex justify-content-center">
                                                    <div class="col-6 p-2">
                                                        <!--  <label for="" *ngIf="nameArchivo != ''">{{nameArchivo}}</label> -->
                                                        <button
                                                            class="btn btn-outline-primary my-2 form-control hob-text"
                                                            (click)="fileInput2.click()">
                                                            <span class="fw-bolder">Cargar Archivo..</span>
                                                        </button>
                                                        <input (change)="handleFileInput($event)" type="file"
                                                            class="d-none" accept=".xlsx, .xls, .xml" #fileInput2 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4" *ngIf="excelData.length > 0">
                                            <div class="col-12 table-responsive">
                                                <table class="table table-bordered"
                                                    style="background: white; font-size: 14px;">
                                                    <thead style="background: #071C34; color: white">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th *ngIf="strTipoLead == 'FAMILIA'" scope="col">Razon
                                                                Social</th>
                                                            <th *ngIf="strTipoLead == 'FAMILIA'" scope="col">RFC</th>
                                                            <th scope="col">Nombre_Sucursal</th>
                                                            <th scope="col">Dirección</th>
                                                            <th scope="col">Dirección_Numero</th>
                                                            <th scope="col">Codigo_Postal</th>
                                                            <th scope="col">Colonia</th>

                                                            <th scope="col">Contacto_Nombre</th>
                                                            <th scope="col">Contacto_Puesto</th>
                                                            <th scope="col">Contacto_Email</th>
                                                            <th scope="col">Contacto_Telefono</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of excelData; let i = index">
                                                            <th scope="row">{{i + 1}}</th>
                                                            <td *ngIf="strTipoLead == 'FAMILIA'">{{item.RAZON_SOCIAL}}
                                                            </td>
                                                            <td *ngIf="strTipoLead == 'FAMILIA'">{{item.RFC}}</td>
                                                            <td>{{item.NOMBRE_SUCURSAL}}</td>
                                                            <td>{{item.DIRECCION}}</td>
                                                            <td>{{item.DIRECCION_NUMERO}}</td>
                                                            <td>{{item.CODIGO_POSTAL}}</td>
                                                            <td>{{item.COLONIA}}</td>
                                                            <td>{{item.CONTACTO_NOMBRE}}</td>
                                                            <td>{{item.CONTACTO_PUESTO}}</td>
                                                            <td>{{item.CONTACTO_EMAIL}}</td>
                                                            <td>{{item.CONTACTO_TELEFONO}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <button *ngIf="strTipoLead == 'FAMILIA'" class="btn btn-primary ms-auto"
                                                    (click)="fnInsertSucursales()">Guardar</button>
                                                <button *ngIf="strTipoLead == 'CLIENTE'" class="btn btn-primary ms-auto"
                                                    (click)="fnInsertSucursalesSucursales()">Guardar C</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-star mx-1 iconoTabs"></i> <span>Articulos Fav.</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3">
                            <!-- <div class="col-12 mt-2 d-flex justify-content-end alight-items-center">
                                <button class="btn btn-success"><i
                                        class="bi bi-file-earmark-excel mx-1"></i>Plantilla Articulos</button>
                            </div> -->
                            <div class="col-12 d-flex">
                                <span class="text-danger ms-auto fw-bold" style="font-size: 12px;">En este listado se
                                    muestran solo un top 5 de los articulos*</span>
                            </div>
                            <div class="col-12 mt-3">
                                <table mat-table [dataSource]="dataSource" matSort #sortArtFav="matSort" class="table">
                                    <ng-container matColumnDef="#">
                                        <th mat-header-cell *matHeaderCellDef class="px-3">
                                            <span class="mx-auto">#</span>
                                        </th>
                                        <td mat-cell *matCellDef="let i = index" class="gastoCell px-3 py-4"><b
                                                class="resalta">{{ i + 1 }}</b>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Imagen">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagen</th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell ">
                                            <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                                                alt="" class="p-3" width="100">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="strArticulo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                            Articulo</th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell fw-bold py-4">
                                            {{element.strArticulo}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="strDescripcion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                            Descripción</th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell text-start py-4">
                                            {{element.strDescripcion}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="intCotizaciones">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                            Cotizaciónes </th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                            <span class="cotizacionesC">{{element.intCotizaciones}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="dblCantidad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-3">
                                            Cantidad
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell px-3 py-4">
                                            <span class="cantidadC">{{element.dblCantidad}}</span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                </table>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-file-earmark-plus mx-1 iconoTabs"></i> <span
                                    class="m-auto">Anexos</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3 mb-3">

                            <div class="col-2 d-flex">
                                <button class="btn btn-warning m-auto text-white" data-bs-toggle="modal"
                                    data-bs-target="#nuevaCarpeta"><i class="fas fa-plus mx-2"></i>Nueva
                                    Carpeta</button>
                            </div>
                            <div class="col-2">

                            </div>
                            <div class="col-2">
                                <a href="https://www.ilovepdf.com/es/comprimir_pdf" target="_blank"
                                    rel="noopener noreferrer"
                                    class="btn btn-warning fs11 rounded-0  fw-bold me-2 shadow-sm">Comprimir PDF</a>
                            </div>
                        </div>
                        <div class="row">
                            <ul class="nav nav-tabs me-3" role="tablist">
                                <li class="nav-item" *ngFor="let c of arrayCarpetas; let i = index"
                                    (click)="seleccionarTab(i)">
                                    <a class="nav-link" [class.active]="i === tabSeleccionada">
                                        <i class="fas fa-folder mx-2 folder" [hidden]="i === tabSeleccionada"></i> <i
                                            class="fas fa-folder-open mx-2 folder" [hidden]="i !== tabSeleccionada"></i>
                                        {{ c.nombreCarpeta }}
                                    </a>
                                </li>
                            </ul>
                            <!-- Contenido de las pestañas -->
                            <div class="tab-content">
                                <div *ngFor="let c of arrayCarpetas; let i = index" class="tab-pane fade"
                                    [class.show]="i === tabSeleccionada" [class.active]="i === tabSeleccionada">
                                    <div class="row d-flex">
                                        <div class="col-md-6 col-12 h-100">
                                            <div class="card mt-3 card-anexos m-auto">
                                                <div class="card-body">
                                                    <div class="row mt-2">
                                                        <div class="col-8 d-flex justify-content-end ">
                                                            <div
                                                                class="input-group w-75 justify-content-center align-items-center">
                                                                <label class="input-group-text"
                                                                    for="inputGroupSelect01">Tipo Documento</label>
                                                                <select name="tipo" id="tipo" class="form-select"
                                                                    [(ngModel)]="strTipoAnexo">
                                                                    <option value="Contrato">Contrato</option>
                                                                    <option value="Materiales">Materiales</option>
                                                                    <option value="Cotización">Cotización</option>
                                                                    <option value="evidencia">Evidencia foto</option>
                                                                    <option value="Servicios especiales">Servicios
                                                                        especiales</option>
                                                                    <option value="Cotizacion Rapida">Cotizacion Rapida
                                                                    </option>
                                                                    <option value="Cotizacion por TYM">Cotizacion por
                                                                        TYM</option>
                                                                    <option value="Licitaciones">Licitaciones</option>
                                                                    <option value="InfoCotizacion">Información para
                                                                        cotizar</option>
                                                                    <option value="Convenio_Precios">Convenio Precios
                                                                    </option>
                                                                    <option value="Catalogo">Catalogo</option>
                                                                    <option value="Formatos">Formatos</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3 d-flex">
                                                            <div
                                                                class="m-auto d-flex justify-content-center align-items-center">
                                                                <label
                                                                    class="btn btn-secondary btn-sm btn-file text-sm">
                                                                    <i class="fas fa-plus"></i> Agregar Anexo
                                                                    <input
                                                                        (change)="agregarAnexo($event, c.nombreCarpeta)"
                                                                        #file type="file" style="display: none;"
                                                                        required
                                                                        accept=".xlsx, .pdf, .png, .jpg, .jpeg">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex mt-3 mb-3"
                                                            *ngIf="c.xmlDocumentos.length == 0">
                                                            <div class="card w-100  m-auto">
                                                                <div class="card-body text-center">
                                                                    <span>SIN DOCUMENTOS</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-4 d-block mb-3 mt-2 text-center"
                                                            *ngFor="let item of c.xmlDocumentos; let i = index">
                                                            <div *ngIf="item?.strTipo == 'xlsx'"
                                                                class="d-flex justify-content-center">
                                                                <div class="card ">
                                                                    <div class="card-body">
                                                                        <a>
                                                                            <svg class="svg_excel"
                                                                                xmsns="http://www.w3.org/2000/svg"
                                                                                class="w-75" viewBox="0 0 384 512">
                                                                                <path
                                                                                    d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <label class=" fs11">{{item.strNombre}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item.strTipo == 'pdf'"
                                                                class="d-flex justify-content-center">
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <a>
                                                                            <svg id="Capa_1"
                                                                                enable-background="new 0 0 512 512"
                                                                                viewBox="0 0 512 512"
                                                                                xmsns="http://www.w3.org/2000/svg">
                                                                                <g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                            fill="#f9f8f9" />
                                                                                        <path
                                                                                            d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                            fill="#dc4955" />
                                                                                        <path
                                                                                            d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                                            fill="#dc4955" />
                                                                                        <path
                                                                                            d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                                            fill="#c42430" />
                                                                                    </g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                                        <path
                                                                                            d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                                        <path
                                                                                            d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                                        <path
                                                                                            d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <label class="fs11">{{item?.strNombre}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item?.strTipo == 'jpg' || item?.strTipo == 'png' || item?.strTipo == 'jpeg' || item?.strTipo == 'jpg'"
                                                                class="d-flex justify-content-center">
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <a>
                                                                            <svg id="Capa_1" class="w-100"
                                                                                enable-background="new 0 0 512 512"
                                                                                viewBox="0 0 512 512"
                                                                                xmsns="http://www.w3.org/2000/svg">
                                                                                <g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                            fill="#f9f8f9" />
                                                                                        <path
                                                                                            d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                            fill="#a29aa5" />
                                                                                        <path
                                                                                            d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                                            fill="#9ec8fc" />
                                                                                        <path
                                                                                            d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                                            fill="#62a4fb" />
                                                                                        <path
                                                                                            d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                            fill="#9ec8fc" />
                                                                                        <path
                                                                                            d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                                            fill="#b9a1d3" />
                                                                                        <path
                                                                                            d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                                            fill="#9573bb" />
                                                                                        <path
                                                                                            d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                            fill="#b9a1d3" />
                                                                                        <circle cx="219.587"
                                                                                            cy="285.995" fill="#faf063"
                                                                                            r="21.578" />
                                                                                    </g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                                                        <path
                                                                                            d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                                                        <path
                                                                                            d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <label class="fs11">{{item?.strNombre}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 mt-2 d-flex">
                                                            <button type="button"
                                                                class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                                                (click)="SubirAnexos()">
                                                                <span class="material-icons-outlined me-1">save</span>
                                                                <span>Guardar</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 m-auto h-100 ">
                                            <div class="card card-anexos mt-3 text-center">
                                                <div class="row">
                                                    <div class="col-11 text-center p-2">
                                                        <h2 class="fw-bold">Anexos Registrados</h2>
                                                    </div>
                                                    <div class="col-12" *ngIf="c.xmlDocumentosBD.length == 0">
                                                        <span class="fw-bold">SIN DATOS</span>
                                                    </div>
                                                    <div class="col-md-4 col-lg-3 col-12 d-block mb-3 text-center"
                                                        *ngFor="let item of c.xmlDocumentosBD; let i = index">
                                                        <div *ngIf="item?.strTipo == 'xlsx'"
                                                            class="d-flex justify-content-center">
                                                            <div class="card m-auto" style="width: 97% !important;">
                                                                <div class="card-body">
                                                                    <a href="https://www.ecodeli.com/{{item.strUrl}}"
                                                                        target="_blank" rel="noopener noreferrer">
                                                                        <svg class="svg_excel"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            class="w-75" viewBox="0 0 384 512">
                                                                            <path
                                                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <label
                                                                        class="text-sm">{{item.strTipo_Documento}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.strTipo == 'pdf'"
                                                            class="d-flex justify-content-center">
                                                            <div class="card m-auto" style="width: 95% !important;">
                                                                <div class="card-body">
                                                                    <a href="https://www.ecodeli.com/{{item.strUrl}}"
                                                                        target="_blank" rel="noopener noreferrer">
                                                                        <svg id="Capa_1"
                                                                            enable-background="new 0 0 512 512"
                                                                            viewBox="0 0 512 512"
                                                                            xmsns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                        fill="#f9f8f9" />
                                                                                    <path
                                                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                        fill="#dc4955" />
                                                                                    <path
                                                                                        d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                                        fill="#dc4955" />
                                                                                    <path
                                                                                        d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                                        fill="#c42430" />
                                                                                </g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                                    <path
                                                                                        d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                                    <path
                                                                                        d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                                    <path
                                                                                        d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <label
                                                                        class="text-sm">{{item.strTipo_Documento}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item?.strTipo == 'jpg' || item?.strTipo == 'png' || item?.strTipo == 'jpeg' || item?.strTipo == 'jpg'"
                                                            class="d-flex justify-content-center">
                                                            <div class="card m-auto" style="width: 95% !important;">
                                                                <div class="card-body">
                                                                    <a href="https://www.ecodeli.com/{{item.strUrl}}"
                                                                        target="_blank" rel="noopener noreferrer">
                                                                        <svg id="Capa_1" class="w-100"
                                                                            enable-background="new 0 0 512 512"
                                                                            viewBox="0 0 512 512"
                                                                            xmsns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                        fill="#f9f8f9" />
                                                                                    <path
                                                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                        fill="#a29aa5" />
                                                                                    <path
                                                                                        d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                                        fill="#9ec8fc" />
                                                                                    <path
                                                                                        d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                                        fill="#62a4fb" />
                                                                                    <path
                                                                                        d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                        fill="#9ec8fc" />
                                                                                    <path
                                                                                        d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                                        fill="#b9a1d3" />
                                                                                    <path
                                                                                        d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                                        fill="#9573bb" />
                                                                                    <path
                                                                                        d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                        fill="#b9a1d3" />
                                                                                    <circle cx="219.587" cy="285.995"
                                                                                        fill="#faf063" r="21.578" />
                                                                                </g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                                                    <path
                                                                                        d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                                                    <path
                                                                                        d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <label
                                                                        class="text-sm">{{item.strTipo_Documento}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
<button class="d-none" id="btnModal" data-bs-toggle="modal" data-bs-target="#mdlRegistrar"></button>

<!-- Modal -->
<div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="formModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal d-flex">
                <h2 class="modal-title m-auto" id="formModalLabel">Formulario de Cliente</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mt-4">
                    <div class="col-12">
                        <mat-accordion>
                            <mat-expansion-panel class="mb-1" (opened)="panelOpenState = true"
                                style="background: rgba(253, 253, 253, 0.815);" (closed)="panelOpenState = false"
                                *ngFor="let item of arraySecciones; let i = index">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class=" container-fluid row ">
                                            <div class="col-11">
                                                {{item.strSeccion}}
                                            </div>
                                            <div class="col-1 d-flex">
                                                <span
                                                    class="m-auto text-sm">{{item.intTotalContestadas}}/{{item.intTotalPreguntas}}</span>
                                            </div>
                                        </div>
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <div class="container-fluid">
                                    <div class="row border-bottom  p-2 mb-1" style="background: white"
                                        *ngFor="let pregunta of item.preguntas; let m = index">
                                        <div class="col-12 p-1">
                                            {{m + 1}}.- {{pregunta.strPregunta}}
                                        </div>

                                        <div class="col-12" *ngIf="pregunta.strTipoRespuesta == 'LISTA'">
                                            <select name="s" id="s" class="form-select"
                                                [(ngModel)]="pregunta.strRespuesta"
                                                (change)="fnNumberPregunta($event, item)">
                                                <option value="">-Selecciona una opcion--</option>
                                                <option *ngFor="let resp of pregunta.arrayRespuestas" value="{{resp}}">
                                                    {{resp}}</option>
                                            </select>
                                        </div>
                                        <div class="col-12" *ngIf="pregunta.strTipoRespuesta == 'INPUT'">
                                            <input type="text" class="form-control"
                                                (input)="fnNumberPregunta($event, item)"
                                                placeholder="Introduce Respuesta" [(ngModel)]="pregunta.strRespuesta">
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel class="mb-1" (opened)="panelOpenState = true"
                                style="background: rgba(253, 253, 253, 0.815);" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class=" container-fluid row ">
                                            <div class="col-12">
                                                Información Prospecto
                                            </div>

                                        </div>
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <div class="container-fluid">
                                    <form [formGroup]="formLead">
                                        <div class="row mt-3">
                                            <div class=" col-12 p-2">
                                                <span class="fw-bold fs11">
                                                    <span class="text-danger">*</span> Nombre Empresa:
                                                </span>
                                                <input type="text" placeholder="Ingresa Nombre empresa"
                                                    class="form-control" [(ngModel)]="strNombreEmpresa"
                                                    formControlName="strNombreEmpresa">
                                                <mat-error
                                                    *ngIf="formLead.get('strNombreEmpresa').hasError('required') "
                                                    class="text-danger small">
                                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                                </mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2">
                                                <span class="fw-bold fs11">
                                                    <span class="text-danger">*</span> Nombre Corto:
                                                </span>
                                                <input type="text" placeholder="Ingresa Nombre Corto"
                                                    class="form-control" formControlName="strNombreCorto"
                                                    [(ngModel)]="strNombreCorto">
                                                <mat-error
                                                    *ngIf="formLead.get('strNombreCorto').hasError('required') && formLead.get('strNombreCorto').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                                <mat-error *ngIf="formLead.get('strNombreCorto').hasError('maxlength')"
                                                    class="fs10">
                                                    son como maximo 60 caracteres</mat-error>
                                            </div>

                                            <div class="col-12 d-block p-2">
                                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                    Sector :
                                                </span>
                                                <select name="sector" id="sector" class="form-select"
                                                    formControlName="strSector" [(ngModel)]="strSector">
                                                    <option selected value="">-Selecciona una opcion-</option>
                                                    <option [value]="s.strSector"
                                                        *ngFor="let s of arraySectores; let i = index">
                                                        {{s.strSector}}</option>
                                                </select>
                                                <mat-error
                                                    *ngIf="formLead.get('strSector').hasError('required') && formLead.get('strSector').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                                <mat-error *ngIf="formLead.get('strSector').hasError('maxlength')"
                                                    class="fs10">
                                                    son
                                                    como maximo 20 caracteres</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2">
                                                <span class="m-auto fw-bold fs11"><span class="text-danger">*</span>
                                                    Giro: </span>
                                                <select name="giro" id="giro" class="form-select"
                                                    formControlName="intId_Giro" [(ngModel)]="intGiro"
                                                    (change)="fnFiltrarSegundaLetra()">
                                                    <option value="0" selected>-Selecciona una opcion-</option>
                                                    <option *ngFor="let item of arrayGiros; let i = index"
                                                        [value]="item.intId_Giro">
                                                        {{item.strGiro}}
                                                    </option>
                                                </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                                <mat-error
                                                    *ngIf="formLead.get('intId_Giro').hasError('required') && formLead.get('intId_Giro').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2" *ngIf="arraySubGiro.length > 1">
                                                <span class="m-auto fw-bold fs11"><span class="text-danger">*</span>
                                                    SubGiro:
                                                </span>
                                                <select name="giro" id="giro" class="form-select"
                                                    formControlName="intId_subGiro">
                                                    <option value="0" selected>-Selecciona una opcion-</option>
                                                    <option *ngFor="let item of arraySubGiro; let i = index"
                                                        [value]="item.intId_subGiro">
                                                        {{item.strTitulo}}
                                                    </option>
                                                </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                                <mat-error
                                                    *ngIf="formLead.get('intId_subGiro').hasError('required') && formLead.get('intId_subGiro').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2">
                                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                    Presencia en el
                                                    pais: </span>
                                                <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                                    (change)="fnFiltrarSegundaLetra()" [(ngModel)]="intIDPresencia"
                                                    formControlName="intIDPresencia"><!--(change)="onGiroChange($event, 'presencia')"-->
                                                    <option value="0" selected>-Selecciona una opcion-</option>
                                                    <option [value]="item.intIDPresencia"
                                                        *ngFor="let item of arrayPresencia; let i = index">
                                                        {{item.strPresencia}}
                                                    </option>
                                                </select>
                                                <mat-error
                                                    *ngIf="formLead.get('intIDPresencia').hasError('required') && formLead.get('intIDPresencia').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2"
                                                *ngIf="arrayFitroSegunda.length > 0 && (intId_segunda !== null && intId_segunda !== undefined)">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> Numero de Habitaciones
                                                </span>
                                                <input type="text" class="form-control" formControlName="intId_segunda"
                                                    placeholder="Ingresa Cantidad de habitaciones"
                                                    [(ngModel)]="intId_segunda">
                                                <mat-error
                                                    *ngIf="formLead.get('intId_segunda').hasError('required') && formLead.get('intId_segunda').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2"
                                                *ngIf="arrayFiltroTercera.length > 0 && (intId_tercera !== null && intId_tercera !== undefined)">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> Numero de Ubicaciones
                                                </span>
                                                <input type="text" class="form-control" formControlName="intId_tercera"
                                                    placeholder="Ingresa Cantidad de Ubicaciones"
                                                    [(ngModel)]="intId_tercera">
                                                <mat-error
                                                    *ngIf="formLead.get('intId_tercera').hasError('required') && formLead.get('intId_tercera').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>

                                            <div class="col-12 d-block p-2"
                                                *ngIf="intPotenciador == 1 || strPotencializador != ''">
                                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                    Lineas:</span>
                                                <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Seleccione:
                                                </button>
                                                <ul class="dropdown-menu w-25">
                                                    <li *ngFor="let j of arrayPotenciador">
                                                        <div class="form-check ms-1">
                                                            <input class="form-check-input" [checked]="j.Seleccionado"
                                                                type="checkbox" [value]="j.intID_linea"
                                                                (change)="toggleMultiple(j.strLinea)"
                                                                id="flexCheckDefault">
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                {{j.strLinea}}
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ng-container *ngFor="let select of arrayPotenciador ; let i = index">
                                                    <span *ngIf="select.Seleccionado"
                                                        class="badge bg-primary text-white p-1 mb-2 mx-1">{{select.strLinea}}</span>
                                                </ng-container>
                                            </div>


                                            <div class="col-12 mb-3">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> Familia
                                                </span>
                                                <div class="d-flex align-items-center">
                                                    <select [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                                        class="form-select" [(ngModel)]="intID_Familia"
                                                        formControlName="intID_Familia">
                                                        <option value="0">--Selecciona una opción--</option>
                                                        <option *ngFor="let item of arrayFamilias; let i = index"
                                                            [value]="item.intID_Familia">{{item.strFamilia}}</option>
                                                    </select>
                                                    <button type="button" class="btn btn-light ms-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Pendiente" *ngIf="intValidacionFamilia == 1">
                                                        <i class="bi bi-exclamation-octagon-fill fs-5 text-warning"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light ms-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Aceptado" *ngIf="intValidacionFamilia == 2">
                                                        <i class="bi bi-check-circle-fill fs-5 text-success"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light ms-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Cancelado" *ngIf="intValidacionFamilia == 3">
                                                        <i class="bi bi-x-circle-fill fs-5 text-danger"></i>
                                                    </button>
                                                </div>
                                                <mat-error *ngIf="formLead.get('intID_Familia').hasError('required') "
                                                    class="text-danger small">
                                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                                </mat-error>
                                            </div>

                                            <div class="col-12 mb-3">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> Fecha Estimada Cierre
                                                </span>
                                                <input type="date" class="form-control"
                                                    [value]="strFechaEstimadaCierreInput"
                                                    (change)="cambiarFecha($event)">
                                            </div>

                                            <div class="col-12 mb-3" *ngIf="strTipoLead != 'FAMILIA'">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> Razon Social
                                                </span>
                                                <input type="text" placeholder="Razón Social" class="form-control"
                                                    [(ngModel)]="strRazonSocial" formControlName="strRazonSocial">
                                                <mat-error *ngIf="formLead.get('strRazonSocial').hasError('required')"
                                                    class="text-danger small">
                                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                                </mat-error>
                                            </div>

                                            <div class="col-12 mb-3" *ngIf="strTipoLead != 'FAMILIA'">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> RFC
                                                </span>
                                                <input type="text" placeholder="RFC" class="form-control"
                                                    [(ngModel)]="strRFC" formControlName="strRFC" [maxlength]="13">
                                                <mat-error *ngIf="formLead.get('strRFC').hasError('required')"
                                                    class="text-danger small">
                                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                                </mat-error>
                                            </div>

                                            <div class="col-12 mb-3">
                                                <label class="form-label">Tipo Cotización:</label> <span
                                                    *ngIf="!banderaCotizaciones" class="text-sm text-danger">* Campo
                                                    Obligatorio</span>
                                                <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Seleccione:
                                                </button>
                                                <ul class="dropdown-menu w-25">
                                                    <li *ngFor="let j of arrayTipoCotizacion">
                                                        <div class="form-check ms-1">
                                                            <input class="form-check-input" [checked]="j.Seleccionado"
                                                                type="checkbox" [value]="j.Seleccionado"
                                                                (change)="toggleCotizacion(j.strTipoCotizacion)"
                                                                id="flexCheckDefault">
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                {{j.strTipoCotizacion}}
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ng-container *ngFor="let j of arrayTipoCotizacion; let i = index">
                                                    <span *ngIf="j.Seleccionado"
                                                        class="badge bg-primary text-white p-1 mb-2 mx-1">{{j.strTipoCotizacion}}</span>
                                                </ng-container>
                                                <mat-error *ngIf="arrayTipoCotizacion.length == 0 "
                                                    class="text-danger small">
                                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                                </mat-error>
                                            </div>

                                            <div class="col-12 mb-3">

                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span> ¿Por cuál medio nos conoció?
                                                </span>
                                                <input type="text" placeholder="VENDEDOR" class="form-control" readonly
                                                    [(ngModel)]="strMedioConocio" formControlName="strMedioConocio">
                                                <mat-error *ngIf="formLead.get('strMedioConocio').hasError('required') "
                                                    class="text-danger small">
                                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                                </mat-error>
                                            </div>

                                            <!-- <div class="col-12 d-flex">
                                <button type="button"
                                    class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                    (click)="SubirAnexos()" [disabled]="banderaContacto">
                                    <span class="material-icons-outlined me-1">save</span>
                                    <span>Guardar</span>
                                </button>
                            </div> -->
                                        </div>
                                    </form>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button data-bs-dismiss="modal" class="btn btn-info ml-auto mx-2"
                    (click)="fnUpdateLead_Nuevo()">Guardar</button> <!--fnSETFORM_RESPUESTA()-->
            </div>
        </div>
    </div>
</div>

<!--Nueva Actividad-->
<div class="modal fade" tabindex="-1" id="nuevaActividad" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable  modal-lg">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-center bgtitModal">
                <h1 class="modal-title fw-bold">Nueva Actividad</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mdalCard">
                    <div class="col-8 mt-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Recordatorio</label>
                            <input type="datetime-local" class="form-control" [(ngModel)]="strFechaActividad">
                        </div>
                    </div>
                    <div class="col-4 mt-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Duración(min.)</label>
                            <input type="number" class="form-control" [(ngModel)]="intDuracionActividad">
                        </div>
                    </div>

                    <div class="col-8 mt-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Se planea hacer
                                (opcional)</label>
                            <textarea [(ngModel)]="strResultado" name="planea" id="planea" cols="5" rows="1"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="col-4 mt-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Tipo Actividad:</label>
                            <select class="form-select" [(ngModel)]="strTipoActividad"
                                (click)="stopPropagation2($event)">
                                <option value="" disabled selected>
                                    Selecciona
                                    una opción</option>
                                <!-- *ngFor="let opcion of opciones" -->
                                <option value="llamada" *ngIf="strEtapa != 'POR CONTACTAR'">
                                    llamada
                                </option>
                                <option value="correo">
                                    <!--*ngIf="strEtapa == 'POR CONTACTAR'"-->
                                    Correo
                                </option>
                                <option value="correo">
                                    <!--*ngIf="strEtapa != 'POR CONTACTAR'"-->
                                    Llamada
                                </option>
                                <option value="visita">
                                    <!--*ngIf="strEtapa != 'POR CONTACTAR'"-->
                                    Visita
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-info text-white ms-auto btn-sm" (click)="fnInsertActividades()"
                    data-bs-dismiss="modal">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nueva actividad -->
<div class="modal fade" id="nuevaOportunidad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="formOportunidades" (ngSubmit)="onSubmitOportunidades()">
                <div class="modal-header bgtitModal">
                    <h1 class="modal-title fs-5 mb-0" id="exampleModalLabel">Nuevo Sueño</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        #btnCerrarOportunidades></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mt-2 mb-2">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Nombre *:</label>
                                <input type="text" class="form-control" formControlName="strTitulo">
                                <mat-error *ngIf="formOportunidades.get('strTitulo').hasError('required')"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Monto *: </label><!-- totalImporte -->
                                <input type="number" class="form-control" formControlName="intMonto"
                                    readonly><!-- [(ngModel)]="dblMonto" -->
                                <span class="fs10 text-secondary">Se sumará según el monto añadido por línea.</span>
                                <mat-error *ngIf="formOportunidades.get('intMonto').hasError('required')"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Fecha Estimada Cierre *:</label>
                                <input type="date" formControlName="strFecha"
                                    class="form-control"><!-- [(ngModel)]="strFechaCierre" -->
                                <mat-error *ngIf="formOportunidades.get('strFecha').hasError('required')"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-12 ">
                            <mat-form-field class="example-full-width w-100">
                                <span matPrefix> </span>
                                <input type="text" class="px-3" matInput placeholder="Nombre del Responsable"
                                    formControlName="strResponsable"
                                    (keyup)="fnBuscarCte(formOportunidades.get('strResponsable')?.value)">
                                <!-- [(ngModel)]="strResponsable" -->
                                <button type="button" matSuffix class="btn btn-darkky d-flex align-items-center ">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-error *ngIf="formOportunidades.get('strResponsable2').hasError('required') "
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                            <ul *ngIf="arrayAsigAgentes.length > 0" class="list-group mt-1 overflowy">
                                <li class="list-group-item cursorPoint hver py-1 fs10"
                                    *ngFor="let age of arrayAsigAgentes" (click)="fnselecCliente(age)">
                                    {{age.strNombreAgente}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 d-flex bg-light py-2">
                            <div class="col-2 my-auto">
                                <h3 class="mb-2 fw-bold text-center">LINEAS</h3>
                            </div>
                            <div class="col-10">
                                <button type="button" class="btn py-0 px-1 text-white  col me-2 fs12 mb-1 shadow-sm"
                                    [ngClass]="{'bg-secondary':x.select==false,'bg-success':x.select==true}"
                                    (click)="fnAddLinea(x)" *ngFor="let x of arrayLineas2">
                                    {{x.strLinea}}
                                </button>
                            </div>

                        </div>
                        <div class="col-12" [hidden]="arrayLineasSelect.length>0">
                            <div class="alert alert-danger text-center fw-bold col-10 mx-auto py-2" role="alert">
                                ¡Seleccione líneas para continuar!
                            </div>
                        </div>
                        <div class="scrollAuto" [hidden]="arrayLineasSelect.length==0">
                            <div class="col-12  d-flex">
                                <span class="col-6 text-center bgSec fw-bold">
                                    LINEA
                                </span>
                                <span class="col-6 text-center bgSec fw-bold">
                                    IMPORTE
                                </span>
                            </div>


                            <div class="col-12 d-flex justify-content-between align-items-center border-bottom py-2"
                                *ngFor="let cet of arrayLineasSelect">
                                <div class="col-6">
                                    <label for="">{{cet.strLinea}}</label>
                                </div>
                                <div class="col-1 text-end">
                                    <span class="text-end">
                                        $
                                    </span>
                                </div>
                                <div class="col-5">
                                    <input type="number" class="form-control fs12 no-spin" [value]="cet.dblCantidad"
                                        (input)="updateCantidad($event, cet)" />
                                </div>
                            </div>
                        </div>
                        <!--  <div class="col-12 mt-2 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Nombre *:</label>
                            <input placeholder="Nueva Oportunidad" type="text" class="form-control"
                                [(ngModel)]="strNombreOportunidad">
                        </div>
                    </div>

                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Monto *:</label>
                            <input placeholder="Monto" type="number" class="form-control" [(ngModel)]="dblMonto">
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Fecha Estimada Cierre *:</label>
                            <input type="date" class="form-control" [(ngModel)]="strFechaCierre">
                        </div>
                    </div> -->
                        <!--  <div class="col-12 mb-2 mb-1">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Lineas *:</label>
                            <select class="form-select" [(ngModel)]="strLinea">
                                <option value="">-Selecciona una opcion--</option>
                                <option [value]="linea.strLinea" *ngFor="let linea of arrayLineas2">
                                    {{linea.strLinea}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Responsable"
                                (keyup)="fnBuscarCte(strResponsable);" name="search" [(ngModel)]="strResponsable">
                            <button matSuffix
                                class="btn btn-darkky d-flex align-items-center ">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                        <ul *ngIf="arrayAsigAgentes.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayAsigAgentes"
                                (click)="fnselecCliente(age)">
                                {{age.strNombreAgente}}
                            </li>
                        </ul>
                    </div> -->
                        <!--(click)="funArticulos(strBuscarArticulo);"-->
                        <!--          <div class="col-12">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Buscar Articulo"
                                (keyup)="funConsultarArt(strBuscarArticulo);" name="search"
                                [(ngModel)]="strBuscarArticulo">
                            <button matSuffix class="btn btn-darkky d-flex align-items-center "
                                (click)="funArticulos(strBuscarArticulo);">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mb-2">
                        <ul *ngIf="arrayArts.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayArts"
                                (click)="fnselecArticulo(age)">
                                ({{age.strArticulo}}) {{age.strDescripcion}}
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 table-responsive mt-4">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayArticuloAgregados.length == 0">
                                    <td colspan="4" class="text-center">
                                        <span>SIN DATOS</span>
                                    </td>
                                </tr>
                                <tr *ngFor="let art of arrayArticuloAgregados; let i = index">
                                    <td class="text-center">
                                        {{ i + 1 }}
                                    </td>
                                    <td>
                                        ({{ art.strArticulo }}) {{ art.strDescripcion }}
                                    </td>
                                    <td>
                                        <button class="btn btn-danger" (click)="fnEiminarProductos(i)"><i
                                                class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                    <!-- <button type="button" class="btn btn-primary" (click)="fnInsertOportunidad()"
                    data-bs-dismiss="modal">Guardar</button> -->
                    <button type="submit" class="btn btn-primary w-100"
                        [disabled]="!formOportunidades || formOportunidades.invalid || arrayLineasSelect.length==0">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="actividades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Agrega actividades para poder avanzar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 d-flex">
                        <button [disabled]="intFlagActividad === 0"
                            class="text-white dropdown-toggle text-sm btn btn-success d-flex justify-content-center align-items-center "
                            data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                            [ngClass]="{'btn-selected-hoy': intTiempoDias == 0 && intTiempoHoras > 0, 'btn-selected-dias': intTiempoDias > 0 }">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh" id="menuActividades"
                            (click)="stopPropagation2($event)">
                            <div class="container-fluid">
                                <div class="row mdalCard" *ngIf="banderaEspera">
                                    <div class="col-8">
                                        <span class="texto-Modal">Recordatorio</span>
                                    </div>
                                    <div class="col-4">
                                        <span class="texto-Modal">Duración(min.)</span>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-8">
                                                <input type="datetime-local" class="form-control"
                                                    [(ngModel)]="strFechaActividad">
                                            </div>
                                            <div class="col-4 d-block">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="intDuracionActividad">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <span class="texto-Modal">Se planea hacer
                                            (opcional)</span>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-8">
                                                <textarea [(ngModel)]="strResultado" name="planea" id="planea" cols="5"
                                                    rows="1" class="form-control"></textarea>
                                            </div>
                                            <div class="col-4">
                                                <select class="form-select" [(ngModel)]="strTipoActividad"
                                                    (click)="stopPropagation2($event)">
                                                    <option value="" disabled selected>
                                                        Selecciona
                                                        una opción</option>
                                                    <!-- *ngFor="let opcion of opciones" -->
                                                    <option value="llamada" *ngIf="strEtapa != 'POR CONTACTAR'">
                                                        llamada
                                                    </option>
                                                    <option value="correo">
                                                        <!--*ngIf="strEtapa == 'POR CONTACTAR'"-->
                                                        Correo
                                                    </option>
                                                    <option value="correo">
                                                        <!--*ngIf="strEtapa != 'POR CONTACTAR'"-->
                                                        Llamada
                                                    </option>
                                                    <option value="visita">
                                                        <!--*ngIf="strEtapa != 'POR CONTACTAR'"-->
                                                        Visita
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex mt-2 p-2">
                                        <button class="btn btn-info text-white ms-auto btn-sm"
                                            (click)="fnInsertActividades()">Guardar</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h5 class="fs-5 fw-bold me-auto d-flex align-items-center mx-2">
                            Nueva Actividad
                        </h5>
                    </div>

                    <div class="table-responsive mt-3 d-flex">
                        <table class="table table-bordered text-center tablass">
                            <thead class="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>Agente</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Comentario</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayActividades.length == 0">
                                    <td colspan="6" class="text-center">SIN DATOS</td>
                                </tr>
                                <tr *ngFor="let item of arrayActividades; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item.strAgente }}</td>
                                    <td>
                                        <i class="fas fa-envelope correo" *ngIf="item.strTipo == 'correo'"></i>
                                        <i class="fas fa-phone-alt" *ngIf="item.strTipo == 'llamada'"></i>
                                        <i class="fas fa-walking" *ngIf="item.strTipo == 'visita'"></i>
                                        <i class="far fa-star text-warning" *ngIf="item.strTipo == 'GANADO'"></i>
                                    </td>
                                    <td>{{ item.strFechaCierre_Estimada }}</td>
                                    <td>{{ item.strOpcion }}</td>
                                    <td>
                                        <button
                                            [ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"
                                            class=" text-white dropdown-toggle text-sm btn btn-sm  justify-content-center align-items-center  "
                                            data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false"><!--(click)="fnInitSummer(item)"-->
                                            <i class="bi bi-patch-question fs-6"
                                                *ngIf="item.strEstatus == 'CONCLUIDO'"></i>

                                            <i class="bi bi-exclamation-diamond fs-6"
                                                *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                        </button><!--[disabled]="item.strEstatus == 'CONCLUIDO'"-->
                                        <div (click)="stopPropagation2($event)"
                                            class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh"
                                            id="dropInsert">
                                            <div class="container-fluid">

                                                <div class="row mt-2">
                                                    <div class="col-12 mt-1">

                                                        <div class="row">
                                                            <!-- <div class="col-12 mb-2">
                                                        <input type="text" placeholder="Asunto"
                                                            class="form-control">
                                                    </div> -->
                                                            <div class="col-12 h-100 mb-2">
                                                                <div class="form-group h-100">
                                                                    <textarea [(ngModel)]="strComentario"
                                                                        placeholder="Escribe un comentario"
                                                                        class="custom-summernote h-100 summernote form-control"
                                                                        id="summernote{{item.intID_Actividad}}"
                                                                        #summernote></textarea>
                                                                </div>
                                                            </div>
                                                            <div class="col-8 d-flex mt-3">
                                                                <select name="accioness" id="acciones"
                                                                    class="form-select m-auto"
                                                                    [(ngModel)]="strResultado">
                                                                    <option value="">
                                                                        --Selecciona
                                                                        una opción--</option>
                                                                    <option value="1">
                                                                        EXITOSO</option>
                                                                    <option value="2">
                                                                        SIN EXITO
                                                                    </option>
                                                                    <!-- <option value="correo Enviado">
                                    Mail Enviado</option>
                                <option value="visita">Visita
                                    realizada</option> -->
                                                                </select>
                                                            </div>
                                                            <div class="col-4 d-flex mt-3">
                                                                <button
                                                                    class="btn btn-primary  text-white ms-auto mt-auto"
                                                                    (click)="fnupdate_Actividad()">Guardar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal Lead -->
<div class="modal fade" id="lead" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Llena los campos requeridos para poder avanzar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <form [formGroup]="formLead">
                            <div class="row mt-3">
                                <div class="col-md-4 col-lg-4 col-12 p-2">
                                    <span class="fw-bold fs11">
                                        <span class="text-danger">*</span> Nombre Empresa:
                                    </span>
                                    <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                        [(ngModel)]="strNombreEmpresa" formControlName="strNombreEmpresa">
                                    <mat-error *ngIf="formLead.get('strNombreEmpresa').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11">
                                        <span class="text-danger">*</span> Nombre Corto:
                                    </span>
                                    <input type="text" placeholder="Ingresa Nombre Corto" class="form-control"
                                        formControlName="strNombreCorto" [(ngModel)]="strNombreCorto">
                                    <mat-error
                                        *ngIf="formLead.get('strNombreCorto').hasError('required') && formLead.get('strNombreCorto').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="formLead.get('strNombreCorto').hasError('maxlength')"
                                        class="fs10">
                                        son como maximo 60 caracteres</mat-error>
                                </div>

                                <div class="col-sm-12 d-block p-2"
                                    [ngClass]="{'col-md-4': arraySubGiro.length < 2, 'col-md-6': arraySubGiro.length > 1}">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Sector :
                                    </span>
                                    <select name="sector" id="sector" class="form-select" formControlName="strSector"
                                        [(ngModel)]="strSector">
                                        <option selected value="">-Selecciona una opcion-</option>
                                        <option [value]="s.strSector" *ngFor="let s of arraySectores; let i = index">
                                            {{s.strSector}} ({{s.strClasificacion}})</option>
                                    </select>
                                    <mat-error
                                        *ngIf="formLead.get('strSector').hasError('required') && formLead.get('strSector').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="formLead.get('strSector').hasError('maxlength')" class="fs10">
                                        son
                                        como maximo 20 caracteres</mat-error>
                                </div>
                                <div class="col-sm-12 d-block p-2"
                                    [ngClass]="{'col-md-4': arraySubGiro.length < 2, 'col-md-6': arraySubGiro.length > 1}">
                                    <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> Giro: </span>
                                    <select name="giro" id="giro" class="form-select" formControlName="intId_Giro"
                                        [(ngModel)]="intGiro" (change)="fnFiltrarSegundaLetra()">
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option *ngFor="let item of arrayGiros; let i = index"
                                            [value]="item.intId_Giro">
                                            {{item.strGiro}}
                                        </option>
                                    </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                    <mat-error
                                        *ngIf="formLead.get('intId_Giro').hasError('required') && formLead.get('intId_Giro').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2" *ngIf="arraySubGiro.length > 1">
                                    <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> SubGiro:
                                    </span>
                                    <select name="giro" id="giro" class="form-select" formControlName="intId_subGiro">
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option *ngFor="let item of arraySubGiro; let i = index"
                                            [value]="item.intId_subGiro">
                                            {{item.strTitulo}}
                                        </option>
                                    </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                    <mat-error
                                        *ngIf="formLead.get('intId_subGiro').hasError('required') && formLead.get('intId_subGiro').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Presencia en el
                                        pais: </span>
                                    <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                        (change)="fnFiltrarSegundaLetra()" [(ngModel)]="intIDPresencia"
                                        formControlName="intIDPresencia"><!--(change)="onGiroChange($event, 'presencia')"-->
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option [value]="item.intIDPresencia"
                                            *ngFor="let item of arrayPresencia; let i = index">
                                            {{item.strPresencia}}
                                        </option>
                                    </select>
                                    <mat-error
                                        *ngIf="formLead.get('intIDPresencia').hasError('required') && formLead.get('intIDPresencia').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2"
                                    *ngIf="arrayFitroSegunda.length > 0 || intId_segunda">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Numero de Habitaciones
                                    </span>
                                    <input type="text" class="form-control" formControlName="intId_segunda"
                                        placeholder="Ingresa Cantidad de habitaciones" [(ngModel)]="intId_segunda">
                                    <mat-error
                                        *ngIf="formLead.get('intId_segunda').hasError('required') && formLead.get('intId_segunda').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2"
                                    *ngIf="arrayFiltroTercera.length > 0 || intId_tercera || intId_tercera > 0">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Numero de Ubicaciones
                                    </span>
                                    <input type="text" class="form-control" formControlName="intId_tercera"
                                        placeholder="Ingresa Cantidad de Ubicaciones" [(ngModel)]="intId_tercera">
                                    <mat-error
                                        *ngIf="formLead.get('intId_tercera').hasError('required') && formLead.get('intId_tercera').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>

                                <div class="col-md-4 col-sm-12 d-block p-2"
                                    *ngIf="intPotenciador == 1 || strPotencializador != ''">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Lineas:</span>
                                    <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Seleccione:
                                    </button>
                                    <ul class="dropdown-menu w-25">
                                        <li *ngFor="let j of arrayPotenciador">
                                            <div class="form-check ms-1">
                                                <input class="form-check-input" [checked]="j.Seleccionado"
                                                    type="checkbox" [value]="j.intID_linea"
                                                    (change)="toggleMultiple(j.strLinea)" id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {{j.strLinea}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngFor="let select of arrayPotenciador ; let i = index">
                                        <span *ngIf="select.Seleccionado"
                                            class="badge bg-primary text-white p-1 mb-2 mx-1">{{select.strLinea}}</span>
                                    </ng-container>
                                </div>


                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Familia
                                    </span>
                                    <div class="d-flex align-items-center">
                                        <select [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                            class="form-select" [(ngModel)]="intID_Familia"
                                            formControlName="intID_Familia">
                                            <option value="0">--Selecciona una opción--</option>
                                            <option *ngFor="let item of arrayFamilias; let i = index"
                                                [value]="item.intID_Familia">{{item.strFamilia}}</option>
                                        </select>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Pendiente" *ngIf="intValidacionFamilia == 1">
                                            <i class="bi bi-exclamation-octagon-fill fs-5 text-warning"></i>
                                        </button>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Aceptado" *ngIf="intValidacionFamilia == 2">
                                            <i class="bi bi-check-circle-fill fs-5 text-success"></i>
                                        </button>
                                        <button type="button" class="btn btn-light ms-2" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Cancelado" *ngIf="intValidacionFamilia == 3">
                                            <i class="bi bi-x-circle-fill fs-5 text-danger"></i>
                                        </button>
                                    </div>
                                    <mat-error *ngIf="formLead.get('intID_Familia').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Fecha Estimada Cierre
                                    </span>
                                    <input type="date" class="form-control" min="{{strFechaEstimadaCierreInput}}"
                                        value="{{strFechaEstimadaCierreInput}}" (change)="cambiarFecha($event)">
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Razon Social
                                    </span>
                                    <input type="text" placeholder="Razón Social" class="form-control"
                                        [(ngModel)]="strRazonSocial" formControlName="strRazonSocial">
                                    <mat-error *ngIf="formLead.get('strRazonSocial').hasError('required')"
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> RFC
                                    </span>
                                    <input type="text" placeholder="RFC" class="form-control" [(ngModel)]="strRFC"
                                        formControlName="strRFC" [maxlength]="13">
                                    <mat-error *ngIf="formLead.get('strRFC').hasError('required')"
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">
                                    <label class="form-label">Tipo Cotización:</label> <span
                                        *ngIf="!banderaCotizaciones" class="text-sm text-danger">* Campo
                                        Obligatorio</span>
                                    <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Seleccione:
                                    </button>
                                    <ul class="dropdown-menu w-25">
                                        <li *ngFor="let j of arrayTipoCotizacion">
                                            <div class="form-check ms-1">
                                                <input class="form-check-input" [checked]="j.Seleccionado"
                                                    type="checkbox" [value]="j.Seleccionado"
                                                    (change)="toggleCotizacion(j.strTipoCotizacion)"
                                                    id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {{j.strTipoCotizacion}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngFor="let j of arrayTipoCotizacion; let i = index">
                                        <span *ngIf="j.Seleccionado"
                                            class="badge bg-primary text-white p-1 mb-2 mx-1">{{j.strTipoCotizacion}}</span>
                                    </ng-container>
                                    <mat-error *ngIf="arrayTipoCotizacion.length == 0 " class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-md-6 col-lg-4 col-12 mb-3">

                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> ¿Por cuál medio nos conoció?
                                    </span>
                                    <input type="text" placeholder="VENDEDOR" class="form-control" readonly
                                        [(ngModel)]="strMedioConocio" formControlName="strMedioConocio">
                                    <mat-error *ngIf="formLead.get('strMedioConocio').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>

                                <div class="col-12 d-flex">
                                    <button type="button"
                                        class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                        (click)="SubirAnexos()" [disabled]="banderaContacto">
                                        <span class="material-icons-outlined me-1">save</span>
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Cotizacion -->
<div class="modal fade" id="cotizacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Agrega una cotizacion para poder avanzar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="d-flex align-items-center mt-3">
                            <span (click)="fnCotizador()" data-bs-dismiss="modal"
                                class="btn btn-success d-flex align-items-center m-auto">
                                <i class="fa-solid fa-plus"></i> Nueva Cotización
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Concluir Actividades-->
<div class="modal fade" id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal text-center d-flex">

                <span class=" text-white shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column  "  [ngClass]="{'bg-secondary':objAct?.strEstatus!= 'CONCLUIDO' && objAct?.strEstatus!= 'PROCESO' && objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.strEstatus== 'PENDIENTE'}">

                    <span class="fw-bold mx-auto fs12" >{{ objAct && objAct.strEstatus ?objAct.strEstatus:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                </span>

                <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">{{objAct?.strEstatus=='CONCLUIDO'?'Actividad Concluida':objAct?.strEstatus == 'POR AUTORIZAR'?'AUTORIZAR ACTIVIDAD':''}}
                    <span class="fw-bold">{{objAct?.strTitulo ? objAct?.strTitulo:objAct?.strOrigen}}</span>
                    <!-- <span *ngIf="objAct?.strEstatus=='PENDIENTE'"> para poder avanzar</span> -->
                </h1>


                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3" *ngIf="spineract">
                    <div class="col-12 d-flex justify-content-center">
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
                <!--  <div class="row mb-3" *ngIf="!spineract">
                    <div class="col-6 col-md-6 d-flex flex-column mt-2">
                        <span>Titulo</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct?.strTitulo}} </span>
                    </div>
                    <div class="col-6 col-md-6 d-flex flex-column mt-2">
                        <span>Asunto</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct?.strOpcion}}</span>
                    </div>
                </div> -->

                <div class="row justify-content-center mb-3" *ngIf="!spineract">


                    <div class="col-11">
                        <!-- *ngIf="objAct?.strEstatus=='CONCLUIDO'|| objAct?.strEstatus == 'POR AUTORIZAR' || objAct?.length == 0" -->
                        <div class="row">
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i>
                                        Responsable:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strNombreAgente?objAct.strNombreAgente:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por:
                                    </span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.nombreCrea?objAct.nombreCrea:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Inicio:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraInicio?objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Fin:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraFin?objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>

                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct&&objAct.strOpcion?objAct.strOpcion:'--Seleccione '+objAct?.strTipo+'--'"></span>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3"
                                *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="titIcon"><i
                                        class="bi bi-chat-dots-fill clsIconModal"></i>Obervaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objAct?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2"
                                *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="float-end titIcon"><i class="clsIconModal bi "
                                        [ngClass]="{'bi-hand-thumbs-up-fill':objAct?.strResultado == '1','bi-hand-thumbs-down-fill':objAct?.strResultado == '2','bi-info-circle-fill':objAct?.strResultado == '0'}"></i>
                                    Resultado</span>
                                <span *ngIf="objAct?.strResultado == '1'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objAct?.strResultado == '2'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                                <span *ngIf="objAct?.strResultado == '0'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN RESPUESTA</span>
                            </div>
                            <!--   <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span class="float-end">Resultado</span>
                                <span *ngIf="objDetActividad?.strResultado == '1'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objDetActividad?.strResultado == '2'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3">
                                <span>Obervaciones</span>
                                <span class="py-1 px-2 col-12 bg-light rounded shadow-sm"
                                    [innerHTML]="objDetActividad?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Inicio</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraInicio}}</span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Fin</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraFin}}</span>
                            </div> -->
                        </div>

                    </div>
                    <div class="row d-flex my-2" style="border-top: 2px dashed #b3abab;">
                        <div class="col-12  colorComentarios d-flex justify-content-center align-items-center      ">
                            <h2 class="m-auto">Comentarios</h2>
                        </div>
                        <div class="col-10 m-auto d-flex flex-column">
                            <textarea [(ngModel)]="strComentarioD" class="form-control mt-3" name="nombre" rows="3" cols="5"
                                placeholder="Escribe un comentario"></textarea>
                            <button class="btn btn-primary w-25 my-3 ms-auto" (click)="fnInsertComentario()">
                                <i class="fas fa-plus"></i> Agregar </button>
                        </div>
                        <div class="col-12">
                            <div class="notification-container">
                                <div class="notification" *ngFor="let item of arrayComentarios; let i = index">
                                    <div class="circle-icon">{{item.strAgente}}</div>
                                    <div class="notification-content">
                                        <div class="notification-header">{{item.strNombreAgente}}</div>
                                        <div class="notification-text">{{item.strComentario}}</div>
                                    </div>
                                    <div class="notification-time">{{item.strFechaRequerida}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
    
    
                    <hr>
                    <div class="col-12 py-3">
                        <div class="col-12 border">
                            <div class="col-12 px-0 ">
                                <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                    <span class=" fs13 mx-auto">ADJUNTOS</span>
                                </h3>
    
                            </div>
                            <div hidden class="col-12 px-0">
                                <input #fileModal type="file" (change)="onFileInsert($event)">
                            </div>
    
                            <div class="col-11 mx-auto">
                                <div class="row ">
                                    <div class="col-6 bg-light pb-2">
                                        <div class="col-12 d-flex py-2 justify-content-between">
                                            <span>Agregar</span>
                                            <button class="me-2 btn btnAdd py-0 px-2 shadow-sm"
                                                (click)="fnAjuntarAnexsModal()">
                                                <i class="bi bi-file-earmark-plus"></i>
                                            </button>
                                        </div>
                                        <div class="row" *ngIf="arrayAnexMdlAdd.length > 0">
                                            <div class="col-12 d-flex my-2" *ngFor="let anex of arrayAnexMdlAdd">
                                                <div class="col-6 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" target="_blank">
                                                        <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'"
                                                            class="bi bi-file-image fs-4 text-primary"></i>
                                                        <i *ngIf="anex.extencion == 'pdf'"
                                                            class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                        <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'"
                                                            class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                        <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'"
                                                            class="bi bi-file-zip fs-4"></i>
                                                        <span class="fs10 text-ellipsi">
                                                            {{anex.nombreArchivo}}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div class="col-6 d-flex justify-content-center align-items-center px-2">
                                                    <button class="btn btn-primary fs12 p-1 me-3"
                                                        (click)="fnAgregarArchivo()"><i class="bi bi-floppy"></i> <span
                                                            class="fs11">Guardar</span></button>
                                                    <button class="btn btn-danger p-1 fs12"
                                                        (click)="fnElinimarArchivos()"><i class="bi bi-trash3"></i> <span
                                                            class="fs11">Eliminar</span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center"
                                            *ngIf="arrayAnexMdlAdd.length == 0">
                                            <div class="col-11 bg-secondary text-center rounded text-white">
                                                Seleccione archivos para agregar.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="col-12 py-2">
                                            <span class="text-center mb-0">Existentes</span>
                                        </div>
                                        <div class="row" *ngIf="arrayAnexosModal.length > 0">
                                            <div class="col-4 d-flex" *ngFor="let anex of arrayAnexosModal">
                                                <div class="col-12 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}"
                                                        target="_blank">
                                                        <i *ngIf="anex.strTipo == 'jpg' || anex.strTipo == 'png'|| anex.strTipo == 'gif'"
                                                            class="bi bi-file-image fs-4 text-primary"></i>
                                                        <i *ngIf="anex.strTipo == 'pdf'"
                                                            class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                        <i *ngIf="anex.strTipo == 'xls' || anex.strTipo == 'xlsx'|| anex.strTipo == 'csv'"
                                                            class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                        <i *ngIf="anex.strTipo == 'zip' || anex.strTipo == 'rar'"
                                                            class="bi bi-file-zip fs-4"></i>
                                                        <span class="fs10 text-ellipsi">
                                                            {{anex.strNombreCorto}}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center"
                                            *ngIf="arrayAnexosModal.length == 0">
                                            <div class="col-11 bg-secondary text-center rounded text-white">
                                                Sin archivos agregados.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                   
                    <div class="col-12 h-100 my-3" [hidden]="!boolConcluir"

                        [ngClass]="{'visually-hidden':objAct?.strEstatus == 'CONCLUIDO'||objAct?.strEstatus == 'POR AUTORIZAR'}">
                        <div class="form-group h-100">
                            <button class="btn btn-success my-2" (click)="fnActividad()">
                                <i class="bi bi-arrow-left-circle-fill"></i> Regresar
                             </button>
                            <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote" #summernote></textarea>
                        </div>
                    </div>
                    <div class="col-12 d-flex mt-2" 
                        *ngIf="(objAct?.strEstatus=='PENDIENTE' || objAct?.length == 0 || objAct?.strEstatus=='PROCESO' ) && (boolConcluir == true)">
                        <select name="accioness" id="acciones" class="form-select m-auto" [(ngModel)]="strResultado"
                            [disabled]="objAct?.strEstatus=='CONCLUIDO'">
                            <option value="">
                                --Selecciona
                                una opción--</option>
                            <option value="1">
                                EXITOSO</option>
                            <option value="2">
                                SIN EXITO
                            </option>
                        </select>
                    </div>
                    <div class="col-12 d-flex mt-2">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            #btnCerrarAct>{{objAct?.strEstatus=='PENDIENTE'?'Cancelar':'Cerrar'}} </button>
                        <button [disabled]="strResultado == '' || contenido == ''"
                        
                            class="btn btn-success  text-white ms-auto mt-auto" (click)="fnConcluirActividad()"
                            *ngIf="(objAct?.strEstatus=='PENDIENTE'  || objAct?.strEstatus=='PROCESO') &&  (boolConcluir == true)"
                            data-bs-dismiss="modal">Concluir</button>
                            <div class="ms-auto" [hidden]="objAct?.strEstatus == 'CONCLUIDO'||objAct?.strEstatus == 'POR AUTORIZAR'   && objAct?.strAgente_Crea == strAgente_Crea" *ngIf="!boolConcluir">
                                <button class="btn btn-success  py-1 px-2" (click)="fnActividad()">
                                   ir a concluir <i class="bi bi-arrow-right-circle-fill fs-6"></i>
                                </button>
                            </div>
                            <div class="ms-auto" [hidden]="objAct?.strEstatus == 'CONCLUIDO'||objAct?.strEstatus == 'POR AUTORIZAR'   && objAct?.strAgente_Crea == strAgente_Crea" *ngIf="!boolConcluir">
                                <button class="btn btn-primary  py-1 px-2" (click)="fnActividad()">
                                   Pasar a proceso <i class="bi bi-arrow-right-circle-fill fs-6"></i>
                                </button>
                            </div>
                        <button type="button" class="btn btn-primary  ms-auto" *ngIf="(objAct?.strEstatus=='PENDIENTE') &&  (boolConcluir == true) "
                            (click)="fnEnProceso(objAct)">En Proceso</button>

                        <button type="button" class="btn btn-success  ms-auto"
                            *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                            (click)="fnAutorizar(objAct)">Autorizar</button>
                        <button type="button" class="btn btn-danger  ms-auto"
                            *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                            (click)="fnRechazar(objAct)">Rechazar</button>
                    </div>
                    <!-- <div class="col-4 d-flex">
                        <button class="btn btn-primary  text-white ms-auto mt-auto" data-bs-dismiss="modal"
                            (click)="fnConcluirActividad()">Guardar</button>
                    </div> -->
                </div>
                
                
            </div>
        </div>
    </div>
</div>

<!-- mdlMartin -->
<div class="modal fade" id="mdlRegistrar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Registrar {{objModal &&
                    objModal.titulo?objModal.titulo:""}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="col-12 mb-3">
                            <div class="col-12">
                                <h3 class="mb-1 fw-bold text-center">
                                    RESPONSABLES
                                </h3>
                            </div>
                            <mat-form-field class="example-full-width w-100">
                                <span matPrefix> </span>
                                <input type="text" class="px-3" matInput placeholder="Nombre agente" autocomplete="off"
                                    (keyup)="fnBusquedaCte(strBuscarAgente);" name="search"
                                    [(ngModel)]="strBuscarAgente">
                                <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                                <li class="list-group-item cursorPoint hver py-1 fs10"
                                    *ngFor="let agt of arrayAgente_Filter" (click)="fnSeleccionaAgente(agt)">
                                    {{agt.strNombre}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12" *ngFor="let agt of arrayAgentesActividades">
                            <button class="btn btn-secondary position-relative fs12 shadow-sm col-12 mb-2">
                                ({{agt.strAgente}}) - {{agt.strNombre}}
                                <i (click)="fnEliminarAgenteAct(agt)"
                                    class="fa-solid fa-x position-absolute top-0 end-0 mt-1 me-1 fs11"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-5">
                                <div class="col-12">
                                    Asunto
                                </div>
                                <div class="col-12">
                                    <input type="text" placeholder="Agregue asunto" class="form-control"
                                        [(ngModel)]="mdlNombre">
                                </div>
                            </div>
                            <div class="col-2">
                            </div>
                            <div class="col-5">
                                <div class="col-12">
                                    Fecha
                                </div>
                                <div class="col-12">
                                    <input type="date" class="form-control" [(ngModel)]="strfechaAct">
                                </div>
                            </div>
                        </div>
                        <div class="row my-3 d-flex">
                            <div class="col-5" *ngIf="objModal.titulo != 'Tarea'">
                                <div class="col-12">
                                    Hora Inicio
                                </div>
                                <div class="col-12 d-flex">
                                    <select class="form-select w-100" aria-label="Default select example"
                                        [(ngModel)]="mdlHoraInicio">
                                        <option *ngFor="let x of arrayHora">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2" *ngIf="objModal.titulo != 'Tarea'">
                            </div>
                            <div class="col-5" *ngIf="objModal.titulo != 'Tarea'">
                                <div class="col-12">
                                    Hora Fin
                                </div>
                                <div class="col-12 d-flex">
                                    <select class="form-select w-100 ms-auto" aria-label="Default select example"
                                        [(ngModel)]="mdlHoraFin">
                                        <option *ngFor="let x of arrayHora">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="col-12 mt-3 mb-2 d-block">
                                <span>Agente</span> <br>
                                <select [disabled]="boolAgente === false" name="agente" id="agente" class="form-select m-auto"
                                    [(ngModel)]="strAgenteLD">
                                    <option value="undefined">-Selecciona un Agente- </option>
                                    <option *ngFor="let item of arrayAgentes_tareas; let i = index" value="{{item.strAgente}}">
                                        {{item.strNombre}} <b *ngIf="item.strTipo  !='Vendedor'"> {{item.strTipo}}</b>
                                    </option>
                                </select>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-12">
                                Comentario
                            </div>
                            <!--<div class="col-12">
                                <textarea class="form-control" name="" id="" [(ngModel)]="mdlComentarios"></textarea>
                            </div>-->
                            <div contenteditable="true" (input)="onInput($event)" #inputComentarios
                                class="editable-area"
                                style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;">
                            </div>

                        </div>

                    </div>
                    <div class="col-12 col-md-3 d-flex mt-3 my-0">
                        <div class="col-12 d-flex">
                            <div class="col-12 border">
                                <div class="col-12 px-0 position-relative">
                                    <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                        <span class="ms-2 fs13">ADJUNTO</span>
                                    </h3>
                                    <button
                                        class="me-2 btn btnAdd position-absolute top-50 end-0 translate-middle-y py-0 px-2 shadow-sm"
                                        (click)="fnAdjuntarArchivosActividad()">
                                        <i class="bi bi-file-earmark-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 px-0">
                                    <input #fileInputActividades type="file" hidden
                                        (change)="onFileSelectedActividad($event)">
                                </div>
                                <div class="col-11 mx-auto" *ngIf="arrayAgregarAnexosActividades.length > 0">
                                    <div class="row">
                                        <div class="col-6 d-flex" *ngFor="let anex of arrayAgregarAnexosActividades">
                                            <div class="col-12 px-0 shadow-sm border text-center">
                                                <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}"
                                                    target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'"
                                                        class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'"
                                                        class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'"
                                                        class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'"
                                                        class="bi bi-file-zip fs-4"></i>
                                                    <span class="fs10 text-ellipsi">
                                                        {{anex.nombreArchivo}}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <button class="btn btn-danger fs12 p-1" (click)="fnEliminarAnexosAct()">
                                                <i class="bi bi-trash3 fs14"></i> Limpiar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrar>Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="fnAnexosActividad()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Cotizaciones -->

<div class="modal fade" id="ConcluirCotizaciones" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header text-center">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row d-flex p-3" *ngIf="cotizacionAcep.length > 0">
                    <div class="col-12 text-center mb-2">
                        <i class="bi bi-hand-thumbs-up-fill fs-1 text-success"></i>
                    </div>
                    <div class="col-12 text-center ">
                        <h2 class="text-success fw-bold">BIEN HECHO</h2>
                    </div>
                    <div class="col-8 text-center m-auto">
                        <h5 class="modal-title text-center" id="exampleModalLabel"><span
                                class="text-secondary ms-auto">{{strMensaje}}</span></h5>
                    </div>
                </div>
                <div class="row d-flex" *ngIf="cotizacionP.length > 0 || cotizacionRec.length > 0">
                    <div class="col-10 m-auto">
                        <h4 class="modal-title text-center" id="exampleModalLabel"><span
                                class="text-danger ms-auto">{{strMensaje}}</span></h4>
                    </div>
                    <div class="col-12 text-center d-flex justify-content-center align-items-center">
                        <span>Da click aqui para generar una nueva cotización</span> <br>
                    </div>
                    <div class="col-12 d-flex mb-3">
                        <button (click)="fnCotizador()" data-bs-dismiss="modal"
                            class="btn btn-success d-flex align-items-center m-auto">
                            <i class="fa-solid fa-plus"></i> Nueva Cotización
                        </button>
                    </div>
                    <div class="col-12 table-responsive">
                        <table class="table table-bordered text-center">
                            <thead class="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>FECHA</th>
                                    <th>MONTO</th>
                                    <th>ESTATUS</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayCotizaciones.length == 0">
                                    <td colspan="5" class="text-center">SIN DATOS</td>
                                </tr>
                                <tr *ngFor="let item of arrayCotizaciones; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>
                                        <span class="m-auto d-flex">
                                            <i class="bi bi-lightning-fill text-warning fs-6"
                                                *ngIf="item.strEstatus == 'POR AUTORIZAR'"></i>
                                            <i class="bi bi-x-circle-fill text-danger fs-6"
                                                *ngIf="item.strEstatus == 'CANCELADO'"></i>
                                            <i class="bi bi-check-circle-fill text-success fs-6"
                                                *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                            <span class="mx-2">{{item.intIDMovimientoVenta}}</span>
                                        </span>
                                    </td>
                                    <td>{{ item.strFecha }}</td>
                                    <td>{{ item.dblImporte | currency }}</td>
                                    <td>{{ item.strEstatus }}</td>
                                    <td class="d-flex">
                                        <!--[ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"-->
                                        <button
                                            [disabled]="item.strEstatusCliente == 'ACEPTADO' || item.strEstatusCliente == 'RECHAZADO'"
                                            [ngClass]="{'btn-success': item.strEstatusCliente == 'ACEPTADO', 'btn-danger': item.strEstatusCliente == 'RECHAZADO', 'btn-primary': (!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')}"
                                            class="text-white dropdown-toggle text-sm btn btn-sm  m-auto "
                                            data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i class="bi bi-x-circle" *ngIf="item.strEstatusCliente == 'RECHAZADO'"></i>
                                            <i class="bi bi-check-circle"
                                                *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>
                                            <span
                                                *ngIf="(!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')"
                                                class="material-symbols-outlined fs-6">
                                                action_key
                                            </span>

                                        </button>
                                        <!--(click)="stopPropagation4(i, 'miDropdownMenu')"-->
                                        <div #dropdownMenu (click)="stopPropagation2($event)" id="miDropdownMenu{{i}}"
                                            class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh">
                                            <div class="container-fluid">
                                                <div class="row mt-2">
                                                    <div class="col-12 mt-1">
                                                        <div class="row">
                                                            <div class="col-8 d-flex justify-content-between">

                                                                <select name="accioness"
                                                                    [(ngModel)]="strEstatusCotizacion" id="acciones"
                                                                    class="form-select" [(ngModel)]="strResultado">
                                                                    <option value="">
                                                                        --Selecciona
                                                                        una opción--</option>
                                                                    <option value="ACEPTADO">
                                                                        ACEPTADO</option>
                                                                    <option value="RECHAZADO">
                                                                        RECHAZADO
                                                                    </option>
                                                                    <!-- <option value="correo Enviado">
                                                            Mail Enviado</option>
                                                        <option value="visita">Visita
                                                            realizada</option> -->
                                                                </select>
                                                            </div>
                                                            <div class="col-3 d-flex">
                                                                <button
                                                                    class="btn btn-primary btn-sm  text-white ms-auto mt-auto fs-6 d-flex justify-content-center alight-items-center "
                                                                    (click)="fnsetEstatus_Cotizacion(item.intIDMovimientoVenta)"
                                                                    data-bs-dismiss="modal">
                                                                    <span class="material-symbols-outlined mt-1 fs-6">
                                                                        save </span>Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex">
                <button class="btn btn-danger ms-auto mx-1" data-bs-dismiss="modal" *ngIf="intFlagAvanza4 == 1">
                    Cerrar
                </button>
                <!-- <button id="boton" type="button" class="btn btn-success ms-auto mx-1"
                    *ngIf="intFlagAvanza4 == 1 && strEtapa !== 'CIERRE'" (click)="fnAvanzarCierre()"
                    data-bs-dismiss="modal">
                    <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i>Avanzar Cierre</button> -->
                <button id="boton" type="button" class="btn btn-success ms-auto mx-1"
                    *ngIf="intFlagAvanza5 == 1 && (strEtapa != 'NEGOCIACION' && strEtapa != 'CIERRE')"
                    (click)="fnAvanzarNegociacion()" data-bs-dismiss="modal">
                    <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i>Avanzar Negociación</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal cierre1 -->
<div class="modal fade" id="modalCierre" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Cerrar Lead</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 mb-3">
                        {{strMensaje_cierre_1}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 fw-bold">
                        {{strMensaje_cierre_2}}
                    </div>
                    <div class="col-12">
                        <textarea class="form-control" name="" id="" [(ngModel)]="strComentarios"></textarea>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btncerrarModal" data-bs-dismiss="modal"
                    #btnCerrarMdlGanado>Cancelar</button>
                <button type="button" class="btn bgtitModal" data-bs-dismiss="modal"
                    (click)="fnCerraLead()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="detalleCotizacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header  d-flex">
                <h1 class="modal-title fs-4 m-auto" id="exampleModalLabel">Detalle Cotización: <span
                        class="fw-bold">{{intIDMovimientoVenta}}</span></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table mat-table [dataSource]="dataSourceCotizaciones" matSort class="table">
                            <ng-container matColumnDef="#">
                                <th mat-header-cell *matHeaderCellDef class="px-3">
                                    <span class="mx-auto">#</span>
                                </th>
                                <td mat-cell *matCellDef="let i = index" class="gastoCell px-3 py-4"><b
                                        class="resalta">{{ i + 1 }}</b>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Imagen">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagen</th>
                                <td mat-cell *matCellDef="let element" class="gastoCell ">
                                    <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                                        alt="" class="p-3" width="100">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="strArticulo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Articulo</th>
                                <td mat-cell *matCellDef="let element" class="gastoCell fw-bold py-4">
                                    {{element.strArticulo}} </td>
                            </ng-container>

                            <ng-container matColumnDef="strDescripcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Descripción</th>
                                <td mat-cell *matCellDef="let element" class="gastoCell text-start py-4 px-3">
                                    {{element.strDescripcion}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="strOpcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-3">
                                    Opción
                                </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell px-3 py-4">
                                    {{element.strOpcion}} {{element.strNombreOpcion}}
                                </td>
                            </ng-container>

                            <!--  <ng-container matColumnDef="strNombreOpcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-3">
                                    Nombre Opción
                                </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell px-3 py-4">
                                    
                                </td>
                            </ng-container> -->

                            <ng-container matColumnDef="strUnidad">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Unidad </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.strUnidad}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dblCantidad">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Cantidad </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.dblCantidad}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dblPrecioSugerido">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Precio Sugerido </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.dblPrecioSugerido | currency:'':'symbol':'1.2-2' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dblPrecio">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Precio </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.dblPrecio | currency:'':'symbol':'1.2-2' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dblImporte">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Importe </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{(element.dblPrecio * element.dblCantidad) | currency:'':'symbol':'1.2-2'}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsCotizacion"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsCotizacion;"></tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="enviarAlta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header bgtitModal h-25 d-flex">
                <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">ALTA {{strTipoLead}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="strTipoLead == 'FAMILIA'">
                    <div class="col-12 mb-4">
                        <span class="me-auto">
                            <span class="material-symbols-outlined fs-6 mx-1">
                                family_restroom
                            </span>Familia:
                        </span>
                    </div>
                    <div class="col-12">
                        <input type="text" placeholder="Escribe el nombre de la familia" class="form-control"
                            [(ngModel)]="strFamiliaNueva">
                    </div>
                </div>
                <div class="row d-flex mt-4" *ngIf="strTipoLead == 'SUCURSAL'">
                    <div class="col-10 m-auto d-flex flex-column">
                        <div class="input-with-label d-flex">
                            <label for="nombre" class="corner-label">Numero de Cliente: <span
                                    class="text-danger fw-bold ms-5">* Obligatorio</span></label>
                            <input type="text" (input)="fnBuscarCliente(strNumeroCliente);" placeholder="Numero Cliente"
                                class="form-control" [(ngModel)]="strNumeroCliente">
                        </div>
                    </div>
                    <div class="col-12">
                        <ul *ngIf="arrayBuscarClientes.length > 0" class="list-group overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10"
                                *ngFor="let cte of arrayBuscarClientes" (click)="fnselecCTE(cte)">
                                ({{cte.strCliente}}){{cte.strNombreMostrar}}
                            </li>
                        </ul>
                    </div>
                    <hr class="col-12 mt-2">
                    <div class="col-6 mt-3 d-flex flex-column mt-1 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Nombre Sucursal:</label>
                            <input type="text" placeholder="Nombre Sucursal" class="form-control"
                                [(ngModel)]="strNombreEmpresa">
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-flex flex-column mt-1 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Agente:</label>
                            <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                                [(ngModel)]="strAgenteLD">
                                <option value="undefined">-Selecciona un Agente- </option>
                                <option *ngFor="let item of arrayAgentes; let i = index" value="{{item.strAgente}}">
                                    {{item.strNombre}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 mt-2 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Codigo Postal:</label>
                            <input type="text" placeholder="codigo postal" class="form-control m-auto"
                                [(ngModel)]="strCodigoPostal" (keyup.enter)="fnGetDatosFiscales()"
                                (blur)="blurevent2()">
                        </div>
                    </div>
                    <div class="col-md-6 mt-2 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Estado:</label>
                            <input [readonly]="true" type="text" placeholder="Estado" class="form-control  m-auto"
                                [(ngModel)]="strEstado">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Delegación:</label>
                            <input readonly [(ngModel)]="strDelegacion" type="text" placeholder="Delegación"
                                class="form-control  m-auto">
                        </div>

                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Colonia:</label>
                            <select name="probabilidad" id="probabilidad" class="form-select" [(ngModel)]="strColonia">
                                <option *ngFor="let item of arrayColonias; let i = index" value="{{item.strColonia}}">
                                    {{item.strColonia}}
                                </option>
                                <option value="otraColonia">Otra</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Calle:</label>
                            <input type="text" placeholder="Calle" class="form-control  m-auto" [(ngModel)]="strCalle">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Entre Calles:</label>
                            <input [(ngModel)]="strEntreCalles" type="text" placeholder="Entre Calles"
                                class="form-control  m-auto">
                        </div>

                    </div>

                    <div class="col-md-6 col-sm-12 mb-3">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Numero Exterior:</label>
                            <input type="text" placeholder="No. Exterior " class="form-control  m-auto"
                                [(ngModel)]="strNumeroExterior">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <!--  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button *ngIf="strTipoLead == 'FAMILIA'" [disabled]="strFamiliaNueva == ''" type="button"
                    class="btn btn-primary" data-bs-dismiss="modal" (click)="fnSetFamilia_Fin()">Enviar Alta</button>
                <button [disabled]="strNumeroCliente === ''" *ngIf="strTipoLead == 'SUCURSAL'"
                    [disabled]="strNumeroCliente == ''" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    (click)="fnsetSucursal_Cte()">Enviar Alta</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="mdlManualSuc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title mb-0 fw-bold fs-5" id="mdlManualSucLabel">Agregar Nueva Sucursal</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSucFamilia" *ngIf="strTipoLead=='FAMILIA'">
                    <div class="row">
                        <div class="col-12 col-md-8 mt-3 mt-md-0">
                            <label for="">Nombre Sucursal</label>
                            <input type="text" name="strNombreEmpresa" [(ngModel)]="objSuFamilia.NOMBRE_SUCURSAL"
                                class="form-control">
                            <mat-error *ngIf="formSucFamilia.get('strNombreEmpresa')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-4 mt-3 mt-md-0">
                            <label for="">RFC</label>
                            <input type="text" name="strRFC" class="form-control" [(ngModel)]="objSuFamilia.RFC">
                            <mat-error *ngIf="formSucFamilia.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucFamilia.get('strRFC')?.hasError('minlength') && !objSuFamilia.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud mínima es 13 caracteres
                            </mat-error>
                            <mat-error
                                *ngIf="formSucFamilia.get('strRFC')?.hasError('maxlength') && !objSuFamilia.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 13 caracteres
                            </mat-error>
                        </div>
                        <div class="col-12  mt-3">
                            <label for="">Razon Social</label>
                            <input type="text" name="strRazonSocial" class="form-control"
                                [(ngModel)]="objSuFamilia.RAZON_SOCIAL">
                            <mat-error *ngIf="formSucFamilia.get('strRazonSocial')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-4 mt-3">
                            <label for="">Codigo Postal</label>
                            <input type="text" name="strCodigoPostal" placeholder="ejemp:36213, 40660, etc.."
                                class="form-control" [(ngModel)]="objSuFamilia.CODIGO_POSTAL"
                                (keydown)="onKeyDown($event)" (keypress)="validarNumeros($event)" (blur)="blurevent()">
                            <mat-error *ngIf="formSucFamilia.get('strCodigoPostal')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucFamilia.get('strCodigoPostal')?.hasError('minlength') && !objSuFamilia.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud mínima es 5 caracteres
                            </mat-error>
                            <mat-error
                                *ngIf="formSucFamilia.get('strCodigoPostal')?.hasError('maxlength') && !objSuFamilia.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 5 caracteres
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-8 mt-3">
                            <label for=""> Colonia</label>
                            <select name="" id="" name="strColonia" class="form-select"
                                [(ngModel)]="objSuFamilia.COLONIA">
                                <option value="">--Seleccione Colonia--</option>
                                <option value="{{col.strColonia}}" *ngFor="let col of arrayColSuc">{{col.strColonia}}
                                </option>
                            </select>
                            <mat-error *ngIf="formSucFamilia.get('strColonia')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>

                        <div class="col-12 col-md-8 mt-3">
                            <label for=""> Dirección</label>
                            <input type="text" name="direccion" class="form-control"
                                [(ngModel)]="objSuFamilia.DIRECCION">
                            <mat-error *ngIf="formSucFamilia.get('direccion')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-4 mt-3">
                            <label for="">Numero Dirección</label>
                            <input type="text" name="numeroDireccion" class="form-control"
                                [(ngModel)]="objSuFamilia.DIRECCION_NUMERO">
                            <mat-error *ngIf="formSucFamilia.get('numeroDireccion')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 text-center mt-3">
                            <span class="fw-bold fs-6">Contacto</span>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="text" class="form-control" name="strContacto_Nombre"
                                [(ngModel)]="objSuFamilia.CONTACTO_NOMBRE">
                            <mat-error *ngIf="formSucFamilia.get('strContacto_Nombre')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Puesto</label>
                            <input type="text" class="form-control" name="strContacto_Puesto"
                                [(ngModel)]="objSuFamilia.CONTACTO_PUESTO">
                            <mat-error *ngIf="formSucFamilia.get('strContacto_Puesto')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Email</label>
                            <input type="text" class="form-control" name="strContacto_Email"
                                [(ngModel)]="objSuFamilia.CONTACTO_EMAIL">
                            <mat-error *ngIf="formSucFamilia.get('strContacto_Email')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>

                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Telefono</label>
                            <input type="text" class="form-control" name="strContacto_Telefono"
                                [(ngModel)]="objSuFamilia.CONTACTO_TELEFONO">
                            <mat-error *ngIf="formSucFamilia.get('strContacto_Email')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucFamilia.get('strContacto_Telefono')?.hasError('pattern') && !formSucFamilia.get('strContacto_Telefono')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Debe ser un número de 10 dígitos
                            </mat-error>
                            <mat-error
                                *ngIf="formSucFamilia.get('strContacto_Telefono')?.hasError('maxlength') && !formSucFamilia.get('strContacto_Telefono')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 10 caracteres
                            </mat-error>
                        </div>
                    </div>
                </form>



                <form [formGroup]="formSucCliente" *ngIf="strTipoLead=='CLIENTE'">
                    <div class="row">
                        <div class="col-12 mt-3 mt-md-0">
                            <label for="">Nombre Sucursal</label>
                            <input type="text" name="strNombreEmpresa" [(ngModel)]="objSucCliente.NOMBRE_SUCURSAL"
                                class="form-control">
                            <mat-error *ngIf="formSucCliente.get('strNombreEmpresa')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>

                        <div class="col-12 col-md-4 mt-3">
                            <label for="">Codigo Postal</label>
                            <input type="text" name="strCodigoPostal" placeholder="ejemp:36213, 40660, etc.."
                                class="form-control" [(ngModel)]="objSucCliente.CODIGO_POSTAL"
                                (keydown)="onKeyDown($event)" (keypress)="validarNumeros($event)" (blur)="blurevent()">
                            <mat-error *ngIf="formSucCliente.get('strCodigoPostal')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucCliente.get('strCodigoPostal')?.hasError('minlength') && !objSucCliente.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud mínima es 5 caracteres
                            </mat-error>
                            <mat-error
                                *ngIf="formSucCliente.get('strCodigoPostal')?.hasError('maxlength') && !objSucCliente.get('strRFC')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 5 caracteres
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-8 mt-3">
                            <label for=""> Colonia</label>
                            <select name="" id="" name="strColonia" class="form-select"
                                [(ngModel)]="objSucCliente.COLONIA">
                                <option value="">--Seleccione Colonia--</option>
                                <option value="{{col.strColonia}}" *ngFor="let col of arrayColSuc">{{col.strColonia}}
                                </option>
                            </select>
                            <mat-error *ngIf="formSucCliente.get('strColonia')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>

                        <div class="col-12 col-md-8 mt-3">
                            <label for=""> Dirección</label>
                            <input type="text" name="direccion" class="form-control"
                                [(ngModel)]="objSucCliente.DIRECCION">
                            <mat-error *ngIf="formSucCliente.get('direccion')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-4 mt-3">
                            <label for="">Numero Dirección</label>
                            <input type="text" name="numeroDireccion" class="form-control"
                                [(ngModel)]="objSucCliente.DIRECCION_NUMERO">
                            <mat-error *ngIf="formSucCliente.get('numeroDireccion')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 text-center mt-3">
                            <span class="fw-bold fs-6">Contacto</span>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="text" class="form-control" name="strContacto_Nombre"
                                [(ngModel)]="objSucCliente.CONTACTO_NOMBRE">
                            <mat-error *ngIf="formSucCliente.get('strContacto_Nombre')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Puesto</label>
                            <input type="text" class="form-control" name="strContacto_Puesto"
                                [(ngModel)]="objSucCliente.CONTACTO_PUESTO">
                            <mat-error *ngIf="formSucCliente.get('strContacto_Puesto')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Email</label>
                            <input type="text" class="form-control" name="strContacto_Email"
                                [(ngModel)]="objSucCliente.CONTACTO_EMAIL">
                            <mat-error *ngIf="formSucCliente.get('strContacto_Email')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>

                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Telefono</label>
                            <input type="text" class="form-control" name="strContacto_Telefono"
                                [(ngModel)]="objSucCliente.CONTACTO_TELEFONO">
                            <mat-error *ngIf="formSucCliente.get('strContacto_Email')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucCliente.get('strContacto_Telefono')?.hasError('pattern') && !formSucFamilia.get('strContacto_Telefono')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Debe ser un número de 10 dígitos
                            </mat-error>
                            <mat-error
                                *ngIf="formSucCliente.get('strContacto_Telefono')?.hasError('maxlength') && !formSucFamilia.get('strContacto_Telefono')?.hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 10 caracteres
                            </mat-error>
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #mdlSuc>Cerrar</button>
                <button type="button" class="btn btn-primary" [disabled]="formSucCliente.invalid"
                    (click)="fnGuardarSuc()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="nuevaCarpeta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Nombre Carpeta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <label for="">Escribe el nombre de una carpeta:</label>
                            <input type="text" placeholder="Escribe el nombre" class="form-control"
                                [(ngModel)]="strNombreCarpeta">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> -->
                <button type="button" class="btn btn-primary" (click)="agregarCarpeta()"
                    data-bs-dismiss="modal">Crear</button>
            </div>
        </div>
    </div>
</div>



<!-- <form id="clienteForm">
                    
                    <div class="form-step form-step-active">
                        <h5 class="fw-bold">Revisión de Datos</h5>
                        <div class="mb-3">
                            <label for="giroEmpresa" class="form-label">1. Colocar el Giro de la empresa</label>
                            <input type="text" class="form-control" id="giroEmpresa" name="giroEmpresa">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">2. Colocar datos del contacto</label>
                            <input type="text" class="form-control mb-2" id="nombreContacto" name="nombreContacto"
                                placeholder="Nombre">
                            <input type="email" class="form-control mb-2" id="correoContacto" name="correoContacto"
                                placeholder="Correo">
                            <input type="tel" class="form-control mb-2" id="telefonoContacto" name="telefonoContacto"
                                placeholder="Teléfono">
                            <input type="text" class="form-control" id="puestoContacto" name="puestoContacto"
                                placeholder="Puesto">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">3. Colocar la dirección de la empresa</label>
                            <input type="text" class="form-control mb-2" id="direccionEmpresa" name="direccionEmpresa"
                                placeholder="Dirección">
                            <input type="text" class="form-control mb-2" id="codigoPostalEmpresa"
                                name="codigoPostalEmpresa" placeholder="Código Postal">
                            <textarea class="form-control" id="otrosDatosDireccion" name="otrosDatosDireccion"
                                placeholder="Otros datos de dirección"></textarea>
                        </div>
                        <button type="button" class="btn btn-primary next-step">Siguiente</button>
                    </div>

                   
                    <div class="form-step">
                        <h5 class="fw-bold">Asociación con Cuenta Actual</h5>
                        <div class="mb-3">
                            <label class="form-label">4. ¿Está asociado a alguna cuenta convenio?</label>
                            <select class="form-control" id="asociadoCuentaConvenio" name="asociadoCuentaConvenio">
                                <option value="si">Sí</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="razonSocial" class="form-label">5. Razón social (opcional)</label>
                            <input type="text" class="form-control" id="razonSocial" name="razonSocial">
                        </div>
                        <div class="mb-3">
                            <label for="rfc" class="form-label">6. RFC (opcional)</label>
                            <input type="text" class="form-control" id="rfc" name="rfc">
                        </div>
                        <button type="button" class="btn btn-secondary prev-step">Anterior</button>
                        <button type="button" class="btn btn-primary next-step">Siguiente</button>
                    </div>

                    
                    <div class="form-step">
                        <h5 class="fw-bold">Decisiones de Compras</h5>
                        <div class="mb-3">
                            <label class="form-label">7. ¿Es una cuenta corporativa?</label>
                            <select class="form-control" id="cuentaCorporativa" name="cuentaCorporativa">
                                <option value="si">Sí</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">8. ¿Se utiliza algún sistema de compras?</label>
                            <input type="text" class="form-control" id="sistemaCompras" name="sistemaCompras">
                        </div>
                        <button type="button" class="btn btn-secondary prev-step">Anterior</button>
                        <button type="button" class="btn btn-primary next-step">Siguiente</button>
                    </div>

                    
                    <div class="form-step">
                        <h5 class="fw-bold">Información Adicional</h5>
                        <div class="mb-3">
                            <label for="medioConocido" class="form-label">9. ¿Por cuál medio nos conoció?</label>
                            <input type="text" class="form-control" id="medioConocido" name="medioConocido">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">10. Seleccione alguna línea de su interés</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="quimicos" name="lineaInteres[]"
                                    value="Químicos">
                                <label class="form-check-label" for="quimicos">Químicos</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="tapetes" name="lineaInteres[]"
                                    value="Tapetes">
                                <label class="form-check-label" for="tapetes">Tapetes</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="accesorios" name="lineaInteres[]"
                                    value="Accesorios">
                                <label class="form-check-label" for="accesorios">Accesorios</label>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="notaEvaluacion" class="form-label">11. Agregar una nota evaluando al
                                cliente</label>
                            <textarea class="form-control" id="notaEvaluacion" name="notaEvaluacion"></textarea>
                        </div>
                        <button type="button" class="btn btn-secondary prev-step">Anterior</button>
                        <button type="button" class="btn btn-primary" onclick="submitForm()">Guardar</button>
                    </div>

                </form> -->

<!-- <mat-tab label="Estado de cuenta">
                    <div class="container-fluid">
                        <div class="row" *ngIf="spinerEdoCta">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                        <div class="row " *ngIf="!spinerEdoCta">
                            <div class="col-10 shadow borderbott bordertop mx-auto my-3">
                                <div class="row">
                                    <div class=" table-responsive mx-auto ">
                                        <table class="table">
                                            <thead>
                                                <tr class="thcenter sticky-top bg-white">
                                                    <th class="bg1"><i class="bi bi-calendar-event"></i>
                                                        Ejercicio</th>
                                                    <th class="bg1"><i class="bi bi-hourglass-bottom"></i>
                                                        Vencido</th>
                                                    <th class="bg1"><i class="bi bi-hourglass-split"></i> Por
                                                        Vencer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="tdcenter" *ngFor="let o of arrayEstadoCuenta">
                                                    <td>{{o.strEjercicioVencimiento }}</td>
                                                    <td>{{o.dblVencido |currency}}</td>
                                                    <td>{{o.dblPorVencer|currency}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-tab> -->
<!-- <mat-tab label="Datos Fiscales">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                        <div *ngIf="!spinerCte" class="row justify-content-evenly">
                            <div class="col-12 col-md-12  shadow borderbott bordertop">
                                <div class="col-12 table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th colspan="2" class="text-center">
                                                    DATOS FISCALES
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start"><i
                                                        class="bi bi-bank"></i> Clave uso CFDI:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strClaveUsoCFDI}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start"><i
                                                        class="bi bi-bank"></i> Descrip. uso CFDI:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strDescripcionUso}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start"><i
                                                        class="bi bi-cash-coin"></i> Forma Pago:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strDescripcionFP}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start"><i
                                                        class="bi bi-arrow-repeat"></i> Giro:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strDescripcion12}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start"><i
                                                        class="bi bi-safe2"></i> Clave Banco:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.intClaveBanco}}</td>
                                            </tr>
                                            <tr class="border-bottom align-middle">
                                                <td
                                                    class="fw-bold bg1 text-nowrap text-start d-flex border-bottom-0 ">
                                                    <span
                                                        class="material-symbols-outlined fs17">account_balance_wallet</span>
                                                    Nombre Banco:
                                                </td>
                                                <td class="ps-0 fs12 fw-bold border-bottom-0">
                                                    {{objModal.strNombreBanco}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start d-flex"><span
                                                        class="material-symbols-outlined fs17">
                                                        support_agent
                                                    </span> Nombre agente:
                                                </td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strAgenteNombre}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1  text-nowrap text-start"> <i
                                                        class="bi bi-credit-card-2-back"></i>Pagos:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strContacto2}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start d-flex"><span
                                                        class="material-symbols-outlined fs17">handshake</span>
                                                    Condición de pago:</td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strCondicionPago}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                                    <span
                                                        class="material-symbols-outlined fs17">checkbook</span>Número
                                                    de cuenta:
                                                </td>
                                                <td class="ps-0 fs12 fw-bold ">{{objModal.strNumeroCuenta}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                                    <span class="material-symbols-outlined fs17">vpn_key</span>
                                                    Clabe Interbancaria:
                                                </td>
                                                <td class="ps-0 fs12 fw-bold ">
                                                    {{objModal.strClaveInterbancaria}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->
<!-- <mat-tab label="Historico Vtas.">
                    <div class="container-fluid">
                         <div class="row " *ngIf="!spinerHisVta">
                            <div class="col-11 borderbott bordertop shadow-sm mx-auto pb-3">
                                <h3 class="my-1">INFORMACIÓN</h3>
                                <div class="col-12">
                                    <hr class="mt-0">
                                </div>
                                <div class="row justify-content-evenly pb-2">
                                    <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1">
                                        <div class="row">
                                            <div
                                                class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                                                <div class="col-12 d-flex flex-column">
                                                    <span class="mx-auto fs11">
                                                        AGENTE
                                                    </span>
                                                    <span class="material-symbols-outlined mx-auto">
                                                        account_circle
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-9 d-flex flex-column">
                                                <span
                                                    class="fs12 fw-bold">{{objModalHistorico?.strAgenteNombre}}</span>
                                                <span
                                                    class="fs12 fw-bold clsHisto">{{objModalHistorico?.strAgenteEmail}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1 my-2 my-lg-0">
                                        <div class="row">
                                            <div
                                                class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                                                <div class="col-12 d-flex flex-column">
                                                    <span class="mx-auto fs11">
                                                        COBRADOR
                                                    </span>
                                                    <span class="material-symbols-outlined mx-auto">
                                                        car_tag
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-9 d-flex flex-column">
                                                <span
                                                    class="fs12 fw-bold">{{objModalHistorico?.strNombreCobrador}}</span>
                                                <span
                                                    class="fs12 fw-bold clsHisto">{{objModalHistorico?.strCobradorEmail}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-evenly pb-2">
                                    <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1">
                                        <div class="row">
                                            <div
                                                class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                                                <div class="col-12 d-flex flex-column">
                                                    <span class="mx-auto fs11">
                                                        DIVISIONAL
                                                    </span>
                                                    <span class="material-symbols-outlined mx-auto">
                                                        deployed_code_account
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-9 d-flex flex-column">
                                                <span
                                                    class="fs12 fw-bold">{{objModalHistorico?.strNombreDivisional}}</span>
                                                <span
                                                    class="fs12 fw-bold clsHisto">{{objModalHistorico?.strEmailDivisional}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1 my-2 my-lg-0">
                                        <div class="row">
                                            <div
                                                class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                                                <div class="col-12 d-flex flex-column">
                                                    <span class="mx-auto fs11">
                                                        ADMINISTRATIVO
                                                    </span>
                                                    <span class="material-symbols-outlined mx-auto">
                                                        manage_accounts
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-9 d-flex flex-column">
                                                <span
                                                    class="fs12 fw-bold">{{objModalHistorico?.strNombreAdministrativo}}</span>
                                                <span
                                                    class="fs12 fw-bold clsHisto">{{objModalHistorico?.strEmailAdministrativo}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-evenly pb-2">
                                    <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1">
                                        <div class="row">
                                            <div
                                                class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                                                <div class="col-12 d-flex flex-column">
                                                    <span class="mx-auto fs11">
                                                        SUCURSAL
                                                    </span>
                                                    <span class="material-symbols-outlined mx-auto">
                                                        source_environment
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-9 d-flex flex-column">
                                                <span
                                                    class="fs12 fw-bold">{{objModalHistorico?.strSucursalNombre}}</span>
                                                <span
                                                    class="fs12 fw-bold clsHisto">{{objModalHistorico?.strEmailCliente}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1 my-2 my-lg-0">
                                        <div class="row">
                                            <div
                                                class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                                                <div class="col-12 d-flex flex-column">
                                                    <span class="mx-auto fs11">
                                                        CLABE
                                                    </span>
                                                    <span class="material-symbols-outlined mx-auto">
                                                        account_balance
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-9 d-flex flex-column">
                                                <span
                                                    class="fs12 fw-bold">{{objModalHistorico?.strClaveInterbancaria}}</span>
                                                <span
                                                    class="fs12 fw-bold clsHisto">CTA:{{objModalHistorico?.strNumeroCuenta}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row  mt-3" *ngIf="!spinerHisVta">
                            <div class="col-11 borderbott bordertop shadow mx-auto pb-3 bgtabHistory">
                                <div class="table-responsive ">
                                    <table class="table">
                                        <thead>
                                            <tr class="thcenter sticky-top clsHisto bgtabHistory">
                                                <th class=""><span
                                                        class="material-symbols-outlined align-middle">
                                                        date_range
                                                    </span><span class="align-middle">EJERCICIO</span></th>
                                                <th class=""><span
                                                        class="material-symbols-outlined align-middle">
                                                        calendar_month
                                                    </span><span class="align-middle">PERIODO</span></th>
                                                <th class=""> <span
                                                        class="material-symbols-outlined align-middle">
                                                        paid
                                                    </span><span class="align-middle">IMPORTE</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="tdcenter" *ngFor="let o of arrayHistoricoVta">
                                                <td>{{o.strEjercicio}}</td>
                                                <td>{{fnNamePeriodo(o.strPeriodo)}}</td>
                                                <td>{{o.dblImporte|currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->
<!-- <mat-tab label="Estado Cuenta">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-11 mx-auto py-2 d-flex justify-content-center">
                                <h2 class="mb-0 fw-bold text-center  mx-auto">ESTADO DE CUENTA</h2>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-11 d-flex justify-content-between mx-auto">
                                <div class="col-8 col-md-3">
                                    <input type="text" class="form-control " placeholder="Movimiento:"
                                        [(ngModel)]="inputSearchInfoAdd">
                                </div>
                                <span class="text-danger fs-2" (click)="fncreatepdf('cuentacte')"><i
                                        class="bi bi-file-pdf"></i></span>
                            </div>
                        </div>
                        <div class="row" *ngIf="spinerInfAdd">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                        <div class="row" *ngIf="!spinerInfAdd">
                            <div class="col-11 mx-auto borderbott bordertop shadow bgtableinfoadd">
                                <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                                    <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                                </h2>
                                <div class="table-responsive mb-0">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr class="fs12 thcenter">
                                                <th>Movimiento</th>
                                                <th>Fecha Emisión</th>
                                                <th>Fecha Vencimiento</th>
                                                <th>Días</th>
                                                <th>Saldo Corriente</th>
                                                <th>de 1 a 15</th>
                                                <th>de 16 a 30</th>
                                                <th>de 31 a 60</th>
                                                <th>de 61 a 90</th>
                                                <th>más de 90</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="fs12 tdcenter"
                                                *ngFor="let o of arrayInfoAdicional | filtersearch: inputSearchInfoAdd : ['strMovID'];">
                                                <td>{{o.strMovID}}</td>
                                                <td>{{o.strFechaEmision}}</td>
                                                <td>{{o.strVencimiento}}</td>
                                                <td>{{o.strDias}}</td>
                                                <td>{{o.dblSaldoCorriente|currency}}</td>
                                                <td>{{o.dblSaldo15}}</td>
                                                <td>{{o.dblSaldo30}}</td>
                                                <td>{{o.dblSaldo60}}</td>
                                                <td>{{o.dblSaldo90}}</td>
                                                <td>{{o.dblSaldoA}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="tdcenter">
                                                <td colspan="4" class="text-center fw-bold"> TOTAL</td>
                                                <td class="fw-bold fs13">{{intSaldoCorriente|currency}}</td>
                                                <td class="fw-bold fs13">{{int15dias|currency}}</td>
                                                <td class="fw-bold fs13">{{int30dias|currency}}</td>
                                                <td class="fw-bold fs13">{{int60dias|currency}}</td>
                                                <td class="fw-bold fs13">{{int90dias|currency}}</td>
                                                <td class="fw-bold fs13">{{int90mas|currency}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="!booltitl">
                            <div class="row" #pdfTableInfoAdd>
                                <div class="col-11 mx-auto py-2 d-flex justify-content-center ">
                                    <div>
                                        <h2 class="mb-0 fw-bold text-center  mx-auto">ESTADO DE CUENTA</h2>
                                    </div>
                                </div>
                                <div class="col-11 mx-auto borderbott bordertop shadow bgtableinfoadd">
                                    <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                                        <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                                    </h2>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr class="fs12 thcenter">
                                                    <th>Movimiento</th>
                                                    <th>Fecha Emisión</th>
                                                    <th>Fecha Vencimiento</th>
                                                    <th>Días</th>
                                                    <th>Saldo Corriente</th>
                                                    <th>de 1 a 15</th>
                                                    <th>de 16 a 30</th>
                                                    <th>de 31 a 60</th>
                                                    <th>de 61 a 90</th>
                                                    <th>más de 90</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="fs12 tdcenter"
                                                    *ngFor="let o of arrayInfoAdicional | filtersearch: inputSearchInfoAdd : ['strMovID'];">
                                                    <td>{{o.strMovID}}</td>
                                                    <td>{{o.strFechaEmision}}</td>
                                                    <td>{{o.strVencimiento}}</td>
                                                    <td>{{o.strDias}}</td>
                                                    <td>{{o.dblSaldoCorriente|currency}}</td>
                                                    <td>{{o.dblSaldo15}}</td>
                                                    <td>{{o.dblSaldo30}}</td>
                                                    <td>{{o.dblSaldo60}}</td>
                                                    <td>{{o.dblSaldo90}}</td>
                                                    <td>{{o.dblSaldoA}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr class="tdcenter">
                                                    <td colspan="4" class="text-center fw-bold"> TOTAL</td>
                                                    <td class="fw-bold fs13">{{intSaldoCorriente|currency}}</td>
                                                    <td class="fw-bold fs13">{{int15dias|currency}}</td>
                                                    <td class="fw-bold fs13">{{int30dias|currency}}</td>
                                                    <td class="fw-bold fs13">{{int60dias|currency}}</td>
                                                    <td class="fw-bold fs13">{{int90dias|currency}}</td>
                                                    <td class="fw-bold fs13">{{int90mas|currency}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->
<!-- <mat-tab label="Flujo Cte">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-11 mx-auto py-2 d-flex justify-content-center">
                                <h2 class="mb-0 fw-bold text-center  mx-auto ">FLUJO DE CLIENTE</h2>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-11 d-flex justify-content-between mx-auto">
                                <div class="col-8 col-md-3">
                                    <input type="text" class="form-control "
                                        placeholder="cte, periodo, ejercicio:" [(ngModel)]="inputFlujoCte">
                                </div>
                                <span class="text-danger fs-2" (click)="fncreatepdf('flujoCte')"><i
                                        class="bi bi-file-pdf"></i></span>
                            </div>
                        </div>
                        <div class="row" *ngIf="spinerFlujoCte">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                        <div class="row" *ngIf="!spinerFlujoCte">
                            <div class="col-11 mx-auto borderbott bordertop shadow bgflujocte">
                                <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                                    <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                                </h2>
                                <div class="table-responsive mb-0">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr class="thcenter">
                                                <th colspan="3" class="text-end">Totales:</th>
                                                <th>{{intTotalImportFCte|currency}}</th>
                                                <th>{{intCobradoFCte|currency}}</th>
                                                <th>{{IntSaldoFCte|currency}}</th>
                                            </tr>
                                            <tr class="fs12 thcenter">
                                                <th>Cliente</th>
                                                <th>Perido</th>
                                                <th>Ejercicio</th>
                                                <th>Importe Ventas</th>
                                                <th>Cobrado</th>
                                                <th>Saldo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="fs12 tdcenter"
                                                *ngFor="let o of arrayFlujocte | filtersearch: inputFlujoCte : ['intPeriodo','intEjercicio','strNombre','strCliente'];">
                                                <td><span
                                                        class="fw-bold">({{o.strCliente}})</span>{{o.strNombre}}
                                                </td>
                                                <td>{{o.intPeriodo}}</td>
                                                <td>{{o.intEjercicio}}</td>
                                                <td>{{o.dblImporte|currency}}</td>
                                                <td>{{o.dblCobrado|currency}}</td>
                                                <td>{{o.dblSaldo|currency}}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="!booltitl">
                            <div class="row" #pdftableFlujoCte>
                                <div class="row">
                                    <div class="col-11 mx-auto py-2 d-flex justify-content-center">
                                        <h2 class="mb-0 fw-bold text-center  mx-auto bgflujoctext">FLUJO DE
                                            CLIENTE</h2>
                                    </div>
                                </div>
                                <div class="col-11 mx-auto borderbott bordertop shadow bgflujocte">
                                    <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                                        <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                                    </h2>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr class="thcenter">
                                                    <th colspan="3" class="text-end">Totales:</th>
                                                    <th>{{intTotalImportFCte|currency}}</th>
                                                    <th>{{intCobradoFCte|currency}}</th>
                                                    <th>{{IntSaldoFCte|currency}}</th>
                                                </tr>
                                                <tr class="fs12 thcenter">
                                                    <th>Cliente</th>
                                                    <th>Perido</th>
                                                    <th>Ejercicio</th>
                                                    <th>Importe Ventas</th>
                                                    <th>Cobrado</th>
                                                    <th>Saldo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="fs12 tdcenter"
                                                    *ngFor="let o of arrayFlujocte | filtersearch: inputFlujoCte : ['intPeriodo','intEjercicio','strNombre','strCliente'];">
                                                    <td><span
                                                            class="fw-bold">({{o.strCliente}})</span>{{o.strNombre}}
                                                    </td>
                                                    <td>{{o.intPeriodo}}</td>
                                                    <td>{{o.intEjercicio}}</td>
                                                    <td>{{o.dblImporte|currency}}</td>
                                                    <td>{{o.dblCobrado|currency}}</td>
                                                    <td>{{o.dblSaldo|currency}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->
<!-- <mat-tab label="Vtas. Por Linea">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 text-center">
                                <h1 class="my-2">HISTORICO DE CLIENTE POR LINEA</h1>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-11 borderbott bordertop shadow-sm">
                                <div class="row">
                                    <div class="col-12 d-flex ">
                                        <h3 class="mx-auto fw-bold  mb-0 d-flex align-items-center">
                                            <i class="bi bi-person-circle me-2 fs-3"></i>
                                            <span class="d-flex flex-column">
                                                <span class="mx-auto">{{objVtasXlinea.strNombre}}</span>
                                                <span class="bg4 mx-auto">({{objVtasXlinea.strCliente}})</span>
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="row justify-content-evenly mb-3">
                                    <div class="col-11 col-md-5 bgtableinfoadd borderbott bordertop shadow">
                                        <h2 class="text-center mb-2">Estrellas</h2>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <span>
                                                    <i class="bi bi-star-fill text-warning fs-3"></i>
                                                    <i class="bi bi-star-fill text-warning fs-3"
                                                        *ngIf="objVtasXlinea.strClasificacionE==2 || objVtasXlinea.strClasificacionE==3"></i>
                                                    <i class="bi bi-star-fill text-warning fs-3"
                                                        *ngIf="objVtasXlinea.strClasificacionE==3"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row my-2">
                                            <div class="col-12 fs11 fw-bold">
                                                Catalogar al Cliente conforme a Potencial de Venta.
                                            </div>
                                            <div class="col-12 fs11">
                                                <i class="bi bi-star-fill text-warning fs10"></i> - No se le
                                                puede vender más de su potencial de venta.
                                            </div>
                                            <div class="col-12 fs11">
                                                <i class="bi bi-star-fill text-warning fs10"></i>
                                                <i class="bi bi-star-fill text-warning fs10"></i> - Venta
                                                superior al 70% de su potencial de venta.
                                            </div>
                                            <div class="col-12 fs11">
                                                <i class="bi bi-star-fill text-warning"></i>
                                                <i class="bi bi-star-fill text-warning"></i>
                                                <i class="bi bi-star-fill text-warning"></i> - Tiene buena
                                                capacidad de compra, hay que redoblar esfuerzos.
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="mt-3 mt-md-0 col-11 col-md-5 bgtableinfoadd borderbott bordertop shadow">
                                        <h2 class="text-center mb-2">Clasificación</h2>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <span class="text-primary fs-3 fw-bold">A</span>
                                                <span class="text-primary fs-3 fw-bold"
                                                    *ngIf="objVtasXlinea.strClasificacionA==2 || objVtasXlinea.strClasificacionA==3">A</span>
                                                <span class="text-primary fs-3 fw-bold"
                                                    *ngIf="objVtasXlinea.strClasificacionA==3">A</span>

                                            </div>
                                        </div>
                                        <div class="row my-2">
                                            <div class="col-12 fs11 fw-bold">
                                                Clasificación del Cliente conforme al monto de compra.
                                            </div>
                                            <div class="col-12 fs11">
                                                <span class="text-primary fw-bold fs12">A</span> - Monto de
                                                compra menor a $10,000
                                            </div>
                                            <div class="col-12 fs11">
                                                <span class="text-primary fw-bold fs12">A</span>
                                                <span class="text-primary fw-bold fs12">A</span> - Monto de
                                                compra mayor a $10,000 y menor a $50,000
                                            </div>
                                            <div class="col-12 fs11">
                                                <span class="text-primary fw-bold fs12">A</span>
                                                <span class="text-primary fw-bold fs12">A</span>
                                                <span class="text-primary fw-bold fs12">A</span> - Monto de
                                                compra mayor a $50,000
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bgtable py-2 ps-2 mx-auto mt-3 w-100 bordertop">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <mat-form-field class="bg-white rounded" appearance="outline">
                                        <mat-label>Buscar</mat-label>
                                        <input class="text-dark" matInput (keyup)="applyFilterhisxvta($event)"
                                            placeholder="Ingrese el dato a filtrar" #inputHisVe>
                                        <mat-icon matSuffix class="text-dark">search</mat-icon>
                                    </mat-form-field>
                                    <span class="fs14 fw-bold">Movimientos: {{arrayAjusteLineas.length}}</span>
                                </div>
                            </div>
                            <div class="row" *ngIf="spinerVtaXlin">
                                <div class="col-12 d-flex justify-content-center">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                            <div class="table-responsive mb-0  px-0" *ngIf="!spinerVtaXlin">
                                <div class="mat-elevation-z8">
                                    <table mat-table [dataSource]="dataHisXvta"
                                        class="table table-bordered table-hover">

                                        <ng-container matColumnDef="ejercicio">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top ">
                                                Ejercicio </th>
                                            <td mat-cell *matCellDef="let row;let i = index;"
                                                class=" align-middle ps-0 fs12 fw-bold text-center">
                                                {{row.strEjercicio}}</td>
                                            <td mat-footer-cell *matFooterCellDef> </td>
                                        </ng-container>

                                        <ng-container matColumnDef="periodo">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Periodo </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs12 fw-bold">{{row.strPeriodo}}
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef class="fw-bold fs15">Total:
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="acces">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Accesorios </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblAccesorios|currency}} </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblAccesorios|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenA">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{(row.dblAccesoriosMargen !== 0) ? (row.dblAccesoriosMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblAccesoriosMargen<50,'bg-warning':row.dblAccesoriosMargen >=50 && row.dblAccesoriosMargen<90,'bg-success':row.dblAccesoriosMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblAccesoriosMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblAccesoriosMargen !== 0) ?
                                                (objHisxLin.dblAccesoriosMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblAccesoriosMargen<50,'bg-warning':objHisxLin.dblAccesoriosMargen >=50 && objHisxLin.dblAccesoriosMargen<90,'bg-success':objHisxLin.dblAccesoriosMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblAccesoriosMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="kimberly">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top ">
                                                Kimberly </th>
                                            <td mat-cell *matCellDef="let row;let i = index;"
                                                class=" align-middle ps-0 fs11 fw-bold">
                                                {{row.dblKimberly|currency}}</td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblKimberly|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenk">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{(row.dblKimberlyMargen !== 0) ? (row.dblKimberlyMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblKimberlyMargen<50,'bg-warning':row.dblKimberlyMargen >=50 && row.dblKimberlyMargen<90,'bg-success':row.dblKimberlyMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblKimberlyMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblKimberlyMargen !== 0) ?
                                                (objHisxLin.dblKimberlyMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblKimberlyMargen<50,'bg-warning':objHisxLin.dblKimberlyMargen >=50 && objHisxLin.dblKimberlyMargen<90,'bg-success':objHisxLin.dblKimberlyMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblKimberlyMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="rubermaid">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Rubbermaid </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblRubbermaid|currency }}</td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblRubbermaid|currency }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenru">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{(row.dblRubbermaidMargen !== 0) ? (row.dblRubbermaidMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblRubbermaidMargen<50,'bg-warning':row.dblRubbermaidMargen >=50 && row.dblRubbermaidMargen<90,'bg-success':row.dblRubbermaidMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblRubbermaidMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblRubbermaidMargen !== 0) ?
                                                (objHisxLin.dblRubbermaidMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblRubbermaidMargen<50,'bg-warning':objHisxLin.dblRubbermaidMargen >=50 && objHisxLin.dblRubbermaidMargen<90,'bg-success':objHisxLin.dblRubbermaidMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblRubbermaidMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="bluetisue">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top ">
                                                BlueTissue </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblBlueTissue|currency}} </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblBlueTissue|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenblu">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row;let i = index;"
                                                class=" align-middle ps-0 fs11 fw-bold">
                                                {{(row.dblBlueTissueMargen !== 0) ? (row.dblBlueTissueMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblBlueTissueMargen<50,'bg-warning':row.dblBlueTissueMargen >=50 && row.dblBlueTissueMargen<90,'bg-success':row.dblBlueTissueMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblBlueTissueMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblBlueTissueMargen !== 0) ?
                                                (objHisxLin.dblBlueTissueMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblBlueTissueMargen<50,'bg-warning':objHisxLin.dblBlueTissueMargen >=50 && objHisxLin.dblBlueTissueMargen<90,'bg-success':objHisxLin.dblBlueTissueMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblBlueTissueMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="quimicos">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Quimicos </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblQuimicos|currency}}</td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblQuimicos|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenqu">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{(row.dblQuimicosMargen !== 0) ? (row.dblQuimicosMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblQuimicosMargen<50,'bg-warning':row.dblQuimicosMargen >=50 && row.dblQuimicosMargen<90,'bg-success':row.dblQuimicosMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblQuimicosMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblQuimicosMargen !== 0) ?
                                                (objHisxLin.dblQuimicosMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblQuimicosMargen<50,'bg-warning':objHisxLin.dblQuimicosMargen >=50 && objHisxLin.dblQuimicosMargen<90,'bg-success':objHisxLin.dblQuimicosMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblQuimicosMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="despachadores">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top ">
                                                Despachadores </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblDespachadores|currency}} </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblDespachadores|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margendes">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row;"
                                                class="text-center align-middle ps-0 fs11 fw-bold">
                                                {{(row.dblDespachadoresMargen !== 0) ?
                                                (row.dblDespachadoresMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblDespachadoresMargen<50,'bg-warning':row.dblDespachadoresMargen >=50 && row.dblDespachadoresMargen<90,'bg-success':row.dblDespachadoresMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblDespachadoresMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblDespachadoresMargen !== 0) ?
                                                (objHisxLin.dblDespachadoresMargen | number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblDespachadoresMargen<50,'bg-warning':objHisxLin.dblDespachadoresMargen >=50 && objHisxLin.dblDespachadoresMargen<90,'bg-success':objHisxLin.dblDespachadoresMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblDespachadoresMargen">
                                                    </div>
                                                </div>
                                            </td>

                                        </ng-container>

                                        <ng-container matColumnDef="tork">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Tork </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblTork|currency}}</td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblTork|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margentork">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{(row.dblTorkMargen !== 0) ? (row.dblTorkMargen |
                                                number:'1.2-2') : 0}}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblTorkMargen<50,'bg-warning':row.dblTorkMargen >=50 && row.dblTorkMargen<90,'bg-success':row.dblTorkMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100" [style.width.%]="row.dblTorkMargen">
                                                    </div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblTorkMargen !== 0) ? (objHisxLin.dblTorkMargen |
                                                number:'1.2-2') : 0}}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblTorkMargen<50,'bg-warning':objHisxLin.dblTorkMargen >=50 && objHisxLin.dblTorkMargen<90,'bg-success':objHisxLin.dblTorkMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblTorkMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="xibu">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top ">
                                                Xibu </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblXibu|currency}} </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblXibu|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenx">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row;"
                                                class="text-center align-middle ps-0 fs11 fw-bold">
                                                {{(row.dblXibuMargen !== 0) ? (row.dblXibuMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblXibuMargen<50,'bg-warning':row.dblXibuMargen >=50 && row.dblXibuMargen<90,'bg-success':row.dblXibuMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100" [style.width.%]="row.dblXibuMargen">
                                                    </div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblXibuMargen !== 0) ? (objHisxLin.dblXibuMargen |
                                                number:'1.2-2') : 0 }}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblXibuMargen<50,'bg-warning':objHisxLin.dblXibuMargen >=50 && objHisxLin.dblXibuMargen<90,'bg-success':objHisxLin.dblXibuMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblXibuMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="foaom">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Foaom </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{row.dblFoaom|currency}}</td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.dblFoaom|currency}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="margenf">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end border-top ">
                                                Margen </th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold">
                                                {{(row.dblFoaomMargen !== 0) ? (row.dblFoaomMargen |
                                                number:'1.2-2') : 0}}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':row.dblFoaomMargen<50,'bg-warning':row.dblFoaomMargen >=50 && row.dblFoaomMargen<90,'bg-success':row.dblFoaomMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="row.dblFoaomMargen"></div>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{(objHisxLin.dblFoaomMargen !== 0) ? (objHisxLin.dblFoaomMargen
                                                | number:'1.2-2') : 0}}%
                                                <div class="progress" style="height:6px">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        [ngClass]="{'bg-danger':objHisxLin.dblFoaomMargen<50,'bg-warning':objHisxLin.dblFoaomMargen >=50 && objHisxLin.dblFoaomMargen<90,'bg-success':objHisxLin.dblFoaomMargen>=90}"
                                                        role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        [style.width.%]="objHisxLin.dblFoaomMargen"></div>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="total">
                                            <th mat-header-cell *matHeaderCellDef
                                                class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top ">
                                                Total</th>
                                            <td mat-cell *matCellDef="let row"
                                                class="text-center align-middle fs11 fw-bold pe-0">
                                                {{row.total|currency}}
                                            <td>
                                            <td mat-footer-cell *matFooterCellDef
                                                class="text-center align-middle fs12 fw-bold">
                                                {{objHisxLin.total|currency}} </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayhisvtas" class="bgtable">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayhisvtas;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="displayhisvtas"
                                            class="bgtable borderbott"></tr>
                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="4" class="">No se encontro el dato:
                                                <span class="fw-bold">{{inputHisVe.value}}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->
<!-- <mat-tab label="Reporte de Vtas">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3>VENTA POR ARTICULO - {{objVtasXlinea.strNombre}}<span
                                        class="bg4 mx-auto">({{objVtasXlinea.strCliente}})</span></h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-md-flex justify-content-evenly">
                                <div class="col-12 col-md-5">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Linea</mat-label>
                                        <select matNativeControl [(ngModel)]="strLinea" name="car">
                                            <option *ngFor="let lin of arrayLineas" [value]="lin.value">
                                                {{lin.viewValue}}
                                            </option>
                                        </select>
                                        <mat-icon matSuffix class="text-dark">search</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-5 mt-4 mt-md-0">
                                    <mat-form-field class="bg-white rounded" appearance="outline">
                                        <mat-label>Buscar Articulo</mat-label>
                                        <input class="text-dark" matInput [(ngModel)]="strBuscar"
                                            placeholder="Ingrese el articulo">
                                        <mat-icon matSuffix class="text-dark">search</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="spinerVtaXart">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="!spinerVtaXart">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-bodered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th colspan="12"
                                                    *ngFor="let i of arrayCabeceraXart;let ind = index"
                                                    class="text-center"
                                                    [ngClass]="{'bgedocta1': ind % 2 === 0, 'bgst': ind % 2 !== 0}">
                                                    {{i.strEjercicio}}
                                                </th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Articulo</th>
                                                <th>Descripcion</th>
                                                <ng-container
                                                    *ngFor="let i of arrayCabeceraXart; let ind = index">
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Ene</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Feb</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Mar</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Abr</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        May</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Jun</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Jul</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Ago</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Sep</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Oct</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Nov</th>
                                                    <th
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Dic</th>
                                                </ng-container>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let f of arrayArts | filtersearch: strBuscar: ['strArticulo']|filtersearch: strLinea: ['strLinea']; let j = index">
                                                <td class="fs12 fw-bold  text-center align-middle "><span
                                                        class="p-1 bgtabHistory rounded">{{f.strArticulo}}</span>
                                                </td>
                                                <td class="fs10">{{f.strDescripcion}}</td>
                                                <ng-container
                                                    *ngFor="let i of arrayCabeceraXart; let ind = index">
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('ene' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['ene' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock>
                                                            <span class="text-secondary fs13 fw-bold">
                                                                0
                                                            </span>
                                                        </ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('feb' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['feb' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('mar' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['mar' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('abr' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['abr' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('may' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['may' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('jun' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['jun' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('jul' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['jul' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('ago' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['ago' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('sep' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['sep' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('oct' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['oct' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('nov' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['nov' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('dic' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">{{ f['dic' +
                                                            i.strEjercicio] }}</span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                </ng-container>
                                                <td class="align-middle text-center fw-bold">{{f.intTotal}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->

                <!-- Modal Editar Actividad -->
<div class="modal fade" id="mdlEditar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header p-2 bgtitModal">
            <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Editar {{ objEditarActividad.strTipo }}
                id: {{ objEditarActividad.intID_Actividad }}</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-5">
                    <div class="col-12">
                        Asunto
                    </div>
                    <div class="col-12">
                        <input type="text" placeholder="Agregue asunto" class="form-control"
                            [(ngModel)]="mdlNombre">
                    </div>
                </div>
                <div class="col-2">

                </div>
                <div class="col-5">
                    <div class="col-12">
                        Fecha
                    </div>
                    <div class="col-12">
                        <input type="date" class="form-control" [(ngModel)]="strfecha_editar">
                    </div>
                </div>

            </div>
            <div class="row my-3">
                <div class="col-12 col-md-5" *ngIf="objEditarActividad.strTipo  != 'Tarea'">
                    <div class="col-12">
                        Hora Inicio
                    </div>
                    <div class="col-12 d-flex">
                        <select class="form-select w-100" *ngIf="time1=='am'" aria-label="Default select example"
                            [(ngModel)]="mdlHoraInicio">
                            <option *ngFor="let x of arrayHora">
                                {{x.hora}}
                            </option>
                        </select>

                    </div>
                </div>
                <div class="col-12 col-md-2" *ngIf="objEditarActividad.strTipo != 'Tarea'">
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objEditarActividad.strTipo != 'Tarea'">
                    <div class="col-12">
                        Hora Fin
                    </div>
                    <div class="col-12 d-flex">
                        <select class="form-select w-100" *ngIf="time2=='am'" aria-label="Default select example"
                            [(ngModel)]="mdlHoraFin">
                            <option *ngFor="let x of arrayHora">
                                {{x.hora}}
                            </option>
                        </select>

                    </div>
                </div>
                <!--<div class="col-12 mt-3 mb-2 d-block">
                    <span>Agente</span> <br>
                    <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                        [(ngModel)]="strAgente">
                        <option value="undefined">-Selecciona un Agente- </option>
                        <option *ngFor="let item of  arrayAgentes_tareas; let i = index" value="{{item.strAgente}}">
                            {{item.strNombre}} <b *ngIf="item.strTipo !='Vendedor'">{{item.strTipo}}</b></option>
                    </select>
                </div>-->
                <div class="col-12 mb-3">
                    <mat-form-field class="example-full-width w-100">
                        <span matPrefix> </span>
                        <input type="text" class="px-3" matInput placeholder="Responsable"
                            (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                        <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                        <li class="list-group-item cursorPoint hver py-1 fs10"
                            *ngFor="let agt of arrayAgente_Filter" (click)="fnSeleccionaAgente(agt)">
                            {{agt.strNombre}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    Comentario
                </div>
                <div #editableDiv contenteditable="true" (input)="onInput($event)" class="editable-area"
                    style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;">
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarEdit>Cancelar</button>
            <button type="button" class="btn btn-primary"
                (click)="fnEditarActividad( objEditarActividad.intID_Actividad )">Editar</button>
        </div>
    </div>
</div>
</div>


<div class="modal fade" id="modalAddMinuta" tabindex="-1" aria-labelledby="modalAddMinutaLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bgtitModal py-2">
          <h2 class="modal-title mx-auto fw-bold mb-0 " id="modalAddMinutaLabel">Minuta</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close" #btnCerrarMinuta></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
                <h3 class="mb-0">
                    INFORMACIÓN MINUTA
                </h3>
            </div>
          </div>
          <form [formGroup]="formMinuta" (ngSubmit)="onSubmit()">
            <div class="row mt-4 justify-content-center">
                <!-- Título -->
                <div class="col-10 fw-bold">
                  <span class="text-danger">*</span> Título
                </div>
                <div class="col-10">
                    <input type="text" class="form-control" placeholder="Ingrese nombre de la minuta" formControlName="strTitulo">
                    <mat-error *ngIf="formMinuta.get('strTitulo').hasError('required')" class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                    </mat-error>
                </div>
            
                <!-- Fecha -->
                <div class="col-10 mt-3 fw-bold">
                    <span class="text-danger">*</span> Fecha
                </div>
                <div class="col-10">
                    <input type="date" class="form-control" formControlName="strFecha">
                    <mat-error *ngIf="formMinuta.get('strFecha').hasError('required') " class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                    </mat-error>
                </div>
            
                <!-- Hora -->
                <div class="col-10 mt-3 fw-bold">
                    <span class="text-danger">*</span> Hora Inicio
                </div>
                <div class="col-10">
                    <select class="form-select w-100" *ngIf="time1 == 'am'" formControlName="strHora" aria-label="Default select example">
                        <option *ngFor="let x of arrayHora">{{ x.hora }}</option>
                    </select>
                  <!-- Error para Hora -->
                    <mat-error *ngIf="formMinuta.get('strHora').hasError('required') " class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                    </mat-error>
                </div>
              </div>
            
              <!-- Botón de enviar -->
              <div class="row mt-3 justify-content-center">
                <div class="col-10">
                  <button type="submit"  class="btn btn-primary w-100" [disabled]="!formMinuta || formMinuta.invalid">Enviar</button>
                </div>
              </div>
        </form>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary ms-auto">Guardar</button>
        </div> -->
      </div>
    </div>
  </div>
