import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import { FiltrosService } from '../servicios/filtros.service';
import { Router } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
declare var $: any;

@Component({
  selector: 'app-tablero-leads-oportunidades',
  templateUrl: './tablero-leads-oportunidades.component.html',
  styleUrls: ['./tablero-leads-oportunidades.component.css']
})
export class TableroLeadsOportunidadesComponent implements OnInit {
  public calendarOptions: any; @ViewChild('calendar', { static: true }) calendar: any;

  @ViewChild('btncerrarFiltros') btncerrarFiltros!: ElementRef;

  public strAgente_Crea: string;
  public objDetActividad: any;
  public activeButton: string = 'cards'
  public banderaCards: boolean = true
  public banderaLista: boolean = false
  public banderaCalendario: boolean = false
  public myForm: FormGroup;
  public strClasificacion: string = "CCC"
  public arrayGiros: any = [];
  public strConexion: string = ''
  public strCodigoPostal: string;
  public strCalle: string = '';
  public strEstado: string = '';
  public strNumeroExterior: string = '';
  public strDelegacion: string = '';
  public strEntreCalles: string = '';
  public arrayColonias: any = [];
  public arrayPresencia: any = [];
  public arrayTamanoEmpresa: any = [];
  public intID_Actividad: any
  public arrayUbicaciones: any = [];
  public strAgenteSP: string = ''
  public strAgente: string = ''
  public array_por_contactar: any = []
  public intTotal_por_contactar: number = 0
  public boolGerente: boolean = false;
  public boolSucursal: boolean = false;
  public boolAgente: boolean = false;
  public strGerente: string = "";
  public arrayGerentes: any = [];
  public arrayEjercicio: any = [];
  public arrayPeriodo: any = [];
  public objAct: any = [];
  public arrayAgentes: any = [];
  public arraySucursales: any = [];
  public strSucursal: string = "";
  public arrayAccesos: any = [];
  public strSucursal_modal: string = "";
  public intPeriodo: number;
  public strResultado: any = '';
  public strGerente_modal: string = "";
  public searchText: any = ''
  public tagName: any;
  public strTituloActividad: any
  public intEjercicio: number;
  public isHoveredButton: boolean = false;
  public filterPost: string = ''
  public arrayPresentacion: any = []
  public intTotal_presentacion: number = 0
  public arrayRecorrido: any = []
  public intTotal_recorrido_lead: number = 0
  public arrayPropuesta: any = []
  public intTotal_propuesta: number = 0
  public arrayNegociacion: any = []
  public intTotal_negociacion: number = 0
  public arrayPerdidos: any = []
  public intTotal_Perdido: number = 0
  public arrayGanados: any = []
  public intTotal_ganados: number = 0
  public intIDLeads: number = 0
  public arrayFechas: any = []
  public contenido: string = '';
  public arrayListaActividades: any = [];
  public arrayAsigContacto1: any = [];
  public arrayAsigContacto2: any = [];
  public arrayAsigContacto3: any = [];
  public strBuscarContacto: any = '';
  public intID_Contacto: number = 0;
  public sugerenciasContacto:any = [];
  public strContacto_Nombre: string = ''
  public strContacto_Telefono: string = ''
  public strContacto_Email: string = ''
  public strContacto_Puesto: string = ''
  public arraySubGiro: any = []
  public arraySubGiroTotal: any = []
  public arraySectores: any = []
  public arrayPreguntaCategoria: any = []
  public arraySegundaLetra: any = []
  public arrayFitroSegunda: any = []
  public arrayTercerLetra: any = []
  public arrayFiltroTercera: any = []
  public intPotenciador: number = 0
  public arrayLineas: any = []
  public segunda: any = ''
  public tercera: any = ''
  public idGiro: any = 0

  checkboxes: any = {
    hoy: false,
    manana: false,
    estaSemana: false,
    esteMes: false,
    futuros: false,
    conDemora: false,
  };
  @ViewChild('scrollbarTop') scrollbarTop!: ElementRef;
  @ViewChild('scrollContent') scrollContent!: ElementRef;
  constructor(private indexedDBService: DataDBService, public _serviciosService: PeticionesService, public localSt: LocalStorageService, private formbuilder: FormBuilder, private _filtroServ: FiltrosService, private router: Router, public elem: ElementRef) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgenteSP = this.localSt.retrieve('strAgente');

    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];

    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;

    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;

        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.strSucursal_modal = this.strSucursal;

        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;

        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.strGerente_modal = this.strGerente;
      }
      this.fnIniciarForm()
    }
  }

  ngOnInit(): void { 
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.fnGetLead_All()
    this.fngetGiros();
    this.fnGetFiltros();
    this.fngetRangoEmpleados();
    this.fngetPresencia();
    this.fngetUbicaciones();
    this.fnGetSubGiro();
    this.fngetSegundaLetra();
    this.fngetTerceraLetra();
    this.fngetLineas();
    
    });
  }

  ngAfterViewInit(): void {
    this.fnScroll();

    $('#summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']]
      ]
    })

  }


  fnScroll() {
    const scrollContentEl = this.scrollContent.nativeElement;
    const scrollbarTopEl = this.scrollbarTop.nativeElement;

    // Crear un div vacío para servir como contenido dentro de la barra de desplazamiento superior
    const scrollbarInnerEl = document.createElement('div');
    scrollbarInnerEl.style.width = `${scrollContentEl.scrollWidth}px`;
    scrollbarInnerEl.style.height = '1px'; // Una altura mínima para que el div sea visible en el DOM
    scrollbarTopEl.appendChild(scrollbarInnerEl);

    // Event listeners para sincronizar el desplazamiento
    scrollbarTopEl.addEventListener('scroll', () => {
      if (scrollContentEl.scrollLeft !== scrollbarTopEl.scrollLeft) {
        scrollContentEl.scrollLeft = scrollbarTopEl.scrollLeft;
      }
    });

    scrollContentEl.addEventListener('scroll', () => {
      if (scrollbarTopEl.scrollLeft !== scrollContentEl.scrollLeft) {
        scrollbarTopEl.scrollLeft = scrollContentEl.scrollLeft;
      }
    });

    // Inicialmente igualamos el scroll para asegurarnos de que estén sincronizados
    scrollbarTopEl.scrollLeft = scrollContentEl.scrollLeft;

    // Si el contenido cambia de tamaño, actualizamos el ancho del scrollbarInnerEl
    const observer = new ResizeObserver(() => {
      scrollbarInnerEl.style.width = `${scrollContentEl.scrollWidth}px`;
    });
    observer.observe(scrollContentEl);

  }

  fnGetPorContactar() {
    this.array_por_contactar = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'POR CONTACTAR',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.array_por_contactar = result
        this.intTotal_por_contactar = this.array_por_contactar.length
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetPresentacion() {
    this.arrayPresentacion = []

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'PRESENTACION',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayPresentacion = result
        this.intTotal_presentacion = this.arrayPresentacion.length
      },
      error => {

        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetRecorrido() {
    this.arrayRecorrido = []

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'RECORRIDO',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayRecorrido = result
        this.intTotal_recorrido_lead = this.arrayRecorrido.length

      },
      error => {
        // this.fnGetPropuesta()
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetPropuesta() {
    this.arrayPropuesta = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'PROPUESTA ECONOMICA',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayPropuesta = result
        this.intTotal_propuesta = this.arrayPropuesta.length

      },
      error => {
        var error = <any>error;
        console.log(error);

      }
    );
  }

  fnGetNegociacion() {
    this.arrayNegociacion = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'NEGOCIACION',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayNegociacion = result
        this.intTotal_negociacion = this.arrayNegociacion.length
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetGanados() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'GANADO',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayGanados = result
        this.intTotal_ganados = this.arrayGanados.length
      },
      error => {

        var error = <any>error;
        console.log(error);

      }
    );
  }

  fnGetPerdidos() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'PERDIDO',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPerdidos = result
        this.intTotal_Perdido = this.arrayPerdidos.length
        this.fnScroll();
        //this.arrayCalendario.push(...this.arrayPerdidos.filter((b: any) => b.strFechaActividad));
      },
      error => {
        this.fnScroll();
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetLead_All() {
    this.fnGetPorContactar()
    this.fnGetPresentacion()
    this.fnGetRecorrido()
    this.fnGetPropuesta()
    this.fnGetNegociacion()
    this.fnGetGanados()
    this.fnGetPerdidos()
    this.fngetSectores()
    this.fnGetListActividades()

  }

  onCheckboxChange(checkboxName: string) {
    Object.keys(this.checkboxes).forEach((name) => {
      if (name !== checkboxName) {
        this.checkboxes[name] = false;
      }
    });

    this.checkboxes[checkboxName] = !this.checkboxes[checkboxName];

    this.aplicarFiltros();
  }

  aplicarFiltros() { }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  cambiarVista(vista: any) {
    this.activeButton = vista
    if (vista == 'cards') {
      this.banderaCards = true
      this.banderaLista = false
      this.banderaCalendario = false
    } else if (vista == 'lista') {
      this.banderaCards = false
      this.banderaLista = true
      this.banderaCalendario = false
    } else {
      this.banderaCards = false
      this.banderaLista = false
      this.banderaCalendario = true
    }
  }

  onSave(): void {
    let rest: any = [];
    let objForm: any;
    let potencializador = ''
    let consentradoPotencializador = this.arrayLineas.filter((b: any) => b.Seleccionado == true)
    if (consentradoPotencializador.length > 0) {
      consentradoPotencializador.forEach(element => {
        potencializador += (element.strLinea) + ','
      });
    }
    this.myForm.value.strPotencializador = potencializador
    objForm = this.myForm.value;
    console.log(objForm)
    this._serviciosService.mostrarCotizaciones(objForm, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        rest = result

        Swal.fire({
          icon: rest[0].code == '200' ? 'success' : 'error',
          title: rest[0].code == '200' ? 'Enviado' : 'Error',
          text: rest[0].strMensaje,
        });
        if (rest[0].code == '200') {
          this.fnGetPorContactar();
          this.fnIniciarForm();
          //this.btnCerrarMdl.nativeElement.click();
        }
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    ); 
  }

  onGiroChange(event: any, tipo: string) {
    let clasificacion = event.target.value.split(',')[1];

    if (tipo === 'presencia') {
      this.strClasificacion = clasificacion + this.strClasificacion.substring(1);
    } else if (tipo === 'sucursales') {
      this.strClasificacion = this.strClasificacion.charAt(0) + clasificacion + this.strClasificacion.substring(2);
    } else {
      this.strClasificacion = this.strClasificacion.substring(0, 2) + clasificacion;
    }

  }

  fngetGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        this.arrayGiros = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetSectores() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSector',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        this.arraySectores = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  onKeyDown(event: KeyboardEvent) {

    // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
    const ENTER_KEY = 13;
    // Verificar si se presionó la tecla Delete o Retroceso
    if (event.keyCode === ENTER_KEY) {
      this.fnGetDatosFiscales();
    } else {
      this.myForm.controls['strEstado'].setValue('');
      this.myForm.controls['strDelegacion'].setValue('');
    }
  }

  blurevent() {
    this.fnGetDatosFiscales();
  }

  fnGetDatosFiscales() {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: (this.strCodigoPostal) ? this.strCodigoPostal : this.myForm.controls['strCodigoPostal'].value
    }).subscribe(
      result => {
        if (result.length > 0) {
          if (this.strCodigoPostal) {
            this.strEstado = result[0].strEstado
            this.strDelegacion = result[0].strDelegacion
            this.arrayColonias = result;
          } else {
            this.myForm.controls['strEstado'].setValue(result[0].strEstado);
            this.myForm.controls['strDelegacion'].setValue(result[0].strDelegacion);
            this.arrayColonias = result;
          }


        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });
          this.myForm.controls['strEstado'].setValue('');
          this.myForm.controls['strDelegacion'].setValue('');
          this.arrayColonias = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  validarNumeros(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
    }
  }

  fngetPresencia() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getPresencia',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        this.arrayPresencia = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetRangoEmpleados() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getRangoEmpleados',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayTamanoEmpresa = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetUbicaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUbicaciones',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayUbicaciones = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  // Método para verificar si el formulario es válido
  isFormValid(): boolean {
    return this.myForm.valid;
  }

  fnDetalleLead(item: any, estatus: any) {
    let strCliente = item.strCliente
    this.intIDLeads = item.intIdLeads

    if (item.intRedireccion == 1) {
      //guardamos en el servicio de indexed db la ruta
      let objRuta_Anterior = {
        ruta_origen: '/tableroLeads',
        ruta_destino: '/detalleLead',
        nombre: 'Tablero Leads'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);

      //redireccionamiento a detalle lead
      this.router.navigate(['/detalleLead'], { queryParams: { idLead: this.intIDLeads } });

    } else if (item.intRedireccion == 2) {
      let objRuta_Anterior = {
        ruta_origen: '/tableroLeads',
        ruta_destino: '/detalleLeadFamilia',
        nombre: 'Tablero Leads'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);
      //redireccionamiento a detalle familia
      this.router.navigate(['/detalleLeadFamilia'], { queryParams: { idFamilia: item.intID_Familia, strFamilia: item.strFamilia } });

    } else if (item.intRedireccion == 3) {
      let objRuta_Anterior = {
        ruta_origen: '/tableroLeads',
        ruta_destino: '/detalleLeadCliente',
        nombre: 'Tablero Leads'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);

      //redireccionamiento a detalle cliente
      this.router.navigate(['/detalleLeadCliente'], { queryParams: { strCliente: item.strCliente } });

    } else if (item.intRedireccion == 4) {
      let objRuta_Anterior = {
        ruta_origen: '/tableroLeads',
        ruta_destino: '/detoport',
        nombre: 'Tablero Leads'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);
      //redireccionamiento a detalle oportunidad
      this.router.navigate(['/detoport'], { queryParams: { idLead: this.intIDLeads, sts: estatus } });

    } else if (item.intRedireccion == 5) {
      let objRuta_Anterior = {
        ruta_origen: '/tableroLeads',
        ruta_destino: '/detalleLeadSucursal',
        nombre: 'Tablero Leads'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);
      //redireccionamiento a detalle sucursal
      this.router.navigate(['/detalleLeadSucursal'], { queryParams: { intId_Direccion: item.intId_Direccion, strCliente: strCliente } });
    } else { }
  }

  fnGetListActividades() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getListado_Actividades',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayListaActividades = []
        let Lista: any = []
        Lista = result
        if (result.length > 0) {
          let fechasMap = new Map<string, any[]>();
          Lista.forEach((element: any) => {
            let fecha = (element.strFechaRequerida) ? (element.strFechaRequerida).split(' ')[0] : '';
            if (!fechasMap.has(fecha)) {
              fechasMap.set(fecha, []);
              this.arrayFechas.push(fecha);
            }
            fechasMap.get(fecha).push(element);
          });

          fechasMap.forEach((arregloAux: any[], seccion: any, index: any) => {
            let actividades = result.filter((b: any) => (b.strFechaRequerida).split(' ')[0] == seccion)
            this.arrayListaActividades.push({
              fecha: seccion,
              arrayActividades: actividades
            });
          });

          this.btncerrarFiltros.nativeElement.click();
        } else { }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  getDayOfWeek(dateString: any) {
    const daysOfWeek = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

    const [month, day, year] = dateString.split("/");
    const date = new Date(`${year}-${month}-${day}`);

    return daysOfWeek[date.getDay()];
  }

  fnGetDetActi(objAct: any) {

    this.intID_Actividad = objAct.intID_Actividad
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result.length > 0) {
          this.objDetActividad = result[0];

        }

      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnConcluirActividad() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad',
      intID_Actividad: this.objAct.intID_Actividad,
      intID_Leads: this.objAct.intIDLeads,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.fnGetListActividades()
        } else {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnmDLConAct(obj: any) {
    this.objDetActividad = {};
    this.objDetActividad = obj;
    this.strTituloActividad = obj.strNombreEmpresa
    this.fnInitSummer()
    this.objAct = obj
    if (obj.strEstatus == "CONCLUIDO") {
      this.fnGetDetActi(obj);
    }
  }

  fnInitSummer() {
    $('#summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']]
      ]
    })
  }

  fnBuscarContacto1(event: Event): void {
    const input = event.target as HTMLInputElement;
    const valor = input.value.toLowerCase().trim();
    if (valor.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getContacto_Busqueda',
        strResultado: valor,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia').subscribe(
        result => {
          this.arrayAsigContacto1 = result
         
          this.sugerenciasContacto = this.arrayAsigContacto1
          .filter(c => c.strContacto_Nombre && c.strContacto_Nombre.toLowerCase().includes(valor))
          .slice(0, 15);
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigContacto1 = [];
    }
    if (valor.length === 0) {
      this.sugerenciasContacto = [];
      return;
    }
  }

  /*   fnBuscarCont(event: Event): void {
    const input = event.target as HTMLInputElement;
    const valor = input.value.toLowerCase().trim();
    console.log(valor);
  
    if (valor.length === 0) {
      this.sugerenciasContacto = [];
      return;
    }
  
    this.sugerenciasContacto = this.arrayAsigContacto1
      .filter(c => c.strContacto_Nombre && c.strContacto_Nombre.toLowerCase().includes(valor))
      .slice(0, 15);
  
    console.log(this.sugerenciasContacto);
  } */

  fnBuscarContacto2(event: KeyboardEvent): void {
    var inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getContacto_Busqueda',
        strResultado: inputValue,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia').subscribe(
        result => {
          this.arrayAsigContacto2 = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigContacto2 = [];
    }
  }

  fnBuscarContacto3(event: KeyboardEvent): void {
    var inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getContacto_Busqueda',
        strResultado: inputValue,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia').subscribe(
        result => {
          this.arrayAsigContacto3 = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigContacto3 = [];
    }
  }

  fnselecContacto(objCont: any) {
    this.strBuscarContacto = '';
    this.myForm.controls['intID_Contacto'].setValue(objCont.intID_Contacto);
    this.myForm.controls['strContacto_Nombre'].setValue(objCont.strContacto_Nombre);
    this.myForm.controls['strContacto_Telefono'].setValue(objCont.strContacto_Telefono);
    this.myForm.controls['strContacto_Email'].setValue(objCont.strContacto_Email);
    this.myForm.controls['strContacto_Puesto'].setValue(objCont.strContacto_Puesto);

    this.myForm.get('strContacto_Nombre').disable();
    this.myForm.get('strContacto_Puesto').disable();
    this.myForm.get('strContacto_Telefono').disable();
    this.myForm.get('strContacto_Email').disable();

    this.arrayAsigContacto1 = [];
    this.arrayAsigContacto2 = [];
    this.arrayAsigContacto3 = [];
  }

  resetForm() {
    this.myForm.controls['intID_Contacto'].setValue(0);
    this.myForm.controls['strContacto_Nombre'].setValue('');
    this.myForm.controls['strContacto_Telefono'].setValue('');
    this.myForm.controls['strContacto_Email'].setValue('');
    this.myForm.controls['strContacto_Puesto'].setValue('');
    this.arrayAsigContacto1 = [];
    this.arrayAsigContacto2 = [];
    this.arrayAsigContacto3 = [];

    this.myForm.get('strContacto_Nombre').enable();
    this.myForm.get('strContacto_Puesto').enable();
    this.myForm.get('strContacto_Telefono').enable();
    this.myForm.get('strContacto_Email').enable();
  }


  fnRechazar(objAct) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Rechazar',
      intID_Actividad: this.objAct.intID_Actividad,
      intID_Leads: this.objAct.intIDLeads,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.fnGetListActividades()
        } else {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnAutorizar(objAct) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Autorizar',
      intID_Actividad: this.objAct.intID_Actividad,
      intID_Leads: this.objAct.intIDLeads,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.fnGetListActividades()
        } else {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );

  }
  fnEnProceso(objAct) {
  }

  fnPreguntaEliminar(idOportunidad: any) {

    let id = idOportunidad.intIdLeads
    Swal.fire({
      title: "Seguro que desea eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.fnEliminarOportunidad(id)
      }
    });
  }

  fnEliminarOportunidad(IDOportunidad: any) {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'Update_Eliminado',
      strEstatus: 'ELIMINADO',
      intID_Leads: IDOportunidad,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        Swal.fire({
          title: "Elemento Eliminado!",
          icon: "success"
        });
        this.fnGetPerdidos()
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetSubGiro() {
    this.arraySubGiroTotal = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSubGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySubGiroTotal = result
      },
      error => {

        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetCategoriaPregunta() {
    this.arrayPreguntaCategoria = []

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getCategoriaPregunta',
      intId_Giro: this.myForm.value.intId_Giro,
      intIDPresencia: this.myForm.value.intIDPresencia,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result.length > 0) {
          result.forEach((element: any) => {
            // Verificar si element.strRespuesta tiene un valor y luego dividir y mapear
            let respuestas = element.strRespuesta
              ? element.strRespuesta.split(',').map((respuesta: string) => {
                return {
                  respuesta: respuesta.trim(),
                  Seleccionado: false
                };
              })
              : [];

            let data = {
              strPregunta: element.strPregunta,
              strTipo: element.strTipo,
              Respuestas: respuestas,
              ngModel: ''
            };

            this.arrayPreguntaCategoria.push(data);
          });
        }

      },
      error => {

        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnFiltroSubGiro(idGiro: any) {
    this.arraySubGiro = []
  }

  /* fnFiltrarSegundaLetra() {
    this.arraySubGiro = this.arraySubGiroTotal.filter((b: any) => b.intId_Giro == this.myForm.value.intId_Giro)
    this.arrayFitroSegunda = this.arraySegundaLetra.filter((b: any) => b.intIDPresencia == this.myForm.value.intIDPresencia && b.intId_Giro == this.myForm.value.intId_Giro)
    this.arrayFiltroTercera = this.arrayTercerLetra.filter((b: any) => b.intIDPresencia == this.myForm.value.intIDPresencia && b.intId_Giro == this.myForm.value.intId_Giro)
    console.log(this.arrayFitroSegunda)
    const potenciador = this.arrayGiros.filter((b:any)=> b.intId_Giro == this.myForm.value.intId_Giro)
    this.intPotenciador = potenciador ? potenciador[0].intPotenciador : 0;
  } */

  toggleMultiple(linea: any) {

    this.arrayLineas.forEach(e => {
      if (linea == e.strLinea) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });
    console.log(this.arrayLineas)
  }

  fngetSegundaLetra() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSegundaLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        this.arraySegundaLetra = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fngetTerceraLetra() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getTerceraLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        this.arrayTercerLetra = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetLineas() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLineas',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        if(result.length > 0){
          result.forEach((element: any) => {
            let data = {
              Seleccionado : false,
              strLinea: element.strLinea,
              intID_linea: element.intID_linea
            }
            this.arrayLineas.push(data)
          });
        }
        
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnIniciarForm(){
    this.myForm = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strNombreCorto: new FormControl('', [Validators.maxLength(60),  Validators.required]),
      strSector: new FormControl('', [Validators.required]),
      intId_Giro: new FormControl(0, [Validators.required]),
      intId_subGiro: new FormControl(0, []),
     /*  strCodigoPostal: new FormControl('', [Validators.maxLength(5), Validators.required]),
      strDelegacion: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strEstado: new FormControl('', [Validators.maxLength(100), Validators.required]), */
      intIDPresencia: new FormControl(0, [Validators.required]),
      intid_Ubicacion: new FormControl('', []),
      intId_segunda: new FormControl(0, []),
      intId_tercera: new FormControl(0, []),
      intID_Contacto: new FormControl(0, []),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50)]),/* , Validators.required */
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50)]),//, Validators.required
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]), //, Validators.required
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50)]), //, Validators.required
      strEstatus: new FormControl('POR CONTACTAR', []),
      strPotencializador: new FormControl('', []),
      strAccion: new FormControl('setLeads', []),
      strConexion: new FormControl(this.strConexion, []),
      strAgente: new FormControl(this.strAgenteSP, []),
    });
  }
}
